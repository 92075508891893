<template>
  <div class="supply-chain">
    <div class="supply-chain__header">Supply Chain</div>

    <!-- Map Section -->
    <div class="map-section supply-chain__map-section">
      <suppliers-map class="map__map" :countries="countries" :data="coordinates" />
    </div>

    <!-- Supplies List -->
    <div class="supplies-list supply-chain__supplies-list">
      <scrollable-block class="supplies-list__list-in-wrap">
        <div v-for="supplier in suppliers" :key="supplier.name" class="single-supply supplies-list__single-supply">
          <div class="single-supply__image-container">
            <img
              class="single-supply__image"
              :src="imagePath(`images/${supplier.coverPhoto}`, 960, 250)"
              :alt="supplier.name"
            />
          </div>

          <div class="single-supply__title">{{ supplier.name }}</div>
        </div>
      </scrollable-block>
    </div>
  </div>
</template>

<script>
import { imagePath } from '@lib/imgproxy';
import uniq from 'lodash/uniq';

// Components
const SuppliersMap = () => import('./../../components/map/suppliers-map.vue');
import ScrollableBlock from '@components/scrollable-block.vue';

export default {
  props: {
    suppliers: Array,
  },
  components: { SuppliersMap, ScrollableBlock },
  data() {
    return {};
  },
  methods: { imagePath },
  computed: {
    requestUrl() {
      let url = `/api/suppliers?category=0`;
      let urlQuery = this.$route.fullPath.split('?')[1];
      if (urlQuery) url += `&${urlQuery}`;
      return url;
    },
    countries() {
      return uniq(this.suppliers.map((s) => s.country));
    },
    coordinates() {
      return this.suppliers
        .filter((supplier) => Boolean(supplier.coordinates))
        .map((supplier) => {
          return {
            id: supplier.id,
            latitude: supplier.coordinates.split(', ')[0],
            longitude: supplier.coordinates.split(', ')[1],
          };
        });
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

// ============================================================================
// ============================================================================
// Map Section
.map-section {
}

// ============================================================================
// ============================================================================
// Supplies List
.supplies-list {
  position: relative;
  overflow: hidden;
  padding: 20px 10px 20px 55px;

  &__list-in-wrap {
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
    box-sizing: border-box;
  }

  .single-supply {
    width: calc(100% - 15px);
    display: flex;
    align-items: center;
    align-content: center;

    &__image-container {
      width: math.div(7rem, 1.6);
      height: math.div(7rem, 1.6);
      margin-right: math.div(1.5rem, 1.6);
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__title {
    }
  }

  &__single-supply {
    margin-bottom: math.div(1.6rem, 1.6);

    &:first-child {
      margin-top: 20px;
    }
    &:last-child {
      margin-bottom: 20px;
    }
  }
}

// ============================================================================
// ============================================================================
// Supply Chain
.supply-chain {
  display: flex;
  flex-wrap: wrap;

  &__header {
    @include heading--small;
    width: 100%;
    margin-bottom: math.div(2.4rem, 1.6);
    text-align: center;
  }

  &__map-section {
    width: calc(100% - #{math.div(34rem, 1.6)});
    height: math.div(38.5rem, 1.6);
    display: flex;
    position: relative;
    overflow: hidden;
    background: #f3f3f3;
  }

  &__supplies-list {
    width: math.div(34rem, 1.6);
    height: math.div(38.5rem, 1.6);
    box-sizing: border-box;
    border: 1px rgba(53, 73, 94, 0.1) solid;
  }
}

@media (max-width: $desktop-width) {
  .supply-chain {
    &__map-section {
      width: 100%;
    }

    &__supplies-list {
      width: 100%;
      height: math.div(30rem, 1.6);
    }
  }
}
</style>
