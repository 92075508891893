var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "footwear-all-units tabs__tab-content",
      attrs: { id: "footwear-all-units" },
    },
    [
      _c("h3", { staticClass: "content-header" }, [
        _vm._v("Our Footwear Production"),
      ]),
      _vm._v(" "),
      _c("company-in-brief", { attrs: { supplier: _vm.supplier } }),
      _vm._v(" "),
      _c("verified-by-fs", { attrs: { supplier: _vm.supplier } }),
      _vm._v(" "),
      _c("sustainability-rating-section", {
        attrs: { "desktop-hidden": "", supplier: _vm.supplier },
      }),
      _vm._v(" "),
      _c("brands-working-with", { attrs: { supplier: _vm.supplier } }),
      _vm._v(" "),
      _c("div", { staticClass: "manufacturing-capabilities" }, [
        _vm.supplier.footwearTypes && _vm.supplier.footwearTypes.length
          ? _c("div", { staticClass: "manufacturing-capabilities__group" }, [
              _c("h4", { staticClass: "manufacturing-capabilities__header" }, [
                _vm._v("Footwear Types"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footwear-types" },
                [
                  _vm._l(_vm.supplier.footwearTypes, function (article, index) {
                    return [
                      _c(
                        "a",
                        {
                          key: article.id + "-type",
                          staticClass: "footwear-type",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$refs["type-popper"][index].toggle(
                                $event
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "footwear-type__icon-container" },
                            [
                              _c("span", {
                                staticClass: "footwear-type__icon",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.svg.footwearTypes[
                                      _vm.camelCase(article.slug)
                                    ]
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "footwear-type__label" }, [
                            _vm._v(_vm._s(article.title)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "fs-popper",
                        {
                          key: article.id + "-popper",
                          ref: "type-popper",
                          refInFor: true,
                          attrs: {
                            animation: "fade",
                            arrow: "",
                            modifiers: [
                              { name: "flip", enabled: false },
                              {
                                name: "preventOverflow",
                                options: { padding: 16 },
                              },
                            ],
                            offset: [0, 30],
                            placement: "top",
                            "wrapper-class": "popper-wrapper supplierProfile",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "popover-close",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fsPopperClose($event)
                                },
                              },
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "supplier-popover" }, [
                            _c(
                              "h5",
                              { staticClass: "supplier-popover__header" },
                              [_vm._v(_vm._s(article.title))]
                            ),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "supplier-popover__icon",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.svg.footwearTypesNoWhitespace[
                                    _vm.camelCase(article.slug)
                                  ]
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "supplier-popover__text-container",
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "supplier-popover__text" },
                                  [_vm._v(_vm._s(article.description || "-"))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "supplier-popover__read-more link",
                                    attrs: {
                                      to: {
                                        name: "article",
                                        params: {
                                          category: article.categorySlug,
                                          slug: article.slug,
                                        },
                                      },
                                    },
                                  },
                                  [_vm._v("Read more")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            false
                              ? _c("span", {
                                  staticClass: "supplier-popover__line",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            false
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "supplier-popover__sub-header",
                                  },
                                  [_vm._v("Processes used")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            false
                              ? _c("div", { staticClass: "sp-tags" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "sp-tags__tag link link--dark",
                                      attrs: { href: "#" },
                                    },
                                    [_vm._v("Vulcanization")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "sp-tags__tag link link--dark",
                                      attrs: { href: "#" },
                                    },
                                    [_vm._v("Moccasins")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "sp-tags__tag link link--dark",
                                      attrs: { href: "#" },
                                    },
                                    [_vm._v("Cementing")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            false
                              ? _c("div", { staticClass: "sp-field" }, [
                                  _c(
                                    "span",
                                    { staticClass: "sp-field__label" },
                                    [_vm._v("Minimum Order Quantity")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "sp-field__value" },
                                    [_vm._v("50 Pairs")]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.supplier.processes && _vm.supplier.processes.length
          ? _c("div", { staticClass: "manufacturing-capabilities__group" }, [
              _c("h4", { staticClass: "manufacturing-capabilities__header" }, [
                _vm._v("Processes"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "processes" },
                [
                  _vm._l(_vm.supplier.processes, function (article, index) {
                    return [
                      _c(
                        "fs-pill",
                        {
                          key: article.id + "-pill",
                          staticClass: "processes__pill",
                          attrs: {
                            size: "extra-large",
                            outlined: "",
                            fixed: false,
                            tag: "a",
                            href: "#",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$refs["process-popper"][index].toggle(
                                $event
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(article.title))]
                      ),
                      _vm._v(" "),
                      _c(
                        "fs-popper",
                        {
                          key: article.id + "-popper",
                          ref: "process-popper",
                          refInFor: true,
                          attrs: {
                            animation: "fade",
                            arrow: "",
                            modifiers: [
                              { name: "flip", enabled: false },
                              {
                                name: "preventOverflow",
                                options: { padding: 16 },
                              },
                            ],
                            offset: [0, 30],
                            placement: "top",
                            "wrapper-class": "popper-wrapper supplierProfile",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "popover-close",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fsPopperClose($event)
                                },
                              },
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "supplier-popover" }, [
                            _c(
                              "h5",
                              { staticClass: "supplier-popover__header" },
                              [_vm._v(_vm._s(article.title))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "supplier-popover__text-container",
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "supplier-popover__text" },
                                  [_vm._v(_vm._s(article.description || "-"))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "supplier-popover__read-more link",
                                    attrs: {
                                      to: {
                                        name: "article",
                                        params: {
                                          category: article.categorySlug,
                                          slug: article.slug,
                                        },
                                      },
                                    },
                                  },
                                  [_vm._v("Read more")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.supplier.lastingMethods && _vm.supplier.lastingMethods.length
                ? _c(
                    "h4",
                    { staticClass: "manufacturing-capabilities__header" },
                    [_vm._v("\n        Lasting Methods\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.supplier.lastingMethods && _vm.supplier.lastingMethods.length
                ? _c(
                    "div",
                    { staticClass: "processes" },
                    _vm._l(_vm.supplier.lastingMethods, function (method) {
                      return _c(
                        "fs-pill",
                        {
                          key: method.id,
                          staticClass: "processes__pill",
                          attrs: {
                            size: "extra-large",
                            outlined: "",
                            fixed: false,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(method.title) + "\n        "
                          ),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "manufacturing-quick-facts" }, [
        _c("div", { staticClass: "manufacturing-quick-facts__group" }, [
          _c("h4", { staticClass: "manufacturing-quick-facts__group-header" }, [
            _vm._v("Gender"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "manufacturing-quick-facts__group-value" }, [
            _vm.supplier.men
              ? _c("div", { staticClass: "mqf-icon-value" }, [
                  _c("span", {
                    staticClass: "mqf-icon-value__icon",
                    domProps: { innerHTML: _vm._s(_vm.svg.gender.men) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "mqf-icon-value__label" }, [
                    _vm._v("Men"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.supplier.ladies
              ? _c("div", { staticClass: "mqf-icon-value" }, [
                  _c("span", {
                    staticClass: "mqf-icon-value__icon",
                    domProps: { innerHTML: _vm._s(_vm.svg.gender.ladies) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "mqf-icon-value__label" }, [
                    _vm._v("Ladies"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.supplier.kids
              ? _c("div", { staticClass: "mqf-icon-value" }, [
                  _c("span", {
                    staticClass: "mqf-icon-value__icon",
                    domProps: { innerHTML: _vm._s(_vm.svg.gender.boysGirls) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "mqf-icon-value__label" }, [
                    _vm._v("Boys & Girls"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.supplier.babies
              ? _c("div", { staticClass: "mqf-icon-value" }, [
                  _c("span", {
                    staticClass: "mqf-icon-value__icon",
                    domProps: { innerHTML: _vm._s(_vm.svg.gender.babies) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "mqf-icon-value__label" }, [
                    _vm._v("Babies"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.supplier.men &&
            !_vm.supplier.ladies &&
            !_vm.supplier.boysGirls &&
            !_vm.supplier.babies
              ? _c("div", { staticClass: "mqf-icon-value" }, [
                  _c("span", { staticClass: "mqf-icon-value__icon" }, [
                    _vm._v("-"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "mqf-icon-value__label" }, [
                    _vm._v("Unknown"),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "manufacturing-quick-facts__group" }, [
          _c("h4", { staticClass: "manufacturing-quick-facts__group-header" }, [
            _vm._v("Specialities"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "manufacturing-quick-facts__group-value" },
            [
              _vm._l(
                _vm.specialities.slice(0, _vm.specialitiesExpanded ? 99 : 3),
                function (speciality) {
                  return _c(
                    "div",
                    { key: speciality.id, staticClass: "mqf-icon-value" },
                    [
                      _c("span", {
                        staticClass: "mqf-icon-value__icon",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.svg.specialities[
                              _vm.camelCase(speciality.title)
                            ]
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "mqf-icon-value__label" }, [
                        _vm._v(_vm._s(speciality.title)),
                      ]),
                    ]
                  )
                }
              ),
              _vm._v(" "),
              _vm.specialities.length > 3 && !_vm.specialitiesExpanded
                ? _c(
                    "a",
                    {
                      staticClass: "mqf-additional-values",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.expandSpecialities($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          +" +
                          _vm._s(_vm.specialities.length - 3) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.specialities.length
                ? _c("div", { staticClass: "mqf-icon-value" }, [
                    _c("span", { staticClass: "mqf-icon-value__icon" }, [
                      _vm._v("-"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mqf-icon-value__label" }, [
                      _vm._v("No specialities"),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "manufacturing-quick-facts__group" }, [
          _c("h4", { staticClass: "manufacturing-quick-facts__group-header" }, [
            _vm._v("Size Range"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "manufacturing-quick-facts__group-value" }, [
            _vm.supplier.sizeRangeFrom && _vm.supplier.sizeRangeTo
              ? _c(
                  "div",
                  { staticClass: "mqf-value" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.supplier.sizeRangeFrom) +
                        "-" +
                        _vm._s(_vm.supplier.sizeRangeTo) +
                        "\n          "
                    ),
                    _c("div", { staticClass: "mqf-value__info-wrapper" }, [
                      _c("a", {
                        staticClass: "mqf-value__info",
                        attrs: { href: "#" },
                        domProps: { innerHTML: _vm._s(_vm.svg.info) },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$refs.sizeInfo.toggle($event)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "fs-popper",
                      {
                        ref: "sizeInfo",
                        attrs: {
                          animation: "fade",
                          arrow: "",
                          modifiers: [
                            { name: "flip", enabled: false },
                            {
                              name: "preventOverflow",
                              options: { padding: 16 },
                            },
                          ],
                          offset: [0, 30],
                          placement: "top",
                          "wrapper-class": "popper-wrapper supplierProfile",
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "popover-close",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fsPopperClose($event)
                              },
                            },
                          },
                          [_vm._v("+")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "supplier-popover supplier-popover--wide",
                          },
                          [
                            _c(
                              "h5",
                              { staticClass: "supplier-popover__header" },
                              [_vm._v("Size Range")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "sp-quick-list" }, [
                              _c(
                                "div",
                                { staticClass: "sp-quick-list__item" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "sp-quick-list__label" },
                                    [_vm._v("EU")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "sp-quick-list__value" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.supplier.sizeRangeFrom) +
                                          "-" +
                                          _vm._s(_vm.supplier.sizeRangeTo)
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "sp-quick-list__item" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "sp-quick-list__label" },
                                    [_vm._v("UK")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "sp-quick-list__value" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sizeRanges.uk.from.prettySize
                                        ) +
                                          "-" +
                                          _vm._s(
                                            _vm.sizeRanges.uk.to.prettySize
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "sp-quick-list__item" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "sp-quick-list__label" },
                                    [_vm._v("US")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "sp-quick-list__value" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sizeRanges.us.from.prettySize
                                        ) +
                                          "-" +
                                          _vm._s(
                                            _vm.sizeRanges.us.to.prettySize
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "sp-quick-list__item" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "sp-quick-list__label" },
                                    [_vm._v("JP (cm)")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "sp-quick-list__value" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.sizeRanges.cm.from.size) +
                                          "-" +
                                          _vm._s(_vm.sizeRanges.cm.to.size)
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "supplier-popover__text-container",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "supplier-popover__text supplier-popover__text--center",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Sizes are converted according to ISO standard. Contact the supplier to get exact sizes handled.\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            false
                              ? _c("span", {
                                  staticClass: "supplier-popover__line",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            false
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "supplier-popover__sub-header",
                                  },
                                  [_vm._v("Processes used")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "mqf-value" }, [_vm._v("-")]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "capabilities footwear-all-units__capabilities" },
        [
          _c("h4", { staticClass: "capabilities__header" }, [
            _vm._v("Capabilities"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "capabilities-flow" }, [
            _c(
              "div",
              {
                staticClass: "capabilities-flow__step",
                class: {
                  "capabilities-flow__step--inactive-from":
                    !_vm.supplier.capabilities ||
                    !_vm.supplier.capabilities.some(function (c) {
                      return c.category === "Pre-Development"
                    }),
                  "capabilities-flow__step--inactive-to":
                    !_vm.supplier.capabilities ||
                    !_vm.supplier.capabilities.some(function (c) {
                      return c.category === "Development"
                    }),
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "capabilities-flow__number",
                    class: {
                      "capabilities-flow__number--inactive":
                        !_vm.supplier.capabilities ||
                        !_vm.supplier.capabilities.some(function (c) {
                          return c.category === "Pre-Development"
                        }),
                    },
                  },
                  [_vm._v("1")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "capabilities-flow__content" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "capabilities-flow__header",
                      class: {
                        "capabilities-flow__header--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.category === "Pre-Development"
                          }),
                      },
                    },
                    [_vm._v("\n            Pre-development\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Design Inspiration"
                          }),
                      },
                    },
                    [_vm._v("Design Inspiration")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Component Sourcing"
                          }),
                      },
                    },
                    [_vm._v("Component Sourcing")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Material Sourcing"
                          }),
                      },
                    },
                    [_vm._v("Material Sourcing")]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "capabilities-flow__step",
                class: {
                  "capabilities-flow__step--inactive-from":
                    !_vm.supplier.capabilities ||
                    !_vm.supplier.capabilities.some(function (c) {
                      return c.category === "Development"
                    }),
                  "capabilities-flow__step--inactive-to":
                    !_vm.supplier.capabilities ||
                    !_vm.supplier.capabilities.some(function (c) {
                      return c.category === "Production"
                    }),
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "capabilities-flow__number",
                    class: {
                      "capabilities-flow__number--inactive":
                        !_vm.supplier.capabilities ||
                        !_vm.supplier.capabilities.some(function (c) {
                          return c.category === "Development"
                        }),
                    },
                  },
                  [_vm._v("2")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "capabilities-flow__content" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "capabilities-flow__header",
                      class: {
                        "capabilities-flow__header--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.category === "Development"
                          }),
                      },
                    },
                    [_vm._v("\n            Development\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Risk Assessment"
                          }),
                      },
                    },
                    [_vm._v("Risk Assessment")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Technical Design"
                          }),
                      },
                    },
                    [_vm._v("Technical Design")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Digital Prototyping"
                          }),
                      },
                    },
                    [_vm._v("Digital Prototyping")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Sample Making"
                          }),
                      },
                    },
                    [_vm._v("Sample Making")]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "capabilities-flow__step",
                class: {
                  "capabilities-flow__step--inactive-from":
                    !_vm.supplier.capabilities ||
                    !_vm.supplier.capabilities.some(function (c) {
                      return c.category === "Production"
                    }),
                  "capabilities-flow__step--inactive-to":
                    !_vm.supplier.capabilities ||
                    !_vm.supplier.capabilities.some(function (c) {
                      return c.category === "Logistic"
                    }),
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "capabilities-flow__number",
                    class: {
                      "capabilities-flow__number--inactive":
                        !_vm.supplier.capabilities ||
                        !_vm.supplier.capabilities.some(function (c) {
                          return c.category === "Production"
                        }),
                    },
                  },
                  [_vm._v("3")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "capabilities-flow__content" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "capabilities-flow__header",
                      class: {
                        "capabilities-flow__header--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.category === "Production"
                          }),
                      },
                    },
                    [_vm._v("\n            Production\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Cutting"
                          }),
                      },
                    },
                    [_vm._v("Cutting")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Stitching"
                          }),
                      },
                    },
                    [_vm._v("Stitching")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Assembly"
                          }),
                      },
                    },
                    [_vm._v("Assembly")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capabilities-flow__capability",
                      class: {
                        "capabilities-flow__capability--inactive":
                          !_vm.supplier.capabilities ||
                          !_vm.supplier.capabilities.some(function (c) {
                            return c.title === "Quality Control"
                          }),
                      },
                    },
                    [_vm._v("Quality Control")]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "capabilities-flow__step" }, [
              _c(
                "span",
                {
                  staticClass: "capabilities-flow__number",
                  class: {
                    "capabilities-flow__number--inactive":
                      !_vm.supplier.capabilities ||
                      !_vm.supplier.capabilities.some(function (c) {
                        return c.category === "Logistic"
                      }),
                  },
                },
                [_vm._v("4")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "capabilities-flow__content" }, [
                _c(
                  "h5",
                  {
                    staticClass: "capabilities-flow__header",
                    class: {
                      "capabilities-flow__header--inactive":
                        !_vm.supplier.capabilities ||
                        !_vm.supplier.capabilities.some(function (c) {
                          return c.category === "Logistic"
                        }),
                    },
                  },
                  [_vm._v("\n            Logistics\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "capabilities-flow__capability",
                    class: {
                      "capabilities-flow__capability--inactive":
                        !_vm.supplier.capabilities ||
                        !_vm.supplier.capabilities.some(function (c) {
                          return c.title === "Shipping and Logistics"
                        }),
                    },
                  },
                  [_vm._v("Shipping and Logistics")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("brands-section", {
        staticClass: "footwear-all-units__brands-section",
        attrs: {
          "brands-list": (_vm.supplier.brands || "").split(", "),
          "new-brands-list": _vm.supplier.supplierBrands,
        },
      }),
      _vm._v(" "),
      _c("code-of-conduct", {
        staticClass: "footwear-all-units__code-of-conduct",
        attrs: { supplier: _vm.supplier },
      }),
      _vm._v(" "),
      _vm.supplier.suppliers.length
        ? _c("supply-chain", {
            staticClass: "footwear-all-units__supply-chain",
            attrs: { suppliers: _vm.supplier.suppliers },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.supplier.gallery.length
        ? _c("supplier-gallery", { attrs: { supplier: _vm.supplier } })
        : _vm._e(),
      _vm._v(" "),
      _vm.supplier.certificates.length
        ? _c("certifications-section", {
            attrs: { data: _vm.supplier.certificates },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("membership-section", { attrs: { supplier: _vm.supplier } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }