var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "single-search-element" }, [
    _vm.listItem.hasOwnProperty("icon")
      ? _c("span", {
          staticClass: "single-search-element__icon",
          domProps: { innerHTML: _vm._s(_vm.listItem.icon) },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.listItem.hasOwnProperty("imageSrc")
      ? _c("img", {
          staticClass: "single-search-element__image",
          attrs: { src: _vm.listItem.imageSrc, alt: _vm.listItem.country },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: {
          "single-search-element__name-n-country": true,
          "single-search-element__name-n-country--horizontal":
            _vm.layoutType === "horizontal",
        },
      },
      [
        _c("span", { staticClass: "single-search-element__name" }, [
          _vm._v("\n      " + _vm._s(_vm.listItem.name) + "\n    "),
        ]),
        _vm._v(" "),
        _vm.listItem.hasOwnProperty("subscript")
          ? _c("span", { staticClass: "single-search-element__country" }, [
              _vm._v("\n      " + _vm._s(_vm.listItem.subscript) + "\n    "),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _vm.alreadyAdded
      ? _c("button", {
          staticClass: "single-search-element__checkmark-icon",
          domProps: { innerHTML: _vm._s(_vm.icons.singles.checkMarkIcon) },
        })
      : _c("button", {
          staticClass: "single-search-element__manage-but",
          domProps: { innerHTML: _vm._s(_vm.icons.singles.addCirclePlusIcon) },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }