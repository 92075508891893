var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs__tab-content", attrs: { id: "components-all-units" } },
    [
      _c("h3", { staticClass: "content-header" }, [
        _vm._v("Our Component Production"),
      ]),
      _vm._v(" "),
      _c("company-in-brief", { attrs: { supplier: _vm.supplier } }),
      _vm._v(" "),
      _c("verified-by-fs", { attrs: { supplier: _vm.supplier } }),
      _vm._v(" "),
      _vm.filteredTypesOfProduction.length
        ? _c(
            "div",
            {
              directives: [
                { name: "column-balancer", rawName: "v-column-balancer" },
              ],
              staticClass: "component-tables",
            },
            _vm._l(_vm.filteredTypesOfProduction, function (tableLevel, i) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "column-balancer",
                      rawName: "v-column-balancer",
                      value:
                        i === 0 &&
                        _vm.filteredTypesOfProduction.length % 2 === 1,
                      expression:
                        "i === 0 && filteredTypesOfProduction.length % 2 === 1",
                    },
                  ],
                  key: i,
                  staticClass: "component-table",
                  class: {
                    "component-table--full-width":
                      i === 0 && _vm.filteredTypesOfProduction.length % 2 === 1,
                  },
                },
                [
                  _c("h4", { staticClass: "component-table__header" }, [
                    _c("span", {
                      staticClass: "component-table__header-icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.material) },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "component-table__header-text" },
                      [_vm._v(_vm._s(tableLevel.title))]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(tableLevel.options, function (sectionLevel, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "component-table__section" },
                      [
                        _c(
                          "h5",
                          { staticClass: "component-table__section-header" },
                          [_vm._v(_vm._s(sectionLevel.title))]
                        ),
                        _vm._v(" "),
                        _vm._l(sectionLevel.options, function (groupLevel, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "component-table__group" },
                            [
                              _c(
                                "h6",
                                {
                                  staticClass: "component-table__group-header",
                                },
                                [_vm._v(_vm._s(groupLevel.groupTitle))]
                              ),
                              _vm._v(" "),
                              _vm._l(groupLevel.options, function (option, i) {
                                return _c(
                                  "span",
                                  {
                                    key: i,
                                    staticClass:
                                      "component-table__pill pill pill--raised",
                                  },
                                  [_vm._v(_vm._s(option.title))]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    )
                  }),
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.supplier.gallery.length
        ? _c("supplier-gallery", { attrs: { supplier: _vm.supplier } })
        : _vm._e(),
      _vm._v(" "),
      _c("certification", { attrs: { supplier: _vm.supplier } }),
      _vm._v(" "),
      _c("membership", { attrs: { supplier: _vm.supplier } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }