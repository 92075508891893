<template>
  <!-- Code of conduct form -->
  <div class="code-of-conduct-form">
    <div class="code-of-conduct-form__main-title">Code of Conduct</div>

    <!-- Form itself -->
    <div class="form-itself code-of-conduct-form__form-itself">
      <div
        v-for="column in [
          { title: 'social', states: socialStates },
          { title: 'environmental', states: environmentalStates },
        ]"
        :key="column.title"
        class="form-itself__separate-column"
      >
        <div class="form-itself__column-header">
          <div class="form-itself__title">
            {{ column.title }}
          </div>
          <div
            :class="{
              'form-itself__points-counter': 1,
              'form-itself__points-counter--green': column.states.filter((item) => item.isActive).length > 2,
            }"
          >
            {{ column.states.filter((item) => item.isActive).length }}
            out of
            {{ column.states.length }}
            points
          </div>
        </div>

        <ul class="form-itself__states-list">
          <!-- Separate state -->
          <li
            v-for="state in column.states"
            :key="state.title"
            :class="{
              'separate-state': 1,
              'separate-state--green-border': expandedState === state.title,
              'separate-state--red-bg': !activeStates.includes(state.title),
              'separate-state--red-border': !activeStates.includes(state.title) && expandedState === state.title,
              'form-itself__separate-state': true,
            }"
          >
            <div class="separate-state__header" @click="expandState(state)">
              <div
                v-if="
                  state.hasOwnProperty('description') || state.hasOwnProperty('comment') || state.hasOwnProperty('file')
                "
                :class="{
                  'separate-state__caret-arr-but': 1,
                  'separate-state__caret-arr-but--turned-to-top': expandedState === state.title,
                }"
                v-html="svg.caretDownIcon"
              ></div>
              <div
                :class="{ 'separate-state__icon': 1, 'separate-state__icon--red': !activeStates.includes(state.title) }"
                v-html="icons.codeOfConduct[state.icon]"
              ></div>
              <div class="separate-state__title">
                {{ state.title }}
              </div>

              <fs-switch
                class="separate-state__checkbox"
                :value="activeStates.includes(state.title)"
                @check="activeStates.push(state.title)"
                @uncheck="activeStates = activeStates.filter((item) => item !== state.title)"
                @click.native.stop="() => {}"
              />
            </div>

            <div
              :class="{
                'separate-state__content': 1,
                'separate-state__content--expanded': expandedState === state.title,
              }"
            >
              <div v-if="state.hasOwnProperty('description')" class="separate-state__description">
                {{ state.description }}
              </div>

              <!-- Comment form -->
              <div v-if="state.hasOwnProperty('comment')" class="comment-form separate-state__comment-form">
                <div class="comment-form__title">Your Comment</div>

                <text-area-custom
                  :min-height="70"
                  :max-height="75"
                  v-model="commentsTextareasValues[lodashCamelCase(state.title)]"
                />
              </div>
              <!-- / Comment form -->

              <input-custom
                v-if="state.hasOwnProperty('file')"
                class="separate-state__file"
                placeholder="Upload Document"
                type="file"
                is-file
              />
            </div>
          </li>
          <!-- / Separate state -->
        </ul>
      </div>
    </div>
    <!-- / Form itself -->

    <cancel-n-action-btn-pair
      class="code-of-conduct-form__manage-buttons-panel"
      @cancel="() => {}"
      @action="() => {}"
    />
  </div>
  <!-- / Code of conduct form -->
</template>

<script>
import lodashCamelCase from 'lodash/camelCase';

import codeOfConductContent from './code-of-conduct-content.json';

// Components
import FsSwitch from '@components/form-elements/fs-switch.vue';
import TextAreaCustom from '@components/form-elements/textarea-custom.vue';
import InputCustom from '@components/form-elements/input-custom.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
import codeOfConductIcons from '@lib/icons-imports/code-of-conduct';
// SVG
import caretDownIcon from '@icons/other/caret-down.svg';

export default {
  name: 'code-of-conduct',

  components: {
    FsSwitch,
    TextAreaCustom,
    InputCustom,
    CancelNActionBtnPair,
  },

  data() {
    return {
      isSaveInProgress: false,

      socialStates: [],
      environmentalStates: [],

      commentsTextareasValues: {},

      activeStates: [],

      expandedState: '',

      icons: {
        interfaceElements: interfaceElementsIcons,
        codeOfConduct: codeOfConductIcons,
      },
      svg: { caretDownIcon },
    };
  },

  mounted() {
    this.socialStates = codeOfConductContent.socialStates;
    this.environmentalStates = codeOfConductContent.environmentalStates;

    this.activeStates = [...codeOfConductContent.socialStates, ...codeOfConductContent.environmentalStates].reduce(
      (acc, item) => {
        if (item.isActive) return [...acc, item.title];
        return acc;
      },
      []
    );

    this.setReactivePropertiesForTextareas();
  },

  updated() {},

  methods: {
    lodashCamelCase,

    setReactivePropertiesForTextareas() {
      const cocStatesWithTextareas = Object.entries(codeOfConductContent)
        .map((item) => item[1])
        .flat()
        .filter((item) => item.hasOwnProperty('comment'));

      // Set reactive data properties for each comment textarea
      for (let i = 0; i < cocStatesWithTextareas.length; i++) {
        this.$set(this.commentsTextareasValues, lodashCamelCase(cocStatesWithTextareas[i].title), '');
      }
    },

    expandState(state) {
      if (!state.hasOwnProperty('description') && !state.hasOwnProperty('comment') && !state.hasOwnProperty('file')) {
        return;
      }

      this.expandedState = state.title === this.expandedState ? '' : state.title;
    },

    // codeOfConductContentParsed() {
    //   return JSON.parse(codeOfConductContent);
    // },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

// ============================================================================
// ============================================================================
// Comment form
.comment-form {
  &__title {
    padding-left: math.div(2.4rem, 1.6);
    margin-bottom: math.div(0.7rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
    text-transform: uppercase;
  }

  &__textarea {
  }
}

// ============================================================================
// ============================================================================
// Separate state
.separate-state {
  border: math.div(0.1rem, 1.6) transparent solid;
  border-radius: math.div(1rem, 1.6);
  box-sizing: border-box;
  //background: rgba(245,115,110,.1);
  background: rgba(132, 191, 110, 0.1);
  transition: border-color 0.15s ease, background-color 0.15s ease;

  &:hover {
    background: rgba(132, 191, 110, 0.2);
  }

  &--red-bg {
    background: rgba(245, 115, 110, 0.1);

    &:hover {
      background: rgba(245, 115, 110, 0.2);
    }
  }

  &--green-border {
    border-color: #84bf6e;
    background: #fff;

    &:hover {
      background: #fff;
    }
  }

  &--red-border {
    border-color: #f5736e;
    background: #fff;

    &:hover {
      background: #fff;
    }
  }

  &__header {
    height: math.div(6.5rem, 1.6);
    padding: 0 math.div(2rem, 1.6);
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  &__caret-arr-but {
    width: math.div(2.4rem, 1.6);
    min-width: math.div(2.4rem, 1.6);
    height: math.div(2.4rem, 1.6);
    margin-right: math.div(1.6rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    pointer-events: none;

    &::before,
    &::after {
      content: '';
      width: calc(100% + #{math.div(1.6rem, 1.6)});
      height: calc(100% + #{math.div(1.6rem, 1.6)});
      border-radius: math.div(10rem, 1.6);
      position: absolute;
      inset: 50% auto auto 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
    }

    &::after {
      width: 100%;
      height: 100%;
      background: #fff;
      box-shadow: 0 math.div(0.1rem, 1.6) math.div(0.3rem, 1.6) rgba(0, 0, 0, 0.16);
      transition: transform 0.15s ease;
    }

    ::v-deep svg {
      width: 45%;
      height: 45%;
      position: relative;
      top: math.div(0.2rem, 1.6);
      fill: rgb(53, 73, 94);
      transition: transform 0.15s ease;
    }

    &--turned-to-top {
      ::v-deep svg {
        transform: rotate(-180deg) translateY(#{math.div(0.2rem, 1.6)});
      }
    }
  }

  &:hover &__caret-arr-but {
    &::after {
      transform: translate(-50%, -50%) scale(1.13);
    }
  }

  &__icon {
    width: math.div(2.8rem, 1.6);
    min-height: math.div(2.8rem, 1.6);
    margin-right: math.div(1.6rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    ::v-deep svg {
      fill: rgb(132, 191, 110);
      transition: fill 0.15s ease;
    }

    &--red {
      ::v-deep svg {
        fill: rgb(245, 115, 110);
      }
    }
  }

  &__title {
    flex-grow: 1;
  }

  &__checkbox {
  }

  &__content {
    max-height: 0;
    padding: 0 math.div(3.8rem, 1.6);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
    position: relative;
    overflow: hidden;
    transition: max-height 0.15s ease, padding-bottom 0.15s ease;

    &--expanded {
      max-height: math.div(100rem, 1.6);
      padding-bottom: math.div(1.8rem, 1.6);
      transition: max-height 0.75s ease;
    }
  }

  &__description {
    margin-bottom: math.div(1.2rem, 1.6);
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
  }

  &__comment-form {
    margin-bottom: math.div(1.4rem, 1.6);
  }

  &__file {
    margin-bottom: math.div(0.9rem, 1.6);
  }
}

// ============================================================================
// ============================================================================
// Form itself
.form-itself {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__separate-column {
    width: calc(50% - #{math.div(1.9rem, 1.6)});
  }

  &__column-header {
    padding: 0 math.div(2rem, 1.6);
    margin-bottom: math.div(1.1rem, 1.6);
    display: flex;
    justify-content: space-between;
    font-size: math.div(1.8rem, 1.6);
    line-height: math.div(2.5rem, 1.6);
  }

  &__title {
    color: rgba(53, 73, 94, 0.5);
    text-transform: uppercase;
  }

  &__points-counter {
    color: rgb(237, 210, 0);
    text-align: right;

    &--green {
      color: rgb(132, 191, 110);
    }
  }

  &__states-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  &__separate-state {
    margin-bottom: math.div(1.2rem, 1.6);
  }
}

@media (max-width: $tablet-l-width) {
  .form-itself {
    flex-direction: column;

    &__separate-column {
      width: auto;

      &:first-child {
        margin-bottom: math.div(2rem, 1.6);
      }
    }
  }
}

// ============================================================================
// ============================================================================
// Code of conduct form
.code-of-conduct-form {
  width: math.div(106rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(3.2rem, 1.6);
  box-sizing: border-box;

  &__main-title {
    margin-bottom: math.div(2.8rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__form-itself {
    margin-bottom: math.div(3rem, 1.6);
  }

  &__manage-buttons-panel {
  }
}

@media (max-width: $tablet-l-width) {
  .code-of-conduct-form {
    width: math.div(50rem, 1.6);
  }
}
</style>
