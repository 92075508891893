var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FsPill",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "circle-sides": "",
            color: _vm.parsedColor,
            counter: _vm.counter,
            tag: _vm.tag,
          },
        },
        "FsPill",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default", [_vm._v("\n    " + _vm._s(_vm.colorName) + "\n  ")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }