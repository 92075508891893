<template>
  <div class="checkbox-button">
    <label class="checkbox-button__label-wrapper">
      <input
        class="checkbox-button__checkbox-input"
        v-model="localIsChecked"
        :value="_value"
        v-bind="$attrs"
        v-on="$listeners"
        ref="input"
        @change="emitCheckboxState"
        type="checkbox"
      />

      <span
        :class="{
          'checkbox-button__text-label-wrap': true,
          'checkbox-button__text-label-wrap--small': size === 'small',
          'checkbox-button__text-label-wrap--clean-design': cleanDesign,
        }"
      >
        <span v-if="additionalIcon" class="checkbox-button__icon" v-html="additionalIcon"> </span>

        <span :class="{ 'checkbox-button__text-label': true, 'checkbox-button__text-label--small': size === 'small' }">
          {{ textLabel }}
        </span>
      </span>
    </label>
  </div>
</template>

<script>
// Icons
// SVG
import icoSelectedIcon from '@icons/other/ico-selected.svg';

export default {
  name: 'custom-checkbox-button',

  props: {
    isChecked: { type: Boolean, default: false },
    textLabel: String,
    additionalIcon: String,
    size: {
      type: String,
      validator: (size) => ['small', 'medium', 'large'].includes(size),
      default: 'medium',
    },
    // No border on initial state and transparent bg + border on hover/checked state
    cleanDesign: { type: Boolean, default: false },
  },

  data() {
    return {
      localIsChecked: false,

      icons: {},
      svg: { icoSelectedIcon },
    };
  },

  mounted() {
    if (this.localIndeterminate) this.$refs.input.indeterminate = true;

    // console.log('%c this.size: ','background:greenyellow;color:black;', this.size);
  },

  updated() {
    // console.log('%c this.size: ','background:greenyellow;color:black;', this.size);
  },

  computed: {
    _value() {},
  },

  methods: {
    emitCheckboxState() {
      if (this.$refs.input.checked) this.$emit('check');
      else this.$emit('uncheck');
    },
  },

  watch: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

// ============================================================================
// ============================================================================
// Checkbox with icon
.checkbox-button {
  user-select: none;

  &__label-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  &__checkbox-input {
    position: absolute;
    opacity: 0;
  }

  &__text-label-wrap {
    width: 100%;
    height: math.div(3rem, 1.6);
    padding: 0 math.div(1.7rem, 1.6);
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    border: 2px rgba(53, 73, 94, 1) solid;
    border-radius: math.div(1rem, 1.6);
    transform: translateY(0);
    opacity: 0.5;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    transition: transform 0.15s ease, opacity 0.15s ease, background-color 0.15s ease, box-shadow 0.15s ease;

    &--clean-design {
      border-color: transparent;
      box-shadow: none;
    }

    &--small {
      height: math.div(2.4rem, 1.6);
      border-width: 1px;
      border-radius: math.div(0.5rem, 1.6);
    }
  }

  &:hover {
    .checkbox-button__text-label-wrap {
      transform: translateY(-1px);
      opacity: 1;
      box-shadow: 0 math.div(0.4rem, 1.6) math.div(0.7rem, 1.6) rgba(0, 0, 0, 0.15);
    }
    .checkbox-button__text-label-wrap--clean-design {
      transform: translateY(-1px);
      opacity: 1;
      border-color: transparent;
      box-shadow: none;
    }
  }

  &__checkbox-input:checked + &__text-label-wrap {
    opacity: 1;
    background: rgb(53, 73, 94);
  }

  &__checkbox-input:checked + &__text-label-wrap--clean-design {
    border-color: rgb(53, 73, 94);
    opacity: 1;
    background: transparent;
  }

  &__icon {
    width: math.div(1.6rem, 1.6);
    min-width: math.div(1.6rem, 1.6);
    height: math.div(1.6rem, 1.6);
    margin-right: math.div(0.7rem, 1.6);

    ::v-deep svg {
      width: 100%;
      height: 100%;
      fill: rgb(53, 73, 94);
      transition: fill 0.15s ease;
    }
  }

  &__checkbox-input:checked + &__text-label-wrap &__icon {
    ::v-deep svg {
      fill: white;
    }
  }

  &__checkbox-input:checked + &__text-label-wrap--clean-design &__icon {
    ::v-deep svg {
      fill: rgb(52, 73, 94);
    }
  }

  &__text-label {
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.7rem, 1.6);
    text-transform: uppercase;
    white-space: nowrap;
    color: rgb(53, 73, 94);
    transition: color 0.15s ease;

    &--small {
      font-size: math.div(1.4rem, 1.6);
      text-transform: none;
    }
  }

  &__checkbox-input:checked + &__text-label-wrap &__text-label {
    color: white;
  }

  &__checkbox-input:checked + &__text-label-wrap--clean-design &__text-label {
    color: rgb(53, 73, 94);
  }
}
</style>
