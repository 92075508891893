var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "size-range-edit-form" },
    [
      _c("div", { staticClass: "size-range-edit-form__main-title" }, [
        _vm._v("Size Range"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "size-selection-form size-range-edit-form__size-selection-form",
        },
        [
          _c(
            "div",
            { staticClass: "size-slider size-selection-form__size-slider" },
            [
              _c("label", { staticClass: "size-slider__text-input-label" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.sliderSizeRange[0],
                      expression: "sliderSizeRange[0]",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "size-slider__text-input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.sliderSizeRange[0] },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.save($event)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.sliderSizeRange,
                        0,
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "size-slider__slider-wrap" },
                [
                  _c("fs-slider", {
                    key: _vm.priceSliderRerenderKey,
                    staticClass: "size-slider__slider",
                    attrs: {
                      min: _vm.minSize,
                      max: _vm.maxSize,
                      width: "100%",
                      "tooltip-dir": "bottom",
                      tooltip: "newer",
                      formatter: function (value) {
                        return value
                      },
                    },
                    model: {
                      value: _vm.sliderSizeRange,
                      callback: function ($$v) {
                        _vm.sliderSizeRange = $$v
                      },
                      expression: "sliderSizeRange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("label", { staticClass: "size-slider__text-input-label" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.sliderSizeRange[1],
                      expression: "sliderSizeRange[1]",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "size-slider__text-input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.sliderSizeRange[1] },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.save($event)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.sliderSizeRange,
                        1,
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "size-range-edit-form__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }