<template>
  <fs-pill
    :color="color"
    :counter="counter"
    :fixed="fixed"
    :size="size"
    :tag="tag"
    v-bind="$attrs"
    v-on="$listeners"
    :hover-mode="hoverMode"
  >
    <slot></slot>
  </fs-pill>
</template>

<script>
// A pre-defined FsPill for tag pills
//
// Pros:
// * Consistency - Having it pre-defined ensures consistency.
// * Easier to use - By having a much smaller interface/API than the
//   full FsPill component, it's faster to get a grip of all relevant
//   properties (by not having non-relevant props here).
// * Easier to refactor - Want to change all tag pills? All code in
//   one place.

import FsPill from './fs-pill.vue';

export default {
  name: 'fs-pill-tag',
  props: {
    // String: The color of the pill, styled based on type (or, should be based on solid/outlined - see below)
    // Object: Style properties, fully managed (basically passed directly into the style-attribute)
    color: { type: [Object, String] },
    counter: { type: Boolean, default: false },
    fixed: { type: Boolean, default: true },
    size: { type: String, validator: (size) => ['large', 'medium', 'small'].includes(size), default: 'medium' },
    // FUTURE: If we need to support router-link, then look at fs-button on how to implement it
    tag: { type: String, default: 'span' },
    hoverMode: { type: String, validator: (mode) => ['add', 'remove'].includes(mode) },
  },
  components: { FsPill },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';

.pill--large {
  font-size: math.div(1.5em, 1.6);
  font-weight: 400;
}

.pill--counter {
  background-color: $c-gray-mid-light;
  color: $c-light;
}
</style>
