<template>
  <FsPopper
    :flip="flip"
    :modifiers="localModifiers"
    :[parentScopeId]="''"
    :preventOverflowAltAxis="preventOverflowAltAxis"
    :preventOverflowPadding="preventOverflowPadding"
    :preventOverflowRootBoundary="preventOverflowRootBoundary"
    v-bind="$attrs"
    v-on="$listeners"
    ref="fsPopper"
    is-close-on-esc
  >
    <slot></slot>
  </FsPopper>
</template>

<script>
import FsPopper from './fs-popper.vue';

export default {
  name: 'FsPopperPrEdit',
  inheritAttrs: false,
  props: {
    flip: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/flip/
      type: [Boolean, Number, Object, String], // Mix of flip.enabled (Boolean), flip.padding (Number | Object) and flip.rootBoundary (String)
      default: false,
    },
    modifiers: {
      type: Array,
    },
    preventOverflowAltAxis: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/preventOverflow/
      type: [Boolean],
      default: true,
    },
    preventOverflowPadding: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/preventOverflow/
      type: [Number, Object],
      default: () => {
        return { top: 70 + 30, bottom: 30, left: 30, right: 30 };
      }, // 30 as base, and an extra 70 to compensate for navbar (the largest px size of the navbar)
    },
    preventOverflowRootBoundary: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/preventOverflow/
      type: [String],
      default: 'viewport',
    },
  },
  components: {
    FsPopper,
  },
  computed: {
    localModifiers() {
      // If modifiers was passed in with eventListeners modifier, then give full control to the user
      if (this.modifiers && this.modifiers.some((modifier) => modifier.name === 'eventListeners'))
        return this.modifiers;

      // Otherwise, limit events to resize to prevent jumping of poppers
      const modifiers = [...(this.modifiers || [])];

      modifiers.push({
        name: 'eventListeners',
        options: {
          scroll: false,
        },
      });

      return modifiers;
    },
    // Workaround for passing scopeId to div.popper (it't not technically the root, but to allow for parent to style with scoped styles - as is expected - it makes sense to add it)
    parentScopeId() {
      return this.$parent.$options._scopeId || null;
    },
  },
  methods: {
    open() {
      return this.$refs.fsPopper.open.apply(this, arguments);
    },
    close() {
      return this.$refs.fsPopper.close.apply(this, arguments);
    },
    toggle(event) {
      return this.$refs.fsPopper.toggle.apply(this, arguments);
    },
  },
};
</script>
