<template>
  <!-- Custom select -->
  <div
    :class="{
      'custom-select': true,
      'custom-select--active': isOpened,
      'custom-select--disabled': isDisabled,
      'custom-select--error': isError,
      'custom-select--medium': size === 'medium',
      'custom-select--unselected': localValue === null,
    }"
    tabindex="0"
    ref="mainSelectContainer"
  >
    <label @keyup.enter="$emit('keyup-enter', $event)">
      <select
        :name="name"
        :id="id"
        class="custom-select__form"
        v-model="localValueAsComparable"
        :disabled="isDisabled"
        ref="select"
        @focus="($event) => $emit('focus', $event)"
        @blur="($event) => $emit('blur', $event)"
      >
        <option
          v-for="(item, index) in optionsAsComparable"
          :key="index"
          :value="item"
          :selected="item == localValueAsComparable"
        >
          <slot :option="comparableToValue(item)">
            {{ comparableToValue(item) }}
          </slot>
        </option></select
      ><!-- / form -->
    </label>
    <!-- Fake input -->
    <div class="custom-select__fake-input" tabindex="0" @click="openDropdown" ref="fakeInput">
      <!--    <div-->
      <!--      :class="{ 'custom-select__fake-input': 1 }"-->
      <!--      tabindex="0"-->
      <!--    >-->
      <span
        v-if="!isWithSearch"
        :class="{
          'custom-select__title': true,
          'custom-select__title--error': isError,
          'custom-select__title--disabled': isDisabled,
          'custom-select__title--medium-size': size === 'medium',
          'custom-select__title--transparent-bg': isTransparentBg,
          'custom-select__title--right-not-rounded': rightNotRounded,
        }"
      >
        <span class="custom-select__title-in-wrap1">
          <span class="custom-select__title-in-wrap2">
            <img
              v-if="flagIcon && $store.countries.codeAlpha3FromName(activeValue)"
              class="custom-select__flag-icon"
              :src="$store.countries.getFlagPathFromName(activeValue)"
              :alt="activeValue"
            />

            <span v-if="icon" class="custom-select__title-icon" v-html="icon"></span>

            <slot v-if="activeValue" :option="activeValue">
              <span class="custom-select__title-text">
                {{ optionDescriptionDelimiter ? activeValue.split(optionDescriptionDelimiter)[0] : activeValue }}
              </span>

              <span
                v-if="activeValue && optionDescriptionDelimiter && activeValue.split(optionDescriptionDelimiter)[1]"
                class="custom-select__title-option-description"
              >
                {{ activeValue.split(optionDescriptionDelimiter)[1] }}
              </span>
            </slot>
            <template v-else>{{ placeholder }}</template>
          </span>
        </span>
      </span>

      <input-custom
        v-else
        class="custom-select__search-field"
        :placeholder="placeholder"
        v-model="searchQuery"
        @real-change="validateNSetActive"
        @focus="
          ($event) => {
            isOpened = true;
            $emit('focus', $event);
          }
        "
        @blur="($event) => $emit('blur', $event)"
        :tabindex="0"
        :is-search="isWithMagnifierIcon"
        :no-clear-button="true"
      />

      <!--      <transition name="simple-fade-in-out">-->

      <fs-popper
        ref="popperDropdownPart"
        placement="bottom-start"
        @open="
          isOpened = true;
          setOptionsListOffset();
        "
        @close="
          isOpened = false;
          optionsListOffset = 0;
        "
        no-styling
        :close-on-outside-click-check="closeOnOutsideClickCheck"
      >
        <!-- Dropdown part -->
        <div
          :class="{
            'dropdown-part': true,
            'dropdown-part--medium': size === 'medium',
            'dropdown-part--error': isError,
            'custom-select__dropdown-part': true,
          }"
          :style="{ maxHeight: maxDropdownHeight + 'px', width: dropdownWidth + 'px', minWidth: dropdownWidth + 'px' }"
          @click="$refs.select.focus()"
        >
          <div v-if="searchQuery && filteredOptionsList.length === 0" class="dropdown-part__no-results-text">
            No such elements
          </div>

          <!-- List over 7 elements long -->
          <scrollable-block
            v-if="filteredOptionsList.length > 8"
            class="dropdown-part__scrollable-block"
            shadows-color="dark-gray"
            :style="{
              height: maxDropdownHeight - 50 + 'px',
              minHeight: maxDropdownHeight - 50 + 'px',
              maxHeight: maxDropdownHeight - 50 + 'px',
            }"
            no-bottom-margin
            :active-element-offset="optionsListOffset"
          >
            <ul class="dropdown-part__options-list" ref="optionsListLong">
              <li
                v-for="(option, index) in filteredOptionsList"
                :key="index"
                :class="{
                  'dropdown-part__item': true,
                  'dropdown-part__item--small-left-padding': !isCheckmarkShowed,
                  'dropdown-part__item--active': compareAsComparable(option, localValue),
                }"
                @click="($event) => selectOption($event, option)"
              >
                <span
                  v-if="compareAsComparable(option, localValue) && isCheckmarkShowed"
                  class="dropdown-part__selected-checkmark"
                  v-html="svg.selected"
                >
                </span>

                <slot :option="option">
                  {{ optionDescriptionDelimiter ? option.split(optionDescriptionDelimiter)[0] : option }}
                  <span
                    v-if="optionDescriptionDelimiter && option.split(optionDescriptionDelimiter)[1]"
                    class="dropdown-part__option-description"
                  >
                    {{ option.split(optionDescriptionDelimiter)[1] }}
                  </span>
                </slot>
              </li>
            </ul> </scrollable-block
          ><!-- / List over 7 elements long -->

          <!-- List under 7 elements long -->
          <ul v-if="filteredOptionsList.length <= 8" class="dropdown-part__options-list" ref="optionsListShort">
            <li
              v-for="(option, index) in filteredOptionsList"
              :key="index"
              :class="{
                'dropdown-part__item': true,
                'dropdown-part__item--small-left-padding': !isCheckmarkShowed,
                'dropdown-part__item--active': compareAsComparable(option, localValue),
              }"
              @click="($event) => selectOption($event, option)"
            >
              <span
                v-if="compareAsComparable(option, localValue) && isCheckmarkShowed"
                class="dropdown-part__selected-checkmark"
                v-html="svg.selected"
              >
              </span>

              <slot :option="option">
                {{ optionDescriptionDelimiter ? option.split(optionDescriptionDelimiter)[0] : option }}
                <span
                  v-if="optionDescriptionDelimiter && option.split(optionDescriptionDelimiter)[1]"
                  class="dropdown-part__option-description"
                >
                  {{ option.split(optionDescriptionDelimiter)[1] }}
                </span>
              </slot>
            </li>
          </ul>
          <!-- / List under 7 elements long -->

          <!-- Additional actions list -->
          <ul v-if="additionalActions.length" class="additional-actions-list dropdown-part__additional-actions-list">
            <li
              v-for="(action, index) in additionalActions"
              :key="action.name"
              :class="{
                'additional-action': true,
                'additional-action--disabled': action.isDisabled,
                'additional-actions-list__action': true,
              }"
              @click="($event) => clickOnAdditionalAction($event, action)"
            >
              <div class="additional-action__icon" v-html="action.icon"></div>

              <div class="additional-action__text">
                {{ action.name }}
              </div>
            </li>
          </ul>
          <!-- / Additional actions list -->
        </div>
        <!-- / Dropdown part -->
      </fs-popper>

      <span
        v-if="!isWithSearch"
        :class="{
          'custom-select__arrow': true,
          'custom-select__arrow--disabled': isDisabled,
          'custom-select__arrow--active': isOpened,
        }"
        v-html="svg.caretDown"
      >
      </span>

      <span
        v-if="isWithSearch && searchQuery"
        class="custom-select__clear-btn"
        v-html="svg.crossIcon"
        @click.stop="clearSearchInput"
        ref="clearBtn"
      >
      </span>
    </div>
    <!-- / Fake input -->
  </div>
  <!-- / Custom select -->
</template>

<script>
import lodashClamp from 'lodash/clamp';
import lodashIsPlainObject from 'lodash/isPlainObject';

// Components
import ScrollableBlock from '@components/scrollable-block.vue';
import FsPopper from '@components/poppers/fs-popper.vue';
import InputCustom from '@components/form-elements/input-custom.vue';

// Icons
// SVG
import selected from '@icons/other/ico-selected.svg';
import caretDown from '@icons/other/caret-down.svg';
import crossIcon from '@icons/other/ico-cross.svg';

/**
 * @deprecated This component is deprecated, use fs-select instead
 */
export default {
  name: 'select-custom',

  props: {
    placeholder: String,

    name: String,

    id: String,
    options: Array,

    isWithMagnifierIcon: { type: Boolean, default: false },

    size: {
      type: String,
      default: 'large',
      validator: (size) => ['small', 'medium', 'large'].includes(size),
    },

    isDisabled: Boolean,
    isError: Boolean,
    value: {},

    icon: { type: String },
    flagIcon: Boolean,

    preselectFirstOption: { type: Boolean, default: true },

    isCheckmarkShowed: { type: Boolean, default: true },

    isTransparentBg: { type: Boolean, default: false },

    maxDropdownHeight: {
      type: Number,
      default: 250,
    },
    optionDescriptionDelimiter: String,
    additionalActions: { type: Array, default: () => [] },
    labelFormatter: { type: Function, default: (item) => item },

    isWithSearch: { type: Boolean, default: false },
    rightNotRounded: { type: Boolean, default: false },
  },

  components: {
    ScrollableBlock,
    FsPopper,
    InputCustom,
  },

  data() {
    return {
      isVisible: true,
      isOpened: false,
      activeIndex: undefined,

      localValue: '',

      searchQuery: '',

      optionsListOffset: 0,

      dropdownWidth: 320,

      svg: { selected, caretDown, crossIcon },
    };
  },

  mounted() {
    this.localValue = this.value;
    if (this.options.includes(this.placeholder)) this.localValue = this.placeholder;

    this.dropdownWidth = this.calculateDropdownWidth();
    if (this.value) {
      this.setActive(this.value);
    }
  },

  updated() {
    this.dropdownWidth = this.calculateDropdownWidth();
  },

  computed: {
    filteredOptionsList() {
      if (!this.isWithSearch) {
        return this.options;
      }

      const showFullList = this.localValue && this.labelFormatter(this.localValue) === this.searchQuery;

      return showFullList
        ? this.options
        : this.options.filter((item) =>
            this.labelFormatter(item).toLowerCase().includes(this.searchQuery.toLowerCase())
          );
    },

    activeValue() {
      if (this.localValue !== undefined && this.localValue !== null) return this.localValue;
    },

    localValueAsComparable: {
      get: function () {
        return this.valueToComparable(this.localValue);
      },
      set: function (newValue) {
        this.localValue = this.comparableToValue(newValue);
      },
    },

    optionsAsComparable() {
      if (!this.options) return this.options;

      return this.options.map((option) => this.valueToComparable(option));
    },
  },

  methods: {
    clickOnAdditionalAction(event, action) {
      if (action.callback) action.callback(event);

      this.$refs.popperDropdownPart.toggle(event);
      this.isOpened = false;
    },

    selectOption(event, option) {
      this.setActive(option);
      this.$refs.popperDropdownPart.toggle(event);
      this.isOpened = false;
      this.$emit('input', this.localValue);
    },

    openDropdown(event) {
      if (this.isWithSearch) this.$refs.popperDropdownPart.open(event);
      else if (!this.isDisabled) this.$refs.popperDropdownPart.toggle(event);
    },

    clearSearchInput() {
      this.isVisible = false;
      this.searchQuery = '';
      this.localValue = '';

      this.$emit('clear-search-input');
    },

    setOptionsListOffset() {
      const optionsNodesList = (this.$refs['optionsListLong'] || this.$refs['optionsListShort'])?.childNodes;
      const optionsArray = Array.from(optionsNodesList);
      const activeElementIndex = optionsArray.findIndex((item) =>
        item.classList.contains('dropdown-part__item--active')
      );

      const activeNode = optionsNodesList[activeElementIndex] || {};

      this.optionsListOffset = activeNode.offsetTop || 0;
    },

    closeOnOutsideClickCheck() {
      const $container = this.$refs.mainSelectContainer;

      if (!$container) return false;

      return $container.contains(document.activeElement) && document.activeElement !== this.$refs.fakeInput;
    },

    isValidOption(value) {
      return this.options.filter((item) => this.labelFormatter(item) === this.labelFormatter(value)).length > 0;
    },

    validateNSetActive(newValue) {
      // setTimeout is neede here to make it rerender to 1value list only after closing transition finishes
      setTimeout(() => {
        if (this.isValidOption(newValue)) {
          this.localValue = newValue;
        } else if (this.isValidOption(this.localValue)) {
          this.searchQuery = this.labelFormatter(this.localValue);
        } else {
          this.searchQuery = '';
          this.localValue = '';
        }
      }, 150);
    },

    setActive(item) {
      this.isVisible = false;
      this.localValue = item;

      // Eliminates rerender of the filtered list to full list while closing it
      this.$nextTick(() => {
        this.searchQuery = this.labelFormatter(item);
      });
    },

    calculateDropdownWidth() {
      const mainContainerWidth = this.$refs.mainSelectContainer?.clientWidth;

      const longestOptionLength =
        [this.placeholder, ...this.options].sort((a, b) => (a.length > b.length ? -1 : 1))[0]?.length || 1;

      return lodashClamp(longestOptionLength * 12, mainContainerWidth, 320);

      // return this.options.map(item => item.length).sort((a,b) => a > b ? -1 : 1)[0] * 7 + 80;
    },
    compareAsComparable(val1, val2) {
      return this.valueToComparable(val1) == this.valueToComparable(val2);
    },
    valueToComparable(value) {
      if (value && lodashIsPlainObject(value)) return JSON.stringify(value);

      return value;
    },
    comparableToValue(comparable) {
      const optionsIndex = this.optionsAsComparable.indexOf(comparable);

      if (optionsIndex < 0) return comparable;

      return this.options[optionsIndex];
    },
  },

  watch: {
    options() {
      if (!this.optionsAsComparable.includes(this.localValueAsComparable) && this.preselectFirstOption) {
        this.localValue = this.options[0];
      }
    },

    searchQuery() {
      this.isVisible = true;
      this.$emit('search-query-change', this.searchQuery);
    },

    value() {
      // Fix for situations when "value" being reinitialised after component has rendered
      this.localValue = this.value;
      this.searchQuery = this.labelFormatter(this.value);
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@import 'common/styles/variables';

// ============================================================================
// ============================================================================
// Additional actions list
.additional-actions-list {
  padding: math.div(0.8rem, 1.6) 0 0 0;
  position: relative;
  list-style: none;

  &::before {
    content: '';
    width: calc(100% + #{math.div(2.8rem, 1.6)});
    height: 1px;
    position: absolute;
    top: 0;
    left: math.div(-1.4rem, 1.6);
    font-size: 0;
    line-height: 0;
    background: rgba(53, 73, 94, 0.1);
  }

  &__action {
  }
  .additional-action {
    padding-left: math.div(0.5rem, 1.6);
    border-radius: math.div(0.5rem, 1.6);
    display: flex;
    align-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.1s ease;

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      background: rgba(53, 73, 94, 0.07);
    }

    &__icon {
      width: math.div(1.2rem, 1.6);
      height: math.div(1.2rem, 1.6);
      flex-shrink: 0;
      margin: 0 math.div(0.7rem, 1.6) 0 1px;
      position: relative;
      top: 0;

      ::v-deep svg {
        fill: rgba(53, 73, 94, 1);
      }
    }
  }
}

// ============================================================================
// ============================================================================
// Dropdown part
.dropdown-part {
  padding: math.div(1rem, 1.6) math.div(1.4rem, 1.6);
  box-sizing: border-box;
  border-radius: math.div(1.2rem, 1.6);
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0 0 auto 0;
  z-index: 2;
  font-size: math.div(1.4rem, 1.6);
  line-height: 180%;
  //overflow: hidden;
  background: $c-bg-gray-darkest;
  box-shadow: 0 math.div(0.3rem, 1.6) math.div(1rem, 1.6) 0 rgba(0, 0, 0, 0.1);
  user-select: none;

  &--error {
    background: #ffbcbc;
  }

  &--medium {
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(2.1rem, 1.6);
  }

  &__no-results-text {
    color: rgba(53, 73, 94, 0.5);
    font-style: italic;
  }

  &__scrollable-block {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  &__options-list {
    padding: 0;
    margin: 0;
    list-style: none;
    color: rgb(60, 60, 60);
    transition: 0.15s;
    cursor: pointer;
  }

  &:hover {
    //.custom-select__options-list {
    //  color: rgba(53,73,94,.75);
    //}
  }

  &__item {
    padding: 0 0 0 math.div(2.3rem, 1.6);
    border-radius: math.div(0.5rem, 1.6);
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: rgba(53, 73, 94, 1);
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: background-color 0.1s ease;

    &--small-left-padding {
      padding-left: math.div(0.7rem, 1.6);
    }

    &:hover {
      background: rgba(53, 73, 94, 0.07);
    }

    &--active:hover {
      color: rgba(53, 73, 94, 1);
    }
  }

  &__option-description {
    padding-left: math.div(0.7rem, 1.6);
    margin-left: math.div(0.4rem, 1.6);
    position: relative;
    font-size: math.div(1rem, 1.6);
    text-transform: uppercase;
    color: rgba(53, 73, 94, 0.5);

    &::before {
      content: '';
      width: 1px;
      height: math.div(1.4rem, 1.6);
      position: absolute;
      top: math.div(-0.3rem, 1.6);
      left: 0;
      background: rgba(53, 73, 94, 0.15);
    }
  }

  &__selected-checkmark {
    width: math.div(1.1rem, 1.6);
    height: math.div(0.9rem, 1.6);
    position: absolute;
    inset: calc(50% - #{math.div(0.5rem, 1.6)}) auto auto math.div(0.7rem, 1.6);
    fill: #35495e;
  }

  &--medium {
    .dropdown-part__selected-checkmark {
      top: calc(50% - #{math.div(0.5rem, 1.6)}) !important;
    }
  }

  &__additional-actions-list {
    margin: math.div(0.3rem, 1.6) 0 0 0;
  }
}

// ============================================================================
// ============================================================================
// Custom select
.custom-select {
  width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 1;
  border-radius: math.div(1.8rem, 1.6);
  color: rgba(53, 73, 94, 1);
  transition: 0.15s;
  cursor: pointer;
  user-select: none;

  &__flag-icon {
    width: math.div(2.2rem, 1.6);
    min-width: math.div(2.2rem, 1.6);
    height: math.div(1.3rem, 1.6);
    margin-right: math.div(0.3rem, 1.6);
    display: inline-block;
    position: relative;
    inset: math.div(0.2rem, 1.6) auto auto auto;
  }

  &__title-icon {
    width: math.div(1.9rem, 1.6);
    min-width: math.div(1.9rem, 1.6);
    height: math.div(1.9rem, 1.6);
    margin-right: math.div(0.8rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    ::v-deep svg {
      width: 100%;
      height: 100%;
      fill: rgb(53, 73, 94);
    }
  }

  &--active {
    z-index: 2;

    //.custom-select__title {
    //  border-radius: 1.125rem 1.125rem 0 0;
    //}
  }

  &__title-text {
    display: block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__form {
    position: absolute;
    left: -9999px;
    opacity: 0;
  }

  &__fake-input {
    width: 100%;
    height: math.div(3.6rem, 1.6);
    padding: 0;
    margin: 0;
    outline: none;
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(3rem, 1.6);
    background: none;
  }

  &--medium {
    .custom-select__fake-input {
      height: math.div(2.9rem, 1.6);
      font-size: math.div(1.2rem, 1.6);
      line-height: math.div(2.5rem, 1.6);
    }
  }

  &__title {
    width: 100%;
    height: math.div(3.6rem, 1.6);
    padding: 0 math.div(1.2rem, 1.6) 0 math.div(1.4rem, 1.6);
    box-sizing: border-box;
    border-radius: math.div(1.8rem, 1.6);
    display: flex;
    align-content: center;
    align-items: center;
    outline: none;
    position: relative;
    overflow: hidden;
    z-index: 3;
    transition: 0.15s;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: rgba(232, 232, 232, 1);

    &--error {
      background: #ffbcbc !important;
    }

    &--disabled {
      color: rgba(53, 73, 94, 0.25) !important;
      background: rgba(232, 232, 232, 0.25) !important;
      cursor: not-allowed;
    }

    &--medium-size {
      position: relative;
    }

    &--transparent-bg {
      background: transparent !important;
    }

    &--right-not-rounded {
      border-radius: math.div(1.8rem, 1.6) 0 0 math.div(1.8rem, 1.6);
    }
  }

  &__title-in-wrap1 {
    width: calc(100% - #{math.div(2rem, 1.6)});
    max-width: calc(100% - #{math.div(2rem, 1.6)});
    height: 100%;
    padding-bottom: 1px;
    box-sizing: border-box;
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__title-in-wrap2 {
    width: 100%;
    height: math.div(2rem, 1.6);
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    line-height: math.div(2rem, 1.6) !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__title-option-description {
    padding-left: math.div(0.7rem, 1.6);
    margin-left: math.div(0.4rem, 1.6);
    position: relative;
    font-size: math.div(1rem, 1.6);
    text-transform: uppercase;
    color: rgba(53, 73, 94, 0.5);

    &::before {
      content: '';
      width: 1px;
      height: math.div(1.4rem, 1.6);
      position: absolute;
      top: math.div(-0.3rem, 1.6);
      left: 0;
      background: rgba(53, 73, 94, 0.15);
    }
  }

  &--medium {
    .custom-select__title {
      height: math.div(2.9rem, 1.6);
      max-height: math.div(2.9rem, 1.6);
      padding-top: 0;
      line-height: math.div(3.1rem, 1.6);
    }
  }

  &--active,
  &:hover {
    .custom-select__title {
      //color: rgba(53,73,94,.75);
      background: rgba(232, 232, 232, 0.75);
    }
  }

  &__dropdown-part {
    position: absolute;
    inset: 0 0 auto 0;
    z-index: 2;
  }

  &__arrow,
  &__clear-btn {
    width: math.div(1rem, 1.6);
    height: math.div(1rem, 1.6);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    z-index: 10;
    inset: calc(50% - #{math.div(0.5rem, 1.6)}) math.div(1.4rem, 1.6) auto auto;
    transition: 0.15s;
    pointer-events: none;

    &--disabled {
      cursor: not-allowed;
      color: rgba(53, 73, 94, 0.25) !important;
      background: rgba(232, 232, 232, 0.25) !important;
      opacity: 0.5;
    }

    &--active {
      transform: rotate(-180deg) translateY(#{math.div(0.2rem, 1.6)});
    }

    ::v-deep svg {
      fill: rgba(53, 73, 94, 1);
    }
  }

  &__clear-btn {
    cursor: pointer;
    z-index: 3;
    pointer-events: auto;

    &::before {
      content: '';
      width: calc(100% + #{math.div(1.6rem, 1.6)});
      height: calc(100% + #{math.div(1.6rem, 1.6)});
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
