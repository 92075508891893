<template>
  <div v-if="supplier.apiccapsId || supplier.satra" class="membership-section">
    <h4 class="membership-section__header">Membership Organizations</h4>
    <div class="membership-section__membership-container">
      <a
        v-if="supplier.apiccapsId"
        class="membership-section__membership"
        href="https://www.apiccaps.pt"
        target="_blank"
        rel="noopener noreferrer"
        @click="$ga.event('partners', 'APICCAPS click - supplier page')"
      >
        <img
          src="common/assets/external-organizations/apiccaps.png"
          class="membership-section__membership-image"
          alt="APICCAPS member"
        />
      </a>
      <a
        v-if="supplier.satra"
        class="membership-section__membership"
        href="https://www.satra.com"
        target="_blank"
        rel="noopener noreferrer"
        @click="$ga.event('partners', 'SATRA click - supplier page')"
      >
        <span class="membership-section__membership-svg" v-html="svg.satraMember"></span>
      </a>
      <a
        v-if="supplier.ciceg"
        class="membership-section__membership"
        href="https://www.ciceg.org"
        target="_blank"
        rel="noopener noreferrer"
        @click="$ga.event('partners', 'CICEG click - supplier page')"
      >
        <img
          src="common/assets/external-organizations/ciceg.png"
          class="membership-section__membership-image"
          alt="CICEG member"
        />
      </a>
    </div>
  </div>
</template>

<script>
// Icons
// SATRA
// TODO: REVIEW if this really should be statically imported - it's unnecessary
// data to load for all profiles except SATRA members.
import satraMember from 'apps/common/assets/external-organizations/satra-member.svg';

export default {
  props: {
    supplier: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      svg: {
        satraMember,
      },
    };
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

.supplierProfile {
  .membership-section {
    &__header {
      @include heading--small;
      text-align: center;
      margin-top: 0;
      padding-top: math.div(4.8rem, 1.6);
    }

    &__membership-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: math.div(1.4rem, 1.6);
    }

    &__membership {
      height: math.div(10.5rem, 1.6);
      padding: math.div(1.2rem, 1.6);
    }

    &__membership-image {
      height: 100%;
    }

    &__membership-svg {
      height: 100%;

      svg {
        height: 100%;
      }
    }
  }
}
</style>
