<template>
  <fs-pill
    :color="_color"
    circle-sides
    :size="size"
    :tag="tag"
    v-bind="$attrs"
    v-on="$listeners"
    :hover-mode="hoverMode"
    :style="style"
    class="status-pill"
  >
    <div
      v-if="ghost && statusType === 'article'"
      class="status-pill__icon"
      v-html="icons.statuses[$store.articles.statusNames[status]]"
      :style="{ fill: _color }"
    />
    {{ label }}
  </fs-pill>
</template>

<script>
// A pre-defined FsPill for status pills
//
// Pros:
// * Consistency - Having it pre-defined ensures consistency.
// * Easier to use - By having a much smaller interface/API than the
//   full FsPill component, it's faster to get a grip of all relevant
//   properties (by not having non-relevant props here).
// * Easier to refactor - Want to change all status pills? All code in
//   one place.

import FsPill from './fs-pill.vue';
import statusIcons from '@lib/icons-imports/statuses';

export default {
  name: 'FsPillStatus',
  props: {
    // String: The color of the pill, styled based on type (or, should be based on solid/outlined - see below)
    // Object: Style properties, fully managed (basically passed directly into the style-attribute)
    // Can be used as override to status
    color: {
      type: [Object, String],
    },
    status: {
      type: [Number, String],
      required: true,
      // See store/articles/status for valid options
    },
    // FUTURE: If we need to support router-link, then look at fs-button on how to implement it
    tag: {
      type: String,
      default: 'span',
    },
    size: {
      type: String,
      validator: (size) => ['small', 'medium', 'large'].includes(size),
      default: 'large',
    },
    hoverMode: { type: String, validator: (mode) => ['add', 'remove'].includes(mode) },
    ghost: { type: Boolean, default: false },
    statusType: { type: String, default: 'article', validator: (type) => ['article', 'component'].includes(type) },
  },
  components: {
    FsPill,
  },

  data() {
    return {
      icons: {
        statuses: statusIcons,
      },
    };
  },

  computed: {
    statusParsed() {
      if (!isNaN(this.status))
        if (this.statusType === 'component') {
          return this.$store.componentStatuses.getById(this.status);
        } else {
          return this.$store.articles.status[this.status];
        }

      return Object.entries(this.$store.articles.status).find(
        (status) => status.name.toLowerCase() === this.status.toLowerCase()
      );
    },
    _color() {
      return this.color || this.statusParsed.color;
    },
    label() {
      return this.statusParsed.name || this.statusParsed.label;
    },
    style() {
      const styleObj = {};

      if (this.ghost) {
        styleObj.color = this._color;
        styleObj.border = 'none';
        styleObj.background = 'transparent';
      }

      return styleObj;
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
.status-pill {
  &__icon {
    width: math.div(1.7rem, 1.6);
    height: math.div(1.7rem, 1.6);
    margin-right: math.div(0.5rem, 1.6);
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
  }

  ::v-deep .pill__text {
    display: flex;
  }
}
</style>
