var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "brands" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "brands__logos-list" },
      _vm._l(
        _vm.newBrandsList.filter(function (item) {
          return item.logo
        }),
        function (brand) {
          return _c(
            "li",
            {
              key: brand.id,
              staticClass: "brand-single-logo brands__single-logo",
            },
            [
              brand.verified
                ? _c("div", {
                    staticClass: "brand-single-logo__checkmark",
                    domProps: {
                      innerHTML: _vm._s(_vm.svg.verifiedCheckmarkIcon),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("img", {
                staticClass: "brand-single-logo__image",
                attrs: {
                  src: _vm.$blobUrl + "/" + brand.logo,
                  alt: brand.name,
                },
              }),
            ]
          )
        }
      ),
      0
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "brands__companies-names" },
      [
        _vm._l(_vm.brandsList, function (brand) {
          return _c(
            "li",
            { key: brand + "old", staticClass: "brands__single-company-name" },
            [_vm._v("\n      " + _vm._s(brand) + "\n    ")]
          )
        }),
        _vm._v(" "),
        _vm._l(
          _vm.newBrandsList.filter(function (item) {
            return !item.logo
          }),
          function (brand) {
            return _c(
              "li",
              {
                key: brand.id + "new",
                staticClass: "brands__single-company-name",
              },
              [_vm._v("\n      " + _vm._s(brand.name) + "\n    ")]
            )
          }
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "brands__header" }, [
      _c("div", { staticClass: "brands__title" }, [_vm._v("Brands")]),
      _vm._v(" "),
      _c("div", { staticClass: "brands__subtitle" }, [_vm._v("we work with")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }