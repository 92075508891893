<template>
  <div
    class="edit-photos-modal"
    :style="{ maxHeight: maxHeight ? maxHeight + 'px' : 'auto', minHeight: maxHeight ? maxHeight + 'px' : 'auto' }"
  >
    <!--    <loading-overlay-->
    <!--      v-if="true"-->
    <!--      class="edit-photos-modal__loading-overlay"-->
    <!--    />-->

    <div
      :class="{
        'edit-photos-modal__uploads-side': true,
        'edit-photos-modal__uploads-side--all-corners-rounded': simpleMode,
      }"
    >
      <div class="edit-photos-modal__content-section">
        <!-- Upload photos progress list -->
        <div
          v-if="imagesInProgressOrUploaded.length > 0"
          :class="{
            'upload-progress-list': true,
            'upload-progress-list--preloader': saving,
            'edit-photos-modal__upload-photos-list': true,
          }"
        >
          <div class="upload-progress-list__main-title">Uploads</div>

          <scrollable-block class="upload-progress-list__files-progress-list-wrapper" shadows-color="light-gray">
            <ul class="upload-progress-list__files-progress-list">
              <li
                v-for="imageInProgress in imagesInProgressOrUploaded"
                :key="imageInProgress.name"
                class="single-file-upload-line upload-progress-list__single-file-progress"
              >
                <div class="single-file-upload-line__header">
                  <div
                    v-if="imageInProgress.progress < 100"
                    :class="`single-file-upload-line__progress-icon
                                single-file-upload-line__progress-icon--spinning
                                single-file-upload-line__progress-icon--spinning${Math.floor(Math.random() * 7 + 1)}`"
                    v-html="svg.loadingSpinnerIcon"
                  ></div>
                  <div
                    v-if="imageInProgress.progress === 100"
                    class="single-file-upload-line__progress-icon"
                    v-html="svg.checkMarkCircleIcon"
                  ></div>
                  <div class="single-file-upload-line__file-name">
                    {{ imageInProgress.name }}
                  </div>
                  <div class="single-file-upload-line__file-size">{{ Math.round(imageInProgress.size / 1024) }} KB</div>
                </div>
                <div class="single-file-upload-line__progress-bar">
                  <div
                    class="single-file-upload-line__progress-bar-scale"
                    :style="{ width: `${imageInProgress.progress}%` }"
                  >
                    &nbsp;
                  </div>
                  <div class="single-file-upload-line__progress-percentage">{{ imageInProgress.progress }}%</div>
                </div>
              </li>
            </ul>
          </scrollable-block>
        </div>

        <!-- Upload Drag&Drop Area -->
        <label
          v-else
          :for="inputFileId"
          :class="{
            'upload-drag-drop-area': true,
            'upload-drag-drop-area--hovered': isDragNDropAreaHovered,
            'edit-photos-modal__upload-drag-drop-area': true,
          }"
          @drop.prevent="uploadEventHandler"
          @dragover.prevent="isDragNDropAreaHovered = true"
          @dragleave.prevent="isDragNDropAreaHovered = false"
        >
          <span class="upload-drag-drop-area__drop-zone">
            <span class="upload-drag-drop-area__icon" v-html="svg.uploadArrowCloudIcon"></span>
            <span class="upload-drag-drop-area__title"> Drag Images To Upload </span>
            <span class="upload-drag-drop-area__description">
              <p>
                - Optimal resolution is 1500x1000px<br />
                - Maximum file size 5 mb
              </p>

              <p>
                - Optimal Main Image:<br />
                PNG-file, transparent background
              </p>
            </span>
          </span>

          <span class="upload-drag-drop-area__or-word"> or </span>
        </label>
      </div>

      <div v-if="!imagesInProgressOrUploaded.length" class="edit-photos-modal__bottom-buttons-panel">
        <label
          class="edit-photos-modal__add-files-but-label"
          :for="inputFileId"
          @drop.prevent="uploadEventHandler"
          @dragover.prevent
        >
          <fs-button class="edit-photos-modal__add-files-but" secondary>
            <fs-button-icon :icon="svg.photoPlaceholderIcon" width="1rem" height="1rem" top="-1px" color="dark-blue" />
            Browse images
          </fs-button>
        </label>

        <input
          class="edit-photos-modal__add-files-input"
          type="file"
          :id="inputFileId"
          multiple
          @change.prevent="uploadEventHandler"
        />
      </div>
    </div>

    <div v-if="!simpleMode" class="edit-photos-modal__photos-list-side">
      <!-- All these drag listeners with stop modifier needed
            to prevent gallery instances switch to show "droppable area overlay" -->
      <div
        class="edit-photos-modal__content-section"
        @dragstart.stop
        @dragend.stop
        @dragover.stop
        @dragleave.stop
        @drop.prevent
      >
        <div class="edit-photos-modal__main-title">
          Product Images
          <div class="edit-photos-modal__title-note">
            <div class="edit-photos-modal__title-note-icon" v-html="svg.moveAllSidesIcon"></div>
            Drag to rearrange
          </div>
        </div>

        <scrollable-block class="edit-photos-modal__images-table-wrapper">
          <div class="edit-photos-modal__images-table" tabindex="0" @keyup.enter="save">
            <draggable
              v-model="localImages"
              v-bind="dragOptions"
              @start="dragNdropState = true"
              @end="dragNdropState = false"
              :component-data="draggableComponentData"
            >
              <transition-group type="transition" :name="!dragNdropState ? 'flip-list' : null">
                <div
                  v-for="(image, index) in localImages"
                  :key="image.editId"
                  :class="{
                    'uploaded-image-cell': true,
                    'uploaded-image-cell--deleted': image.deleted,
                    'uploaded-image-cell--with-gray-border': index === 0 && !image.deleted,
                    'edit-photos-modal__single-image-cell': true,
                  }"
                >
                  <div
                    v-if="!image.deleted"
                    class="uploaded-image-cell__delete-button"
                    v-html="svg.trashCanIcon"
                    @click="toggleDeleted(image)"
                  ></div>

                  <div v-if="image.deleted" class="uploaded-image-cell__restore-button" @click="toggleDeleted(image)">
                    restore
                  </div>

                  <div class="uploaded-image-cell__move-cross-icon" v-html="svg.moveAllSidesIcon"></div>

                  <div v-if="index === 0 && !image.deleted" class="uploaded-image-cell__main-image-badge">
                    <div class="uploaded-image-cell__main-image-badgeIcon" v-html="svg.starOutlinedIcon"></div>
                    <div class="uploaded-image-cell__main-image-badgeText">Main Image</div>
                  </div>

                  <div
                    class="uploaded-image-cell__photo-container"
                    :style="{ backgroundImage: `url(${$blobUrl}/${encodeURIComponent(image.url)})` }"
                  >
                    &nbsp;
                  </div>

                  <div class="uploaded-image-cell__file-name">
                    {{ refineFileName(image.url) }}
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </scrollable-block>
      </div>

      <div class="edit-photos-modal__bottom-buttons-panel">
        <fs-button
          class="edit-photos-modal__bottom-button"
          secondary
          @click.native="
            ($event) => {
              fsPopperClose($event);
              $emit('cancel', $event);
            }
          "
        >
          <fs-button-icon
            :icon="svg.closeCircleOutlineButton"
            width="1rem"
            height="1rem"
            top="-1px"
            color="dark-blue"
          />
          Cancel
        </fs-button>

        <fs-button class="edit-photos-modal__bottom-button" @click="save" :loading="saving">
          <fs-button-icon :icon="svg.saveFloppyIcon" width="1rem" height="1rem" top="-1px" color="white" />
          Save
        </fs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { fsPopperClose } from '@components/poppers/fs-popper.vue';
import { v4 as uuidv4 } from 'uuid';

// Components
import Draggable from 'vuedraggable';
import FsButton from '@components/buttons/fs-button.vue';
import FsButtonIcon from '@components/fs-button-icon.vue';
import ScrollableBlock from '@components/scrollable-block.vue';

// Icons
import moveAllSidesIcon from '@icons/other/move-all-sides-icon.svg';
import uploadArrowCloudIcon from '@icons/other/upload-arrow-cloud-icon.svg';
import photoPlaceholderIcon from '@icons/other/photo-placeholder-icon.svg';
import closeCircleOutlineButton from '@icons/other/close-circle-outline-icon.svg';
import saveFloppyIcon from '@icons/other/save-floppy-icon.svg';
import starOutlinedIcon from '@icons/other/star-outlined-icon.svg';
import trashCanIcon from '@icons/other/trash-can-icon.svg';
import checkMarkCircleIcon from '@icons/other/check-mark-circle-icon.svg';
import loadingSpinnerIcon from '@icons/other/loading-spinner-icon.svg';

export default {
  components: { Draggable, FsButton, FsButtonIcon, ScrollableBlock },

  props: {
    filesToUpload: {
      // Since FileList doesn't exist in node, we can't use it here (even though it'll
      // only ever be called client-side we'll get a ReferenceError when doing SSR).
      // type: [Array, FileList] // FileList or Array of File (converted from FileList)
    },
    images: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: Number,
    },
    saveFunc: {
      type: Function,
      required: true,
    },
    simpleMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    // Add local editId to use in for loops as key
    const images = this.images.map((image) => {
      return {
        ...image,
        editId: uuidv4(),
        deleted: false,
      };
    });

    return {
      draggableComponentData: {
        props: {
          type: 'transition',
          name: 'flip-list',
        },
      },
      dragNdropState: null,
      dragOptions: {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'uploaded-image-cell--drag-drop-ghost',
      },

      isDragNDropAreaHovered: false,

      imagesInProgress: [],
      imagesUploaded: [],
      inputFileId: 'uploadPhotosInput-' + uuidv4(), // To avoid collision with other instances of this component
      localImages: images,
      pendingTimeouts: [],
      saving: false,
      svg: {
        moveAllSidesIcon,
        uploadArrowCloudIcon,
        photoPlaceholderIcon,
        closeCircleOutlineButton,
        saveFloppyIcon,
        starOutlinedIcon,
        trashCanIcon,
        checkMarkCircleIcon,
        loadingSpinnerIcon,
      },
    };
  },

  computed: {
    imagesInProgressOrUploaded() {
      return [...this.imagesInProgress, ...this.imagesUploaded];
    },
  },

  methods: {
    fsPopperClose,

    allUploaded() {
      // If saving is on hold (due to not all images being uploaded), resume
      if (this.saving) return this.save();

      // If simple mode, save automatically when the last image has been uploaded
      if (this.simpleMode) return this.save();
    },

    // TODO: REFACTOR into format helper fn? (and rename to something more clear what it does)
    refineFileName(imageURLstring) {
      let imageName = (imageURLstring || '').replace(/\/([\w-\s]+\/){1,}/g, '');

      if (imageName.length > 20) {
        imageName = imageName.slice(0, 5) + '...' + imageName.slice(imageName.length - 7, imageName.length);
      }

      return imageName;
    },

    async save() {
      this.saving = true;

      // Put saving on hold while we wait for any remaining images to upload (last image will re-trigger this method)
      if (this.imagesInProgress.length) return;

      this.$emit('upload-finish');

      // Filter out removed images
      const images = this.localImages
        .filter((image) => !image.deleted)
        .map((image, index) => {
          // Re-create order of all images from 0 -> n
          image = {
            ...image,
            order: index,
          };

          // Remove "local" id and deleted prop, since it'Ws irrelevant for parent
          delete image.editId;
          delete image.deleted;

          return image;
        });

      await this.saveFunc(images);

      this.saving = false;
      fsPopperClose(this.$el);
      this.$emit('save-finish');
    },

    toggleDeleted(image) {
      image.deleted = !image.deleted;
    },

    uploadImage(file) {
      const imageInProgress = {
        id: uuidv4(),
        name: file.name,
        size: file.size,
        status: 0,
        progress: 0,
      };
      this.imagesInProgress.push(imageInProgress);

      const formData = new FormData();
      formData.append('file', file);

      const updateProgress = (percentage) => (imageInProgress.progress = percentage);

      return this.$store.companies.uploadFile(formData, updateProgress).then(({ file: url }) => {
        // Move from in progress to uploaded
        this.imagesUploaded.unshift(...this.imagesInProgress.splice(this.imagesInProgress.indexOf(imageInProgress), 1));

        // Schedule removal to avoid having too many images in the left list (unless simple mode)
        if (!this.simpleMode) {
          const timeout = setTimeout(() => {
            this.imagesUploaded.splice(this.imagesUploaded.indexOf(imageInProgress), 1);

            // Remove as pending timeout
            this.pendingTimeouts = this.pendingTimeouts.filter((to) => to !== timeout);
          }, 3 * 1000);

          this.pendingTimeouts.push(timeout);
        }

        // Add to images
        const largestExistingOrderNumber = Math.max(...this.localImages.map((image) => image.order)) || 0;

        this.localImages.push({
          editId: imageInProgress.id,
          deleted: false,
          url: url,
          order: largestExistingOrderNumber + 1,
        });

        // If last image, call last image function
        if (!this.imagesInProgress.length) this.allUploaded();
      });
    },

    async uploadEventHandler(event) {
      const files = event.dataTransfer?.files || event.target.files;
      if (!files) return;

      await this.uploadImages(files);
    },

    async uploadImages(files) {
      await Promise.all([...files].map((file) => this.uploadImage(file)));
    },
  },

  created() {
    if (this.filesToUpload) this.uploadImages(this.filesToUpload);
  },

  beforeDestroy() {
    // Clear/cleanup any pending timeouts to avoid weird post-destroy issues (running code without instance)
    this.pendingTimeouts.forEach((timeout) => clearTimeout(timeout));
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// Single file upload line
.single-file-upload-line {
  &__header {
    height: math.div(3.3rem, 1.6);
    padding-left: math.div(5.2rem, 1.6);
    margin-bottom: math.div(1.3rem, 1.6);
    position: relative;
  }

  &__progress-icon {
    width: math.div(2.9rem, 1.6);
    height: math.div(2.9rem, 1.6);
    position: absolute;
    top: math.div(0.3rem, 1.6);
    left: 0;

    ::v-deep svg {
      fill: rgba(53, 73, 94, 1);
    }

    &--spinning {
      ::v-deep svg {
        animation: spinningProgressIcon;
        animation-duration: 0.7s;
        animation-iteration-count: infinite;
        animation-timing-function: step-end;
      }

      &1 {
        ::v-deep svg {
          animation-duration: 0.5s;
        }
      }
      &2 {
        ::v-deep svg {
          animation-duration: 1.1s;
        }
      }
      &3 {
        ::v-deep svg {
          animation-duration: 0.8s;
        }
      }
      &4 {
        ::v-deep svg {
          animation-duration: 0.9s;
        }
      }
      &5 {
        ::v-deep svg {
          animation-duration: 0.7s;
        }
      }
      &6 {
        ::v-deep svg {
          animation-duration: 0.6s;
        }
      }
    }
  }

  @keyframes spinningProgressIcon {
    0% {
      transform: rotate(0);
    }
    11% {
      transform: rotate(40deg);
    }
    22% {
      transform: rotate(80deg);
    }
    33% {
      transform: rotate(120deg);
    }
    44% {
      transform: rotate(160deg);
    }
    55% {
      transform: rotate(200deg);
    }
    66% {
      transform: rotate(240deg);
    }
    77% {
      transform: rotate(280deg);
    }
    88% {
      transform: rotate(320deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__file-name {
    width: 100%;
    max-width: math.div(15rem, 1.6);
    margin-bottom: math.div(0.2rem, 1.6);
    position: relative;
    overflow: hidden;
    font-size: math.div(1.6rem, 1.6);
    color: rgba(53, 73, 94, 1);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__file-size {
    font-size: math.div(1.2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
  }

  &__progress-bar {
    width: math.div(20.2rem, 1.6);
    height: math.div(0.8rem, 1.6);
    border-radius: math.div(10rem, 1.6);
    position: relative;
    background: rgba(53, 73, 94, 0.25);
  }

  &__progress-bar-scale {
    height: math.div(0.8rem, 1.6);
    border-radius: math.div(10rem, 1.6);
    background: rgba(53, 73, 94, 1);
  }

  &__progress-percentage {
    width: math.div(1.5rem, 1.6);
    height: math.div(0.8rem, 1.6);
    position: absolute;
    top: math.div(-0.2rem, 1.6);
    right: math.div(-2.7rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
  }
}

// ============================================================================
// Upload photos progress list
.upload-progress-list {
  position: relative;

  &--preloader {
    &::before {
      content: '';
      width: calc(100% + #{math.div(2rem, 1.6)});
      height: calc(100% + #{math.div(2rem, 1.6)});
      position: absolute;
      top: math.div(-1rem, 1.6);
      left: math.div(-1rem, 1.6);
      z-index: 2;
      background: rgba(250, 250, 250, 0.6);
    }

    &::after {
      content: '';
      width: math.div(6rem, 1.6);
      height: math.div(6rem, 1.6);
      position: absolute;
      top: calc(50% - #{math.div(3rem, 1.6)});
      left: calc(50% - #{math.div(3rem, 1.6)});
      z-index: 3;
      background: 50% 50%/62% no-repeat;
      background-image: url(../../../../common/assets/icons/other/spinner_icon.png);
      animation: 0.7s step-start 0s infinite running spinning;
    }

    @keyframes spinning {
      0% {
        transform: rotate(0);
      }
      12% {
        transform: rotate(45deg);
      }
      25% {
        transform: rotate(90deg);
      }
      37% {
        transform: rotate(135deg);
      }
      50% {
        transform: rotate(180deg);
      }
      62% {
        transform: rotate(225deg);
      }
      75% {
        transform: rotate(270deg);
      }
      87% {
        transform: rotate(315deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__main-title {
    margin-bottom: math.div(2.3rem, 1.6);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    font-size: math.div(2rem, 1.6);
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    color: rgb(53, 73, 94);
  }

  &__files-progress-list-wrapper {
    width: math.div(25rem, 1.6);
    height: math.div(45rem, 1.6);
    max-height: calc(100% - #{math.div(5rem, 1.6)});
  }
  &__files-progress-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__single-file-progress {
    margin-bottom: math.div(2.4rem, 1.6);
  }
}

// ============================================================================
// Uploaded image cell
.uploaded-image-cell {
  width: math.div(12.7rem, 1.6);
  height: math.div(12.7rem, 1.6);
  margin: 0 math.div(3rem, 1.6) math.div(3rem, 1.6) 0;
  position: relative;
  user-select: none;
  cursor: grab;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: math.div(1rem, 1.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background: rgba(53, 73, 94, 0.7);
    opacity: 0;
    transition: opacity 0.15s ease;
  }

  &--drag-drop-ghost {
    &::after {
      opacity: 1;
    }
  }

  &__move-cross-icon {
    width: math.div(3.2rem, 1.6);
    height: math.div(3.2rem, 1.6);
    position: absolute;
    top: calc(50% - #{math.div(1.6rem, 1.6)});
    left: calc(50% - #{math.div(1.6rem, 1.6)});
    z-index: 4;
    opacity: 0;
    transition: opacity 0.15s ease;

    ::v-deep svg {
      width: math.div(3.2rem, 1.6);
      height: math.div(3.2rem, 1.6);
      fill: #fff;
    }
  }

  &--drag-drop-ghost {
    .uploaded-image-cell__move-cross-icon {
      opacity: 1;
    }
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  &--with-gray-border {
    &::before {
      content: '';
      width: calc(100% + #{math.div(1rem, 1.6)});
      height: calc(100% + #{math.div(1rem, 1.6)});
      box-sizing: border-box;
      border: 2px rgba(53, 73, 94, 0.25) solid;
      border-radius: math.div(1.5rem, 1.6);
      position: absolute;
      top: math.div(-0.5rem, 1.6);
      left: math.div(-0.5rem, 1.6);
      user-select: none;
    }
  }

  &__delete-button,
  &__restore-button {
    width: math.div(2.4rem, 1.6);
    height: math.div(2.4rem, 1.6);
    margin: math.div(0.3rem, 1.6) 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    top: math.div(0.5rem, 1.6);
    right: math.div(0.8rem, 1.6);
    z-index: 5;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.15s ease;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px #35495e solid;
      border-radius: math.div(10rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: scale(1);
      background: #efefef;
      transition: transform 0.15s ease, border-color 0.15s ease, background-color 0.15s ease;
    }

    &::after {
      content: '';
      width: calc(100% + #{math.div(1.6rem, 1.6)});
      height: calc(100% + #{math.div(1.6rem, 1.6)});
      border-radius: math.div(10rem, 1.6);
      position: absolute;
      left: math.div(-0.8rem, 1.6);
    }

    ::v-deep svg {
      width: math.div(1.2rem, 1.6);
      transition: fill 0.15s ease;
      fill: #35495e;
    }

    &:hover {
      &::before {
        border-color: rgb(235, 31, 31);
        transform: scale(1.13);
        background: #fff;
      }

      ::v-deep svg {
        fill: rgba(235, 31, 31, 1);
      }
    }
  }

  &__restore-button {
    width: math.div(6rem, 1.6);
    font-size: math.div(1.1rem, 1.6);
    text-transform: uppercase;

    &:hover {
      &::before {
        border-color: rgb(31, 235, 31);
      }
    }
  }

  &:hover .uploaded-image-cell__delete-button,
  &:hover .uploaded-image-cell__restore-button {
    opacity: 1;
  }

  &__main-image-badge {
    width: 100%;
    height: math.div(2.2rem, 1.6);
    padding-bottom: 1px;
    border-radius: math.div(1rem, 1.6) math.div(1rem, 1.6) 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgb(194, 199, 204);
  }

  &__main-image-badgeIcon {
    width: math.div(1.3rem, 1.6);
    height: math.div(1.3rem, 1.6);
    margin-right: math.div(0.6rem, 1.6);
    position: relative;
    top: 1px;

    ::v-deep svg {
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }
  &__main-image-badgeText {
    font-size: math.div(1.2rem, 1.6);
    color: #fff;
    text-transform: uppercase;
  }

  &__photo-container {
    width: 100%;
    height: 100%;
    border-radius: math.div(1rem, 1.6);
    position: relative;
    z-index: 0;
    overflow: hidden;
    background: #fff 50% 50% / contain no-repeat;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: rgba(0, 0, 0, 0.05);
    }
  }

  &--deleted {
    .uploaded-image-cell__photo-container {
      opacity: 0.07;
    }
  }

  &__file-name {
    width: 100%;
    height: math.div(1.7rem, 1.6);
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: math.div(-2.5rem, 1.6);
    font-size: math.div(1rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
    text-align: center;
  }
}

// ============================================================================
// Upload Drag&Drop area
.upload-drag-drop-area {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  transition: opacity 0.15s ease;

  &:hover,
  &--hovered {
    opacity: 0.6;
  }

  &__drop-zone {
    width: 100%;
    height: math.div(24rem, 1.6);
    padding-top: math.div(0.8rem, 1.6);
    margin-bottom: math.div(1.5rem, 1.6);
    border: 1px rgb(146, 156, 167) dashed;
    border-radius: math.div(1rem, 1.6);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: rgba(53, 73, 94, 0.5);
    background: #fff;
  }

  &__icon {
    width: math.div(7.4rem, 1.6);
    height: math.div(6.8rem, 1.6);

    ::v-deep svg {
      width: math.div(7.4rem, 1.6);
      height: math.div(6.8rem, 1.6);
      fill: rgba(53, 73, 94, 0.5);
    }
  }

  &__title {
    font-size: math.div(2rem, 1.6);
    font-family: 'Montserrat', sans-serif;
  }

  &__description {
    font-size: math.div(1.2rem, 1.6);
    text-align: center;

    ::v-deep p {
      margin-bottom: math.div(1.4rem, 1.6);
    }
  }

  &__or-word {
    font-size: math.div(1.2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
  }
}

// ============================================================================
// Edit product photos modal
.edit-photos-modal {
  min-height: math.div(50rem, 1.6);
  display: flex;
  border-radius: math.div(1rem, 1.6);
  box-shadow: 0 0 math.div(3rem, 1.6) rgba(0, 0, 0, 0.1);

  &__loading-overlay {
    width: 100%;
    height: 100%;
    border-radius: math.div(1.2rem, 1.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__uploads-side {
    width: math.div(32.5rem, 1.6);
    padding: math.div(2.6rem, 1.6) math.div(3.1rem, 1.6) math.div(3.1rem, 1.6);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background: rgb(250, 250, 250);
    border-radius: math.div(1rem, 1.6) 0 0 math.div(1rem, 1.6);

    &--all-corners-rounded {
      border-radius: math.div(1rem, 1.6);
    }
  }

  &__photos-list-side {
    width: math.div(56.5rem, 1.6);
    min-width: math.div(56.5rem, 1.6);
    padding: math.div(3.1rem, 1.6) math.div(3.1rem, 1.6) math.div(3.1rem, 1.6);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  &__content-section {
    width: 100%;
    max-height: 100%;
    margin-bottom: math.div(1.5rem, 1.6);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-grow: 1;
  }

  &__main-title {
    margin-bottom: math.div(1.7rem, 1.6);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    font-size: math.div(2rem, 1.6);
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    color: rgb(53, 73, 94);
  }

  &__title-note {
    padding-top: math.div(0.8rem, 1.6);
    display: flex;
    font-size: math.div(1.2rem, 1.6);
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    color: rgba(53, 73, 94, 0.5);
  }

  &__title-note-icon {
    width: math.div(1.4rem, 1.6);
    height: math.div(1.4rem, 1.6);
    margin: 0 math.div(0.8rem, 1.6) 0 0;
    position: relative;
    top: 1px;

    ::v-deep svg {
      width: 100%;
      height: 100%;
      fill: rgba(53, 73, 94, 0.5);
    }
  }

  &__upload-hotos-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 math.div(1rem, 1.6);
    flex-grow: 1;
  }

  &__upload-photos-list {
    max-height: 100% !important;
    inset: 0 auto auto calc(50% - #{math.div(12rem, 1.6)});
    z-index: 20;
  }

  &__upload-drag-drop-area {
    width: 100%;
    flex-grow: 1;
  }

  &__images-table-wrapper {
    width: 100%;
    height: math.div(40rem, 1.6);
  }

  &__images-table {
    width: 100%;
    padding: math.div(0.5rem, 1.6) 0 math.div(0.5rem, 1.6) math.div(2.5rem, 1.6);
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    outline: none;
  }

  &__images-table > ::v-deep div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__images-table > ::v-deep div > span {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__single-image-cell {
  }

  &__bottom-buttons-panel {
    display: flex;
    justify-content: center;
    position: relative;
  }

  &__bottom-button {
    min-width: math.div(13.5rem, 1.6);
    margin: 0 math.div(1.2rem, 1.6);
  }

  &__add-files-input {
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    top: math.div(2rem, 1.6);
    left: math.div(2rem, 1.6);
    z-index: -1;
  }

  &__add-files-but-label {
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &__add-files-but {
  }
}
</style>
