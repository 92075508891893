<template>
  <div id="components-all-units" class="tabs__tab-content">
    <h3 class="content-header">Our Component Production</h3>
    <company-in-brief :supplier="supplier"></company-in-brief>
    <verified-by-fs :supplier="supplier"></verified-by-fs>
    <div class="component-tables" v-if="filteredTypesOfProduction.length" v-column-balancer>
      <div
        class="component-table"
        v-for="(tableLevel, i) in filteredTypesOfProduction"
        :key="i"
        :class="{ 'component-table--full-width': i === 0 && filteredTypesOfProduction.length % 2 === 1 }"
        v-column-balancer="i === 0 && filteredTypesOfProduction.length % 2 === 1"
      >
        <h4 class="component-table__header">
          <span class="component-table__header-icon" v-html="svg.material"></span>
          <span class="component-table__header-text">{{ tableLevel.title }}</span>
        </h4>
        <div class="component-table__section" v-for="(sectionLevel, i) in tableLevel.options" :key="i">
          <h5 class="component-table__section-header">{{ sectionLevel.title }}</h5>
          <div class="component-table__group" v-for="(groupLevel, i) in sectionLevel.options" :key="i">
            <h6 class="component-table__group-header">{{ groupLevel.groupTitle }}</h6>
            <span class="component-table__pill pill pill--raised" v-for="(option, i) in groupLevel.options" :key="i">{{
              option.title
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <supplier-gallery :supplier="supplier" v-if="supplier.gallery.length" />
    <certification :supplier="supplier"></certification>
    <membership :supplier="supplier"></membership>
  </div>
</template>

<script>
import columnBalancer from 'apps/public/lib/column-balancer';

import Certification from '../sections/certifications.vue';
import CompanyInBrief from './../sections/company-in-brief.vue';
import Membership from './../sections/membership.vue';
const SupplierGallery = () => import('./../sections/gallery.vue');
import VerifiedByFs from './../sections/verified-by-fs.vue';

// Icons
import lastsRelated from 'apps/common/assets/icons/components/lasts-related.svg';
import logistics from 'apps/common/assets/icons/components/logistics.svg';
import material from 'apps/common/assets/icons/components/material.svg';
import solesHeelsRelated from 'apps/common/assets/icons/components/soles-heels-related.svg';
import trims from 'apps/common/assets/icons/components/trims.svg';

export default {
  components: { Certification, CompanyInBrief, Membership, SupplierGallery, VerifiedByFs },
  directives: {
    'column-balancer': columnBalancer,
  },
  props: {
    supplier: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      svg: {
        lastsRelated,
        logistics,
        material,
        solesHeelsRelated,
        trims,
      },
    };
  },
  computed: {
    filteredTypesOfProduction() {
      const filterOptions = (group) => {
        if (group.options) {
          const newGroup = {
            ...group,
            options: group.options.map((option) => filterOptions(option)).filter(Boolean),
          };

          if (newGroup.options.length) return newGroup;

          return null;
        }

        return this.supplier[group.key] ? group : null;
      };

      return (filterOptions({ options: this.$store.suppliers.typeOfProduction }) || {}).options || [];
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

.supplierProfile {
  // TODO: REVIEW - exactly the same as in footwear tab
  // Tabs content (right column)
  .content-header {
    @include heading--large;
    text-align: center;
    padding-bottom: 0.625rem;
  }

  .component-tables {
    columns: 2 26rem;
    column-gap: 2rem;
  }

  .component-table {
    display: inline-flex; // Fixes issues with boxes ending up in multiple columns (cut off);
    flex-direction: column;
    align-items: center;
    border: 1px solid $c-border-gray;
    padding: 0 2rem 1rem 2rem;
    margin-top: 2.125rem;
    width: 100%;

    &--full-width {
      display: block; // Fixes spanning;
      column-span: all;
      columns: 2 21.5rem;
      column-gap: 3rem;
      // column-fill: balance;

      .component-table__section {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $c-dark;
      margin: -1px calc(-2rem - 1px) 1.25rem calc(-2rem - 1px);
      column-span: all;
      width: calc(100% + 4rem + 2px);
    }

    &__header-icon {
      height: 1.5rem;
      width: 1.5rem;
      fill: $c-light;
      margin-right: 1rem;

      // TODO: FIXME - should probably be fixed in icons rather than here;
      &--small {
        height: 1.125rem;
        width: 1.125rem;
        margin-right: 0.875rem;
      }

      &--large {
        height: 2rem;
        width: 2rem;
      }
    }

    &__header-text {
      @include body;
      font-family: 'Lato', sans-serif; // TODO: REVIEW - should we really manage font-family from here?;
      color: $c-light;
      line-height: 2.75rem;
      text-transform: uppercase;
    }

    &__section {
      display: inline-block;
      width: 100%;
      max-width: 25.5rem;
    }

    &__section-header {
      @include heading--small;
      border-bottom: 1px solid $c-dark;
      margin: 0;
      padding: 0.875rem 0.0625rem 0 0.0625rem;
      margin-bottom: 1.5rem;
    }

    &__group {
      display: flex;
      flex-wrap: wrap;
      padding: 0 0 1rem 1rem;
      margin: 0 -0.75rem;
    }

    &__group-header {
      @include body--small;
      font-family: 'Lato', sans-serif; // TODO: REVIEW - should we really manage font-family from here?;
      opacity: 0.6;
      flex: 0 0 100%;
      margin: 0;
      padding: 0 0.75rem 0 0.75rem;
    }

    &__pill {
      margin: 0.625rem 0.75rem !important; // TODO: FIXME - remove !important - but for now we need it due to parent component precedence (on same level)
    }
  }
}
</style>
