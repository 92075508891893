<template>
  <div class="select-input">
    <select-custom
      class="select-input__select"
      :style="{
        width: (selectWidth || calculatedSelectWidth) + 'px',
        maxWidth: (selectWidth || calculatedSelectWidth) + 'px',
      }"
      :placeholder="selectPlaceholder"
      :name="selectName"
      :id="selectId"
      :options="selectOptions"
      :is-disabled="isSelectDisabled"
      :is-error="isSelectError"
    />
    <!-- TODO: refactor emits to be able to shorten most of the events to [EVENT_NAME].sync=..." -->
    <input-custom
      class="select-input__text-input"
      :is-search="isInputSearch"
      :placeholder="inputPlaceholder"
      :value="inputValue"
      :type="inputType"
      :is-disabled="isInputDisabled"
      :is-error="isInputError"
    />
  </div>
  <!-- / select-input -->
</template>

<script>
import InputCustom from '@components/form-elements/input-custom.vue';
import SelectCustom from '@components/form-elements/selects/select-custom.vue';

/**
 * @deprecated This component is deprecated, use fs-select together with input-custom instead
 */
export default {
  components: {
    InputCustom,
    SelectCustom,
  },
  props: {
    // Select props
    selectPlaceholder: String,
    selectName: String,
    selectId: String,
    selectOptions: Array,
    isSelectDisabled: Boolean,
    isSelectError: Boolean,
    selectWidth: Number,
    // Text input props
    inputPlaceholder: String,
    inputValue: String,
    inputType: {
      type: String,
      default: 'text',
    },
    isInputDisabled: Boolean,
    isInputError: Boolean,
    isInputSearch: { type: Boolean, default: false },
  },

  data() {
    return {
      calculatedSelectWidth: 100,
    };
  },

  mounted() {
    this.calculatedSelectWidth = this.calculateSelectWidth();
  },

  methods: {
    calculateSelectWidth() {
      const longestOptionLength =
        [this.selectPlaceholder, ...this.selectOptions].sort((a, b) => (a.length > b.length ? -1 : 1))[0].length || 1;
      return longestOptionLength * 12 > 100 ? longestOptionLength * 12 : 100;
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

.select-input {
  display: flex;
  align-items: center;

  &__text-input {
    width: 50%;
    flex-grow: 1;
    border-radius: 0 math.div(1.8rem, 1.6) math.div(1.8rem, 1.6) 0;
  }

  &__select {
    width: 50%;

    ::v-deep .custom-select__title {
      border-radius: math.div(1.8rem, 1.6) 0 0 math.div(1.8rem, 1.6) !important;
      background: #d6d6d6 !important;
    }

    ::v-deep .custom-select__item {
      //background: #d6d6d6 !important;
    }
  }

  & ::v-deep .select.active .custom-select__title {
    border-radius: math.div(1.8rem, 1.6) 0 0 0 !important;
  }

  ::v-deep .txt.search .txt__search {
    display: none;
  }
}
</style>
