var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.anyCompleted
    ? _c(
        "div",
        { staticClass: "code-of-conduct" },
        [
          _c("div", { staticClass: "code-of-conduct__header" }, [
            _vm._v("Code of Conduct"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.modules, function (module, index) {
            return _c(
              "div",
              {
                key: index,
                class: {
                  "single-conduct-code": 1,
                  "single-conduct-code--inactive":
                    _vm.openedCodeOfConduct !== index &&
                    _vm.openedCodeOfConduct !== null,

                  "code-of-conduct__single-conduct-code": 1,
                  "code-of-conduct__single-conduct-code--social": index === 0,
                  "code-of-conduct__single-conduct-code--environmental":
                    index === 1,
                },
                on: {
                  click: function ($event) {
                    return _vm.toggleCodeOfConduct(index)
                  },
                },
              },
              [
                _c("div", { staticClass: "single-conduct-code__header" }, [
                  _vm._v(_vm._s(module.label)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "single-conduct-code__scale" },
                  _vm._l(module.features.length, function (index) {
                    return _c("div", {
                      key: index,
                      class: {
                        "single-conduct-code__scale-division": 1,
                        "single-conduct-code__scale-division--red":
                          module.count <=
                          Math.ceil(module.features.length * 0.25),
                        "single-conduct-code__scale-division--orange":
                          module.count >
                            Math.ceil(module.features.length * 0.25) &&
                          module.count <=
                            Math.floor(module.features.length * 0.75),
                        "single-conduct-code__scale-division--active":
                          index <= module.count,
                      },
                    })
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "single-conduct-code__subscript" }, [
                  _c(
                    "div",
                    { staticClass: "single-conduct-code__points-counter" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(module.count) +
                          " out of " +
                          _vm._s(module.features.length) +
                          " points\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "single-conduct-code__completion-date" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            module.completed
                              ? "Completed " +
                                  _vm.dateFormatter(
                                    module.completed,
                                    "yyyy-MM-dd"
                                  )
                              : "Module not completed"
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "single-conduct-code__more-details-link": 1,
                      "single-conduct-code__more-details-link--closed":
                        _vm.openedCodeOfConduct !== null,
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "single-conduct-code__more-details-icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.arrowDownIcon) },
                    }),
                    _vm._v("\n      More details\n    "),
                  ]
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.modules, function (module, index) {
            return _c(
              "div",
              {
                key: "details-" + index,
                class: {
                  "code-of-conduct__conduct-details-wrapper": 1,
                  "code-of-conduct__conduct-details-wrapper--social":
                    index === 0,
                  "code-of-conduct__conduct-details-wrapper--environmental":
                    index === 1,
                  "code-of-conduct__conduct-details-wrapper--opened":
                    _vm.openedCodeOfConduct === index,
                },
              },
              [
                _c(
                  "div",
                  {
                    class: {
                      "conduct-details": 1,
                      "conduct-details--social": index === 0,
                      "conduct-details--environmental": index === 1,

                      "code-of-conduct__conduct-details": 1,
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "conduct-details__pointing-arrow",
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "conduct-details__close-but",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.icons.interfaceElements.thinClose
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleCodeOfConduct(index)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(module.features, function (feature, featureIndex) {
                      return _c(
                        "div",
                        {
                          key: featureIndex,
                          class: {
                            "conduct-single-feature": 1,
                            "conduct-single-feature--disabled":
                              !_vm.supplier[feature.key] ||
                              !_vm.supplier[feature.key].checked,

                            "conduct-details__conduct-single-feature": 1,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "conduct-single-feature__name-side",
                            },
                            [
                              _c("div", {
                                staticClass: "conduct-single-feature__icon",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.svg[feature.icon + "Icon"]
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "conduct-single-feature__description",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(feature.label) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              feature.infoText &&
                              _vm.supplier[feature.key] &&
                              _vm.supplier[feature.key].checked
                                ? _c("div", {
                                    staticClass:
                                      "conduct-single-feature__info-but",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.icons.miscellaneous.info
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.$refs[
                                          "codeOfConductInfo-" +
                                            index +
                                            "-" +
                                            featureIndex
                                        ][0].toggle($event)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "fs-popper",
                                {
                                  ref:
                                    "codeOfConductInfo-" +
                                    index +
                                    "-" +
                                    featureIndex,
                                  refInFor: true,
                                  class: {
                                    "conduct-single-feature__info-popper": 1,
                                    "conduct-single-feature__info-popper--wide":
                                      feature.infoText.length > 400,
                                  },
                                  attrs: {
                                    animation: "fade",
                                    arrow: "",
                                    modifiers: [
                                      { name: "flip", enabled: false },
                                      {
                                        name: "preventOverflow",
                                        options: { padding: 16 },
                                      },
                                    ],
                                    offset: [0, 30],
                                    placement: "top",
                                    "wrapper-class":
                                      "popper-wrapper\n                            supplierProfile",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "popover-close",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.fsPopperClose($event)
                                        },
                                      },
                                    },
                                    [_vm._v("+")]
                                  ),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(feature.infoText) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.supplier[feature.key] &&
                          _vm.supplier[feature.key].checked
                            ? _c("div", {
                                staticClass:
                                  "conduct-single-feature__status-icon conduct-single-feature__status-icon--checkmark",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.icons.miscellaneous.checkMark
                                  ),
                                },
                              })
                            : _c("div", {
                                staticClass:
                                  "conduct-single-feature__status-icon conduct-single-feature__status-icon--cross",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.icons.interfaceElements.crossClear
                                  ),
                                },
                              }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: {
                                "conduct-single-feature__description-side": 1,
                                "conduct-single-feature__description-side--red-bg":
                                  !_vm.supplier[feature.key] ||
                                  !_vm.supplier[feature.key].checked,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: {
                                    "conduct-single-feature__description": 1,
                                    "conduct-single-feature__description--no-details":
                                      _vm.supplier[feature.key] &&
                                      _vm.supplier[feature.key].checked &&
                                      !_vm.supplier[feature.key].comment,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.supplier[feature.key] &&
                                          _vm.supplier[feature.key].checked
                                          ? _vm.supplier[feature.key].comment
                                            ? _vm.supplier[feature.key].comment
                                            : "No details"
                                          : "Unfulfilled point"
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.supplier[feature.key] &&
                              _vm.supplier[feature.key].checked &&
                              _vm.supplier[feature.key].file
                                ? _c("a", {
                                    staticClass:
                                      "conduct-single-feature__attachment-but",
                                    attrs: {
                                      href: _vm.supplier[feature.key].file,
                                      target: "_blank",
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.icons.miscellaneous.clip
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }