<template>
  <component :is="tag" v-bind="$attrs" v-on="events">
    <slot></slot>
    <FsPopper
      :animation="animation"
      :beforeClose="beforeClose"
      :defaultClass="defaultClass"
      :modifiers="modifiers"
      :offset="offset"
      :onFirstUpdate="onFirstUpdate"
      :show="show"
      :placement="placement"
      :class="popperClass"
      :style="popperStyle"
      :tag="popperTag"
      :selector="selector"
      :strategy="strategy"
      :[parentScopeId]="''"
      v-bind="popperAttrs"
      v-on="popperListeners"
      ref="popper"
    >
      <slot name="popper"></slot>
    </FsPopper>
  </component>
</template>

<script>
import FsPopper from './fs-popper.vue';

function mergeEvents(self, originalEvent, newEvent) {
  return function () {
    originalEvent.apply(this, arguments);
    newEvent.apply(self, arguments);
  };
}

export default {
  name: 'FsPopperEventWrapper',
  inheritAttrs: false,
  props: {
    animation: {
      // Built in animations: none, fade, slide-fade
      type: String,
      default: 'none',
    },
    beforeClose: {
      type: Function,
      default: () => {},
    },
    defaultClass: {
      type: String,
      default: 'popper',
    },
    modifiers: {
      type: Array,
    },
    offset: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/offset/
      type: [Array, Function],
    },
    onFirstUpdate: {
      type: Function,
    },
    show: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      // Placements taken straight from: https://popper.js.org/docs/v2/constructors/#options
      validator: (placement) =>
        [
          'auto',
          'auto-start',
          'auto-end',
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'right',
          'right-start',
          'right-end',
          'left',
          'left-start',
          'left-end',
        ].includes(placement),
      default: 'bottom',
    },
    popperAttrs: {
      Object,
    },
    popperListeners: {
      Object,
    },
    popperClass: {
      type: [Array, Object, String],
    },
    popperStyle: {
      type: [Array, Object, String],
    },
    popperTag: {
      type: String,
      default: 'div',
    },
    selector: {
      type: String,
    },
    // See Popper docs for options: https://popper.js.org/docs/v2/constructors/#options
    strategy: {
      type: String,
      // Strategies taken straight from: https://popper.js.org/docs/v2/constructors/#options
      validator: (strategy) => ['absolute', 'fixed'].includes(strategy),
      default: 'absolute',
    },
    tag: {
      type: String,
      default: 'div',
    },
    trigger: {
      type: String,
      default: 'click',
    },
    isDelayed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FsPopper,
  },

  data() {
    return {
      openTimeout: null,
    };
  },
  computed: {
    events() {
      // Loosely inspired by v-tooltip: https://github.com/Akryum/v-tooltip/blob/master/src/components/Popover.vue#L485
      const events = { ...this.$listeners };
      const self = this;

      this.trigger.split(' ').forEach((e) => {
        if (e === 'click') {
          events['click'] = events['click'] ? mergeEvents(this, events['click'], this.toggle) : this.toggle;
        }

        if (e === 'focus') {
          events['focus'] = events['focus'] ? mergeEvents(this, events['focus'], this.open) : this.open;
          events['blur'] = events['blur'] ? mergeEvents(this, events['blur'], this.close) : this.close;
        }

        if (e === 'hover') {
          events['mouseenter'] = events['mouseenter'] ? mergeEvents(this, events['mouseenter'], this.open) : this.open;
          events['mouseleave'] = events['mouseleave']
            ? mergeEvents(this, events['mouseleave'], this.close)
            : this.close;
        }
      });

      return events;
    },
    // Workaround for passing scopeId to div.popper (it't not technically the root, but to allow for parent to style with scoped styles - as is expected - it makes sense to add it)
    parentScopeId() {
      return this.$parent.$options._scopeId || null;
    },
    delayInMs() {
      return this.isDelayed ? 600 : 0;
    },
  },
  methods: {
    open(event) {
      this.openTimeout = setTimeout(() => {
        this.$refs.popper?.open(event);
      }, this.delayInMs);
    },
    close(event) {
      clearTimeout(this.openTimeout);
      this.$refs.popper?.close({ type: 'popper-wrapper-event', event });
    },
    toggle(event) {
      this.$refs.popper?.toggle(event);
    },
  },
};
</script>
