var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "suggestions-list-component" },
    [
      _vm.itemsList.length !== 0 && !_vm.isSkeletonVisible
        ? _c(
            "scrollable-block",
            {
              class: {
                "suggestions-list-component__scrollable-container": true,
                "suggestions-list-component__scrollable-container--fixed-height":
                  _vm.itemsList.length + _vm.additionalItemsList.length > 5,
                "suggestions-list-component__scrollable-container--auto-height":
                  _vm.itemsList.length + _vm.additionalItemsList.length <= 5,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "search-result-lists suggestions-list-component__search-result-lists",
                },
                [
                  _c("div", { staticClass: "search-result-lists__section" }, [
                    _vm.itemsListTitle
                      ? _c(
                          "div",
                          { staticClass: "search-result-lists__title" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.itemsListTitle) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "search-result-lists__items-list" },
                      _vm._l(_vm.itemsList, function (listItem) {
                        return _c("list-item", {
                          key: listItem.id,
                          class: {
                            "search-result-lists__single-item": true,
                            "search-result-lists__single-item--not-clickable":
                              _vm.existingItemsIdsList.includes(listItem.id),
                          },
                          attrs: {
                            "list-item": listItem,
                            "already-added": _vm.existingItemsIdsList.includes(
                              listItem.id
                            ),
                            "layout-type": _vm.layoutType,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleListItemClick(listItem)
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.additionalItemsList.length
                    ? _c(
                        "div",
                        { staticClass: "search-result-lists__section" },
                        [
                          _vm.additionalItemsListTitle
                            ? _c(
                                "div",
                                { staticClass: "search-result-lists__title" },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.additionalItemsListTitle) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "search-result-lists__items-list" },
                            _vm._l(
                              _vm.additionalItemsList,
                              function (listItem) {
                                return _c("list-item", {
                                  key: listItem.id,
                                  class: {
                                    "search-result-lists__single-item": true,
                                    "search-result-lists__single-item--not-clickable":
                                      _vm.existingItemsIdsList.includes(
                                        listItem.id
                                      ),
                                  },
                                  attrs: {
                                    "list-item": listItem,
                                    "already-added":
                                      _vm.existingItemsIdsList.includes(
                                        listItem.id
                                      ),
                                    "layout-type": _vm.layoutType,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleListItemClick(listItem)
                                    },
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSkeletonVisible
        ? _c(
            "div",
            {
              staticClass:
                "search-preload-skeleton suggestions-list-component__search-preload-skeleton",
            },
            _vm._l([0, 1, 2, 3, 4, 5, 6, 7, 8], function (listItem) {
              return _c(
                "div",
                {
                  key: listItem,
                  class: {
                    "skeleton-item": true,
                    "search-preload-skeleton__skeleton-item": true,
                    "search-preload-skeleton__skeleton-item--small-bot-margin":
                      _vm.skeletonType === "title-subtitle-horizontal",
                  },
                },
                [
                  _vm.skeletonType === "flag-title-subtitle-vertical" ||
                  _vm.skeletonType === "flag-title-subtitle-horizontal"
                    ? _c("div", { staticClass: "skeleton-item__flag" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "skeleton-item__text-wrap": true,
                        "skeleton-item__text-wrap--horizontal":
                          _vm.skeletonType === "title-subtitle-horizontal",
                      },
                    },
                    [
                      _c("div", {
                        class: {
                          "skeleton-item__title": true,
                          "skeleton-item__title--fixed-width":
                            _vm.skeletonType === "title-subtitle-horizontal",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        class: {
                          "skeleton-item__subscript": true,
                          "skeleton-item__subscript--fixed-width":
                            _vm.skeletonType === "title-subtitle-horizontal",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "skeleton-item__plus-icon" }),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isSkeletonVisible && _vm.itemsList.length === 0
        ? _c(
            "div",
            {
              staticClass: "suggestions-list-component__no-results-placeholder",
            },
            [_vm._v("\n    No results\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "suggestions-list-component__bottom-panel",
          on: {
            click: function ($event) {
              return _vm.$emit("create-new")
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "add-new-element-btn suggestions-list-component__add-new-element-btn",
            },
            [
              _c("span", {
                staticClass: "add-new-element-btn__plus-icon",
                domProps: { innerHTML: _vm._s(_vm.svg.addCirclePlus) },
              }),
              _vm._v("\n      " + _vm._s(_vm.createBtnText) + "\n    "),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }