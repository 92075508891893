var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: function () {
            return (_vm.hintsDropdownForceHide = true)
          },
          expression: "() => (hintsDropdownForceHide = true)",
        },
      ],
      staticClass: "input-country-with-hints",
    },
    [
      _c(
        "input-custom",
        {
          ref: "inputSearchQuery",
          class: {
            "input-country-with-hints__search-input": true,
            "input-country-with-hints__search-input--active":
              _vm.searchQuery !== "" && !_vm.hintsDropdownForceHide,
          },
          attrs: {
            placeholder: _vm.placeholder,
            "autocomplete-off": _vm.autocompleteOff,
            tabindex: -100,
          },
          on: {
            "reset-input": function ($event) {
              _vm.searchQuery = ""
            },
            input: function ($event) {
              _vm.hintsDropdownForceHide = false
            },
            focus: function ($event) {
              _vm.hintsDropdownForceHide = false
            },
            blur: function ($event) {
              _vm.hintsDropdownForceHide = true
            },
          },
          model: {
            value: _vm.searchQuery,
            callback: function ($$v) {
              _vm.searchQuery = $$v
            },
            expression: "searchQuery",
          },
        },
        [
          _vm.$store.countries.all.includes(_vm.searchQuery)
            ? _c("img", {
                staticClass: "input-country-with-hints__country-flag",
                attrs: {
                  src: _vm.$store.countries.getFlagPathFromName(
                    _vm.searchQuery
                  ),
                  alt: _vm.searchQuery,
                },
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fall-from-top-with-fade" } }, [
        _vm.searchQuery !== "" && !_vm.hintsDropdownForceHide
          ? _c(
              "div",
              {
                staticClass:
                  "hints-dropdown input-country-with-hints__hints-dropdown",
              },
              [
                !_vm.searchInProgress &&
                _vm.filteredSearchResult.length !== 0 &&
                _vm.searchQuery.length
                  ? _c(
                      "scrollable-block",
                      {
                        class: {
                          "hints-dropdown__scrollable-container": true,
                          "hints-dropdown__scrollable-container--height-auto":
                            _vm.filteredSearchResult.length < 4,
                        },
                        attrs: { "shadows-color": "dark-gray" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "search-result-list hints-dropdown__search-result-list",
                          },
                          _vm._l(
                            _vm.filteredSearchResult ||
                              _vm.$store.countries.all,
                            function (searchResultItem, index) {
                              return _c(
                                "div",
                                {
                                  key: searchResultItem,
                                  ref: "single-search-element-" + index,
                                  refInFor: true,
                                  class: {
                                    "single-search-element": true,
                                    "single-search-element--clickable": true,
                                    "single-search-element--focused":
                                      _vm.focusedListItem === index,
                                    "search-result-list__single-search-element": true,
                                  },
                                  on: {
                                    mouseenter: function ($event) {
                                      _vm.focusedListItem = index
                                    },
                                    click: function ($event) {
                                      _vm.searchQuery = searchResultItem
                                      _vm.hintsDropdownForceHide = true
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "single-search-element__flag-icon",
                                    attrs: {
                                      src: _vm.$store.countries.getFlagPathFromName(
                                        searchResultItem
                                      ),
                                      alt: searchResultItem,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "single-search-element__name-n-country",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "single-search-element__name",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(searchResultItem) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchInProgress
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "search-preload-skeleton hints-dropdown__search-preload-skeleton",
                      },
                      _vm._l([0, 1, 2, 3, 4, 5], function (item) {
                        return _c(
                          "div",
                          {
                            key: item,
                            staticClass:
                              "skeleton-item search-preload-skeleton__skeleton-item",
                          },
                          [
                            _c("div", { staticClass: "skeleton-item__flag" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "skeleton-item__text-wrap" },
                              [
                                _c("div", {
                                  staticClass: "skeleton-item__title",
                                }),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.searchInProgress && _vm.filteredSearchResult.length === 0
                  ? _c(
                      "div",
                      { staticClass: "hints-dropdown__no-results-placeholder" },
                      [_vm._v("\n        No results\n      ")]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }