<template>
  <!-- Contact persons form -->
  <div class="contact-persons-form">
    <div class="contact-persons-form__main-title">Contact Person</div>

    <!-- Persons list -->
    <ul class="persons-list contact-persons-form__persons-list">
      <!-- Separate person form -->
      <inputs-section-frame
        v-for="(person, index) in personsData"
        :key="person.fullName"
        tag="li"
        class="persons-list__separate-person-form"
        show-drag-handle
        show-delete-but
      >
        <!-- Form elements list -->
        <div class="form-elements-list">
          <div class="form-elements-list__form-element-wrap">
            <input-custom
              class="form-elements-list__text-input"
              placeholder="Full Name"
              v-model.trim="personsData[index].fullName"
              no-clear-button
            />
          </div>

          <div class="form-elements-list__form-element-wrap">
            <input-custom
              class="form-elements-list__text-input"
              placeholder="Email"
              v-model.trim="personsData[index].email"
              no-clear-button
            />
          </div>

          <div class="form-elements-list__form-element-wrap">
            <select-with-input
              class="form-elements-list__select-input"
              select-placeholder="Code"
              :select-options="['+38', '+39', '+40', '+41']"
              input-placeholder="Phone Number"
              v-model.trim="personsData[index].phoneNumber"
            />
          </div>

          <div class="form-elements-list__form-element-wrap">
            <input-custom
              class="form-elements-list__text-input"
              placeholder="Skype"
              v-model.trim="personsData[index].skype"
              no-clear-button
              :additional-icon="icons.socials.skype"
            />
          </div>

          <div class="form-elements-list__form-element-wrap">
            <input-custom
              class="form-elements-list__text-input"
              placeholder="LinkedIn profile"
              v-model.trim="personsData[index].linkedIn"
              no-clear-button
              :additional-icon="icons.socials.linkedin"
            />
          </div>

          <div class="form-elements-list__form-element-wrap">
            <input-custom
              class="form-elements-list__text-input"
              placeholder="WhatsApp"
              v-model.trim="personsData[index].whatsApp"
              no-clear-button
              :additional-icon="icons.socials.whatsapp"
            />
          </div>

          <div class="form-elements-list__form-element-wrap">
            <input-custom
              class="form-elements-list__text-input"
              placeholder="WeChat"
              v-model.trim="personsData[index].weChat"
              no-clear-button
              :additional-icon="icons.socials.wechat"
            />
          </div>
        </div>
        <!-- / Form elements list --> </inputs-section-frame
      ><!-- / Separate person form -->
    </ul>
    <!-- / Persons list -->

    <fs-button secondary class="contact-persons-form__add-person-but">
      <fs-button-icon
        :icon="icons.interfaceElements.plusInCircleIcon"
        width="1.5rem/1.6"
        height="1.5rem/1.6"
        top="0"
        color="dark-blue"
      />
      Add Contact Person
    </fs-button>

    <cancel-n-action-btn-pair
      class="contact-persons-form__manage-buttons-panel"
      @cancel="() => {}"
      @action="() => {}"
    />
  </div>
  <!-- / Contact persons form -->
</template>

<script>
// Components
import FsButton from '@components/buttons/fs-button.vue';
import FsButtonIcon from '@components/fs-button-icon.vue';
import InputCustom from '@components/form-elements/input-custom.vue';
import SelectWithInput from '@components/form-elements/selects/select-with-input.vue';
import InputsSectionFrame from '@components/inputs-section-frame.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
import socialsIcons from '@lib/icons-imports/socials';
// SVG
import photoPlaceholderIcon from '@icons/other/photo-placeholder-icon.svg';

export default {
  name: 'contact-persons-list',

  props: {},

  components: { FsButton, FsButtonIcon, InputCustom, SelectWithInput, InputsSectionFrame, CancelNActionBtnPair },

  data() {
    return {
      personsData: [{ fullName: '', email: '', phoneNumber: '', skype: '', linkedIn: '', whatsApp: '', weChat: '' }],

      isSaveInProgress: false,

      icons: {
        interfaceElements: interfaceElementsIcons,
        socials: socialsIcons,
      },

      svg: { photoPlaceholderIcon },
    };
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// ============================================================================
// Form elements list
.form-elements-list {
  &__form-element-wrap {
    height: math.div(3.6rem, 1.6);
    margin-bottom: math.div(1.2rem, 1.6);
    display: flex;
    position: relative;
  }

  &__form-element-icon {
    width: math.div(1.5rem, 1.6);
    height: math.div(1.5rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    top: math.div(1rem, 1.6);
    left: math.div(1.6rem, 1.6);
    z-index: 2;
    pointer-events: none;

    ::v-deep svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text-input {
    flex-grow: 1;
  }
}

// ============================================================================
// ============================================================================
// Persons list
.persons-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__separate-person-form {
    margin-bottom: math.div(3.5rem, 1.6);
  }
}

// ============================================================================
// ============================================================================
// Contact persons form
.contact-persons-form {
  width: math.div(55rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(5rem, 1.6);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  &__main-title {
    margin-bottom: math.div(2.7rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__persons-list {
    width: 100%;
  }

  &__add-person-but {
    margin-bottom: math.div(3.5rem, 1.6);
    border-color: white !important;
    outline: none;
    font-weight: 400;
    text-transform: none;
    transition: border-color 0.15s ease, opacity 0.15s ease;

    &:hover {
      border-color: rgb(53, 73, 94) !important;
    }
  }

  &__manage-buttons-panel {
  }
}
</style>
