var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-input-form",
      attrs: { slot: "popover" },
      slot: "popover",
    },
    [
      _vm.title
        ? _c("h3", { staticClass: "text-input-form__title" }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.label
        ? _c("label", { staticClass: "text-input-form__label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("input-custom", {
        ref: "textInput",
        staticClass: "text-input-form__text-input",
        attrs: { placeholder: _vm.placeholder },
        on: {
          "keyup-enter": function ($event) {
            if (_vm.newValue && !_vm.isSaveBtnDisabled) {
              _vm.$emit("save", _vm.newValue)
              _vm.fsPopperClose($event)
            }
          },
        },
        model: {
          value: _vm.newValue,
          callback: function ($$v) {
            _vm.newValue = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "newValue",
        },
      }),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "text-input-form__buttons-section",
        attrs: {
          "action-btn-name": _vm.saveBtnName,
          "action-btn-icon": _vm.saveBtnIcon,
          "is-disabled": _vm.isSaveBtnDisabled,
          "is-save-in-progress": _vm.saveInProgress,
        },
        on: {
          cancel: function ($event) {
            _vm.$emit("close")
            _vm.fsPopperClose($event)
          },
          action: function ($event) {
            return _vm.$emit("save", _vm.newValue)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }