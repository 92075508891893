<template>
  <div class="speciality-edit-form">
    <div class="speciality-edit-form__main-title">Specialities</div>

    <!-- Form itself -->
    <div class="form-itself speciality-edit-form__form-itself">
      <checkbox-custom-button
        v-for="speciality in specialitiesList"
        :key="speciality"
        class="form-itself__single-speciality"
        @check="() => {}"
        @uncheck="() => {}"
        :additional-icon="icons.specialities[speciality.toLowerCase()]"
        :text-label="speciality"
        size="small"
        clean-design
      />
    </div>
    <!-- / Form itself -->

    <cancel-n-action-btn-pair
      class="speciality-edit-form__manage-buttons-panel"
      @cancel="() => {}"
      @action="() => {}"
    />
  </div>
</template>

<script>
import lodashCamelCase from 'lodash/camelCase';

// Components
import CheckboxCustomButton from '@components/form-elements/checkbox-custom-button.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
import typeNProcessIcons from '@lib/icons-imports/type-n-process';
import specialitiesIcons from '@lib/icons-imports/specialities';

export default {
  name: 'specialities-edit',

  components: {
    CheckboxCustomButton,
    CancelNActionBtnPair,
  },

  data() {
    return {
      specialitiesList: [
        'Dance',
        'Medical',
        'Riding',
        'Sports',
        'Waterproof',
        'Golf',
        'Orthopedic',
        'Safety',
        'Trekking',
      ],

      isSaveInProgress: false,

      icons: {
        interfaceElements: interfaceElementsIcons,
        typeNProcess: typeNProcessIcons,
        specialities: specialitiesIcons,
      },
    };
  },

  methods: {
    lodashCamelCase,
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

// ============================================================================
// ============================================================================
// Form itself
.form-itself {
  width: math.div(30rem, 1.6);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__single-speciality {
    width: calc(50% - #{math.div(1.2rem, 1.6)});
    margin-bottom: math.div(0.7rem, 1.6);
  }
}

// ==========================================================================
// ==========================================================================
// Size range edit form
.speciality-edit-form {
  width: math.div(38.5rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(3.2rem, 1.6);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  &__main-title {
    margin-bottom: math.div(2.8rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__form-itself {
    margin-bottom: math.div(2rem, 1.6);
  }

  &__manage-buttons-panel {
  }
}
</style>
