var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "supply-chain" }, [
    _c("div", { staticClass: "supply-chain__header" }, [
      _vm._v("Supply Chain"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "map-section supply-chain__map-section" },
      [
        _c("suppliers-map", {
          staticClass: "map__map",
          attrs: { countries: _vm.countries, data: _vm.coordinates },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "supplies-list supply-chain__supplies-list" },
      [
        _c(
          "scrollable-block",
          { staticClass: "supplies-list__list-in-wrap" },
          _vm._l(_vm.suppliers, function (supplier) {
            return _c(
              "div",
              {
                key: supplier.name,
                staticClass: "single-supply supplies-list__single-supply",
              },
              [
                _c("div", { staticClass: "single-supply__image-container" }, [
                  _c("img", {
                    staticClass: "single-supply__image",
                    attrs: {
                      src: _vm.imagePath(
                        "images/" + supplier.coverPhoto,
                        960,
                        250
                      ),
                      alt: supplier.name,
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "single-supply__title" }, [
                  _vm._v(_vm._s(supplier.name)),
                ]),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }