var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$store.user.loggedIn
    ? _c(
        "popover-container",
        { attrs: { name: "pageblock", overlayClose: false, escClose: false } },
        [
          _c(
            "popover",
            { attrs: { container: "pageblock", name: "logIn", default: "" } },
            [
              _c("LogIn", {
                attrs: {
                  mode: "popup",
                  email: _vm.email,
                  password: _vm.password,
                  toggled: _vm.toggled,
                },
                on: {
                  "update:email": function ($event) {
                    _vm.email = $event
                  },
                  "update:password": function ($event) {
                    _vm.password = $event
                  },
                  "update:toggled": function ($event) {
                    _vm.toggled = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "popover",
            { attrs: { container: "pageblock", name: "signUp" } },
            [
              _c("SignUp", {
                attrs: {
                  mode: "popup",
                  email: _vm.email,
                  password: _vm.password,
                  toggled: _vm.toggled,
                },
                on: {
                  "update:email": function ($event) {
                    _vm.email = $event
                  },
                  "update:password": function ($event) {
                    _vm.password = $event
                  },
                  "update:toggled": function ($event) {
                    _vm.toggled = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "popover",
            { attrs: { container: "pageblock", name: "forgotPassword" } },
            [
              _c("ForgotPassword", {
                attrs: { mode: "popup", email: _vm.email },
                on: {
                  "update:email": function ($event) {
                    _vm.email = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }