// Inspired by https://github.com/phegman/v-show-slide
const _targets = [];

import camelCase from 'lodash/camelCase';

function _getTargetFromEl(el) {
  return _targets.find((target) => target.el === el);
}

function _transitionEndFactory(el, target) {
  return function (event) {
    if (event.target !== el) return;

    if (target.show) {
      el.style[target.property] = 'auto';
    }

    target.isAnimating = false;
    el.removeEventListener('transitionend', target.transitionEnd);
  };
}

// Helper fn that sets height without transition
function _changeHeightWithoutTransition(el, property, height) {
  return new Promise((resolve, reject) => {
    // To avoid waiting for any current transition, let's "remove" it (make it instant)
    // But first save it, so we can "reset" it afterwards
    const oldTransitionDuration = el.style.transitionDuration;
    const oldTransitionDelay = el.style.transitionDelay;
    el.style.transitionDuration = el.style.transitionDelay = '0s';

    el.style[property] = height;

    // Wait until it's been properly set
    requestAnimationFrame(() => {
      // First, "reset" the transition (timings; duration/delay) to it's original state so we respect what the user's transition
      el.style.transitionDuration = oldTransitionDuration;
      el.style.transitionDelay = oldTransitionDelay;
      // Then, let's move back to parent flow
      resolve();
    });
  });
}

function bind(el, binding) {
  const target = {};

  target.el = el;
  target.isAnimating = false;
  target.property = camelCase(binding.arg) || 'height';
  target.show = false;
  target.transitionEnd = _transitionEndFactory(el, target);

  _targets.push(target);
}

function inserted(el, { value: show }) {
  const target = _getTargetFromEl(el);
  target.show = show;
}

async function componentUpdated(el, { value: show, oldValue: oldShow }) {
  if (show != oldShow) {
    const target = _getTargetFromEl(el);
    const isAnimating = target.isAnimating;

    target.isAnimating = true;
    target.show = show;

    const baseScrollHeight = el.scrollHeight;
    const computedStyle = window.getComputedStyle(el);
    const borderBottom = parseFloat(computedStyle.getPropertyValue('border-bottom-width'));
    const borderTop = parseFloat(computedStyle.getPropertyValue('border-top-width'));
    const currentHeight = computedStyle.getPropertyValue('height');
    const autoHeight = `${baseScrollHeight + borderBottom + borderTop}px`;

    if (show) {
      // To ensure we animate from the proper height value, base it off of computed
      // (since the currently set value might be something else).
      if (!isAnimating) await _changeHeightWithoutTransition(el, target.property, currentHeight);

      el.addEventListener('transitionend', target.transitionEnd);

      // Set the new height that corresponds to auto (caluclated based on border + scroll height)
      el.style[target.property] = autoHeight;
    } else {
      if (!isAnimating) await _changeHeightWithoutTransition(el, target.property, autoHeight);

      el.addEventListener('transitionend', target.transitionEnd);

      el.style[target.property] = ''; // TODO: Rework so that it's reset to it's original value? That way we don't mess with the user's original state.
    }
  }
}

function unbind(el) {
  // Cleanup
  const target = _getTargetFromEl(el);
  el.removeEventListener('transitionend', target.transitionEnd);
  _targets.splice(_targets.indexOf(target), 1);
}

export default {
  bind,
  inserted,
  componentUpdated,
  unbind,
};
