var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox-button" }, [
    _c("label", { staticClass: "checkbox-button__label-wrapper" }, [
      _c(
        "input",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.localIsChecked,
                  expression: "localIsChecked",
                },
              ],
              ref: "input",
              staticClass: "checkbox-button__checkbox-input",
              attrs: { type: "checkbox" },
              domProps: {
                value: _vm._value,
                checked: Array.isArray(_vm.localIsChecked)
                  ? _vm._i(_vm.localIsChecked, _vm._value) > -1
                  : _vm.localIsChecked,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.localIsChecked,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm._value,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.localIsChecked = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.localIsChecked = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.localIsChecked = $$c
                    }
                  },
                  _vm.emitCheckboxState,
                ],
              },
            },
            "input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          class: {
            "checkbox-button__text-label-wrap": true,
            "checkbox-button__text-label-wrap--small": _vm.size === "small",
            "checkbox-button__text-label-wrap--clean-design": _vm.cleanDesign,
          },
        },
        [
          _vm.additionalIcon
            ? _c("span", {
                staticClass: "checkbox-button__icon",
                domProps: { innerHTML: _vm._s(_vm.additionalIcon) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              class: {
                "checkbox-button__text-label": true,
                "checkbox-button__text-label--small": _vm.size === "small",
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.textLabel) + "\n      ")]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }