<template>
  <div class="suggestions-list-component">
    <scrollable-block
      v-if="itemsList.length !== 0 && !isSkeletonVisible"
      :class="{
        'suggestions-list-component__scrollable-container': true,
        'suggestions-list-component__scrollable-container--fixed-height':
          itemsList.length + additionalItemsList.length > 5,
        'suggestions-list-component__scrollable-container--auto-height':
          itemsList.length + additionalItemsList.length <= 5,
      }"
    >
      <!-- Search results lists -->
      <div class="search-result-lists suggestions-list-component__search-result-lists">
        <div class="search-result-lists__section">
          <div v-if="itemsListTitle" class="search-result-lists__title">
            {{ itemsListTitle }}
          </div>

          <ul class="search-result-lists__items-list">
            <list-item
              :class="{
                'search-result-lists__single-item': true,
                'search-result-lists__single-item--not-clickable': existingItemsIdsList.includes(listItem.id),
              }"
              v-for="listItem in itemsList"
              :key="listItem.id"
              :list-item="listItem"
              :already-added="existingItemsIdsList.includes(listItem.id)"
              :layout-type="layoutType"
              @click.native="handleListItemClick(listItem)"
            />
          </ul>
        </div>

        <div v-if="additionalItemsList.length" class="search-result-lists__section">
          <div v-if="additionalItemsListTitle" class="search-result-lists__title">
            {{ additionalItemsListTitle }}
          </div>

          <ul class="search-result-lists__items-list">
            <list-item
              :class="{
                'search-result-lists__single-item': true,
                'search-result-lists__single-item--not-clickable': existingItemsIdsList.includes(listItem.id),
              }"
              v-for="listItem in additionalItemsList"
              :key="listItem.id"
              :list-item="listItem"
              :already-added="existingItemsIdsList.includes(listItem.id)"
              :layout-type="layoutType"
              @click.native="handleListItemClick(listItem)"
            />
          </ul>
        </div>
      </div>
      <!-- / Search results lists -->
    </scrollable-block>

    <!-- Search preload skeleton -->
    <div v-if="isSkeletonVisible" class="search-preload-skeleton suggestions-list-component__search-preload-skeleton">
      <div
        :class="{
          'skeleton-item': true,
          'search-preload-skeleton__skeleton-item': true,
          'search-preload-skeleton__skeleton-item--small-bot-margin': skeletonType === 'title-subtitle-horizontal',
        }"
        v-for="listItem in [0, 1, 2, 3, 4, 5, 6, 7, 8]"
        :key="listItem"
      >
        <div
          v-if="skeletonType === 'flag-title-subtitle-vertical' || skeletonType === 'flag-title-subtitle-horizontal'"
          class="skeleton-item__flag"
        ></div>

        <div
          :class="{
            'skeleton-item__text-wrap': true,
            'skeleton-item__text-wrap--horizontal': skeletonType === 'title-subtitle-horizontal',
          }"
        >
          <div
            :class="{
              'skeleton-item__title': true,
              'skeleton-item__title--fixed-width': skeletonType === 'title-subtitle-horizontal',
            }"
          ></div>
          <div
            :class="{
              'skeleton-item__subscript': true,
              'skeleton-item__subscript--fixed-width': skeletonType === 'title-subtitle-horizontal',
            }"
          ></div>
        </div>

        <div class="skeleton-item__plus-icon"></div>
      </div>
    </div>
    <!-- / Search preload skeleton -->

    <div v-if="!isSkeletonVisible && itemsList.length === 0" class="suggestions-list-component__no-results-placeholder">
      No results
    </div>

    <div class="suggestions-list-component__bottom-panel" @click="$emit('create-new')">
      <div class="add-new-element-btn suggestions-list-component__add-new-element-btn">
        <span class="add-new-element-btn__plus-icon" v-html="svg.addCirclePlus"> </span>
        {{ createBtnText }}
      </div>
    </div>
  </div>
</template>

<script>
// Components
import ListItem from './list-item.vue';
import ScrollableBlock from '@components/scrollable-block.vue';

// Icons
// SVG
import addCirclePlus from '@icons/other/add-circle-plus.svg';
import checkMarkIcon from '@icons/other/check-mark-icon.svg';

export default {
  name: 'selectable-search-suggestions-list',

  props: {
    // Items list data structure:
    /*
    [
      {
        id: [Number,String],
        icon: String, // each item should contain "icon" OR "imageSrc"
        imageSrc: String,
        name: String, // required
        subscript: String
      }
    ]
     */
    itemsList: { type: Array, default: () => [] },
    itemsListTitle: { type: String },
    additionalItemsList: { type: Array, default: () => [] },
    additionalItemsListTitle: { type: String },
    // id's of items that should be hidden from the results list
    existingItemsIdsList: { type: Array, default: () => [] },
    isSkeletonVisible: { type: Boolean, default: false },

    isCreateNewBtnVisible: { type: Boolean, default: false },
    // Name of the entity for "Add new ***" button
    createBtnText: String,
    searchQuery: { type: String, required: true },
    layoutType: { type: String, validator: (type) => ['vertical', 'horizontal'].includes(type), default: 'vertical' },

    skeletonType: {
      type: String,
      validator: (type) =>
        ['flag-title-subtitle-vertical', 'flag-title-subtitle-horizontal', 'title-subtitle-horizontal'].includes(type),
    },
  },

  components: {
    ListItem,
    ScrollableBlock,
  },

  data() {
    return {
      svg: {
        addCirclePlus,
        checkMarkIcon,
      },
    };
  },

  methods: {
    handleListItemClick(listItem) {
      if (!this.existingItemsIdsList.includes(listItem.id)) {
        this.$emit('select-item', listItem);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@import 'common/styles/variables.scss';

// ==========================================================================
// Suppliers lists
.search-result-lists {
  //max-height: math.div(32.0rem, 1.6);
  padding: 0 math.div(1rem, 1.6) 0 0;
  box-sizing: border-box;
  overflow: auto;

  &__section {
    margin-bottom: math.div(1rem, 1.6);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    padding: 0 math.div(1rem, 1.6) math.div(0.6rem, 1.6);
    position: relative;
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
    text-transform: uppercase;

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      inset: auto auto 0 0;
      background: rgba(53, 73, 94, 0.1);
    }
  }

  &__items-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__single-item {
    margin: 0;

    &--not-clickable {
      pointer-events: none;
    }
  }
}

// ============================================================================
// Skeleton item
.skeleton-item {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  &__flag {
    @include skeletonPlaceholder;
    width: math.div(2.6rem, 1.6);
    height: math.div(1.7rem, 1.6);
    margin-right: math.div(1.4rem, 1.6);
    opacity: 0.5;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-grow: 1;

    &--horizontal {
      flex-direction: row;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
    }
  }

  &__title {
    @include skeletonPlaceholder;
    width: math.div(8.6rem, 1.6);
    height: math.div(1.3rem, 1.6);
    margin-bottom: math.div(0.5rem, 1.6);
    opacity: 0.5;

    &--fixed-width {
      width: 100px;
      margin: 0 50px 0 0;
    }
  }

  &__subscript {
    @include skeletonPlaceholder;
    width: math.div(13rem, 1.6);
    height: math.div(0.9rem, 1.6);
    opacity: 0.5;

    &--fixed-width {
      width: 60px;
    }
  }

  &__plus-icon {
    @include skeletonPlaceholder;
    width: math.div(1.6rem, 1.6);
    height: math.div(1.6rem, 1.6);
    opacity: 0.5;
  }
}

// ==========================================================================
// Search Preload Skeleton
.search-preload-skeleton {
  padding-top: math.div(1.4rem, 1.6);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    width: 100%;
    height: math.div(10rem, 1.6);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(0, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &::after {
    content: '';
    width: math.div(0.8rem, 1.6);
    height: math.div(9rem, 1.6);
    border-radius: math.div(10rem, 1.6);
    position: absolute;
    top: 0;
    right: 0;
    background: rgb(210, 210, 210);
  }

  &__skeleton-item {
    margin: 0 math.div(2.8rem, 1.6) math.div(2.1rem, 1.6) math.div(1rem, 1.6);

    &--small-bot-margin {
      margin-bottom: 16px;
    }
  }
}

// ==========================================================================
// Suggestions list
.suggestions-list-component {
  padding: math.div(1.5rem, 1.6);
  box-sizing: border-box;
  border-radius: math.div(1rem, 1.6);
  box-shadow: 0 math.div(0.3rem, 1.6) math.div(1rem, 1.6) rgba(0, 0, 0, 0.1);
  background: #fff;

  &__scrollable-container {
    margin-bottom: math.div(1.5rem, 1.6);

    &--auto-height {
      height: auto;
      min-height: auto;
      margin-top: math.div(-0.5rem, 1.6);
      margin-bottom: math.div(-1.8rem, 1.6);
    }

    &--fixed-height {
      height: math.div(23rem, 1.6);
      min-height: math.div(23rem, 1.6);
      max-height: math.div(23rem, 1.6);
    }
  }

  &__search-result-lists {
    width: 100%;
    margin: math.div(0.8rem, 1.6) 0;
  }

  &__search-preload-skeleton {
    height: math.div(24.5rem, 1.6);
    min-height: math.div(24.5rem, 1.6);
  }

  &__no-results-placeholder {
    height: math.div(8.5rem, 1.6);
    padding-bottom: math.div(1.5rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: rgba(53, 73, 94, 0.5);
    line-height: math.div(1.6rem, 1.6);
  }

  &__bottom-panel {
    padding: math.div(1rem, 1.6) math.div(0.9rem, 1.6) 0;
    border-top: 1px rgba(53, 73, 94, 0.1) solid;
    font-size: math.div(1.4rem, 1.6);

    &--no-border {
      padding-top: 0;
      border-top: none;
    }
  }

  &__add-new-element-btn {
  }
  .add-new-element-btn {
    position: relative;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.1s ease;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: '';
      width: calc(100% + #{math.div(1rem, 1.6)});
      height: calc(100% + #{math.div(1rem, 1.6)});
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__plus-icon {
      width: math.div(1.4rem, 1.6);
      height: math.div(1.4rem, 1.6);
      margin: 0 math.div(0.6rem, 1.6) 0 0;
      display: inline-block;
      position: relative;
      top: 2px;

      ::v-deep svg {
        fill: rgba(53, 73, 94, 1);
      }
    }
  }
}
</style>
