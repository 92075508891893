import men from '@icons/gender/men.svg';
import ladies from '@icons/gender/ladies.svg';
import boysGirls from '@icons/gender/boys-girls.svg';
import boysGirlsThin from '@icons/gender/boys-girls-thin.svg';
import babies from '@icons/gender/babies.svg';

export default {
  men,
  ladies,
  women: ladies,
  boysGirls,
  boysAndGirls: boysGirls,
  kids: boysGirls,
  boysGirlsThin,
  kidsThin: boysGirlsThin,
  babies,
};
