var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-supplier-form" },
    [
      _c("div", { staticClass: "new-supplier-form__main-title" }, [
        _vm._v("Create new Supplier"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-itself new-supplier-form__form-itself" }, [
        _c(
          "div",
          {
            staticClass:
              "form-itself__separate-section form-itself__separate-section--company-name",
          },
          [
            _c("input-custom", {
              staticClass: "form-itself__company-name-input",
              attrs: { placeholder: "Enter Company Name" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-itself__separate-section" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-itself__section-content" },
            _vm._l(_vm.whatYouProducing, function (item) {
              return _c("checkbox-custom-with-icon", {
                key: item,
                attrs: {
                  icon: {
                    footwear: _vm.svg.shoesIcon,
                    components: _vm.svg.componentsIcon,
                  }[item.toLowerCase()],
                  "text-label": item,
                },
                on: { check: function () {}, uncheck: function () {} },
              })
            }),
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-itself__separate-section" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-itself__section-content" },
            _vm._l(_vm.whatDescribesYou, function (item) {
              return _c("checkbox-custom-button", {
                key: item,
                attrs: { "text-label": item },
                on: { check: function () {}, uncheck: function () {} },
              })
            }),
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "new-supplier-form__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "section-subtitle form-itself__section-subtitle" },
      [
        _vm._v("\n        What are you producing? "),
        _c("span", { staticClass: "section-subtitle__red-asterix" }, [
          _vm._v("*"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "section-subtitle form-itself__section-subtitle" },
      [
        _vm._v("\n        What describes you the best? "),
        _c("span", { staticClass: "section-subtitle__red-asterix" }, [
          _vm._v("*"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }