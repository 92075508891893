<template>
  <div class="brands">
    <div class="brands__header">
      <div class="brands__title">Brands</div>
      <div class="brands__subtitle">we work with</div>
    </div>

    <ul class="brands__logos-list">
      <li
        class="brand-single-logo brands__single-logo"
        v-for="brand in newBrandsList.filter((item) => item.logo)"
        :key="brand.id"
      >
        <div v-if="brand.verified" class="brand-single-logo__checkmark" v-html="svg.verifiedCheckmarkIcon"></div>
        <img class="brand-single-logo__image" :src="$blobUrl + '/' + brand.logo" :alt="brand.name" />
      </li>
    </ul>

    <ul class="brands__companies-names">
      <li v-for="brand in brandsList" :key="brand + 'old'" class="brands__single-company-name">
        {{ brand }}
      </li>
      <li
        v-for="brand in newBrandsList.filter((item) => !item.logo)"
        :key="brand.id + 'new'"
        class="brands__single-company-name"
      >
        {{ brand.name }}
      </li>
    </ul>
  </div>
</template>

<script>
// Icons
import verifiedCheckmarkIcon from '@icons/other/verified-checkmark.svg';

export default {
  props: {
    brandsList: Array,
    newBrandsList: Array,
  },

  data() {
    return {
      svg: { verifiedCheckmarkIcon },
    };
  },

  mounted() {},

  updated() {},

  methods: {},

  computed: {},

  watch: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

// ============================================================================
// ============================================================================
// Brand Single Logo
.brand-single-logo {
  height: math.div(5.5rem, 1.6);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;

  &__checkmark {
    width: math.div(1.5rem, 1.6);
    height: math.div(1.5rem, 1.6);
    position: absolute;
    top: math.div(-0.4rem, 1.6);
    right: 0;

    ::v-deep svg {
      fill: rgba(53, 73, 94, 0.5);
    }
  }

  &__image {
    max-width: math.div(19.5rem, 1.6);
  }
}

// ============================================================================
// ============================================================================
// Brands
.brands {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  &__header {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  &__title {
    @include heading--small;
  }

  &__subtitle {
    font-size: math.div(1.4rem, 1.6);
  }

  &__logos-list {
    list-style: none;
  }

  &__single-logo {
  }

  &__companies-names {
    max-width: math.div(50rem, 1.6);
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
  }

  &__single-company-name {
    margin: 0 math.div(1.2rem, 1.6) math.div(1.6rem, 1.6);
    font-size: math.div(1.6rem, 1.6);
    text-transform: uppercase;
    white-space: nowrap;
  }
}
</style>
