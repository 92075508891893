<template>
  <div class="company-in-brief">
    <div class="company-in-brief__description-container">
      <div
        v-show-hide:flex-basis="descriptionOpen"
        v-fully-visible:1="descriptionFullyVisible"
        :class="{ description: true, 'description--open': descriptionOpen }"
      >
        <div
          class="description__content"
          :class="{ 'description__content--one-paragraph': numberOfOverviewParagraphs === 1 }"
          v-html="supplier.overview"
        ></div>
        <div
          v-if="descriptionOpen || !descriptionFullyVisible"
          :class="{
            'description__read-more-container': true,
            'description__read-more-container--open': descriptionOpen,
          }"
        >
          <span class="description__read-more link" @click.prevent="() => (descriptionOpen = !descriptionOpen)">
            Read more<span class="description__read-more-icon"></span>
          </span>
        </div>
      </div>
    </div>
    <div class="company-in-brief__numbers-container">
      <!-- CIB Fields -->
      <div class="cib-fields">
        <div class="cib-fields__column">
          <!-- CIB single field -->
          <div class="cib-field cib-fields__single-field">
            <span class="cib-field__icon cib-field__icon--small" v-html="icons.miscellaneous.officeBuilding"> </span>
            <div class="cib-field__label-value-wrapper">
              <span class="cib-field__label">Founded</span>
              <span class="cib-field__value">{{ supplier.founded || '-' }}</span>
            </div>
          </div>

          <!-- CIB single field -->
          <div v-if="supplier.category === 0" class="cib-field cib-fields__single-field">
            <span class="cib-field__icon" v-html="svg.moq"> </span>
            <div class="cib-field__label-value-wrapper">
              <span class="cib-field__label">MOQ</span>
              <span class="cib-field__value">
                {{ supplier.moq || '-' }}
                <template v-if="false">
                  1000-5000
                  <span class="cib-field__info">i</span>
                </template>
              </span>
            </div>
          </div>
        </div>
        <div class="cib-fields__column">
          <!-- CIB single field -->
          <div class="cib-field cib-field--disabled cib-fields__single-field">
            <span class="cib-field__icon cib-field__icon--small" v-html="icons.miscellaneous.circleA"></span>
            <div class="cib-field__label-value-wrapper">
              <span class="cib-field__label">OAR-ID</span>
              <span class="cib-field__value cib-field__value--small">-</span>
              <span v-if="false" class="cib-field__more">+2 MORE</span>
            </div>
          </div>

          <!-- CIB single field -->
          <div v-if="supplier.category === 0" class="cib-field cib-fields__single-field">
            <span class="cib-field__icon" v-html="svg.pairsPerDay"></span>
            <div class="cib-field__label-value-wrapper">
              <span class="cib-field__label">Pairs / day</span>
              <span v-if="supplier.dailyProductionFrom && supplier.dailyProductionTo" class="cib-field__value">
                {{
                  supplier.dailyProductionFrom && supplier.dailyProductionTo
                    ? `${supplier.dailyProductionFrom}-${supplier.dailyProductionTo}`
                    : '-'
                }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Employees -->
      <div class="cib-employees">
        <div class="employees-header cib-employees__header">
          <div class="employees-header__icon" v-html="icons.miscellaneous.employees"></div>
          <div class="employees-header__text-part">
            <div class="employees-header__title">Employees</div>
            <div class="employees-header__number">{{ supplier.employees || '-' }}</div>
          </div>
        </div>

        <div class="cib-employees__teasers-list">
          <div class="employee-teaser cib-employees__single-teaser">
            <div class="employee-teaser__header">Line Workers</div>
            <div class="employee-teaser__number">{{ supplier.lineWorkers || '-' }}</div>
          </div>

          <div class="employee-teaser cib-employees__single-teaser">
            <div class="employee-teaser__header">Female</div>
            <div class="employee-teaser__number">
              {{ isNull(supplier.femaleEmployees) ? '-' : `${supplier.femaleEmployees}%` }}
            </div>
          </div>

          <div class="employee-teaser cib-employees__single-teaser">
            <div class="employee-teaser__header">Migrant</div>
            <div class="employee-teaser__number">
              {{ isNull(supplier.immigrantEmployees) ? '-' : `${supplier.immigrantEmployees}%` }}
            </div>
          </div>
        </div>
      </div>

      <!-- CIB Certificates -->
      <div v-if="satraAccreditation" class="cib-certificates">
        <div class="cib-certificates__container">
          <a
            class="cib-certificate"
            href="#certification-section"
            v-scroll-to="'#certification-section'"
            v-html="svg.satraAccreditation"
          ></a>
        </div>
        <a class="cib-certificates__details" v-scroll-to="'#certification-section'" href="#certification-section">
          <span class="cib-certificates__details-icon" v-html="svg.info"></span>
          <span class="cib-certificates__details-text link link--dark">Details</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import isNull from 'lodash/isNull';

import fullyVisible from 'apps/public/lib/fully-visible';
import showHide from 'apps/public/lib/show-hide';

// Icons
import info from 'apps/common/assets/icons/other/info.svg';
import employees from 'apps/common/assets/icons/other/employees.svg';
import founded from 'apps/common/assets/icons/other/founded.svg';
import moq from 'apps/common/assets/icons/other/moq.svg';
import pairsPerDay from 'apps/common/assets/icons/other/pairs-per-day.svg';

import miscellaneousIcons from '@lib/icons-imports/miscellaneous';

// SATRA
// TODO: REVIEW if this really should be statically imported - it's unnecessary
// data to load for all profiles except SATRA members.
import satraAccreditation from 'apps/common/assets/external-organizations/satra-accreditation.svg';

export default {
  directives: {
    'fully-visible': fullyVisible,
    'show-hide': showHide,
  },
  props: {
    supplier: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      descriptionFullyVisible: false,
      descriptionOpen: false,
      satraAccreditation: this.$store.general.satra,
      icons: {
        miscellaneous: miscellaneousIcons,
      },
      svg: {
        info,
        employees,
        founded,
        moq,
        pairsPerDay,
        satraAccreditation,
      },
    };
  },
  mounted() {
    // console.log('this.supplier: ',this.supplier);
    // console.log('%c this.supplier: ','background:cyan;color:black;', this.supplier);
  },
  computed: {
    numberOfOverviewParagraphs() {
      if (!this.supplier || !this.supplier.overview) return 0;

      return (this.supplier.overview.match(/<p/g) || []).length;
    },
  },
  methods: {
    isNull,
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

.supplierProfile {
  // Max-width (@1680px) ~67.125rem
  .company-in-brief {
    min-height: 12.25rem; // Fix for component suppliers, where we only have 1 row of fields to the right

    @include tablet-landscape-min {
      display: flex;
    }

    &__description-container {
      display: flex;
      flex-direction: column;
      // Only for tablet-landscape-min;
      flex-basis: 41rem;
      flex-grow: 0;
      flex-shrink: 1;
    }

    &__numbers-container {
      display: flex;
      flex-direction: column;
      padding-bottom: 0.5rem; // To accommodate READ MORE in the description column being below the content in the numbers container;

      @include tablet-landscape-min {
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 0;
        padding-bottom: 2rem;
      }
    }
  }

  .description {
    position: relative;
    padding: 0 0.5rem 1.5rem 0.5rem;
    flex-basis: 10rem;
    flex-grow: 1;
    overflow-y: hidden;
    transition: flex 0.5s ease; // TODO: REVIEW, should be standardized somewhere;

    @include tablet-landscape-min {
      flex-basis: 0;
      margin-right: 1.75rem;
      padding-left: 0;
      padding-right: 0;
    }

    &__content {
      @include body;

      &:not(.description__content--one-paragraph) {
        ::v-deep p:first-child {
          @include heading--tiny;
          font-size: 1.125em;
        }
      }
    }

    &__read-more-container {
      position: absolute;
      height: 6.75rem;
      max-height: 100%;
      left: 0;
      right: 0;
      bottom: -1px; // Fixes issue with animation jank, due to background-size transititioning. Basically, there's a small line/gap below the container when animating. This fixes/removes that gap.';
      display: flex;
      justify-content: center;
      align-items: flex-end;
      // Background based on pages/quotations/request/quotation.vue -> __company-message-more-container;
      background: linear-gradient(rgba($c-bg-light, 0), rgba($c-bg-light, 0.6) 33%, rgba($c-bg-light, 1) 68%);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: 100% 100%;
      transition: background-size 0.5s ease; // TODO: REVIEW, should be standardized somewhere;

      &--open {
        background-size: 100% 0;
      }
    }

    &__read-more {
      @include button;
      // Note: When animating, the background gradually disappears which makes the read more text be on top of the underlying content. There's no simple workaround to this, since background-image (which linear-gradient really uses) is not animatable (more than discretely 0 -> 1 in one step)';
      display: inline-block;
      padding: 0.5rem 0.75rem;
      vertical-align: bottom;

      &:hover {
        .description__read-more-icon {
          transform: translateY(1px);
        }
      }
    }

    &__read-more-icon {
      position: relative;
      top: 0.1875rem;
      display: inline-block;
      height: 0;
      width: 0;
      border: 0.3125rem solid transparent;
      border-top-color: $c-blue;
      margin-left: 0.4375rem;
      transition: transform 0.5s ease;
    }

    &--open {
      .description__read-more-icon {
        transform: translateY(#{math.div(-0.6rem, 1.6)}) scaleY(-1);
      }

      .description__read-more:hover {
        .description__read-more-icon {
          transform: translateY(#{math.div(-0.7rem, 1.6)}) scaleY(-1);
        }
      }
    }
  }

  .cib-fields {
    padding-top: math.div(1.6rem, 1.6);
    margin-bottom: math.div(2.3rem, 1.6);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include tablet-landscape-min {
      display: block;
      padding-top: 0.25rem;
    }

    @include desktop-large-min {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
    }

    &__column {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      justify-content: space-around;

      @include tablet-landscape-min {
        display: block;
        padding: 0 0.25rem;
      }

      @include desktop-large-min {
        flex-shrink: 0;
      }

      @include mobile-xs-only {
        flex-wrap: wrap;
      }
    }

    &__single-field {
    }
  }

  .cib-field {
    padding: math.div(1.6rem, 1.6) 0;
    display: flex;
    align-items: center;

    &--disabled {
      opacity: 0.5;
    }

    @include mobile-to-tablet {
      min-width: 10rem;
    }

    &__icon {
      height: 3rem;
      width: 3rem;
      margin: 0 1rem;
      flex-shrink: 0;
      display: inline-flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      fill: $c-dark;

      ::v-deep svg {
        height: 3rem;
        width: 3rem;
      }

      &--small {
        ::v-deep svg {
          width: 73%;
          height: 73%;
        }
      }
    }

    &__label-value-wrapper {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
    }

    &__label {
      @include body--small;
      display: inline-block;
      color: rgba(
        $c-dark,
        0.6
      ); // TODO: This should be in a variable? Either as a complete color, or with the opacity specified, so we use the same level in different places;
      line-height: 1;
      padding-bottom: 0.125rem;
    }

    &__value {
      @include body;
      display: inline-block;
      font-size: 1.25em;
      line-height: 1;

      &--small {
        font-size: math.div(1.6rem, 1.6);
      }
    }

    &__more {
      padding-top: 3px;
      font-size: math.div(1rem, 1.6);
      color: rgba(53, 73, 94, 0.5);
    }

    &__info {
      font-size: 0.75em;
      font-weight: $bold-lato;
      position: relative;
      top: -0.875rem;
      right: -0.375rem;
      margin-right: 0.375rem;
      display: inline-block;
      height: 1rem;
      width: 1rem;
      border: 0.125rem solid $c-dark;
      border-radius: 50%;
      text-align: center;
    }
  }

  .cib-employees {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    &__header {
    }

    .employees-header {
      padding: 0 math.div(2.7rem, 1.6);
      display: flex;
      align-items: center;
      align-content: center;
      position: relative;
      background: #fff;

      &__icon {
        width: math.div(3.6rem, 1.6);
        height: math.div(3.6rem, 1.6);
        margin-right: math.div(1.9rem, 1.6);

        ::v-deep svg {
          fill: rgb(53, 73, 94);
        }
      }

      &__text-part {
      }

      &__title {
        margin-bottom: 2px;
        font-size: math.div(1.5rem, 1.6);
        color: rgba(53, 73, 94, 0.6);
      }

      &__number {
        font-size: math.div(2.1rem, 1.6);
      }
    }

    &__teasers-list {
      width: 100%;
      padding: math.div(4rem, 1.6) 0 0;
      margin-top: math.div(-2.2rem, 1.6);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      border: 1px rgba(53, 73, 94, 0.1) solid;
      border-radius: math.div(1rem, 1.6);
    }

    &__single-teaser {
      margin: 0 math.div(2rem, 1.6) math.div(2.3rem, 1.6);
    }

    .employee-teaser {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;

      &__header {
        margin-bottom: 2px;
        font-size: math.div(1.5rem, 1.6);
        color: rgba(53, 73, 94, 0.6);
      }

      &__number {
        font-size: math.div(2.1rem, 1.6);
      }
    }
  }

  .cib-certificates {
    width: 100%;
    text-align: center;
    padding-top: 0.5em;

    &__container {
      display: flex;
      justify-content: center;
    }

    &__details {
      display: inline-flex;
      align-items: center;
      margin: 0 auto;
    }

    &__details-icon {
      display: block;
      height: 1rem;
      width: 1rem;
      margin-right: 0.625rem;
      fill: $c-dark;
    }

    &__details-text {
      @include link($font-weight: 400);
      line-height: 1rem;
    }
  }

  .cib-certificate {
    display: block;
    height: 2.25rem;
    width: 2.25rem;
    margin: 0.875rem;
  }
}
</style>
