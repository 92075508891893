var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "types-n-processes" },
    [
      _c("div", { staticClass: "types-n-processes__main-title" }, [
        _vm._v("Footwear Types & Production Processes"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-itself types-n-processes__form-itself" }, [
        _c(
          "div",
          { staticClass: "form-itself__column" },
          _vm._l(_vm.footwearTypes, function (item) {
            return _c("checkbox-custom-with-icon", {
              key: item,
              staticClass: "form-itself__checkbox-with-icon",
              attrs: {
                icon: _vm.icons.footwearTypes[_vm.camelCase(item)],
                "text-label": item,
              },
              on: { check: function () {}, uncheck: function () {} },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "form-itself__column form-itself__column--checkbox-buttons-list",
          },
          _vm._l(_vm.productionProcesses, function (item) {
            return _c("checkbox-custom-button", {
              key: item,
              staticClass: "form-itself__checkbox-button",
              attrs: {
                "additional-icon": _vm.icons.typeNProcess[_vm.camelCase(item)],
                "text-label": item,
                size: "small",
                "clean-design": "",
              },
              on: { check: function () {}, uncheck: function () {} },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "types-n-processes__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }