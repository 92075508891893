var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "verified-by-findsourcing" }, [
    _c("h4", { staticClass: "verified-by-findsourcing__header" }, [
      _vm._v("Verified by FindSourcing"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "verified-by-findsourcing__container" }, [
      _c(
        "div",
        { staticClass: "verifications verified-by-findsourcing__group" },
        [
          _c("span", {
            staticClass: "verifications__checkbox",
            domProps: {
              innerHTML: _vm._s(
                _vm.supplier.isEmailConfirmed
                  ? _vm.svg.verifiedCheckmark
                  : _vm.svg.verifiedCircle
              ),
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "verifications__label" }, [
            _vm._v("E-mail address"),
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "verifications__checkbox",
            domProps: { innerHTML: _vm._s(_vm.svg.verifiedCheckmark) },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "verifications__label" }, [
            _vm._v("Profile information"),
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "verifications__checkbox",
            domProps: { innerHTML: _vm._s(_vm.svg.verifiedCheckmark) },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "verifications__label" }, [
            _vm._v("Location"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "supplier-stats verified-by-findsourcing__group" },
        [
          _c("span", {
            staticClass: "supplier-stats__icon",
            domProps: { innerHTML: _vm._s(_vm.svg.responseRate) },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "supplier-stats__label" }, [
            _vm._v("Response rate"),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "supplier-stats__value" }, [
            _vm._v("100%"),
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "supplier-stats__icon",
            domProps: { innerHTML: _vm._s(_vm.svg.requestsHandled) },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "supplier-stats__label" }, [
            _vm._v("Offers submitted"),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "supplier-stats__value" }, [
            _vm._v(_vm._s(_vm.supplier.quotationCount)),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }