var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "sustainability-rating": 1,
        "sustainability-rating--desktop-hidden": _vm.desktopHidden,
      },
    },
    [
      _c("div", { staticClass: "sustainability-rating__header" }, [
        _vm._v("Sustainability Rating"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rating-scale sustainability-rating__rating-scale" },
        [
          _c(
            "div",
            { staticClass: "rating-scale__scale" },
            _vm._l(
              _vm.sustainabilityPropsWithAvailability,
              function (division, index) {
                return _c("div", {
                  key: index,
                  class: {
                    "rating-scale__scale-division": 1,
                    "rating-scale__scale-division--active":
                      index <
                      _vm.sustainabilityPropsWithAvailability.filter(function (
                        itm
                      ) {
                        return itm.available
                      }).length,
                  },
                })
              }
            ),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "rating-scale__counter" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.sustainabilityPropsWithAvailability.filter(function (
                    itm
                  ) {
                    return itm.available
                  }).length +
                    "/" +
                    _vm.sustainabilityPropsWithAvailability.length
                ) +
                "\n    "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "benefits-list sustainability-rating__benefits-list" },
        _vm._l(
          _vm.sustainabilityPropsWithAvailability,
          function (property, index) {
            return _c(
              "div",
              {
                key: property.title,
                class: {
                  "single-benefit": 1,
                  "single-benefit--inactive": !property.available,
                  "benefits-list__single-benefit": 1,
                },
              },
              [
                _c("div", {
                  staticClass: "single-benefit__icon",
                  domProps: {
                    innerHTML: _vm._s(_vm.svg[property.icon + "Icon"]),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "single-benefit__description" }, [
                  _c("div", { staticClass: "single-benefit__name" }, [
                    property.infoText
                      ? _c("div", {
                          staticClass: "single-benefit__info-but",
                          domProps: {
                            innerHTML: _vm._s(_vm.icons.miscellaneous.info),
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$refs[
                                "sustainabilityPropInfo-" + index
                              ][0].toggle($event)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(
                      "\n\n          " + _vm._s(property.title) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  property.subscript
                    ? _c("div", { staticClass: "single-benefit__subscript" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(property.subscript) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                property.infoText
                  ? _c(
                      "fs-popper",
                      {
                        ref: "sustainabilityPropInfo-" + index,
                        refInFor: true,
                        staticStyle: { width: "300px" },
                        attrs: {
                          animation: "fade",
                          arrow: "",
                          modifiers: [
                            { name: "flip", enabled: false },
                            {
                              name: "preventOverflow",
                              options: { padding: 16 },
                            },
                          ],
                          offset: [0, 30],
                          placement: "top",
                          "wrapper-class":
                            "popper-wrapper\n                        supplierProfile",
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "popover-close",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fsPopperClose($event)
                              },
                            },
                          },
                          [_vm._v("+")]
                        ),
                        _vm._v(
                          "\n        " + _vm._s(property.infoText) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                property.available
                  ? _c("div", {
                      staticClass: "single-benefit__availability-icon",
                      domProps: {
                        innerHTML: _vm._s(_vm.icons.miscellaneous.checkMark),
                      },
                    })
                  : _c("div", {
                      staticClass:
                        "single-benefit__availability-icon single-benefit__availability-icon--cross",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.icons.interfaceElements.crossClear
                        ),
                      },
                    }),
              ],
              1
            )
          }
        ),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }