var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-in-brief" }, [
    _c("div", { staticClass: "company-in-brief__description-container" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show-hide",
              rawName: "v-show-hide:flex-basis",
              value: _vm.descriptionOpen,
              expression: "descriptionOpen",
              arg: "flex-basis",
            },
            {
              name: "fully-visible",
              rawName: "v-fully-visible:1",
              value: _vm.descriptionFullyVisible,
              expression: "descriptionFullyVisible",
              arg: "1",
            },
          ],
          class: {
            description: true,
            "description--open": _vm.descriptionOpen,
          },
        },
        [
          _c("div", {
            staticClass: "description__content",
            class: {
              "description__content--one-paragraph":
                _vm.numberOfOverviewParagraphs === 1,
            },
            domProps: { innerHTML: _vm._s(_vm.supplier.overview) },
          }),
          _vm._v(" "),
          _vm.descriptionOpen || !_vm.descriptionFullyVisible
            ? _c(
                "div",
                {
                  class: {
                    "description__read-more-container": true,
                    "description__read-more-container--open":
                      _vm.descriptionOpen,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "description__read-more link",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return (function () {
                            return (_vm.descriptionOpen = !_vm.descriptionOpen)
                          })($event)
                        },
                      },
                    },
                    [
                      _vm._v("\n          Read more"),
                      _c("span", {
                        staticClass: "description__read-more-icon",
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "company-in-brief__numbers-container" }, [
      _c("div", { staticClass: "cib-fields" }, [
        _c("div", { staticClass: "cib-fields__column" }, [
          _c("div", { staticClass: "cib-field cib-fields__single-field" }, [
            _c("span", {
              staticClass: "cib-field__icon cib-field__icon--small",
              domProps: {
                innerHTML: _vm._s(_vm.icons.miscellaneous.officeBuilding),
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "cib-field__label-value-wrapper" }, [
              _c("span", { staticClass: "cib-field__label" }, [
                _vm._v("Founded"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "cib-field__value" }, [
                _vm._v(_vm._s(_vm.supplier.founded || "-")),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.supplier.category === 0
            ? _c("div", { staticClass: "cib-field cib-fields__single-field" }, [
                _c("span", {
                  staticClass: "cib-field__icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.moq) },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "cib-field__label-value-wrapper" }, [
                  _c("span", { staticClass: "cib-field__label" }, [
                    _vm._v("MOQ"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "cib-field__value" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.supplier.moq || "-") +
                          "\n              "
                      ),
                      false
                        ? [
                            _vm._v(
                              "\n                1000-5000\n                "
                            ),
                            _c("span", { staticClass: "cib-field__info" }, [
                              _vm._v("i"),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cib-fields__column" }, [
          _c(
            "div",
            {
              staticClass:
                "cib-field cib-field--disabled cib-fields__single-field",
            },
            [
              _c("span", {
                staticClass: "cib-field__icon cib-field__icon--small",
                domProps: {
                  innerHTML: _vm._s(_vm.icons.miscellaneous.circleA),
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "cib-field__label-value-wrapper" }, [
                _c("span", { staticClass: "cib-field__label" }, [
                  _vm._v("OAR-ID"),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "cib-field__value cib-field__value--small" },
                  [_vm._v("-")]
                ),
                _vm._v(" "),
                false
                  ? _c("span", { staticClass: "cib-field__more" }, [
                      _vm._v("+2 MORE"),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.supplier.category === 0
            ? _c("div", { staticClass: "cib-field cib-fields__single-field" }, [
                _c("span", {
                  staticClass: "cib-field__icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.pairsPerDay) },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "cib-field__label-value-wrapper" }, [
                  _c("span", { staticClass: "cib-field__label" }, [
                    _vm._v("Pairs / day"),
                  ]),
                  _vm._v(" "),
                  _vm.supplier.dailyProductionFrom &&
                  _vm.supplier.dailyProductionTo
                    ? _c("span", { staticClass: "cib-field__value" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.supplier.dailyProductionFrom &&
                                _vm.supplier.dailyProductionTo
                                ? _vm.supplier.dailyProductionFrom +
                                    "-" +
                                    _vm.supplier.dailyProductionTo
                                : "-"
                            ) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cib-employees" }, [
        _c("div", { staticClass: "employees-header cib-employees__header" }, [
          _c("div", {
            staticClass: "employees-header__icon",
            domProps: { innerHTML: _vm._s(_vm.icons.miscellaneous.employees) },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "employees-header__text-part" }, [
            _c("div", { staticClass: "employees-header__title" }, [
              _vm._v("Employees"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "employees-header__number" }, [
              _vm._v(_vm._s(_vm.supplier.employees || "-")),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cib-employees__teasers-list" }, [
          _c(
            "div",
            { staticClass: "employee-teaser cib-employees__single-teaser" },
            [
              _c("div", { staticClass: "employee-teaser__header" }, [
                _vm._v("Line Workers"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "employee-teaser__number" }, [
                _vm._v(_vm._s(_vm.supplier.lineWorkers || "-")),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "employee-teaser cib-employees__single-teaser" },
            [
              _c("div", { staticClass: "employee-teaser__header" }, [
                _vm._v("Female"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "employee-teaser__number" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.isNull(_vm.supplier.femaleEmployees)
                        ? "-"
                        : _vm.supplier.femaleEmployees + "%"
                    ) +
                    "\n          "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "employee-teaser cib-employees__single-teaser" },
            [
              _c("div", { staticClass: "employee-teaser__header" }, [
                _vm._v("Migrant"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "employee-teaser__number" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.isNull(_vm.supplier.immigrantEmployees)
                        ? "-"
                        : _vm.supplier.immigrantEmployees + "%"
                    ) +
                    "\n          "
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.satraAccreditation
        ? _c("div", { staticClass: "cib-certificates" }, [
            _c("div", { staticClass: "cib-certificates__container" }, [
              _c("a", {
                directives: [
                  {
                    name: "scroll-to",
                    rawName: "v-scroll-to",
                    value: "#certification-section",
                    expression: "'#certification-section'",
                  },
                ],
                staticClass: "cib-certificate",
                attrs: { href: "#certification-section" },
                domProps: { innerHTML: _vm._s(_vm.svg.satraAccreditation) },
              }),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "scroll-to",
                    rawName: "v-scroll-to",
                    value: "#certification-section",
                    expression: "'#certification-section'",
                  },
                ],
                staticClass: "cib-certificates__details",
                attrs: { href: "#certification-section" },
              },
              [
                _c("span", {
                  staticClass: "cib-certificates__details-icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.info) },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "cib-certificates__details-text link link--dark",
                  },
                  [_vm._v("Details")]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }