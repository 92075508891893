<template>
  <div class="brands-working-with" v-if="brandsWorkingWith.length">
    <h4 class="brands-working-with__header">Brands working with</h4>
    <div class="brands-labels-container">
      <span class="brands-labels-container__brand" v-for="brand of brandsWorkingWith" :key="brand">{{ brand }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    supplier: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    // TODO: REFACTOR - copy exists in pages/supplier/index. Needs to be DRY-ified
    // TODO: REVIEW if this strategy of splitting based on hyphen/comma really works.
    // Long term, we really should turn this into a proper list.
    brandsWorkingWith() {
      if (!this.supplier || !this.supplier.brands) return [];

      const commaCount = (this.supplier.brands.match(/,/g) || []).length;
      const hyphenCount = (this.supplier.brands.match(/-/g) || []).length;

      const regex = hyphenCount > commaCount ? /-/ : /,/;

      return this.supplier.brands
        .split(regex)
        .filter(Boolean)
        .map((brand) => brand.trim()); // filter(Boolean) => filter out empty items
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

.supplierProfile {
  // TODO: REFACTOR - fix below mess
  // NOTE! This is quite messy, but depends on styling on the left column of the page
  // In other words, this is NOT all styling needed for his component.
  // ONLY Mobile
  .brands-working-with {
    @include tablet-min {
      display: none;
    }

    &__header {
      @include heading--small;
      text-align: center;
      padding-bottom: 0.5rem;
    }
  }
}
</style>
