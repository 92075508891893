<template>
  <div class="input-country-with-hints" v-click-outside="() => (hintsDropdownForceHide = true)">
    <input-custom
      :class="{
        'input-country-with-hints__search-input': true,
        'input-country-with-hints__search-input--active': searchQuery !== '' && !hintsDropdownForceHide,
      }"
      v-model="searchQuery"
      :placeholder="placeholder"
      @reset-input="searchQuery = ''"
      @input="hintsDropdownForceHide = false"
      @focus="hintsDropdownForceHide = false"
      @blur="hintsDropdownForceHide = true"
      :autocomplete-off="autocompleteOff"
      :tabindex="-100"
      ref="inputSearchQuery"
    >
      <img
        v-if="$store.countries.all.includes(searchQuery)"
        class="input-country-with-hints__country-flag"
        :src="$store.countries.getFlagPathFromName(searchQuery)"
        :alt="searchQuery"
      />
    </input-custom>

    <!-- Hints Dropdown -->
    <transition name="fall-from-top-with-fade">
      <div
        v-if="searchQuery !== '' && !hintsDropdownForceHide"
        class="hints-dropdown input-country-with-hints__hints-dropdown"
      >
        <scrollable-block
          v-if="!searchInProgress && filteredSearchResult.length !== 0 && searchQuery.length"
          :class="{
            'hints-dropdown__scrollable-container': true,
            'hints-dropdown__scrollable-container--height-auto': filteredSearchResult.length < 4,
          }"
          shadows-color="dark-gray"
        >
          <!-- Search results list -->
          <div class="search-result-list hints-dropdown__search-result-list">
            <div
              :class="{
                'single-search-element': true,
                'single-search-element--clickable': true,
                'single-search-element--focused': focusedListItem === index,
                'search-result-list__single-search-element': true,
              }"
              v-for="(searchResultItem, index) in filteredSearchResult || $store.countries.all"
              :key="searchResultItem"
              :ref="`single-search-element-${index}`"
              @mouseenter="focusedListItem = index"
              @click="
                searchQuery = searchResultItem;
                hintsDropdownForceHide = true;
              "
            >
              <img
                class="single-search-element__flag-icon"
                :src="$store.countries.getFlagPathFromName(searchResultItem)"
                :alt="searchResultItem"
              />

              <div class="single-search-element__name-n-country">
                <span class="single-search-element__name">
                  {{ searchResultItem }}
                </span>
              </div>
            </div>
          </div>
        </scrollable-block>

        <div v-if="searchInProgress" class="search-preload-skeleton hints-dropdown__search-preload-skeleton">
          <div
            class="skeleton-item search-preload-skeleton__skeleton-item"
            v-for="item in [0, 1, 2, 3, 4, 5]"
            :key="item"
          >
            <div class="skeleton-item__flag"></div>
            <div class="skeleton-item__text-wrap">
              <div class="skeleton-item__title"></div>
            </div>
          </div>
        </div>

        <div
          v-if="!searchInProgress && filteredSearchResult.length === 0"
          class="hints-dropdown__no-results-placeholder"
        >
          No results
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

// Components
import InputCustom from '@components/form-elements/input-custom.vue';
import ScrollableBlock from '@components/scrollable-block.vue';
import lodashDebounce from 'lodash/debounce';

// Icons
import closeCircleOutlineIcon from '@icons/other/close-circle-outline-icon.svg';
import saveFloppyIcon from '@icons/other/save-floppy-icon.svg';
import deleteBtn from '@icons/other/trash-can-icon.svg';
import addCirclePlus from '@icons/other/add-circle-plus.svg';
import checkMarkIcon from '@icons/other/check-mark-icon.svg';

export default {
  name: 'input-country-with-hints',

  components: { InputCustom, ScrollableBlock },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    placeholder: {
      type: String,
      default: '',
    },
    selectedElements: Array,

    autocompleteOff: Boolean,
  },

  data() {
    return {
      searchQuery: '',
      searchResult: [],
      searchInProgress: false,

      focusedListItem: null,

      hintsDropdownForceHide: false,

      //icons
      svg: {
        closeCircleOutlineIcon,
        saveFloppyIcon,
        deleteBtn,
        addCirclePlus,
        checkMarkIcon,
      },
    };
  },

  mounted() {
    window.addEventListener('keydown', this.handleArrowKeysClick);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleArrowKeysClick);
  },

  methods: {
    focus() {
      this.$refs.inputSearchQuery.focus();
    },

    handleArrowKeysClick(event) {
      const listLength = this.filteredSearchResult.length;

      if (event.key === 'ArrowUp') {
        if (this.focusedListItem === null) return;
        else this.focusedListItem = this.focusedListItem - 1 < 0 ? 0 : this.focusedListItem - 1;

        this.$refs[`single-search-element-${this.focusedListItem}`]?.[0]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });

        event.stopPropagation();
        event.preventDefault();
      } else if (event.key === 'ArrowDown') {
        if (this.focusedListItem === null) this.focusedListItem = 0;
        else
          this.focusedListItem = this.focusedListItem + 1 > listLength - 1 ? listLength - 1 : this.focusedListItem + 1;
        // filteredSearchResult

        this.$refs[`single-search-element-${this.focusedListItem}`]?.[0]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });

        event.stopPropagation();
        event.preventDefault();
      } else if (event.key === 'Enter' && this.focusedListItem !== null) {
        this.searchQuery = this.filteredSearchResult[this.focusedListItem];
        this.hintsDropdownForceHide = true;
        this.focusedListItem = null;

        event.stopPropagation();
        event.preventDefault();
      }
    },

    async search() {
      const searchTerm = this.searchQuery.trim();

      if (searchTerm === '') {
        this.searchResult = [];
        return;
      }

      this.searchInProgress = true;
      const { suppliers } = await this.$store.suppliers.simpleSearch(searchTerm, 5);
      this.searchInProgress = false;

      this.searchResult = suppliers;
    },
  },

  computed: {
    filteredSearchResult() {
      return this.$store.countries.all.filter((item) => {
        return item.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },

  watch: {
    searchQuery() {
      this.$emit('input', this.searchQuery);
      this.debouncedSearch();
    },
  },

  created() {
    this.debouncedSearch = lodashDebounce(this.search, 100, { leading: true, trailing: true });
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables.scss';

// ==========================================================================
// ==========================================================================
// Single Search element
.single-search-element {
  padding: math.div(0.8rem, 1.6) math.div(1rem, 1.6);
  border-radius: math.div(0.4rem, 1.6);
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
  cursor: pointer;

  //&::before {
  //  content: '';
  //  width: 100%;
  //  height: 100%;
  //  border-radius: 100px;
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  background: rgba(255,0,0,.2);
  //}

  &--clickable {
    cursor: pointer;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--focused {
    outline: none;
    background: rgba(53, 73, 94, 0.1);
  }

  &__flag-icon {
    float: left;
    width: math.div(2.6rem, 1.6);
    margin: 0 math.div(1.3rem, 1.6) 0 0;
  }

  &__name-n-country {
    width: calc(100% - #{math.div(5.5rem, 1.6)});
    min-width: calc(100% - #{math.div(5.5rem, 1.6)});
    max-width: calc(100% - #{math.div(5.5rem, 1.6)});
    flex: 1;
  }

  &__name {
    width: calc(100% - #{math.div(1rem, 1.6)});
    margin: 0;
    display: block;
    position: relative;
    overflow: hidden;
    font-size: math.div(1.2rem, 1.6);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__manage-but {
    width: math.div(1.6rem, 1.6);
    min-width: math.div(1.6rem, 1.6);
    height: math.div(1.6rem, 1.6);
    padding: 0;
    margin: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    transition: opacity 0.3s;

    &:focus {
      outline: none;
    }

    ::v-deep svg {
      width: 100%;
      height: 100%;
      fill: #35495e;
    }
  }
}

// ==========================================================================
// ==========================================================================
// Suppliers list
.search-result-list {
  padding: 0 math.div(1rem, 1.6) 0 0;
  box-sizing: border-box;
  overflow: auto;

  &__single-search-element {
    margin: 0;
  }
}

// ==========================================================================
// ==========================================================================
// Search Preload Skeleton
.search-preload-skeleton {
  padding-top: math.div(0.9rem, 1.6);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    width: 100%;
    height: math.div(10rem, 1.6);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(0, rgba(232, 232, 232, 1) 0%, rgba(232, 232, 232, 0) 100%);
  }

  &::after {
    content: '';
    width: math.div(0.8rem, 1.6);
    height: math.div(9rem, 1.6);
    border-radius: math.div(10rem, 1.6);
    position: absolute;
    top: 0;
    right: 0;
    background: rgb(210, 210, 210);
  }

  &__skeleton-item {
    margin: 0 math.div(2.8rem, 1.6) math.div(1.6rem, 1.6) math.div(1rem, 1.6);
  }

  .skeleton-item {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    &__flag {
      @include skeletonPlaceholder;
      width: math.div(2.6rem, 1.6);
      height: math.div(1.7rem, 1.6);
      margin-right: math.div(1.4rem, 1.6);
      opacity: 0.9;
    }

    &__text-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      flex-grow: 1;
    }

    &__title {
      @include skeletonPlaceholder;
      width: math.div(9.6rem, 1.6);
      height: math.div(1.3rem, 1.6);
      opacity: 0.9;
    }
  }
}

// ==========================================================================
// ==========================================================================
// Hints dropdown
.hints-dropdown {
  padding: math.div(2.2rem, 1.6) math.div(1.2rem, 1.6) math.div(1.8rem, 1.6);
  box-sizing: border-box;
  border-radius: 0 0 math.div(1.9rem, 1.6) math.div(1.9rem, 1.6);
  box-shadow: 0 3px math.div(1rem, 1.6) rgba(0, 0, 0, 0.1);
  background: #e8e8e8;

  &__scrollable-container {
    height: math.div(16rem, 1.6);
    min-height: math.div(16rem, 1.6);

    &--height-auto {
      height: auto;
      min-height: auto;
    }
  }

  &__search-result-list {
    width: 100%;
    margin: 0;
  }

  ::v-deep .generic-scrollable-block__inner {
    margin-bottom: 0;
  }

  &__search-preload-skeleton {
    height: math.div(16rem, 1.6);
    min-height: math.div(16rem, 1.6);
  }

  &__no-results-placeholder {
    height: math.div(4rem, 1.6);
    padding-bottom: math.div(1.5rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: rgba(53, 73, 94, 0.5);
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(1.6rem, 1.6);
  }

  &__bottom-panel {
    padding: math.div(1rem, 1.6) math.div(0.9rem, 1.6) 0;
    border-top: 1px rgba(53, 73, 94, 0.1) solid;
    font-size: math.div(1.4rem, 1.6);

    &--no-border {
      padding-top: 0;
      border-top: none;
    }
  }

  &__action-but {
    position: relative;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.1s ease;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: '';
      width: calc(100% + #{math.div(1rem, 1.6)});
      height: calc(100% + #{math.div(1rem, 1.6)});
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__action-but-plus-icon {
    width: math.div(1.4rem, 1.6);
    height: math.div(1.4rem, 1.6);
    margin: 0 math.div(0.6rem, 1.6) 0 0;
    display: inline-block;
    position: relative;
    top: 2px;

    ::v-deep svg {
      fill: rgba(53, 73, 94, 1);
    }
  }
}

// ==========================================================================
// ==========================================================================
// Input country with hints
.input-country-with-hints {
  position: relative;

  &__search-input {
    ::v-deep .input-main__search-icon {
      display: none;
    }

    &--active {
      background: rgba(232, 232, 232, 0.9) !important;
    }
  }

  &__country-flag {
    width: math.div(2.2rem, 1.6);
    height: math.div(2.2rem, 1.6);
    margin-right: math.div(0.8rem, 1.6);
  }

  &__hints-dropdown {
    width: 100%;
    position: absolute;
    inset: math.div(1.9rem, 1.6) auto auto 0;
  }
}
</style>
