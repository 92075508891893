var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-info-edit" },
    [
      _c("div", { staticClass: "contact-info-edit__title" }, [
        _vm._v("Contact info"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-itself contact-info-edit__form-itself" }, [
        _c("div", { staticClass: "form-itself__section" }, [
          _c(
            "div",
            { staticClass: "form-subtitle form-itself__form-subtitle" },
            [_vm._v("Website")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-itself__section-content" }, [
            _c(
              "div",
              { staticClass: "form-itself__inputs-line-container" },
              [
                _c("input-custom", {
                  staticClass: "form-itself__input",
                  attrs: { placeholder: "Website address" },
                  model: {
                    value: _vm.websiteInput,
                    callback: function ($$v) {
                      _vm.websiteInput = $$v
                    },
                    expression: "websiteInput",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-itself__section" }, [
          _c(
            "div",
            { staticClass: "form-subtitle form-itself__form-subtitle" },
            [_vm._v("E-mail")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-itself__section-content" },
            [
              _vm._l(_vm.emailsInputs, function (inputValue, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: {
                      "form-itself__inputs-line-container": 1,
                      "form-itself__inputs-line-container--small-bottom-margin":
                        index + 1 === _vm.emailsInputs.length,
                    },
                  },
                  [
                    _c("input-custom", {
                      staticClass: "form-itself__input",
                      attrs: { placeholder: "Email address" },
                      model: {
                        value: _vm.emailsInputs[index],
                        callback: function ($$v) {
                          _vm.$set(_vm.emailsInputs, index, $$v)
                        },
                        expression: "emailsInputs[index]",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "remove-icon-but remove-icon-but--smaller form-itself__remove-line-but",
                      },
                      [
                        _c("div", {
                          staticClass: "remove-icon-but__in-wrap",
                          domProps: {
                            innerHTML: _vm._s(_vm.svg.crossClearIcon),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "add-section-button form-itself__add-section-button",
                },
                [
                  _c("div", {
                    staticClass: "add-section-button__icon",
                    domProps: {
                      innerHTML: _vm._s(_vm.icons.interfaceElements.addCircle),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "add-section-button__text" }, [
                    _vm._v("Add E-mail"),
                  ]),
                ]
              ),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-itself__section" }, [
          _c(
            "div",
            { staticClass: "form-subtitle form-itself__form-subtitle" },
            [_vm._v("Phone")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-itself__section-content" },
            [
              _vm._l(_vm.phonesInputsGroups, function (inputValue, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: {
                      "form-itself__inputs-line-container": 1,
                      "form-itself__inputs-line-container--small-bottom-margin":
                        index + 1 === _vm.phonesInputsGroups.length,
                    },
                  },
                  [
                    _c("input-custom", {
                      staticClass: "form-itself__input",
                      attrs: { placeholder: "Office" },
                      model: {
                        value: _vm.phonesInputsGroups[index].type,
                        callback: function ($$v) {
                          _vm.$set(_vm.phonesInputsGroups[index], "type", $$v)
                        },
                        expression: "phonesInputsGroups[index].type",
                      },
                    }),
                    _vm._v(" "),
                    _c("select-with-input", {
                      staticClass: "form-itself__select-input",
                      attrs: {
                        "select-placeholder": "Code1",
                        "select-width": 80,
                        "select-options": ["+38", "+390", "+400", "+410"],
                        "input-value": _vm.phonesInputsGroups[index].number,
                        "input-placeholder": "Number",
                      },
                      on: { input: function () {} },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "remove-icon-but remove-icon-but--smaller form-itself__remove-line-but",
                      },
                      [
                        _c("div", {
                          staticClass: "remove-icon-but__in-wrap",
                          domProps: {
                            innerHTML: _vm._s(_vm.svg.crossClearIcon),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "add-section-button form-itself__add-section-button",
                },
                [
                  _c("div", {
                    staticClass: "add-section-button__icon",
                    domProps: {
                      innerHTML: _vm._s(_vm.icons.interfaceElements.addCircle),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "add-section-button__text" }, [
                    _vm._v("Add Phone Number"),
                  ]),
                ]
              ),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-itself__section" },
          [
            _c(
              "div",
              {
                staticClass:
                  "form-subtitle form-subtitle--no-paddings form-itself__form-subtitle",
              },
              [
                _c("div", { staticClass: "form-subtitle__left-side" }, [
                  _vm._v("Opening Hours"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-subtitle__right-side" },
                  [
                    _c("select-custom", {
                      staticClass: "form-subtitle__timezone-dropdown",
                      attrs: {
                        placeholder: "Timezone",
                        options: [
                          "GMT+2:00",
                          "GMT+3:00",
                          "GMT+4:00",
                          "GMT+5:00",
                          "GMT+6:00",
                        ],
                        "is-transparent-bg": "",
                      },
                      on: { input: function () {} },
                      model: {
                        value: _vm.openingHoursTimezone,
                        callback: function ($$v) {
                          _vm.openingHoursTimezone = $$v
                        },
                        expression: "openingHoursTimezone",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.openingHoursInputsGroups, function (daysSpan, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "form-itself__section-content form-itself__section-content--with-border",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-itself__inputs-line-container form-itself__inputs-line-container--narrow form-itself__inputs-line-container--big-bottom-margin",
                    },
                    [
                      _c("select-custom", {
                        staticClass: "form-itself__select",
                        attrs: {
                          placeholder: "Weekday",
                          options: [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                            "Sunday",
                          ],
                        },
                        on: { input: function () {} },
                        model: {
                          value:
                            _vm.openingHoursInputsGroups[index].weekDayFrom,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.openingHoursInputsGroups[index],
                              "weekDayFrom",
                              $$v
                            )
                          },
                          expression:
                            "openingHoursInputsGroups[index].weekDayFrom",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-itself__dash" }, [
                        _vm._v("-"),
                      ]),
                      _vm._v(" "),
                      _c("select-custom", {
                        staticClass: "form-itself__select",
                        attrs: {
                          placeholder: "Weekday",
                          options: [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                            "Sunday",
                          ],
                        },
                        on: { input: function () {} },
                        model: {
                          value: _vm.openingHoursInputsGroups[index].weekDayTo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.openingHoursInputsGroups[index],
                              "weekDayTo",
                              $$v
                            )
                          },
                          expression:
                            "openingHoursInputsGroups[index].weekDayTo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(daysSpan.timeSpans, function (timeSpan, idx) {
                    return _c(
                      "div",
                      {
                        key: idx,
                        class: {
                          "form-itself__inputs-line-container": 1,
                          "form-itself__inputs-line-container--narrower": 1,
                          "form-itself__inputs-line-container--medium-bottom-margin":
                            idx + 1 === daysSpan.timeSpans.length,
                        },
                      },
                      [
                        _c("input-custom", {
                          staticClass: "form-itself__input",
                          attrs: { placeholder: "From" },
                          model: {
                            value:
                              _vm.openingHoursInputsGroups[index].timeSpans[idx]
                                .from,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.openingHoursInputsGroups[index].timeSpans[
                                  idx
                                ],
                                "from",
                                $$v
                              )
                            },
                            expression:
                              "openingHoursInputsGroups[index].timeSpans[idx].from",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-itself__dash" }, [
                          _vm._v("-"),
                        ]),
                        _vm._v(" "),
                        _c("input-custom", {
                          staticClass: "form-itself__input",
                          attrs: { placeholder: "To" },
                          model: {
                            value:
                              _vm.openingHoursInputsGroups[index].timeSpans[idx]
                                .to,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.openingHoursInputsGroups[index].timeSpans[
                                  idx
                                ],
                                "to",
                                $$v
                              )
                            },
                            expression:
                              "openingHoursInputsGroups[index].timeSpans[idx].to",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "remove-icon-but remove-icon-but--smaller form-itself__remove-line-but",
                          },
                          [
                            _c("div", {
                              staticClass: "remove-icon-but__in-wrap",
                              domProps: {
                                innerHTML: _vm._s(_vm.svg.crossClearIcon),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "add-section-button form-itself__add-section-button form-itself__add-section-button--centered",
                    },
                    [
                      _c("div", {
                        staticClass: "add-section-button__icon",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.icons.interfaceElements.addCircle
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "add-section-button__text" }, [
                        _vm._v("Add Time Span"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "remove-icon-but form-itself__remove-section-but",
                    },
                    [
                      _c("div", {
                        staticClass: "remove-icon-but__in-wrap",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.icons.interfaceElements.trashCanIcon
                          ),
                        },
                      }),
                    ]
                  ),
                ],
                2
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "add-section-button add-section-button--big form-itself__add-section-button form-itself__add-section-button--centered",
          },
          [
            _c("div", {
              staticClass:
                "add-section-button__icon add-section-button__icon--big",
              domProps: {
                innerHTML: _vm._s(_vm.icons.interfaceElements.addCircle),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "add-section-button__text add-section-button__text--big",
              },
              [_vm._v("Add Opening Time")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "code-of-conduct-form__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }