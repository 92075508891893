<template>
  <!-- Input main component -->
  <div
    :class="{
      'input-main-component': true,
      'input-main-component--disabled': isDisabled,
      'input-main-component--error': isError,
      'input-main-component--search': isSearch,

      'input-main-component--focus': isInputOnFocus,

      'input-main-component--mid-code': variant === 'mid-code',
      'input-main-component--mid': variant === 'mid',
      'input-main-component--min-code': variant === 'min-code',

      'input-main-component--position-left': position === 'left',
      'input-main-component--position-right': position === 'right',
    }"
    @mouseenter="isComponentHovered = true"
    @mouseleave="isComponentHovered = false"
  >
    <label class="input-main-component__text-input-label">
      <!-- Upload area -->
      <span v-if="localType === 'file' && !localValue" class="upload-area input-main-component__upload-area">
        <span class="upload-area__icon" v-html="svg.uploadCloudArrowIcon"></span>

        <span class="upload-area__text">
          {{ placeholder || 'Upload Document' }}
        </span>

        <span :class="{ 'upload-area__bg-layer': true, 'upload-area__bg-layer--hovered': isComponentHovered }">
        </span> </span
      ><!-- / Upload area -->

      <!-- Upload or type URL -->
      <span
        v-if="localType === 'fileOrUrl' && !localValue"
        class="upload-or-type-url input-main-component__upload-or-type-url"
        @mouseenter="
          () => {
            if (!isUploadSelected && !isAddUrlSelected) isUploadOrAddUrlMenuVisible = true;
          }
        "
        @mouseleave="
          () => {
            if (!isUploadSelected && !isAddUrlSelected) isUploadOrAddUrlMenuVisible = false;
          }
        "
        @dragenter="
          localType = 'file';
          isUploadSelected = true;
          isUploadOrAddUrlMenuVisible = false;
        "
      >
        <span
          v-if="!isUploadOrAddUrlMenuVisible && !isUploadSelected && !isAddUrlSelected"
          class="upload-or-type-url__text"
        >
          {{ placeholder || 'Add Document' }}
        </span>

        <span
          v-if="isUploadOrAddUrlMenuVisible && !isUploadSelected && !isAddUrlSelected"
          class="upload-or-type-buttons upload-or-type-url__upload-or-type-buttons"
        >
          <span
            class="upload-or-type-buttons__but"
            @click="
              isUploadSelected = true;
              localType = 'file';
            "
          >
            <span class="upload-or-type-buttons__but-icon" v-html="svg.uploadCloudArrowIcon"> </span>
            <span class="upload-or-type-buttons__but-text">Upload</span>
          </span>
          <span class="upload-or-type-buttons__but" @click="isAddUrlSelected = true">
            <span
              class="upload-or-type-buttons__but-icon upload-or-type-buttons__but-icon--external-link"
              v-html="svg.externalLinkIcon"
            >
            </span>
            <span class="upload-or-type-buttons__but-text"> Add Link </span>
          </span>
        </span>

        <span
          v-if="!isUploadSelected && !isAddUrlSelected"
          :class="{
            'upload-or-type-url__bg-layer': true,
            'upload-or-type-url__bg-layer--with-divider':
              isUploadOrAddUrlMenuVisible && !isUploadSelected && !isAddUrlSelected,
            'upload-or-type-url__bg-layer--hovered': isComponentHovered,
          }"
        >
        </span> </span
      ><!-- / Upload or type URL -->

      <span v-if="isSearch && !noMagnifierButton" class="input-main-component__search-icon" v-html="svg.searchIcon">
      </span>

      <slot></slot>

      <span v-if="additionalIcon" v-html="additionalIcon" class="input-main-component__additional-icon"> </span>

      <span v-if="localType === 'file'" class="input-main-component__clip-icon" v-html="svg.clipIcon"> </span>

      <span
        v-if="localType === 'fileOrUrl' && isAddUrlSelected"
        class="input-main-component__external-link-icon"
        v-html="svg.externalLinkIcon"
      >
      </span>

      <span class="input-main-component__file-name" v-if="localType === 'file'">
        {{ retrieveFileName(localValue) || 'Choose file' }}
      </span>

      <span v-if="prefix || $slots.prefix" class="input-main-component__prefix">
        <slot name="prefix">
          {{ prefix }}
        </slot>
      </span>

      <!-- Input itself -->
      <input
        v-if="isLazy"
        :class="{
          'input-itself': true,
          'input-itself--disabled': isDisabled,
          'input-itself--hidden': localType === 'file',
          'input-itself--cursor-pointer': localType === 'file',
          'input-itself--text-align-center': textAlignCenter,
          'input-itself--additional-left-padding': additionalIcon,
          'input-itself--additional-right-padding': isDisabled,
          'input-itself--mid': variant === 'mid',
          'input-itself--mid-code': variant === 'mid-code',
          'input-itself--min-code': variant === 'min-code',
          'input-itself--with-suffix': !isInputOnFocus && (suffix || $slots.suffix),
          'input-main-component__input-itself': true,
        }"
        :type="localType"
        :disabled="isDisabled"
        :placeholder="localType === 'fileOrUrl' ? 'File URL' : placeholder"
        v-model.lazy="localValue"
        ref="input"
        @keyup.enter="$emit('keyup-enter', $event)"
        @focus="
          isInputOnFocus = true;
          $emit('focus');
        "
        @blur="
          isInputOnFocus = false;
          $emit('blur');
        "
        :autocomplete="autocompleteOff ? 'none' : 'on'"
        :tabindex="tabindex ? +tabindex : 1"
        @change="realChange()"
        :min="min || Number(min) === 0 ? min : ''"
      />
      <input
        v-else
        :class="{
          'input-itself': true,
          'input-itself--disabled': isDisabled,
          'input-itself--hidden': localType === 'file',
          'input-itself--text-align-center': textAlignCenter,
          'input-itself--additional-left-padding': additionalIcon,
          'input-itself--additional-right-padding': isDisabled,
          'input-itself--with-suffix': !isInputOnFocus && (suffix || $slots.suffix),
          'input-itself--mid': variant === 'mid',
          'input-itself--mid-code': variant === 'mid-code',
          'input-itself--min-code': variant === 'min-code',
          'input-main-component__input-itself': true,
          'input-main-component__input-itself--smaller-width': isClearButtonVisible,
        }"
        :type="localType"
        :disabled="isDisabled"
        :placeholder="localType === 'fileOrUrl' ? 'File URL' : placeholder"
        v-model="localValue"
        ref="input"
        @keyup.enter="$emit('keyup-enter', $event)"
        @focus="
          isInputOnFocus = true;
          $emit('focus');
        "
        @blur="
          isInputOnFocus = false;
          $emit('blur');
        "
        :autocomplete="autocompleteOff ? 'none' : 'on'"
        :tabindex="tabindex ? +tabindex : 1"
        @change="realChange()"
        :min="min || Number(min) === 0 ? min : ''"
      />
      <!-- / Input itself -->

      <span v-if="suffix || $slots.suffix" class="input-main-component__suffix">
        <slot name="suffix">
          {{ suffix }}
        </slot>
      </span>
    </label>

    <span
      v-if="isExpandButtonVisible"
      :class="{
        'input-main-component__arrow-button': true,
        'input-main-component__arrow-button--flip': !_localIsExpanded,
      }"
      v-html="svg.arrowUpIcon"
      @click="toggleExpand"
    />

    <span
      v-if="isClearButtonVisible"
      class="input-main-component__cross-button"
      v-html="svg.crossIcon"
      @click="resetInput"
    >
    </span>

    <span
      v-if="hasText && localType === 'file' && !noClearButton"
      class="input-main-component__trash-can-button"
      v-html="svg.trashCanOutlinedIcon"
      @click="resetInput"
    >
    </span>

    <fs-tooltip
      v-if="isDisabled && disabledTooltip"
      :text="disabledTooltip"
      class="input-main-component__lock-icon-tooltip-wrapper"
    >
      <span
        :class="{
          'input-main-component__lock-icon': true,
          'input-main-component__lock-icon--with-tooltip': true,
        }"
        v-html="svg.lockIcon"
      ></span>
    </fs-tooltip>

    <span v-else-if="isDisabled" class="input-main-component__lock-icon" v-html="svg.lockIcon"></span>
  </div>
  <!-- / Input main component -->
</template>

<script>
import twoWayProperty from '@lib/twoWayProperty';

// Components
import FsTooltip from '@components/poppers/fs-tooltip.vue';

// Icons
// SVG
import lockIcon from '@icons/other/ico-lock.svg';
import crossIcon from '@icons/other/ico-cross.svg';
import searchIcon from '@icons/other/ico-search.svg';
import clipIcon from '@icons/miscellaneous/clip.svg';
import arrowUpIcon from '@icons/other/arrow-up.svg';
import uploadCloudArrowIcon from '@icons/interface/upload-cloud-arrow.svg';
import externalLinkIcon from '@icons/interface/external-link.svg';
import trashCanOutlinedIcon from '@icons/interface/trash-can-outlined.svg';

export default {
  props: {
    placeholder: String,

    value: {
      type: [String, Number],
      // Be careful not to add a default value here, since that breaks twoWayProperty's fallback feature
    },

    variant: {
      type: String,
      validator: (variant) => ['standard', 'mid-code', 'mid', 'min-code'].includes(variant),
      default: 'standard',
    },

    type: {
      type: String,
      default: 'text',
      validator: (type) => ['text', 'number', 'file', 'fileOrUrl'].includes(type), // Type file is deprecated
    },

    // For the cases when input type is 'file'
    // Deprecated. Use file-input component instead.
    inputFileName: {
      default: '',
    },

    isExpanded: Boolean,
    isExpandable: Boolean,

    isSearch: Boolean,

    isDisabled: Boolean,
    disabledTooltip: String, // Tooltip to show when hovering lock icon

    isError: Boolean,

    isLazy: Boolean,
    autocompleteOff: Boolean,
    tabindex: Number,

    textAlignCenter: { type: Boolean, default: false },

    additionalIcon: String, // Deprecated, use prefix or slot prefix instead
    suffix: { type: String, default: '' },
    prefix: { type: String, default: '' },

    // attributes which used for input number
    min: { type: [String, Number] },
    max: { type: [String, Number] },

    // Set to left or right when using select in a select-with-input.
    position: { type: String, validator: (position) => ['left', 'right'].includes(position) },

    // Use with care, these one changes the intended behavior of the component
    noClearButton: Boolean,
    forceShowClearButton: Boolean,
    noMagnifierButton: { type: Boolean, default: false },
  },

  components: { FsTooltip },

  data() {
    return {
      isComponentHovered: false,

      localType: null,

      // "Upload or type URL" options
      isUploadSelected: false,
      isAddUrlSelected: false,
      isUploadOrAddUrlMenuVisible: false,

      lastEmittedLocalValue: this.value,
      previousValue: this.value,

      svg: {
        arrowUpIcon,
        lockIcon,
        crossIcon,
        searchIcon,
        clipIcon,
        uploadCloudArrowIcon,
        externalLinkIcon,
        trashCanOutlinedIcon,
      },
      isInputOnFocus: false,
    };
  },

  mounted() {
    this.localType = this.type;
  },

  updated() {},

  computed: {
    isClearButtonVisible() {
      return (
        this.forceShowClearButton ||
        (this.hasText &&
          this.localType !== 'file' &&
          (this.isSearch || this.localType === 'fileOrUrl') &&
          !this.noClearButton)
      );
    },

    isExpandButtonVisible() {
      return this.isExpandable && !this.isClearButtonVisible;
    },

    hasText() {
      return this.localValue && String(this.localValue).length > 0;
    },
    localValue: twoWayProperty('value', {
      emitParameterFactory: function (newValue) {
        return [newValue, this.previousValue];
      },
    }),

    _localIsExpanded: twoWayProperty('isExpanded'),
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    },

    /**
     * Works the same like v-model.lazy
     */
    realChange() {
      this.$emit('real-change', this.localValue, this.previousValue);
      this.previousValue = this.localValue;
    },

    resetInput() {
      this.localValue = '';
      this.isAddUrlSelected = false;
      this.isUploadSelected = false;
      this.isUploadOrAddUrlMenuVisible = false;
      this.localType = this.type;
      this.$emit('reset-input', this.hasText);

      this.$refs.input.focus();
    },

    retrieveFileName(value) {
      return (value || '').match(/(.*[\\\/])(.*)/)?.[2];
    },

    toggleExpand() {
      this._localIsExpanded = !this._localIsExpanded;
      this.$refs.input.focus();
    },
  },

  watch: {
    localValue() {
      this.lastEmittedLocalValue = this.localValue;
      this.$emit('change', this.localValue, this.previousValue); // TODO: FIXME - we def shouldn't use the change event for something else than what it is
    },
    value() {
      // Update previousValue on any external updates of value
      if (this.value !== this.lastEmittedLocalValue) {
        this.previousValue = this.value;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@import 'common/styles/popover.scss';
@import 'common/styles/variables';

// ============================================================================
// ============================================================================
// Upload or type URL
.upload-or-type-url {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  &__text {
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(1.4rem, 1.6);
    color: rgba($c-dark, 0.5);
    pointer-events: none;
  }

  &__upload-or-type-buttons {
    width: 100%;
    height: 100%;
  }
  .upload-or-type-buttons {
    display: flex;
    cursor: pointer;

    &__but {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      color: rgba($c-dark, 0.5);
      user-select: none;
    }

    &__but-icon {
      width: math.div(1.7rem, 1.6);
      height: math.div(1.7rem, 1.6);
      margin-right: math.div(1.3rem, 1.6);
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      pointer-events: none;

      ::v-deep svg {
        fill: rgba(53, 73, 95, 0.5);
      }

      &--external-link {
        ::v-deep svg {
          width: 83%;
          height: 83%;
        }
      }
    }

    &__but-text {
    }
  }

  &__bg-layer {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px rgba($c-dark, 0.4) dashed;
    border-radius: math.div(10rem, 1.6);
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: #f7f7f7;
    transition: transform 0.1s ease;
    cursor: pointer;

    &--hovered {
      transform: scaleX(1.02) scaleY(1.05);
    }

    &--with-divider {
      &::before {
        content: '';
        width: 50%;
        height: 100%;
        position: absolute;
        inset: 0 auto auto 0;
        border-right: 1px rgba($c-dark, 0.4) dashed;
      }
    }
  }
}

// ============================================================================
// ============================================================================
// Upload area
.upload-area {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  pointer-events: none;

  &__icon {
    width: math.div(1.7rem, 1.6);
    height: math.div(1.7rem, 1.6);
    margin-right: math.div(1.3rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    pointer-events: none;

    ::v-deep svg {
      fill: rgba(53, 73, 95, 0.5);
    }
  }

  &__text {
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(1.4rem, 1.6);
    color: rgba($c-dark, 0.5);
    pointer-events: none;
  }

  &__bg-layer {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px rgba($c-dark, 0.4) dashed;
    border-radius: math.div(10rem, 1.6);
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: #f7f7f7;
    transition: transform 0.1s ease;

    &--hovered {
      transform: scaleX(1.02) scaleY(1.05);
    }
  }
}

// ============================================================================
// ============================================================================
// Input itself
.input-itself {
  width: 100%;
  height: 100%;
  padding: math.div(0.1rem, 1.6) 0 0;
  background: none;
  margin: 0;
  border: none;
  color: inherit;
  font-size: math.div(1.4rem, 1.6);
  line-height: math.div(1.4rem, 1.6);
  outline: none;

  &::placeholder {
    color: rgba($c-dark, 0.5);
  }

  &[value=''] + .input-main-component__cross-button {
    display: none;
  }

  &[value=''] + .input-main-component__trash-can-button {
    display: none;
  }

  &--with-suffix {
    -webkit-mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 82%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 82%, rgba(0, 0, 0, 0) 100%);
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--hidden {
    position: absolute;
    opacity: 0;
  }

  &--cursor-pointer {
    cursor: pointer;
  }

  &--text-align-center {
    text-align: center;
  }

  &--additional-left-padding {
    padding-left: math.div(2.7rem, 1.6);
  }

  &--additional-right-padding {
    padding-right: math.div(1.5rem, 1.6);
  }

  &--mid-code {
  }

  &--mid {
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.2rem, 1.6);
  }

  &--min-code {
  }
}

// ============================================================================
// ============================================================================
// Input main component
.input-main-component {
  height: math.div(3.7rem, 1.6);
  border-radius: math.div(1.9rem, 1.6);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  color: rgba($c-dark, 1);
  background: rgba($c-bg-gray-darkest, 0.5);
  transition: 0.1s;
  user-select: none;

  &--position-left {
    flex: 1;
    border-radius: math.div(1.9rem, 1.6) 0 0 math.div(1.9rem, 1.6);
    border-right: 1px solid rgba($c-dark, 0.1);
  }

  &--position-right {
    flex: 1;
    border-radius: 0 math.div(1.9rem, 1.6) math.div(1.9rem, 1.6) 0;
    border-left: 1px solid rgba($c-dark, 0.1);
  }

  &:hover {
    color: rgba($c-dark, 0.75);
    background: rgba($c-bg-gray-darkest, 0.75);
  }

  &--focus,
  &--focus:hover {
    color: rgba($c-dark, 1);
    background: rgba($c-bg-gray-darkest, 1);
  }

  &--error {
    background: #ffbcbc !important;
  }

  &--disabled {
    color: rgba($c-dark, 0.25) !important;
    background: rgba($c-bg-gray-darkest, 0.25) !important;
  }

  &--mid-code {
    height: math.div(3.1rem, 1.6);
    border-radius: math.div(1.6rem, 1.6);
  }

  &--mid {
    height: math.div(3.1rem, 1.6);
    border-radius: math.div(1.6rem, 1.6);
  }

  &--min-code {
    height: math.div(2.5rem, 1.6);
    border-radius: math.div(0.5rem, 1.6);
  }

  &__text-input-label {
    width: 100%;
    height: 100%;
    padding: 0 math.div(2rem, 1.6);
    box-sizing: border-box;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(1.4rem, 1.6);
    cursor: text;
  }

  &--mid-code &__text-input-label {
    padding: 0 math.div(1.6rem, 1.6);
  }

  &--mid &__text-input-label {
    padding: 0 math.div(1.6rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.2rem, 1.6);
  }

  &--min-code &__text-input-label {
    padding: 0 math.div(0.8rem, 1.6) 0;
  }

  &__upload-area,
  &__upload-or-type-url {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__input-itself {
    &--smaller-width {
      width: calc(100% - #{math.div(5.5rem, 1.6)});
    }
  }

  &__lock-icon-tooltip-wrapper {
    width: math.div(1rem, 1.6);
    height: math.div(1.2rem, 1.6);
    position: absolute;
    z-index: 2;
    inset: calc(50% - #{math.div(0.5rem, 1.6)}) math.div(1.2rem, 1.6) auto auto;
  }

  &__lock-icon {
    width: math.div(1.1rem, 1.6);
    height: math.div(1.4rem, 1.6);
    position: absolute;
    z-index: 2;
    inset: auto math.div(1.6rem, 1.6) auto auto;
    pointer-events: none;

    &--with-tooltip {
      inset: auto;
      pointer-events: auto;
    }
  }

  &--mid &__lock-icon,
  &--min-code &__lock-icon {
    width: math.div(1rem, 1.6);
    height: math.div(1.2rem, 1.6);
  }

  &--min-code &__lock-icon {
    inset: auto math.div(0.8rem, 1.6) auto auto;
  }

  &__search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: math.div(2rem, 1.6);
    height: math.div(2rem, 1.6);
    margin-right: math.div(0.8rem, 1.6);

    ::v-deep svg {
      width: math.div(1.4rem, 1.6);
      height: math.div(1.4rem, 1.6);
      fill: rgba($c-dark, 0.7);
    }
  }

  &__additional-icon {
    width: math.div(1.6rem, 1.6);
    height: math.div(1.6rem, 1.6);
    position: absolute;
    top: calc(50% - #{math.div(0.8rem, 1.6)});
    left: math.div(1.7rem, 1.6);
    pointer-events: none;

    ::v-deep svg {
      width: 100%;
      height: 100%;
    }
  }

  &__clip-icon,
  &__external-link-icon {
    width: math.div(1.5rem, 1.6);
    height: math.div(1.5rem, 1.6);
    margin-right: math.div(0.5rem, 1.6);
    margin-left: math.div(0.5rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    pointer-events: none;

    ::v-deep svg {
      fill: rgba($c-dark, 0.6);
    }
  }
  &__external-link-icon {
    position: relative;
    top: -1px;
  }

  &__prefix,
  &__suffix {
    height: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    position: relative;
    color: rgba($c-dark, 0.5);
    pointer-events: none;
    user-select: none;
  }

  &__prefix {
    padding-right: math.div(1rem, 1.6);
    justify-content: flex-start;
    font-size: math.div(1.6rem, 1.6);
    line-height: math.div(1.9rem, 1.6);
  }

  &__suffix {
    padding-left: math.div(1rem, 1.6);
    justify-content: flex-end;
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(1.4rem, 1.6);
  }

  &--min-code &__suffix {
    font-size: math.div(1rem, 1.6);
    line-height: math.div(1rem, 1.6);
  }

  &--mid &__prefix,
  &--min-code &__prefix {
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(1.7rem, 1.6);
  }

  &__arrow-button,
  &__cross-button,
  &__trash-can-button {
    width: math.div(1.1rem, 1.6);
    height: math.div(1.1rem, 1.6);
    position: absolute;
    inset: 40% math.div(2rem, 1.6) auto auto;
    z-index: 2;
    cursor: pointer;

    &::before {
      content: '';
      width: calc(100% + #{math.div(1rem, 1.6)});
      height: calc(100% + #{math.div(1rem, 1.6)});
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }

    ::v-deep svg {
      fill: rgba($c-dark, 0.6);
    }
  }

  &__cross-button {
    width: math.div(0.9rem, 1.6);
    height: math.div(0.9rem, 1.6);
    inset: 40% math.div(2.3rem, 1.6) auto auto;

    ::v-deep svg {
      fill: $c-dark;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &__arrow-button {
    width: math.div(1rem, 1.6);
    height: math.div(1rem, 1.6);
    inset: 40% math.div(2rem, 1.6) auto auto;
    transition: transform 0.2s ease-in-out;

    ::v-deep svg {
      fill: rgba($c-dark, 0.5);
    }

    &:hover {
      ::v-deep svg {
        fill: $c-dark;
      }
    }

    &--flip {
      transform: rotate(180deg);
    }
  }

  &__trash-can-button {
    width: math.div(1.4rem, 1.6);
    height: math.div(1.4rem, 1.6);
    top: math.div(1.1rem, 1.6);
  }

  &__file-name {
    width: calc(100% - #{math.div(5.5rem, 1.6)});
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
