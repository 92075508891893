<template>
  <vue-slider class="slider" :use-keyboard="true" ref="vueSlider" v-bind="$attrs" v-on="$listeners"
    ><slot></slot
  ></vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component/src/vue2-slider.vue';

export default {
  name: 'fs-slider',
  components: { VueSlider },
  inheritAttrs: false,
  methods: {
    refreshSlider() {
      // This whole thing is two hacks on top of each other to get the slider to update properly when underlying data changes
      this.$nextTick(() => {
        this.$refs.vueSlider.getStaticData();

        // Fixes issue with mouse cursor being off position wise compared to dot
        this.$nextTick(() => {
          this.$refs.vueSlider.getStaticData();
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';

.slider {
  background: transparent;

  ::v-deep .vue-slider-piecewise-item {
    border-radius: 100px;
    background: #ccd1d7;
    transform: scaleX(0.2) scaleY(1.4);
  }

  ::v-deep .vue-slider-piecewise-dot {
    border-radius: 100px;
    background: transparent;
  }

  ::v-deep .vue-slider-tooltip {
    border-color: $c-dark;
    background-color: $c-dark;
    font-size: math.div(1.2rem, 1.6);
    opacity: 1;

    &:hover {
      opacity: 0.75;
    }
  }

  ::v-deep .vue-slider-tooltip-wrap.vue-slider-tooltip-bottom {
    bottom: -5px;
  }

  // Tooltip
  .diy-tooltip {
    margin-top: math.div(-0.7rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
  }

  // Line between dots
  ::v-deep .vue-slider-process {
    background: transparent;

    &::before {
      content: '';
      width: 100%;
      height: math.div(0.2rem, 1.6);
      position: absolute;
      top: math.div(0.2rem, 1.6);
      left: 0;
      background: rgba(53, 73, 94, 1);
    }
  }

  // Dots
  ::v-deep .vue-slider-dot {
    .vue-slider-dot-handle {
      width: math.div(1.6rem, 1.6);
      height: math.div(1.6rem, 1.6);
      position: relative;
      background: transparent;
      box-shadow: none;

      &::before {
        content: '';
        width: math.div(3rem, 1.6);
        height: math.div(3rem, 1.6);
        position: absolute;
        top: math.div(-0.7rem, 1.6);
        left: math.div(-0.7rem, 1.6);
      }

      &::after {
        content: '';
        width: math.div(1.2rem, 1.6);
        height: math.div(1.2rem, 1.6);
        box-sizing: border-box;
        border: math.div(0.2rem, 1.6) rgba(53, 73, 94, 1) solid;
        border-radius: math.div(10rem, 1.6);
        position: absolute;
        top: math.div(0.2rem, 1.6);
        left: math.div(0.2rem, 1.6);
        background: #fff;
        box-shadow: 0 1px math.div(0.2rem, 1.6) rgba(0, 0, 0, 0.25);
        transition: width 0.15s ease, height 0.15s ease, top 0.15s ease, left 0.15s ease;
      }
    }
  }

  ::v-deep .vue-slider-dot-handle:hover,
  ::v-deep .vue-slider-dot-dragging .vue-slider-dot-handle {
    &::after {
      width: calc(#{math.div(1.2rem, 1.6)} + #{math.div(0.4rem, 1.6)});
      height: calc(#{math.div(1.2rem, 1.6)} + #{math.div(0.4rem, 1.6)});
      top: 0;
      left: 0;
    }
  }

  // Back line
  ::v-deep .vue-slider-piecewise {
    background: #fff;

    &::before {
      content: '';
      width: 100%;
      height: math.div(3.6rem, 1.6);
      position: absolute;
      top: math.div(-1.5rem, 1.6);
    }

    &::after {
      content: '';
      width: 100%;
      height: math.div(0.2rem, 1.6);
      position: absolute;
      top: math.div(0.2rem, 1.6);
      left: 0;
      background: rgba(
        53,
        73,
        94,
        0.25
      ); // REVIEW: Was set to #ccd1d7 in (bom) type-n-composition component, what's correct?
    }
  }
}
</style>
