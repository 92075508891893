// Inspiration taken from lib/show-hide.js
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

const _targets = [];

function getResizeObserverConstructor() {
  return window.ResizeObserver || ResizeObserverPolyfill;
}

function _getTargetFromEl(el) {
  return _targets.find((target) => target.el === el);
}

function observe(el, callback) {
  const target = _getTargetFromEl(el);

  // Cleanup of potential previous observers
  unobserve(el, target);

  // Setup new observer
  const resizeObserver = new (getResizeObserverConstructor())(callback);

  target.observer = resizeObserver;

  resizeObserver.observe(el);

  return target;
}

function unobserve(el, target) {
  target = target || _getTargetFromEl(el);

  if (target.observer) target.observer.disconnect();

  return target;
}

function bind(el, binding) {
  const target = {};
  target.el = el;
  _targets.push(target);
}

function inserted(el, { value: callback }) {
  observe(el, callback);
}

async function componentUpdated(el, { value: callback, oldValue: oldCallback }) {
  if (callback != oldCallback) observe(el, callback);
}

function unbind(el) {
  const target = unobserve(el);
  _targets.splice(_targets.indexOf(target), 1);
}

export default {
  bind,
  inserted,
  componentUpdated,
  unbind,
};
