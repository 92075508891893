import facebook from '@icons/social/facebook.svg';
import instagram from '@icons/social/instagram.svg';
import linkedin from '@icons/social/linkedin.svg';
import pinterest from '@icons/social/pinterest.svg';
import skype from '@icons/social/skype.svg';
import twitter from '@icons/social/twitter.svg';
import wechat from '@icons/social/wechat.svg';
import whatsapp from '@icons/social/whatsapp.svg';
import youtube from '@icons/social/youtube.svg';

import facebookMonochrome from '@icons/social/facebook-monochrome.svg';
import instagramMonochrome from '@icons/social/instagram-monochrome.svg';
import twitterMonochrome from '@icons/social/twitter-monochrome.svg';
import linkedinMonochrome from '@icons/social/linkedin-monochrome.svg';

export default {
  facebook,
  instagram,
  linkedin,
  pinterest,
  skype,
  twitter,
  wechat,
  whatsapp,
  youtube,
  facebookMonochrome,
  instagramMonochrome,
  twitterMonochrome,
  linkedinMonochrome,
};
