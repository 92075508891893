var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.componentTag,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          class: _vm.classes,
          attrs: { tag: _vm.tagAttr, to: _vm.to, exact: _vm.exact },
        },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.$slots.icon
        ? _c(
            "div",
            { staticClass: "button__icon-wrapper" },
            [_vm._t("icon")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.loading
        ? _c("circular-spinner", {
            staticClass: "button__circular-spinner",
            attrs: { inverse: !_vm.inverted, dark: _vm.inverted },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }