<template>
  <div :class="`generic-scrollable-block`" :style="{ height }" ref="rootContainer">
    <transition name="simple-fade-in-out">
      <div
        v-if="showShadows && isTopShadowVisible"
        :class="{
          'generic-scrollable-block__shadow': true,
          'generic-scrollable-block__shadow--top': true,
          'generic-scrollable-block__shadow--color-white': shadowsColor === 'white',
          'generic-scrollable-block__shadow--color-light-gray': shadowsColor === 'light-gray',
          'generic-scrollable-block__shadow--color-dark-gray': shadowsColor === 'dark-gray',
        }"
      >
        &nbsp;
      </div>
    </transition>

    <transition name="simple-fade-in-out">
      <div
        v-if="showShadows && isContentExceedParentSize && isBottomShadowVisible"
        :class="{
          'generic-scrollable-block__shadow': true,
          'generic-scrollable-block__shadow--bottom': true,
          'generic-scrollable-block__shadow--color-white': shadowsColor === 'white',
          'generic-scrollable-block__shadow--color-light-gray': shadowsColor === 'light-gray',
          'generic-scrollable-block__shadow--color-dark-gray': shadowsColor === 'dark-gray',
        }"
      >
        &nbsp;
      </div>
    </transition>

    <div
      :class="{
        'generic-scrollable-block__inner': true,
        'generic-scrollable-block__inner--no-scrollbar': noScrollbar,
        'generic-scrollable-block__inner--no-bottom-margin': noBottomMargin || !showShadows,
        'generic-scrollable-block__inner--with-scrollbar': isScrollBarShowed,
      }"
      @scroll="toggleShadows($event)"
      ref="scrollableContainer"
    >
      <div class="generic-scrollable-block__content-wrapper" ref="contentWrapper">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @deprecated This component is deprecated, use fs-scrollable instead
 */
export default {
  name: 'scrollable-block',

  props: {
    height: Number,
    shadowsColor: {
      type: String,
      validator: (color) => ['white', 'light-gray', 'dark-gray'].includes(color),
      default: 'white',
    },
    showShadows: {
      type: Boolean,
      default: true,
    },
    scrollToEndOnUpdate: Boolean,
    hideScrollBar: Boolean,
    activeElementOffset: Number,
    noScrollbar: Boolean,
    noBottomMargin: Boolean,
  },

  data() {
    return {
      eventThrottlingTimestamp: 0,

      isScrollBarShowed: false,

      isContentExceedParentSize: false,

      isTopShadowVisible: false,
      isBottomShadowVisible: true,
    };
  },

  mounted() {
    this.setIsContentExceedParentSize();
  },

  updated() {
    this.setIsContentExceedParentSize();
  },

  methods: {
    setIsContentExceedParentSize() {
      const contentWrapperHeight = this.$refs.contentWrapper?.clientHeight,
        rootContainerHeight = this.$refs.rootContainer?.clientHeight;

      this.isContentExceedParentSize = contentWrapperHeight > rootContainerHeight;

      // timeout is needed because block height values updates with slight delay
      setTimeout(this.setIsScrollbarShowed, 350);
    },

    toggleShadows(event) {
      // Throttling
      if (this.eventThrottlingTimestamp !== 0 && Date.now() - this.eventThrottlingTimestamp < 10) return;
      this.eventThrottlingTimestamp = Date.now();

      if (!this.isScrollBarShowed) {
        this.isTopShadowVisible = false;
        this.isBottomShadowVisible = false;

        return;
      }

      const shadowShoeThreshold = 20;
      const minScrollPosition = shadowShoeThreshold;
      const maxScrollPosition = event.target.scrollHeight - event.target?.clientHeight - shadowShoeThreshold;
      const currentScrollPosition = event.target.scrollTop;

      if (currentScrollPosition > minScrollPosition) this.isTopShadowVisible = true;
      else if (currentScrollPosition <= minScrollPosition) this.isTopShadowVisible = false;

      if (currentScrollPosition < maxScrollPosition) this.isBottomShadowVisible = true;
      else if (currentScrollPosition > maxScrollPosition) this.isBottomShadowVisible = false;
    },

    setIsScrollbarShowed() {
      const scrollableContainerHeight = this.$refs.scrollableContainer?.clientHeight;
      const contentContainerHeight = this.$refs.contentWrapper?.clientHeight;

      const isScrollbarShowed = scrollableContainerHeight < contentContainerHeight;

      if (this.isScrollBarShowed !== isScrollbarShowed) {
        this.isScrollBarShowed = isScrollbarShowed;
      }
    },
  },

  watch: {
    activeElementOffset() {
      if (this.activeElementOffset) {
        this.$refs.scrollableContainer.scrollTop = this.activeElementOffset - 100;
      }
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';
@import 'common/styles/variables';

// ============================================================================
// ============================================================================
// Generic Scrollable Block
.generic-scrollable-block {
  min-height: math.div(20rem, 1.6);
  position: relative;
  display: flex;
  overflow: hidden;

  &__shadow {
    width: 100%;
    height: math.div(6rem, 1.6);
    max-height: 10%;
    position: absolute;
    left: math.div(-0.8rem, 1.6);
    z-index: 2;
    background: rgba(255, 0, 0, 0.1);
    pointer-events: none;

    &--top {
      top: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%);

      &.generic-scrollable-block__shadow--color-light-gray {
        background: rgb(250, 250, 250);
        background: linear-gradient(180deg, rgba(250, 250, 250, 1) 0, rgba(250, 250, 250, 0) 100%);
      }

      &.generic-scrollable-block__shadow--color-dark-gray {
        background: rgb(250, 250, 250);
        background: linear-gradient(180deg, rgba(232, 232, 232, 1) 0, rgba(232, 232, 232, 0) 100%);
      }

      &.generic-scrollable-block__shadow--color-white {
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%);
      }
    }

    &--bottom {
      bottom: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%);

      &.generic-scrollable-block__shadow--color-light-gray {
        background: rgb(250, 250, 250);
        background: linear-gradient(0deg, rgba(250, 250, 250, 1) 0, rgba(250, 250, 250, 0) 100%);
      }

      &.generic-scrollable-block__shadow--color-dark-gray {
        background: rgb(250, 250, 250);
        background: linear-gradient(0deg, rgba(232, 232, 232, 1) 0, rgba(232, 232, 232, 0) 100%);
      }

      &.generic-scrollable-block__shadow--color-white {
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%);
      }
    }
  }

  // Extra 15px is required to cover all inner content that can wbe wider than shadow layer
  &__inner {
    width: calc(100% + #{math.div(1.5rem, 1.6)});
    height: 100%;
    padding: 1px 0 1px;
    margin-bottom: math.div(2.5rem, 1.6);
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: height 0.15s ease;
    scrollbar-width: thin;

    &--no-scrollbar {
      overflow-y: hidden;
    }

    &--no-bottom-margin {
      margin-bottom: 0;
    }

    &::-webkit-scrollbar {
      width: math.div(0.8rem, 1.6);
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: math.div(10rem, 1.6);
      background: rgba($c-dark, 0.15);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba($c-dark, 0.25);
    }
  }

  &__content-wrapper {
  }
}
</style>
