var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-unit-form" }, [
    _c("div", { staticClass: "add-unit-form__main-title" }, [
      _vm._v("Add Production Unit"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "add-unit-form__frames-row-wrap" }, [
      _c(
        "div",
        {
          class: {
            "add-unit-form__frames-row": 1,
            "add-unit-form__frames-row--position-0": _vm.visibleFrame === 0,
            "add-unit-form__frames-row--position-1": _vm.visibleFrame === 1,
            "add-unit-form__frames-row--position-2": _vm.visibleFrame === 2,
          },
          style: {
            maxHeight:
              (_vm.$refs["frame" + _vm.visibleFrame] &&
                _vm.$refs["frame" + _vm.visibleFrame].clientHeight) + "px",
          },
        },
        [
          _c(
            "div",
            {
              ref: "frame0",
              class: {
                "separate-frame": 1,
                "add-unit-form__separate-frame": 1,
                "add-unit-form__separate-frame--invisible":
                  _vm.visibleFrame !== 0,
              },
            },
            [
              _c(
                "div",
                { staticClass: "separate-frame__description-n-input" },
                [
                  _c("div", { staticClass: "separate-frame__description" }, [
                    _vm._v(
                      "\n            You are about to create a multi-unit Company. In order to proceed, please input name of Mother Company.\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input-custom", {
                    staticClass: "separate-frame__text-input",
                    attrs: { placeholder: "Enter Mother Company Name" },
                    model: {
                      value: _vm.motherCompanyName,
                      callback: function ($$v) {
                        _vm.motherCompanyName = $$v
                      },
                      expression: "motherCompanyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("cancel-n-action-btn-pair", {
                staticClass: "separate-frame__manage-buttons-panel",
                attrs: {
                  "action-btn-name": "Proceed",
                  "action-btn-icon": _vm.svg.arrowCircleRightIcon,
                },
                on: {
                  cancel: function () {},
                  action: function ($event) {
                    _vm.visibleFrame = 1
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "frame1",
              class: {
                "separate-frame": 1,
                "add-unit-form__separate-frame": 1,
                "add-unit-form__separate-frame--invisible":
                  _vm.visibleFrame !== 1,
              },
            },
            [
              _c(
                "div",
                { staticClass: "separate-frame__description-n-input" },
                [
                  _c("div", { staticClass: "separate-frame__description" }, [
                    _vm._v(
                      "\n            Thanks! We have now adjusted Supplier Name to <Mother Company Name> and created a new Unit. Please\n            name this new Unit.\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input-custom", {
                    staticClass: "separate-frame__text-input",
                    attrs: { placeholder: "Enter Mother Company Name" },
                    model: {
                      value: _vm.motherCompanyName,
                      callback: function ($$v) {
                        _vm.motherCompanyName = $$v
                      },
                      expression: "motherCompanyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("cancel-n-action-btn-pair", {
                staticClass: "separate-frame__manage-buttons-panel",
                on: {
                  cancel: function () {},
                  action: function ($event) {
                    _vm.visibleFrame = 2
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "frame2",
              class: {
                "separate-frame": 1,
                "add-unit-form__separate-frame": 1,
                "add-unit-form__separate-frame--invisible":
                  _vm.visibleFrame !== 2,
              },
            },
            [
              _c(
                "div",
                { staticClass: "separate-frame__description-n-input" },
                [
                  _c("input-custom", {
                    staticClass: "separate-frame__text-input",
                    attrs: { placeholder: "Enter Mother Company Name" },
                    model: {
                      value: _vm.motherCompanyName,
                      callback: function ($$v) {
                        _vm.motherCompanyName = $$v
                      },
                      expression: "motherCompanyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("cancel-n-action-btn-pair", {
                staticClass: "separate-frame__manage-buttons-panel",
                on: {
                  cancel: function () {},
                  action: function ($event) {
                    _vm.visibleFrame = 0
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }