<template>
  <FsPill circle-sides :color="parsedColor" :counter="counter" :tag="tag" v-bind="$attrs" v-on="$listeners">
    <slot>
      {{ colorName }}
    </slot>
  </FsPill>
</template>

<script>
// A pre-defined FsPill for color pills
//
// Pros:
// * Consistency - Having it pre-defined ensures consistency.
// * Easier to use - By having a much smaller interface/API than the
//   full FsPill component, it's faster to get a grip of all relevant
//   properties (by not having non-relevant props here).
// * Easier to refactor - Want to change all color pills? All code in
//   one place.

import { colorToBaseColor, convertRGBtoHEX, parseColor } from '@lib/colorManagement';

import FsPill from './fs-pill.vue';

export default {
  name: 'FsPillColor',
  props: {
    // String: The color of the pill, styled based on type (or, should be based on solid/outlined - see below)
    // Object: Style properties, fully managed (basically passed directly into the style-attribute)
    color: {
      type: [Object, String],
    },
    counter: {
      type: Boolean,
      default: false,
    },
    // FUTURE: If we need to support router-link, then look at fs-button on how to implement it
    tag: {
      type: String,
      default: 'span',
    },
  },

  components: { FsPill },

  computed: {
    parsedColor() {
      return parseColor(this.color);
    },

    colorName() {
      if (!this.color) return 'No color';

      return (colorToBaseColor(this.color) || this.color)?.name;
    },
  },

  methods: {
    colorToBaseColor,
    convertRGBtoHEX,
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';

.pill--counter {
  background-color: $c-bg-light;
  border-color: $c-dark;
  color: $c-dark;
}
</style>
