var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    { tag: "component", staticClass: "inputs-section-frame" },
    [
      _c(
        "div",
        {
          staticClass: "photo-container inputs-section-frame__photo-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "photo-placeholder photo-container__photo-placeholder",
            },
            [
              _c("div", {
                staticClass: "photo-placeholder__icon",
                domProps: { innerHTML: _vm._s(_vm.svg.photoPlaceholderIcon) },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "photo-placeholder__text" }, [
                _vm._v("Select image"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "photo-placeholder__bg-layer" }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "inputs-section-frame__form-elements-container" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.showDeleteBut
        ? _c("div", {
            staticClass: "inputs-section-frame__delete-but",
            domProps: {
              innerHTML: _vm._s(_vm.icons.interfaceElements.trashCanIcon),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDragHandle
        ? _c("div", {
            staticClass: "inputs-section-frame__drag-n-drop-icon",
            domProps: {
              innerHTML: _vm._s(
                _vm.icons.interfaceElements.dragNDropVertically
              ),
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }