var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "general-info-form" },
    [
      _c("div", { staticClass: "general-info-form__main-title" }, [
        _vm._v("General Information"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-itself general-info-form__form-itself" }, [
        _c("div", { staticClass: "form-itself__section" }, [
          _c(
            "div",
            {
              staticClass:
                "form-elements-with-title form-itself__form-elements-with-title",
            },
            [
              _c("div", { staticClass: "form-elements-with-title__title" }, [
                _vm._v("Founded"),
              ]),
              _vm._v(" "),
              _c("select-custom", {
                staticClass: "form-elements-with-title__form-element",
                attrs: {
                  placeholder: "Select year",
                  value: "1989",
                  options: ["1989", "1990", "1991", "1992", "1993"],
                },
                on: { input: function () {} },
                model: {
                  value: _vm.foundedYear,
                  callback: function ($$v) {
                    _vm.foundedYear = $$v
                  },
                  expression: "foundedYear",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "form-elements-with-title form-itself__form-elements-with-title",
            },
            [
              _c("div", { staticClass: "form-elements-with-title__title" }, [
                _vm._v("Pairs / Day"),
              ]),
              _vm._v(" "),
              _c("input-custom", {
                staticClass: "form-elements-with-title__form-element",
                attrs: { placeholder: "Pairs / Day" },
                model: {
                  value: _vm.pairsPerDay,
                  callback: function ($$v) {
                    _vm.pairsPerDay = $$v
                  },
                  expression: "pairsPerDay",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "form-elements-with-title form-itself__form-elements-with-title form-itself__form-elements-with-title--full-width",
            },
            [
              _c("div", { staticClass: "form-elements-with-title__title" }, [
                _vm._v("OAR-ID"),
              ]),
              _vm._v(" "),
              _c("input-custom", {
                staticClass: "form-elements-with-title__form-element",
                attrs: { placeholder: "OAR-ID" },
                model: {
                  value: _vm.oarId,
                  callback: function ($$v) {
                    _vm.oarId = $$v
                  },
                  expression: "oarId",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "form-itself__section form-itself__section--with-border",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "form-elements-with-title form-itself__form-elements-with-title",
              },
              [
                _c("div", { staticClass: "form-elements-with-title__title" }, [
                  _vm._v("Total"),
                ]),
                _vm._v(" "),
                _c("input-custom", {
                  staticClass: "form-elements-with-title__form-element",
                  attrs: { placeholder: "0", suffix: "pairs" },
                  model: {
                    value: _vm.totalPairs,
                    callback: function ($$v) {
                      _vm.totalPairs = $$v
                    },
                    expression: "totalPairs",
                  },
                }),
                _vm._v(" "),
                _c("fs-slider", {
                  ref: "slider",
                  staticClass: "form-elements-with-title__slider",
                  attrs: {
                    "dot-size": 18,
                    min: 0,
                    max: 200,
                    piecewise: false,
                    disabled: false,
                    width: "100%",
                    tooltip: false,
                  },
                  on: { callback: function () {} },
                  model: {
                    value: _vm.totalPairs,
                    callback: function ($$v) {
                      _vm.totalPairs = $$v
                    },
                    expression: "totalPairs",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "form-elements-with-title form-itself__form-elements-with-title",
              },
              [
                _c("div", { staticClass: "form-elements-with-title__title" }, [
                  _vm._v("Per Color"),
                ]),
                _vm._v(" "),
                _c("input-custom", {
                  staticClass: "form-elements-with-title__form-element",
                  attrs: { placeholder: "0", suffix: "pairs" },
                  model: {
                    value: _vm.perColorPairs,
                    callback: function ($$v) {
                      _vm.perColorPairs = $$v
                    },
                    expression: "perColorPairs",
                  },
                }),
                _vm._v(" "),
                _c("fs-slider", {
                  ref: "slider",
                  staticClass: "form-elements-with-title__slider",
                  attrs: {
                    "dot-size": 18,
                    min: 0,
                    max: 200,
                    piecewise: false,
                    disabled: false,
                    width: "100%",
                    tooltip: false,
                  },
                  on: { callback: function () {} },
                  model: {
                    value: _vm.perColorPairs,
                    callback: function ($$v) {
                      _vm.perColorPairs = $$v
                    },
                    expression: "perColorPairs",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "form-itself__section form-itself__section--with-border",
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "form-elements-with-title form-itself__form-elements-with-title",
              },
              [
                _c("div", { staticClass: "form-elements-with-title__title" }, [
                  _vm._v("Total Employees"),
                ]),
                _vm._v(" "),
                _c("input-custom", {
                  staticClass: "form-elements-with-title__form-element",
                  attrs: { placeholder: "0" },
                  model: {
                    value: _vm.totalEmployees,
                    callback: function ($$v) {
                      _vm.totalEmployees = $$v
                    },
                    expression: "totalEmployees",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "form-elements-with-title form-itself__form-elements-with-title",
              },
              [
                _c("div", { staticClass: "form-elements-with-title__title" }, [
                  _vm._v("Line Workers"),
                ]),
                _vm._v(" "),
                _c("input-custom", {
                  staticClass: "form-elements-with-title__form-element",
                  attrs: { placeholder: "0" },
                  model: {
                    value: _vm.lineWorkers,
                    callback: function ($$v) {
                      _vm.lineWorkers = $$v
                    },
                    expression: "lineWorkers",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "form-elements-with-title form-itself__form-elements-with-title",
              },
              [
                _c("div", { staticClass: "form-elements-with-title__title" }, [
                  _vm._v("Female"),
                ]),
                _vm._v(" "),
                _c("input-custom", {
                  staticClass: "form-elements-with-title__form-element",
                  attrs: { placeholder: "0", suffix: "%" },
                  model: {
                    value: _vm.femaleEmployees,
                    callback: function ($$v) {
                      _vm.femaleEmployees = $$v
                    },
                    expression: "femaleEmployees",
                  },
                }),
                _vm._v(" "),
                _c("fs-slider", {
                  ref: "slider",
                  staticClass: "form-elements-with-title__slider",
                  attrs: {
                    "dot-size": 18,
                    min: 0,
                    max: 100,
                    piecewise: false,
                    disabled: false,
                    width: "100%",
                    tooltip: false,
                  },
                  on: { callback: function () {} },
                  model: {
                    value: _vm.femaleEmployees,
                    callback: function ($$v) {
                      _vm.femaleEmployees = $$v
                    },
                    expression: "femaleEmployees",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "form-elements-with-title form-itself__form-elements-with-title",
              },
              [
                _c("div", { staticClass: "form-elements-with-title__title" }, [
                  _vm._v("Migrant"),
                ]),
                _vm._v(" "),
                _c("input-custom", {
                  staticClass: "form-elements-with-title__form-element",
                  attrs: { placeholder: "0", suffix: "%" },
                  model: {
                    value: _vm.migrantEmployees,
                    callback: function ($$v) {
                      _vm.migrantEmployees = $$v
                    },
                    expression: "migrantEmployees",
                  },
                }),
                _vm._v(" "),
                _c("fs-slider", {
                  ref: "slider",
                  staticClass: "form-elements-with-title__slider",
                  attrs: {
                    "dot-size": 18,
                    min: 0,
                    max: 100,
                    piecewise: false,
                    disabled: false,
                    width: "100%",
                    tooltip: false,
                  },
                  on: { callback: function () {} },
                  model: {
                    value: _vm.migrantEmployees,
                    callback: function ($$v) {
                      _vm.migrantEmployees = $$v
                    },
                    expression: "migrantEmployees",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "general-info-form__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-itself__section-title" }, [
      _c("div", { staticClass: "form-itself__section-title-in-wrap" }, [
        _vm._v("Minimum Order Quantity"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-itself__section-title" }, [
      _c("div", { staticClass: "form-itself__section-title-in-wrap" }, [
        _vm._v("Employees"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }