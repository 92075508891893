<template>
  <div
    class="custom-textarea"
    :class="`${isDisabled && 'custom-textarea--disabled'}
              ${isError && 'custom-textarea--error'}
              ${isFocus && 'custom-textarea--active'}`"
  >
    <label class="custom-textarea__textarea-label">
      <textarea
        :style="computedStyles"
        :disabled="isDisabled"
        :placeholder="placeholder"
        :maxlength="maxSymbols"
        v-model="val"
        :class="`custom-textarea__textarea
                ${maxSymbols && count > 0 && 'custom-textarea__textarea--bottom-counter-padding'}`"
        ref="input"
        @input="update()"
        @focus="
          ($event) => {
            isFocus = true;
            $emit('focus', $event);
            resize;
          }
        "
        @blur="
          ($event) => {
            isFocus = false;
            $emit('blur', $event);
          }
        "
      >
      </textarea>
    </label>
    <span v-if="maxSymbols && count > 0" class="custom-textarea__counter"> {{ count }}/{{ maxSymbols }} </span>
    <span v-if="isDisabled" class="custom-textarea__lock" v-html="svg.lock"> </span>
  </div>
  <!-- / txt -->
</template>

<script>
import lock from 'apps/common/assets/icons/other/ico-lock.svg';

export default {
  props: {
    placeholder: String,
    value: {
      type: [String, Number],
      default: '',
    },
    autosize: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: Number,
      default: null,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    important: {
      type: [Boolean, Array],
      default: false,
    },
    maxSymbols: {
      type: [Boolean, Number, String],
      default: '1000',
    },
    isDisabled: Boolean,
    isError: Boolean,
  },
  data() {
    return {
      count: '0',
      isFocus: false,
      val: null,
      maxHeightScroll: false,
      height: 'auto',
      svg: {
        lock,
      },
    };
  },
  computed: {
    computedStyles() {
      if (!this.autosize) return {};
      return {
        resize: !this.isResizeImportant ? 'none' : 'none !important',
        height: this.height,
        overflow: this.maxHeightScroll ? 'auto' : !this.isOverflowImportant ? 'hidden' : 'hidden !important',
      };
    },
    isResizeImportant() {
      const imp = this.important;
      return imp === true || (Array.isArray(imp) && imp.includes('resize'));
    },
    isOverflowImportant() {
      const imp = this.important;
      return imp === true || (Array.isArray(imp) && imp.includes('overflow'));
    },
    isHeightImportant() {
      const imp = this.important;
      return imp === true || (Array.isArray(imp) && imp.includes('height'));
    },
  },
  watch: {
    value(val) {
      this.val = val;
    },
    val(val) {
      this.$nextTick(this.resize);
      this.$emit('input', val);
    },
    minHeight() {
      this.$nextTick(this.resize);
    },
    maxHeight() {
      this.$nextTick(this.resize);
    },
    autosize(val) {
      if (val) this.resize();
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },

    update() {
      this.$emit('input', this.$refs.input.value);
      this.count = this.$refs.input.value.length;
    },

    resize() {
      const important = this.isHeightImportant ? 'important' : '';
      this.height = `auto${important ? ' !important' : ''}`;
      this.$nextTick(() => {
        let contentHeight = this.$refs.input.scrollHeight + 1;
        if (this.minHeight) {
          contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight;
        }
        if (this.maxHeight) {
          if (contentHeight > this.maxHeight) {
            contentHeight = this.maxHeight;
            this.maxHeightScroll = true;
          } else {
            this.maxHeightScroll = false;
          }
        }
        const heightVal = contentHeight + 'px';
        this.height = `${heightVal}${important ? ' !important' : ''}`;
      });
      return this;
    },
  },
  created() {
    this.val = this.value;
  },
  mounted() {
    this.resize();
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/popover.scss';

.custom-textarea {
  padding: 0;
  display: flex;
  align-items: center;
  border-radius: math.div(1.9rem, 1.6);
  position: relative;
  z-index: 1;
  color: rgba(53, 73, 94, 1);
  background-color: rgba(232, 232, 232, 0.5);
  transition: height 0.15s ease, background-color 0.1s ease;

  &:hover {
    background-color: rgba(232, 232, 232, 0.75);
  }

  &--active {
    background: rgba(232, 232, 232, 1);

    &:hover {
      background: rgba(232, 232, 232, 1);
    }
  }

  &--disabled {
    color: rgba(53, 73, 94, 0.25) !important;
    background: rgba(232, 232, 232, 0.25) !important;

    ::v-deep textarea {
      cursor: not-allowed;
    }
  }

  &--error {
    background: #ffbcbc !important;
  }

  &__textarea-label {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: math.div(1rem, 1.6) math.div(1rem, 1.6);
    box-sizing: border-box;
    display: flex;
    cursor: text;
    transition: padding 0.15s ease;

    &--bottom-counter-padding {
      padding-bottom: math.div(2.3rem, 1.6);
    }
  }

  &__textarea {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: math.div(0.8rem, 1.6) math.div(1rem, 1.6) math.div(0.8rem, 1.6);
    box-sizing: border-box;
    overflow: hidden;
    background: none;
    margin: 0;
    border: none;
    color: inherit;
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(1.9rem, 1.6);
    outline: none;
    resize: none;

    &::placeholder {
      color: rgba(53, 73, 94, 0.5);
    }

    &--bottom-counter-padding {
      height: calc(100% - #{math.div(2.3rem, 1.6)});
    }

    &::-webkit-scrollbar {
      width: math.div(0.8rem, 1.6);
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: math.div(10rem, 1.6);
      background: rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &__counter {
    position: absolute;
    inset: auto math.div(2.4rem, 1.6) math.div(1.4rem, 1.6) auto;
    font-size: math.div(1rem, 1.6);
    line-height: math.div(1rem, 1.6);
    opacity: 0.5;
    pointer-events: none;
  }

  &__lock {
    position: absolute;
    z-index: 2;
    top: math.div(1.1rem, 1.6);
    right: math.div(2.4rem, 1.6);
  }
}
</style>
