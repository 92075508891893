<template>
  <div v-if="anyCompleted" class="code-of-conduct">
    <div class="code-of-conduct__header">Code of Conduct</div>

    <div
      v-for="(module, index) in modules"
      :key="index"
      :class="{
        'single-conduct-code': 1,
        'single-conduct-code--inactive': openedCodeOfConduct !== index && openedCodeOfConduct !== null,

        'code-of-conduct__single-conduct-code': 1,
        'code-of-conduct__single-conduct-code--social': index === 0,
        'code-of-conduct__single-conduct-code--environmental': index === 1,
      }"
      @click="toggleCodeOfConduct(index)"
    >
      <div class="single-conduct-code__header">{{ module.label }}</div>

      <div class="single-conduct-code__scale">
        <div
          v-for="index in module.features.length"
          :key="index"
          :class="{
            'single-conduct-code__scale-division': 1,
            'single-conduct-code__scale-division--red': module.count <= Math.ceil(module.features.length * 0.25),
            'single-conduct-code__scale-division--orange':
              module.count > Math.ceil(module.features.length * 0.25) &&
              module.count <= Math.floor(module.features.length * 0.75),
            'single-conduct-code__scale-division--active': index <= module.count,
          }"
        ></div>
      </div>

      <div class="single-conduct-code__subscript">
        <div class="single-conduct-code__points-counter">
          {{ module.count }} out of {{ module.features.length }} points
        </div>
        <div class="single-conduct-code__completion-date">
          {{ module.completed ? `Completed ${dateFormatter(module.completed, 'yyyy-MM-dd')}` : 'Module not completed' }}
        </div>
      </div>

      <div
        :class="{
          'single-conduct-code__more-details-link': 1,
          'single-conduct-code__more-details-link--closed': openedCodeOfConduct !== null,
        }"
      >
        <div class="single-conduct-code__more-details-icon" v-html="svg.arrowDownIcon"></div>
        More details
      </div>
    </div>

    <div
      :class="{
        'code-of-conduct__conduct-details-wrapper': 1,
        'code-of-conduct__conduct-details-wrapper--social': index === 0,
        'code-of-conduct__conduct-details-wrapper--environmental': index === 1,
        'code-of-conduct__conduct-details-wrapper--opened': openedCodeOfConduct === index,
      }"
      v-for="(module, index) in modules"
      :key="`details-${index}`"
    >
      <!-- Conduct Details -->
      <div
        :class="{
          'conduct-details': 1,
          'conduct-details--social': index === 0,
          'conduct-details--environmental': index === 1,

          'code-of-conduct__conduct-details': 1,
        }"
      >
        <div class="conduct-details__pointing-arrow"></div>

        <div
          class="conduct-details__close-but"
          v-html="icons.interfaceElements.thinClose"
          @click="toggleCodeOfConduct(index)"
        ></div>

        <div
          v-for="(feature, featureIndex) in module.features"
          :key="featureIndex"
          :class="{
            'conduct-single-feature': 1,
            'conduct-single-feature--disabled': !supplier[feature.key] || !supplier[feature.key].checked,

            'conduct-details__conduct-single-feature': 1,
          }"
        >
          <div class="conduct-single-feature__name-side">
            <div class="conduct-single-feature__icon" v-html="svg[`${feature.icon}Icon`]"></div>
            <div class="conduct-single-feature__description">
              {{ feature.label }}
            </div>
            <div
              v-if="feature.infoText && supplier[feature.key] && supplier[feature.key].checked"
              class="conduct-single-feature__info-but"
              @click.prevent="$refs[`codeOfConductInfo-${index}-${featureIndex}`][0].toggle($event)"
              v-html="icons.miscellaneous.info"
            ></div>

            <fs-popper
              :ref="`codeOfConductInfo-${index}-${featureIndex}`"
              :class="{
                'conduct-single-feature__info-popper': 1,
                'conduct-single-feature__info-popper--wide': feature.infoText.length > 400,
              }"
              animation="fade"
              arrow
              :modifiers="[
                { name: 'flip', enabled: false },
                { name: 'preventOverflow', options: { padding: 16 } },
              ]"
              :offset="[0, 30]"
              placement="top"
              wrapper-class="popper-wrapper
                              supplierProfile"
            >
              <a class="popover-close" @click.prevent="fsPopperClose">+</a>
              {{ feature.infoText }}
            </fs-popper>
          </div>

          <div
            v-if="supplier[feature.key] && supplier[feature.key].checked"
            class="conduct-single-feature__status-icon conduct-single-feature__status-icon--checkmark"
            v-html="icons.miscellaneous.checkMark"
          ></div>
          <div
            v-else
            class="conduct-single-feature__status-icon conduct-single-feature__status-icon--cross"
            v-html="icons.interfaceElements.crossClear"
          ></div>

          <div
            :class="{
              'conduct-single-feature__description-side': 1,
              'conduct-single-feature__description-side--red-bg':
                !supplier[feature.key] || !supplier[feature.key].checked,
            }"
          >
            <div
              :class="{
                'conduct-single-feature__description': 1,
                'conduct-single-feature__description--no-details':
                  supplier[feature.key] && supplier[feature.key].checked && !supplier[feature.key].comment,
              }"
            >
              {{
                supplier[feature.key] && supplier[feature.key].checked
                  ? supplier[feature.key].comment
                    ? supplier[feature.key].comment
                    : 'No details'
                  : 'Unfulfilled point'
              }}
            </div>
            <a
              v-if="supplier[feature.key] && supplier[feature.key].checked && supplier[feature.key].file"
              :href="supplier[feature.key].file"
              target="_blank"
              class="conduct-single-feature__attachment-but"
              v-html="icons.miscellaneous.clip"
            >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from '@lib/formatters';
import { FsPopper, fsPopperClose } from '@components/poppers/fs-popper.vue';

// Icons
import miscellaneousIcons from '@lib/icons-imports/miscellaneous';
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
import peopleIcons from '@lib/icons-imports/people';

import arrowDownIcon from '@icons/other/arrow-down.svg';
import peopleWithFlagIcon from '@icons/people/people-with-flag.svg';
import headQuestionIndexFingersIcon from '@icons/people/head-question-index-fingers.svg';
import manClockIcon from '@icons/people/man-clock.svg';
import twoPeopleBlockIcon from '@icons/people/two-people-block.svg';
import energyIcon from '@icons/code-of-conduct/energy.svg';
import waterIcon from '@icons/code-of-conduct/water.svg';
import recyclingIcon from '@icons/code-of-conduct/recycling.svg';
import greenInvestmentIcon from '@icons/code-of-conduct/green-investment.svg';
import forcedLabourIcon from '@icons/code-of-conduct/forced-labour.svg';
import statuaryRemunerationIcon from '@icons/code-of-conduct/statuary-remuneration.svg';
import healthSafetyIcon from '@icons/code-of-conduct/health-safety.svg';
import humaneWorkIcon from '@icons/code-of-conduct/humane-work.svg';
import environmentProtectionIcon from '@icons/code-of-conduct/environment-protection.svg';
import ethicalBusinessIcon from '@icons/code-of-conduct/ethical-business.svg';
import restrictedChemicalsIcon from '@icons/code-of-conduct/restricted-chemicals.svg';

export default {
  props: {
    supplier: Object,
  },

  components: { FsPopper },

  data() {
    return {
      modules: this.$store.suppliers.codeOfConduct.map((module) => {
        return {
          label: module.label,
          completed: this.supplier[module.key + 'Completed'],
          count: module.features.filter((prop) => this.supplier[prop.key] && this.supplier[prop.key].checked).length,
          features: module.features,
        };
      }),
      openedCodeOfConduct: null,
      svg: {
        arrowDownIcon,
        peopleWithFlagIcon,
        headQuestionIndexFingersIcon,
        manClockIcon,
        twoPeopleBlockIcon,
        energyIcon,
        waterIcon,
        recyclingIcon,
        greenInvestmentIcon,
        forcedLabourIcon,
        statuaryRemunerationIcon,
        healthSafetyIcon,
        humaneWorkIcon,
        environmentProtectionIcon,
        ethicalBusinessIcon,
        restrictedChemicalsIcon,
      },
      icons: {
        miscellaneous: miscellaneousIcons,
        people: peopleIcons,
        interfaceElements: interfaceElementsIcons,
      },
    };
  },
  computed: {
    anyCompleted() {
      return Boolean(this.modules.find((module) => module.completed));
    },
  },

  mounted() {
    // console.log('this.supplier: ',this.supplier);
    // console.log('%c : ','background:cyan;color:black;', );
  },

  updated() {
    // console.log('this.supplier: ',this.supplier);
    // console.log('this.openedCodeOfConduct: ',this.openedCodeOfConduct);
  },

  methods: {
    dateFormatter,
    fsPopperClose,
    toggleCodeOfConduct(sectionId) {
      // If some section already opened and we want to open other one
      if (this.openedCodeOfConduct !== sectionId && this.openedCodeOfConduct !== null) {
        // 1. close opened section
        this.openedCodeOfConduct = undefined;
        // 2. open new section
        setTimeout(() => {
          this.openedCodeOfConduct = sectionId;
        }, 400);
        return;
      }

      // If se toggle those section that already opened
      if (sectionId === this.openedCodeOfConduct) {
        this.openedCodeOfConduct = null;
        return;
      }

      // Open certain section
      this.openedCodeOfConduct = sectionId;
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

// ============================================================================
// ============================================================================
// Conduct single feature
.conduct-single-feature {
  display: flex;
  justify-content: space-between;
  position: relative;

  &--disabled {
    opacity: 0.5;
  }

  &__info-popper {
    max-width: math.div(35rem, 1.6);
    font-size: math.div(1.6rem, 1.6);
    line-height: math.div(2.4rem, 1.6);
    padding: math.div(3.2rem, 1.6) !important;
    white-space: pre-line;

    &:first-line {
      line-height: 0 !important;
    }

    &--wide {
      max-width: math.div(50rem, 1.6);
    }
  }

  &__name-side {
    width: calc(50% - #{math.div(5rem, 1.6)});
    min-width: calc(50% - #{math.div(5rem, 1.6)});
    padding: math.div(0.6rem, 1.6) math.div(2rem, 1.6) math.div(0.7rem, 1.6) 0;
    box-sizing: border-box;
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
    background: #fff;

    &::before {
      content: '';
      width: 2px;
      height: 100%;
      position: absolute;
      top: 0;
      left: math.div(6.5rem, 1.6);
      background: rgba(53, 73, 94, 0.06);
    }
  }

  &__icon {
    width: math.div(6.5rem, 1.6);
    min-width: math.div(6.5rem, 1.6);
    height: math.div(2.8rem, 1.6);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    ::v-deep svg {
      width: math.div(2.8rem, 1.6);
      height: math.div(2.8rem, 1.6);
      fill: rgb(53, 73, 94);
    }
  }

  &__description {
    padding: 0 math.div(2.2rem, 1.6);
    line-height: math.div(2.2rem, 1.6);

    &--no-details {
      color: rgba($c-dark, 0.5);
      font-style: italic;
    }
  }

  &__info-but {
    width: math.div(2rem, 1.6);
    height: math.div(2rem, 1.6);
    border-radius: math.div(10rem, 1.6);
    position: absolute;
    top: math.div(-1rem, 1.6);
    right: math.div(-1rem, 1.6);
    background: #fff;
    cursor: pointer;

    ::v-deep svg {
      fill: rgb(53, 73, 94);
    }
  }

  &__description-side {
    width: calc(50% - #{math.div(5rem, 1.6)});
    min-width: calc(50% - #{math.div(5rem, 1.6)});
    padding: math.div(0.5rem, 1.6) math.div(0.4rem, 1.6);
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: math.div(1.4rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
    background: #fff;

    &::before {
      content: '';
      width: 100%;
      height: math.div(0.6rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
      background: rgb(132, 191, 110);
    }

    &--red-bg {
      color: #fff;
      background: rgb(245, 115, 110);

      &::before {
        display: none;
      }
    }
  }

  &__status-icon {
    width: math.div(2.2rem, 1.6);
    height: math.div(2.2rem, 1.6);
    position: absolute;
    top: calc(50% - #{math.div(1rem, 1.6)});
    left: calc(50% - #{math.div(1rem, 1.6)});
    z-index: 3;

    &--checkmark {
      ::v-deep svg {
        fill: rgb(132, 191, 110);
      }
    }

    &--cross {
      width: math.div(1.8rem, 1.6);
      height: math.div(1.8rem, 1.6);

      ::v-deep svg {
        fill: rgb(245, 115, 110);
      }
    }
  }

  &__description {
  }

  &__attachment-but {
    width: math.div(3.6rem, 1.6);
    height: math.div(3.6rem, 1.6);
    border-radius: math.div(10rem, 1.6);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: calc(50% - #{math.div(1.8rem, 1.6)});
    right: math.div(-1.5rem, 1.6);
    background: #fff;
    box-shadow: 0 3px math.div(0.6rem, 1.6) rgba(0, 0, 0, 0.16);
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.1s ease;

    &:hover {
      opacity: 0.75;
    }

    ::v-deep svg {
      width: math.div(2.2rem, 1.6);
      height: math.div(2.2rem, 1.6);
      fill: rgb(53, 73, 94);
    }
  }
}

@media (max-width: $desktop-width) {
  .conduct-single-feature {
    flex-direction: column;

    &__name-side {
      width: 100%;
      margin-bottom: math.div(1rem, 1.6);
    }

    &__description-side {
      width: 100%;
    }

    &__status-icon {
      top: calc(50% - #{math.div(1.4rem, 1.6)});
      left: math.div(-4rem, 1.6);
    }
  }
}

@media (max-width: $tablet-p-width) {
  .conduct-single-feature {
    &__description-side {
      padding: math.div(1.3rem, 1.6) math.div(1rem, 1.6) math.div(1.2rem, 1.6) math.div(5rem, 1.6);
    }

    &__status-icon {
      top: auto;
      bottom: math.div(1.2rem, 1.6);
      left: math.div(1.7rem, 1.6);
      z-index: 4;

      &--cross {
        ::v-deep svg {
          fill: #fff;
        }
      }

      &--checkmark {
        bottom: math.div(1rem, 1.6);
      }
    }
  }
}

// ============================================================================
// ============================================================================
// Conduct details
.conduct-details {
  padding: math.div(7.5rem, 1.6) math.div(6.5rem, 1.6) math.div(3.5rem, 1.6);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: math.div(3rem, 1.6);
    left: 0;
    z-index: -1;
    background: rgba(53, 73, 94, 0.06);
  }

  &__pointing-arrow {
    width: math.div(5rem, 1.6);
    height: math.div(5rem, 1.6);
    position: absolute;
    top: math.div(1rem, 1.6);
    z-index: -1;
    background: rgb(243, 244, 246);
    transform: rotate(45deg);
  }

  &--social {
    .conduct-details__pointing-arrow {
      left: math.div(20rem, 1.6);
    }
  }

  &--environmental {
    .conduct-details__pointing-arrow {
      right: math.div(20rem, 1.6);
    }
  }

  &__close-but {
    width: math.div(1.7rem, 1.6);
    height: math.div(1.7rem, 1.6);
    position: absolute;
    top: math.div(4.5rem, 1.6);
    right: math.div(1.5rem, 1.6);
    cursor: pointer;

    &::before {
      content: '';
      width: calc(100% + #{math.div(1.5rem, 1.6)});
      height: calc(100% + #{math.div(1.5rem, 1.6)});
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    ::v-deep svg {
      fill: rgba(53, 73, 94, 0.5);
      transition: fill 0.1s ease;
    }

    &:hover {
      ::v-deep svg {
        fill: rgba(53, 73, 94, 0.8);
        transition: fill 0.1s ease;
      }
    }
  }

  &__conduct-single-feature {
    width: 100%;
    margin-bottom: math.div(2rem, 1.6);
  }
}

@media (max-width: $desktop-l-width) {
  .conduct-details {
    padding: math.div(8.5rem, 1.6) math.div(3rem, 1.6) math.div(1.1rem, 1.6);
  }
}

@media (max-width: $desktop-width) {
  .conduct-details {
    padding-left: math.div(7rem, 1.6);

    &--social {
      .conduct-details__pointing-arrow {
        left: math.div(10rem, 1.6);
      }
    }

    &--environmental {
      .conduct-details__pointing-arrow {
        right: math.div(10rem, 1.6);
      }
    }
  }
}

@media (max-width: $tablet-p-width) {
  .conduct-details {
    padding: math.div(9rem, 1.6) math.div(1.5rem, 1.6) math.div(1rem, 1.6);

    &--social {
      .conduct-details__pointing-arrow {
        left: calc(50% - #{math.div(3rem, 1.6)});
      }
    }

    &--environmental {
      .conduct-details__pointing-arrow {
        right: calc(50% - #{math.div(3rem, 1.6)});
      }
    }

    &__conduct-single-feature {
      margin-bottom: math.div(2.5rem, 1.6);
    }
  }
}

// ============================================================================
// ============================================================================
// Single conduct code
.single-conduct-code {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  &--inactive {
    opacity: 0.5;
    cursor: pointer;
  }

  &__header {
    margin-bottom: math.div(1.9rem, 1.6);
    font-size: math.div(2.8rem, 1.6);
  }

  &__scale {
    width: 100%;
    margin-bottom: math.div(1.1rem, 1.6);
    height: math.div(1.2rem, 1.6);
    display: flex;
    justify-content: space-between;
  }

  &__scale-division {
    margin-right: math.div(0.6rem, 1.6);
    flex-grow: 1;
    background: rgb(132, 191, 110);
    opacity: 0.25;

    &--red {
      background: rgb(245, 115, 110);
    }

    &--orange {
      background: rgb(237, 210, 0);
    }

    &--active {
      opacity: 1;
    }

    &:first-child {
      border-radius: math.div(10rem, 1.6) 0 0 math.div(10rem, 1.6);
    }

    &:last-child {
      margin-right: 0;
      border-radius: 0 math.div(10rem, 1.6) math.div(10rem, 1.6) 0;
    }
  }

  &__subscript {
    width: 100%;
    margin-bottom: math.div(1.5rem, 1.6);
    display: flex;
    justify-content: space-between;
  }

  &__points-counter {
    font-size: math.div(1.4rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
  }

  &__completion-date {
    font-size: math.div(1.4rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
  }

  &__more-details-link {
    height: math.div(1.6rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 1;
    transition: height 0.15s ease, opacity 0.15s ease;

    &--closed {
      height: 0;
      opacity: 0;
    }
  }

  &__more-details-icon {
    width: math.div(1.8rem, 1.6);
    height: math.div(1.8rem, 1.6);
    margin-right: math.div(1rem, 1.6);

    ::v-deep svg {
      fill: rgb(53, 73, 94);
    }
  }
}

@media (max-width: $desktop-width) {
  .single-conduct-code {
    &__subscript {
      flex-direction: column;
      align-items: center;
      align-content: center;
    }
  }
}

// ============================================================================
// ============================================================================
// Code of conduct
.code-of-conduct {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__header {
    @include heading--small;
    width: 100%;
    margin-bottom: math.div(3.5rem, 1.6);
    text-align: center;
  }

  &__single-conduct-code {
    width: calc(50% - #{math.div(4.8rem, 1.6)});
    margin-bottom: math.div(1.6rem, 1.6);

    &--social {
    }

    &--environmental {
    }
  }

  &__conduct-details-wrapper {
    width: 100%;
    max-height: 0;
    position: relative;
    overflow: hidden;
    transition: max-height 0.35s ease;

    &--opened {
      max-height: math.div(150rem, 1.6);
    }

    &--social {
    }

    &--environmental {
    }
  }

  &__conduct-details {
  }
}

@media (max-width: $tablet-p-width) {
  .code-of-conduct {
    flex-direction: column;

    &__header {
      order: -2;
    }

    &__single-conduct-code {
      width: 100%;
      margin-bottom: math.div(4.5rem, 1.6);

      &--social {
        order: 1;
      }

      &--environmental {
        order: -1;
      }
    }

    &__conduct-details-wrapper {
      margin-top: math.div(-3.5rem, 1.6);
      margin-bottom: math.div(3.5rem, 1.6);

      &--social {
        order: 5;
      }

      &--environmental {
        order: 0;
      }
    }
  }
}
</style>
