var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.brandsWorkingWith.length
    ? _c("div", { staticClass: "brands-working-with" }, [
        _c("h4", { staticClass: "brands-working-with__header" }, [
          _vm._v("Brands working with"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "brands-labels-container" },
          _vm._l(_vm.brandsWorkingWith, function (brand) {
            return _c(
              "span",
              { key: brand, staticClass: "brands-labels-container__brand" },
              [_vm._v(_vm._s(brand))]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }