<template>
  <li class="single-search-element">
    <span v-if="listItem.hasOwnProperty('icon')" class="single-search-element__icon" v-html="listItem.icon"></span>

    <img
      v-if="listItem.hasOwnProperty('imageSrc')"
      class="single-search-element__image"
      :src="listItem.imageSrc"
      :alt="listItem.country"
    />

    <div
      :class="{
        'single-search-element__name-n-country': true,
        'single-search-element__name-n-country--horizontal': layoutType === 'horizontal',
      }"
    >
      <span class="single-search-element__name">
        {{ listItem.name }}
      </span>

      <span v-if="listItem.hasOwnProperty('subscript')" class="single-search-element__country">
        {{ listItem.subscript }}
      </span>
    </div>

    <button
      v-if="alreadyAdded"
      class="single-search-element__checkmark-icon"
      v-html="icons.singles.checkMarkIcon"
    ></button>

    <button v-else class="single-search-element__manage-but" v-html="icons.singles.addCirclePlusIcon"></button>
  </li>
</template>

<script>
// Icons
// singles
import addCirclePlusIcon from '@icons/other/add-circle-plus.svg';
import checkMarkIcon from '@icons/other/check-mark-icon.svg';

export default {
  name: 'search-item',

  props: {
    alreadyAdded: { type: Boolean, default: false },
    layoutType: {
      type: String,
      validator: (type) => ['vertical', 'horizontal'].includes(type),
      default: 'vertical',
    },
    listItem: { type: Object, required: true },
  },

  components: {},

  data() {
    return {
      icons: {
        singles: {
          addCirclePlusIcon,
          checkMarkIcon,
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@import 'common/styles/variables.scss';

// ==========================================================================
// Single Search element
.single-search-element {
  padding: math.div(0.7rem, 1.6) math.div(1rem, 1.6);
  border-radius: math.div(0.4rem, 1.6);
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  transition: 0.3s;
  cursor: pointer;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background: #f0f0f0;
  }

  &__icon {
    width: math.div(2.6rem, 1.6);
    margin: math.div(0.8rem, 1.6) math.div(1.3rem, 1.6) math.div(0.8rem, 1.6) 0;
  }

  &__image {
    width: math.div(2.6rem, 1.6);
    margin: math.div(0.8rem, 1.6) math.div(1.3rem, 1.6) math.div(0.8rem, 1.6) 0;
  }

  &__name-n-country {
    width: calc(100% - #{math.div(5.5rem, 1.6)});
    min-width: calc(100% - #{math.div(5.5rem, 1.6)});
    max-width: calc(100% - #{math.div(5.5rem, 1.6)});
    flex: 1;

    &--horizontal {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
    }
  }

  &__name {
    width: calc(100% - #{math.div(1rem, 1.6)});
    margin: 0;
    display: block;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__country {
    display: block;
    opacity: 0.5;
    text-transform: uppercase;
    font-size: math.div(1.2rem, 1.6);
  }

  &__name-n-country--horizontal {
    .single-search-element__name {
      width: calc(55% - #{math.div(0.8rem, 1.6)});
    }
    .single-search-element__country {
      max-width: calc(45% - #{math.div(0.8rem, 1.6)});
    }
  }

  &__manage-but,
  &__checkmark-icon {
    width: math.div(1.6rem, 1.6);
    min-width: math.div(1.6rem, 1.6);
    height: math.div(1.6rem, 1.6);
    padding: 0;
    margin: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    transition: opacity 0.3s;
    pointer-events: none;

    &:focus {
      outline: none;
    }

    ::v-deep svg {
      width: 100%;
      height: 100%;
      fill: #35495e;
    }
  }
}
</style>
