var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fs-popper-event-wrapper",
    {
      attrs: {
        tag: "span",
        trigger: _vm.trigger,
        placement: "top",
        offset: _vm.offset,
        "is-delayed": _vm.isDelayed,
        animation: "slide-fade-fast",
        "default-class":
          "\n    popper\n    " +
          (_vm.color === "white" && "popper--white") +
          "\n  ",
      },
      scopedSlots: _vm._u([
        {
          key: "popper",
          fn: function () {
            return [
              _c(
                "span",
                { staticClass: "popper__text" },
                [
                  _vm.text
                    ? [_vm._v("\n        " + _vm._s(_vm.text) + "\n      ")]
                    : _vm.$slots.tooltipContent
                    ? [_vm._t("tooltipContent")]
                    : [_vm._t("default")],
                ],
                2
              ),
              _vm._v(" "),
              _c("span", { staticClass: "popper__caret-icon" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "512",
                      height: "171",
                      viewBox: "0 0 512 171",
                    },
                  },
                  [
                    _c("path", {
                      attrs: { d: "M256.016,170.646L-0.011.181H512.042Z" },
                    }),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }