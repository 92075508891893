import dance from '@icons/specialities/dance.svg';
import golf from '@icons/specialities/golf.svg';
import medical from '@icons/specialities/medical.svg';
import orthopedic from '@icons/specialities/orthopedic.svg';
import riding from '@icons/specialities/riding.svg';
import safety from '@icons/specialities/safety.svg';
import sports from '@icons/specialities/sports.svg';
import trekking from '@icons/specialities/trekking.svg';
import waterproof from '@icons/specialities/waterproof.svg';

export default {
  dance,
  golf,
  medical,
  orthopedic,
  riding,
  safety,
  sports,
  trekking,
  waterproof,
};
