<template>
  <fs-popper-event-wrapper
    tag="span"
    :trigger="trigger"
    placement="top"
    :offset="offset"
    :is-delayed="isDelayed"
    animation="slide-fade-fast"
    :default-class="`
      popper
      ${color === 'white' && 'popper--white'}
    `"
  >
    <slot />

    <template #popper>
      <span class="popper__text">
        <template v-if="text">
          {{ text }}
        </template>
        <template v-else-if="$slots.tooltipContent">
          <slot name="tooltipContent" />
        </template>
        <template v-else>
          <slot />
        </template>
      </span>

      <span class="popper__caret-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="171" viewBox="0 0 512 171">
          <path d="M256.016,170.646L-0.011.181H512.042Z" />
        </svg>
      </span>
    </template>
  </fs-popper-event-wrapper>
</template>

<script>
// Components
import FsPopperEventWrapper from '@components/poppers/fs-popper-event-wrapper.vue';

export default {
  name: 'fs-tooltip',

  props: {
    text: { type: String },
    color: { type: String, default: 'gray', validator: (color) => ['white', 'gray'].includes(color) },
    offset: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/offset/
      type: [Array, Function],
    },
    isDelayed: {
      type: Boolean,
    },
    showEvenIfNotEllipsed: {
      // Show tooltip even if hovered content is not ellipsed
      type: Boolean,
      default: false,
    },
    show: {
      // Conditionally set when tooltip should show or not, overrides 'showEvenIfNotEllipsed' and default behaviour
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isTooltipNeeded: false,
    };
  },

  components: {
    FsPopperEventWrapper,
  },

  computed: {
    trigger() {
      if (this.show && (this.showEvenIfNotEllipsed || this.$slots.tooltipContent || this.isTooltipNeeded)) {
        return 'hover';
      }

      return 'none';
    },
  },

  mounted() {
    this.setIsTooltipNeeded();
  },

  methods: {
    setIsTooltipNeeded() {
      const nodeWithText =
        this.$el.children.length > 0
          ? Array.from(this.$el.querySelectorAll('*')).find((el) => el.innerHTML.trim() === this.text)
          : this.$el;

      if (!nodeWithText || nodeWithText.textContent?.trim().length === 0) {
        // Content seems to be something else than text
        this.isTooltipNeeded = true;

        return;
      }

      // Text is with ellipsis
      this.isTooltipNeeded = nodeWithText.scrollWidth > nodeWithText.offsetWidth;
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@import 'common/styles/text';
@import 'common/styles/variables.scss';

// ============================================================================
// Popper
.popper {
  @include body--small;
  min-width: math.div(12.8rem, 1.6);
  max-width: math.div(32rem, 1.6);
  word-break: break-word;
  padding: math.div(0.5rem, 1.6) math.div(1.6rem, 1.6);
  border-radius: math.div(1rem, 1.6);
  position: relative;
  top: math.div(-0.6rem, 1.6);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  line-height: math.div(2rem, 1.6);
  color: #fff;
  background: #aaa;
  pointer-events: none;

  &--white {
    color: $c-dark;
    background: #fff;
    box-shadow: 0 7px 13px -4px rgba(53, 73, 94, 0.25);

    ::v-deep .popper__caret-icon svg {
      fill: #fff;
    }
  }

  &__caret-icon {
    width: math.div(2rem, 1.6);
    height: math.div(0.7rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    inset: auto auto math.div(-0.6rem, 1.6) calc(50% - #{math.div(1rem, 1.6)});

    ::v-deep svg {
      fill: #aaa;
    }
  }
}
</style>
