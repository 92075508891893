var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "location-edit-form" }, [
    _c(
      "div",
      { staticClass: "location-edit-form__left-side" },
      [
        _c("div", { staticClass: "location-edit-form__main-title" }, [
          _vm._v("Location"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-itself location-edit-form__form-itself" },
          [
            _c(
              "div",
              { staticClass: "form-itself__separate-section" },
              [
                _c("div", { staticClass: "form-itself__title" }, [
                  _vm._v("Address"),
                ]),
                _vm._v(" "),
                _c("input-custom", {
                  staticClass: "form-itself__form-element",
                  attrs: { placeholder: "Address" },
                  model: {
                    value: _vm.address,
                    callback: function ($$v) {
                      _vm.address = $$v
                    },
                    expression: "address",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-itself__separate-section" },
              [
                _c("div", { staticClass: "form-itself__title" }, [
                  _vm._v("City"),
                ]),
                _vm._v(" "),
                _c("input-custom", {
                  staticClass: "form-itself__form-element",
                  attrs: { placeholder: "City" },
                  model: {
                    value: _vm.city,
                    callback: function ($$v) {
                      _vm.city = $$v
                    },
                    expression: "city",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-itself__separate-section" },
              [
                _c("div", { staticClass: "form-itself__title" }, [
                  _vm._v("Country"),
                ]),
                _vm._v(" "),
                _c("input-country-with-hints", {
                  staticClass: "form-itself__form-element",
                  attrs: {
                    placeholder: "Country name",
                    "autocomplete-off": "",
                  },
                  model: {
                    value: _vm.country,
                    callback: function ($$v) {
                      _vm.country = $$v
                    },
                    expression: "country",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("cancel-n-action-btn-pair", {
          staticClass: "location-edit-form__manage-buttons-panel",
          on: { cancel: function () {}, action: function () {} },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "location-edit-form__right-side" }, [
      _c("div", { staticClass: "map-box location-edit-form__map-box" }, [
        _c("div", { staticClass: "map-box__usage-description" }, [
          _vm._v("Drag pin to change your location"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "map-box__map-itself" }, [
          _c("div", { staticClass: "map-box__map-itself-in-wrap" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }