import childLabour from '@icons/code-of-conduct/child-labour.svg';
import discrimination from '@icons/code-of-conduct/discrimination.svg';
import energy from '@icons/code-of-conduct/energy.svg';
import environmentProtection from '@icons/code-of-conduct/environment-protection.svg';
import ethicalBusiness from '@icons/code-of-conduct/ethical-business.svg';
import forcedLabour from '@icons/code-of-conduct/forced-labour.svg';
import greenInvestment from '@icons/code-of-conduct/green-investment.svg';
import healthSafety from '@icons/code-of-conduct/health-safety.svg';
import humaneWork from '@icons/code-of-conduct/humane-work.svg';
import recycling from '@icons/code-of-conduct/recycling.svg';
import restrictedChemicals from '@icons/code-of-conduct/restricted-chemicals.svg';
import statuaryRemuneration from '@icons/code-of-conduct/statuary-remuneration.svg';
import union from '@icons/code-of-conduct/union.svg';
import water from '@icons/code-of-conduct/water.svg';
import workingHours from '@icons/code-of-conduct/working-hours.svg';

export default {
  childLabour,
  discrimination,
  energy,
  environmentProtection,
  ethicalBusiness,
  forcedLabour,
  greenInvestment,
  healthSafety,
  humaneWork,
  recycling,
  restrictedChemicals,
  statuaryRemuneration,
  union,
  water,
  workingHours,
};
