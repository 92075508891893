<template>
  <component
    :is="componentTag"
    :class="classes"
    v-bind="$attrs"
    v-on="$listeners"
    :tag="tagAttr"
    :to="to"
    :exact="exact"
  >
    <div v-if="$slots.icon" class="button__icon-wrapper">
      <slot name="icon" />
    </div>

    <slot />

    <circular-spinner v-if="loading" class="button__circular-spinner" :inverse="!inverted" :dark="inverted" />
  </component>
</template>

<script>
import CircularSpinner from 'apps/public/pages/components/circular-spinner.vue';

export default {
  name: 'fs-button',

  props: {
    secondary: { type: Boolean, default: false },
    tertiary: { type: Boolean, default: false },
    ghost: { type: Boolean, default: false },
    // Button size
    size: {
      type: String,
      validator: (size) => ['nano', 'micro', 'tiny', 'extra-small', 'small', 'medium', 'large', 'huge'].includes(size),
      default: 'medium',
    },
    inverted: { type: Boolean, default: false },
    // Show button in disabled state
    disabled: { type: Boolean, default: false },
    // When loading
    loading: { type: Boolean, default: false },
    // The tag to use as the button element (can for example be an anchor)
    tag: {
      type: String,
      // Defaults to button, unless component is treated as a router-link wrapper, in which it defaults to the anchor tag
    },
    // Special properties used when using component as a router-link wrapper
    // Pass anything to the to attribute and the component will be treated as a router-link wrapper
    to: {},
    exact: {},
    noSidePaddings: Boolean, // it's needed for tertiary button. It should be optional for backward compatibility
  },

  components: { CircularSpinner },

  inheritAttrs: false,

  computed: {
    buttonType() {
      if (this.secondary) return 'secondary';
      if (this.ghost) return 'ghost';
      if (this.tertiary) return 'tertiary';
      return null;
    },

    classes() {
      const classes = ['button'];

      if (this.disabled) classes.push(`button--disabled`);
      if (this.inverted) classes.push(`button--inverted`);
      if (this.loading) classes.push(`button--loading`);
      if (this.size) classes.push(`button--${this.size}`);
      if (this.buttonType) classes.push(`button--${this.buttonType}`);
      if (this.noSidePaddings) classes.push(`button--no-side-paddings`);

      return classes;
    },
    componentTag() {
      return this.to ? 'router-link' : this.tag || 'button'; // Default to button tag unless router-link wrapper
    },
    tagAttr() {
      return this.to && (this.tag || 'a'); // Default to anchor tag for router-link wrapper scenarios
    },
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@import 'common/styles/variables';
@import 'common/styles/text';

// Inspired by bootstrap - https://github.com/twbs/bootstrap/blob/master/scss/_buttons.scss
.button {
  @include button;
  position: relative;
  font-weight: 400;
  background-color: $c-dark;
  border: 1px solid transparent;
  border-radius: $br-round-corner;
  color: $c-light;
  fill: $c-light;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /*vertical-align: top;*/ // Removed because it makes it less inline'y. Originally removed due to link--button (see link.scss)
  padding: math.div(1rem, 1.6) math.div(2.4rem, 1.6);
  user-select: none;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, opacity 0.1s ease;
  white-space: nowrap;
  background-clip: border-box;
  outline: none;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.85;
  }

  // Secondary button style
  &--secondary {
    border-color: $c-dark;
    color: $c-dark;
    fill: $c-dark;
    background: transparent !important;

    &.button--inverted {
      border-color: $c-light;
      color: $c-light;
      fill: $c-light;
    }
  }

  // Ghost button style
  &--ghost {
    border-color: transparent;
    color: $c-dark;
    fill: $c-dark;
    background: transparent !important;

    &:hover,
    &:active {
      border-color: $c-dark;
      opacity: 1;
    }

    &.button--inverted {
      color: $c-light;
      fill: $c-light;

      &:hover,
      &:active {
        border-color: $c-light;
        opacity: 1;
      }
    }
  }

  // Tertiary button style
  &--tertiary {
    border-color: transparent;
    color: $c-dark;
    fill: $c-dark;
    background: transparent !important;

    &.button--inverted {
      color: $c-light;
      fill: $c-light;
    }
  }

  // Disabled styling
  &--disabled {
    opacity: 0.6 !important;
    pointer-events: none !important;
  }

  // Inverted, when on dark background
  &--inverted {
    background-color: $c-bg-light;
    color: $c-dark;
    fill: $c-dark;
  }

  // Sizing
  &--nano {
    height: math.div(1.5rem, 1.6);
    font-size: math.div(1rem, 1.6);
    font-weight: 400;
    text-transform: none;
  }
  &--micro {
    height: math.div(2rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    font-weight: 400;
    text-transform: none;
  }
  &--tiny {
    height: math.div(2.4rem, 1.6);
    max-height: math.div(2.4rem, 1.6);
    padding: 1px math.div(1.4rem, 1.6) 0;
    font-size: math.div(1.2rem, 1.6);
    font-weight: 400;
    text-transform: none;
  }
  &--extra-small {
    @include button--small;
    min-height: math.div(2.9rem, 1.6);
    max-height: math.div(2.9rem, 1.6);
  }
  &--small {
    @include button--small;
    max-height: math.div(3.5rem, 1.6);
  }

  &--medium {
    @include button--small;
    font-size: math.div(1.4rem, 1.6);
    min-height: math.div(3.7rem, 1.6);
    max-height: math.div(3.7rem, 1.6);

    ::v-deep .icon {
      position: relative;
      top: -1px;
    }
  }

  &--large {
    @include button--large;
  }

  &--huge {
    @include button--huge;
  }

  // Loading styling
  // Hide slot content when loading
  &--loading {
    > * {
      visibility: hidden;
    }
    > .button__circular-spinner {
      visibility: visible;
    }
    color: rgba(0, 0, 0, 0);
  }

  &--no-side-paddings {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &__circular-spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__icon-wrapper {
    height: 100%;
    width: 15px;
    margin-right: 5px;
    padding: 0 0 1px 1px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
}
</style>
