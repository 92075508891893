var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "description-edit-form" },
    [
      _c("h3", { staticClass: "description-edit-form__title" }, [
        _vm._v("Description"),
      ]),
      _vm._v(" "),
      _c("text-area-custom", {
        ref: "textareaDescription",
        staticClass: "description-edit-form__textarea",
        attrs: {
          "min-height": 80,
          "max-height": 186,
          "max-symbols": 1000,
          autosize: "",
          placeholder: "Write a description",
        },
        model: {
          value: _vm.localDescription,
          callback: function ($$v) {
            _vm.localDescription = $$v
          },
          expression: "localDescription",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "description-edit-form__btn-row" },
        [
          _c(
            "fs-button",
            {
              attrs: { secondary: "" },
              nativeOn: {
                click: function ($event) {
                  return (function ($event) {
                    _vm.fsPopperClose($event)
                    _vm.$emit("cancel", $event)
                  })($event)
                },
              },
            },
            [
              _c("fs-button-icon", {
                attrs: {
                  icon: _vm.svg.closeCircleOutlineIcon,
                  width: "1rem",
                  height: "1rem",
                  top: "-1px",
                  color: "dark-blue",
                },
              }),
              _vm._v("\n      Cancel\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fs-button",
            {
              attrs: { loading: _vm.saving },
              nativeOn: {
                click: function ($event) {
                  return _vm.save($event)
                },
              },
            },
            [
              _c("fs-button-icon", {
                attrs: {
                  icon: _vm.svg.saveFloppyIcon,
                  width: "1rem",
                  height: "1rem",
                  top: "-1px",
                  color: "white",
                },
              }),
              _vm._v("\n      Save\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }