var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FsPopper",
    _vm._g(
      _vm._b(
        _vm._b(
          {
            ref: "fsPopper",
            attrs: {
              flip: _vm.flip,
              modifiers: _vm.localModifiers,
              preventOverflowAltAxis: _vm.preventOverflowAltAxis,
              preventOverflowPadding: _vm.preventOverflowPadding,
              preventOverflowRootBoundary: _vm.preventOverflowRootBoundary,
              "is-close-on-esc": "",
            },
          },
          "FsPopper",
          _vm._d({}, [_vm.parentScopeId, ""])
        ),
        "FsPopper",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }