// Processes
import blakeBlakeRapid from 'apps/common/assets/icons/processes/blake-blake-rapid.svg';
import bolognaSacchetto from 'apps/common/assets/icons/processes/bologna-sacchetto.svg';
import cementing from 'apps/common/assets/icons/processes/cementing.svg';
import goodyearWelted from 'apps/common/assets/icons/processes/goodyear-welted.svg';
import handStitched from 'apps/common/assets/icons/processes/hand-stitched.svg';
import directInjectionProcess from 'apps/common/assets/icons/processes/injection.svg';
import moccasins from 'apps/common/assets/icons/processes/moccasins.svg';
import norwegian from 'apps/common/assets/icons/processes/norwegian.svg';
import opanka from 'apps/common/assets/icons/processes/opanka.svg';
import pegged from 'apps/common/assets/icons/processes/pegged.svg';
import stitchTurn from 'apps/common/assets/icons/processes/stitch-and-turn.svg';
import stitchDownRelated from 'apps/common/assets/icons/processes/stitch-down-related.svg';
import vulcanized from 'apps/common/assets/icons/processes/vulcanized.svg';

export default {
  blakeBlakeRapid,
  bolognaSacchetto,
  cementing,
  cemented: cementing,
  goodyearWelted,
  handStitched,
  directInjectionProcess,
  injection: directInjectionProcess,
  directInjectionProcessDip: directInjectionProcess,
  moccasins,
  norwegian,
  opanka,
  pegged,
  stitchTurn,
  stitchDownRelated,
  vulcanization: vulcanized,
  vulcanized,
};
