var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox-with-icon" }, [
    _c("label", { staticClass: "checkbox-with-icon__label-wrapper" }, [
      _c(
        "input",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.localIsChecked,
                  expression: "localIsChecked",
                },
              ],
              ref: "input",
              staticClass: "checkbox-with-icon__checkbox-input",
              attrs: { type: "checkbox" },
              domProps: {
                value: _vm._value,
                checked: Array.isArray(_vm.localIsChecked)
                  ? _vm._i(_vm.localIsChecked, _vm._value) > -1
                  : _vm.localIsChecked,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.localIsChecked,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm._value,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.localIsChecked = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.localIsChecked = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.localIsChecked = $$c
                    }
                  },
                  _vm.emitCheckboxState,
                ],
              },
            },
            "input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox-with-icon__icon-container" }, [
        _c("span", {
          staticClass: "checkbox-with-icon__icon",
          domProps: { innerHTML: _vm._s(_vm.icon || _vm.svg.icoSelectedIcon) },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox-with-icon__text-label" }, [
        _vm._v("\n      " + _vm._s(_vm.textLabel) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }