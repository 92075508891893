<template>
  <div class="checkbox-mobilelike">
    <label
      :class="{
        'checkbox-mobilelike__label-wrapper': true,
        'checkbox-mobilelike__label-wrapper--tiny': size === 'tiny',
        'checkbox-mobilelike__label-wrapper--small': size === 'small',
      }"
    >
      <input
        class="checkbox-mobilelike__checkbox-input"
        v-model="localChecked"
        v-bind="$attrs"
        v-on="$listeners"
        ref="input"
        type="checkbox"
      />

      <!-- Checkbox imitation -->
      <span
        :class="{
          'checkbox-imitation': true,
          'checkbox-imitation--tiny': size === 'tiny',
          'checkbox-imitation--small': size === 'small',
          'checkbox-imitation--gray': color === 'gray',
          'checkbox-imitation--inactive': !localChecked,
          'checkbox-imitation--active': localChecked,
          'checkbox-mobilelike__checkbox-imitation': true,
        }"
      >
        <transition name="simple-fade-in-out">
          <span
            v-if="localChecked"
            :class="{
              'checkbox-imitation__icon': true,
              'checkbox-imitation__icon--tiny': size === 'tiny',
              'checkbox-imitation__icon--small': size === 'small',
              'checkbox-imitation__icon--checkmark': true,
            }"
            v-html="icons.checkMarkIcon"
          >
          </span>
        </transition>

        <transition name="simple-fade-in-out">
          <span
            v-if="!localChecked"
            :class="{
              'checkbox-imitation__icon': true,
              'checkbox-imitation__icon--tiny': size === 'tiny',
              'checkbox-imitation__icon--small': size === 'small',
              'checkbox-imitation__icon--cross': true,
            }"
            v-html="icons.icoCrossIcon"
          >
          </span>
        </transition>

        <span
          :class="{
            'checkbox-imitation__handler': true,
            'checkbox-imitation__handler--tiny': size === 'tiny',
            'checkbox-imitation__handler--small': size === 'small',
            'checkbox-imitation__handler--checked-position': localChecked,
          }"
        ></span> </span
      ><!-- / Checkbox imitation -->
    </label>
  </div>
</template>

<script>
// General
import twoWayProperty from '@lib/twoWayProperty';

// Icons
import checkMarkIcon from '@icons/other/check-mark-icon.svg';
import icoCrossIcon from '@icons/other/ico-cross.svg';

export default {
  name: 'fs-switch',

  model: {
    prop: 'checked',
    event: 'update:checked',
  },

  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'large',
      validator: (size) => ['tiny', 'small', 'medium', 'large'].includes(size),
    },
    color: { type: String, validator: (color) => ['gray'].includes(color) },
  },

  data() {
    return {
      icons: { checkMarkIcon, icoCrossIcon },
    };
  },

  computed: {
    localChecked: twoWayProperty('checked'),
  },

  watch: {
    localChecked(oldValue, newValue) {
      if (oldValue == newValue) return;

      const checkEvent = this.localChecked ? 'checked' : 'uncheck';

      this.$emit(checkEvent, this.localChecked);
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

// ============================================================================
// ============================================================================
// Checkbox imitation
.checkbox-imitation {
  width: math.div(6.5rem, 1.6);
  height: math.div(3rem, 1.6);
  display: block;
  border-radius: math.div(10rem, 1.6);
  position: relative;
  transition: background-color 0.1s ease;

  &--inactive {
    background: rgb(245, 115, 110);
  }

  &--active {
    background: rgb(53, 73, 94);
  }

  &--gray.checkbox-imitation--inactive {
    background: #ccd1d6;
  }

  &--tiny {
    width: math.div(2.8rem, 1.6);
    height: math.div(1.5rem, 1.6);
  }

  &--small {
    width: math.div(3.7rem, 1.6);
    height: math.div(1.8rem, 1.6);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;

    ::v-deep svg {
      width: 100%;
      height: 100%;
      fill: white;
    }

    &--checkmark {
      width: math.div(1.8rem, 1.6);
      height: math.div(1.8rem, 1.6);
      inset: calc(50% - #{math.div(0.9rem, 1.6)}) auto auto math.div(1.2rem, 1.6);
    }
    &--cross {
      width: math.div(1.4rem, 1.6);
      height: math.div(1.4rem, 1.6);
      inset: calc(50% - #{math.div(0.7rem, 1.6)}) math.div(1.3rem, 1.6) auto auto;
    }

    &--tiny.checkbox-imitation__icon--checkmark {
      display: none;
    }
    &--tiny.checkbox-imitation__icon--cross {
      display: none;
    }

    &--small.checkbox-imitation__icon--checkmark {
      width: math.div(1.2rem, 1.6);
      height: math.div(1.2rem, 1.6);
      inset: 3px auto auto math.div(0.6rem, 1.6);
    }
    &--small.checkbox-imitation__icon--cross {
      width: math.div(1rem, 1.6);
      height: math.div(1rem, 1.6);
      inset: 4px math.div(0.7rem, 1.6) auto auto;
    }
  }

  &__handler {
    width: math.div(2.4rem, 1.6);
    height: math.div(2.4rem, 1.6);
    border-radius: math.div(10rem, 1.6);
    position: absolute;
    top: math.div(0.3rem, 1.6);
    left: math.div(0.4rem, 1.6);
    background: white;
    transition: left 0.1s ease;

    &--checked-position {
      left: calc(100% - #{math.div(2.4rem, 1.6)} - #{math.div(0.4rem, 1.6)});
    }

    &--tiny {
      width: math.div(1.1rem, 1.6);
      height: math.div(1.1rem, 1.6);
      inset: 2px auto auto 3px;
    }
    &--tiny.checkbox-imitation__handler--checked-position {
      left: math.div(1.4rem, 1.6);
    }

    &--small {
      width: math.div(1.4rem, 1.6);
      height: math.div(1.4rem, 1.6);
      inset: 2px auto auto 3px;
    }
    &--small.checkbox-imitation__handler--checked-position {
      left: math.div(2rem, 1.6);
    }
  }
}

// ============================================================================
// ============================================================================
// Checkbox mobilelike
.checkbox-mobilelike {
  user-select: none;

  &__label-wrapper {
    height: math.div(3rem, 1.6);
    min-height: math.div(3rem, 1.6);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &--tiny {
      height: math.div(1.5rem, 1.6);
      min-height: math.div(1.5rem, 1.6);
    }

    &--small {
      height: math.div(1.8rem, 1.6);
      min-height: math.div(1.8rem, 1.6);
    }

    &::before {
      content: '';
      width: calc(100% + #{math.div(1rem, 1.6)});
      height: calc(100% + #{math.div(1rem, 1.6)});
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__checkbox-input {
    position: absolute;
    opacity: 0;
  }

  &__checkbox-imitation {
  }
}
</style>
