var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fs-pill",
    _vm._g(
      _vm._b(
        {
          staticClass: "status-pill",
          style: _vm.style,
          attrs: {
            color: _vm._color,
            "circle-sides": "",
            size: _vm.size,
            tag: _vm.tag,
            "hover-mode": _vm.hoverMode,
          },
        },
        "fs-pill",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.ghost && _vm.statusType === "article"
        ? _c("div", {
            staticClass: "status-pill__icon",
            style: { fill: _vm._color },
            domProps: {
              innerHTML: _vm._s(
                _vm.icons.statuses[_vm.$store.articles.statusNames[_vm.status]]
              ),
            },
          })
        : _vm._e(),
      _vm._v("\n  " + _vm._s(_vm.label) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }