<template>
  <component
    :is="tag"
    :class="{
      pill: true,
      'pill--circle-sides': circleSides,
      'pill--clickable': clickable || hoverMode,
      'pill--counter': counter,
      'pill--not-fixed': !fixed,
      'pill--inversed': inversed,
      'pill--outlined': _outlined,

      'pill--small': size === 'small',
      'pill--medium': size === 'medium',
      'pill--semi-large': size === 'semi-large',
      'pill--large': size === 'large',
      'pill--extra-large': size === 'extra-large',

      'pill--add-mode': hoverMode === 'add',
      'pill--remove-mode': hoverMode === 'remove',
    }"
    v-bind="$attrs"
    v-on="$listeners"
    :style="style"
  >
    <span
      v-if="hoverMode"
      :class="{
        'plus-or-cross-icon': true,
        'pill__plus-or-cross-icon': true,
        'plus-or-cross-icon--small        pill__plus-or-cross-icon--small': size === 'small',
        'plus-or-cross-icon--medium       pill__plus-or-cross-icon--medium': size === 'medium' || size === 'semi-large',
        'plus-or-cross-icon--large        pill__plus-or-cross-icon--large': size === 'large' || size === 'extra-large',
        'plus-or-cross-icon--45-deg-turn  pill__plus-or-cross-icon--45-deg-turn': hoverMode === 'remove',
      }"
      v-html="icons.singles.addIcon"
    ></span>

    <slot name="pre"></slot>

    <span class="pill__text">
      <slot></slot>
    </span>

    <slot name="post"></slot>
  </component>
</template>

<script>
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

import { calculateColorBrightness } from '@lib/colorManagement';

// Icons
// singles
import addIcon from '@icons/other/add.svg';

export default {
  name: 'fs-pill',

  props: {
    circleSides: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    // String: The color of the pill, styled based on type (or, should be based on solid/outlined - see below)
    // Object: Style properties, fully managed (basically passed directly into the style-attribute)
    color: {
      type: [Object, String],
    },
    counter: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    outlinedColored: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (size) => ['small', 'medium', 'semi-large', 'large', 'extra-large'].includes(size),
      default: 'medium',
    },
    // FUTURE: If we need to support router-link, then look at fs-button on how to implement it
    tag: {
      type: String,
      default: 'span',
    },
    hoverMode: { type: String, validator: (mode) => ['add', 'remove'].includes(mode) },
  },

  data() {
    return {
      icons: {
        singles: {
          addIcon,
        },
      },
    };
  },

  computed: {
    colorIsBright() {
      if (this.rainbow) return true;

      if (this.color === 'transparent') return true;

      return calculateColorBrightness(this.color) > 205;
    },

    colorIsReallyBright() {
      if (this.rainbow) return true;

      if (this.color === 'transparent') return true;

      return calculateColorBrightness(this.color) > 240;
    },

    colorIsObject() {
      return !isString(this.color) && isObject(this.color);
    },

    style() {
      if (this.rainbow) {
        return {
          borderWidth: '0',
          color: '#fff',
          backgroundColor: 'rgb(224,89,100)',
          background: 'linear-gradient(90deg, rgba(224,89,100,1) 0%, rgba(124,199,106,1) 50%, rgba(91,107,199,1) 100%)',
        };
      }

      if (!this.color) return {};

      if (this.colorIsObject) return this.color;

      const style = {};
      // Note! This is based on the outlined prop - NOT the _outlined computed value (which takes "inverse" scenarios, solid background too bright to have white text, into considerations)
      style[this.outlined || this.outlinedColored ? 'borderColor' : 'backgroundColor'] = this.color;

      if (this.outlinedColored) style['color'] = this.color;

      if (this.color === 'transparent') {
        style.backgroundColor = 'transparent';
        style.color = 'rgb(53,73,94)';
        style.borderColor = 'rgb(53,73,94)';
      }

      return style;
    },

    inversed() {
      return !this._outlined && this.color && !this.colorIsObject && this.colorIsBright;
    },

    _outlined() {
      return this.outlined || this.outlinedColored || (this.color && !this.colorIsObject && this.colorIsReallyBright);
    },

    rainbow() {
      return this.color === 'rainbow';
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/text';
@import 'common/styles/variables';

// Plus or Cross icon ---------------------------------------------------------
.plus-or-cross-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  &--small {
    width: math.div(1rem, 1.6);
    height: math.div(1rem, 1.6);
  }
  &--medium {
    width: math.div(1.2rem, 1.6);
    height: math.div(1.2rem, 1.6);
  }
  &--large {
    width: math.div(1.4rem, 1.6);
    height: math.div(1.4rem, 1.6);
  }

  &--45-deg-turn {
    transform: rotate(45deg);
  }

  ::v-deep svg {
    width: 100%;
    height: 100%;
  }
}

// TODO: REVIEW/REFACTOR Should we perhaps add an "icon"-version so that we can have
// New supplier page -> .footwear-type / __icon-container be a type of pill?
// They sure look like pills with icons + labels. It would be easy to only do the
// icon part - but the labels are quite critical (also since the container is where
// the hover lies). OR, are they simply looking very similar but are not really pills?
.pill {
  flex-shrink: 0;
  min-width: math.div(7.4rem, 1.6);
  height: math.div(2rem, 1.6);
  padding: 0 math.div(0.6rem, 1.6);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  // Defaults to solid style, rather than outlined
  border: 1px transparent solid;
  border-radius: $br-medium;
  position: relative;
  color: $c-light;
  // Default size (aka medium)
  @include caption--small;
  fill: $c-light;
  background-color: $c-dark;
  user-select: none;

  &--add-mode,
  &--remove-mode {
    cursor: pointer;
  }

  &__text {
    width: auto;
    max-width: 100%;
    padding: 2px 0; // Because of overflow: hidden, g's get cut off at the bottom - so we add extra padding to accomodate for that.
    display: block;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1;
    text-align: center;
    opacity: 1;
    transition: color 0.1s ease, fill 0.1s ease, opacity 0.05s ease-in-out;
  }

  &--add-mode:hover &__text,
  &--remove-mode:hover &__text {
    opacity: 0;
  }

  &__plus-or-cross-icon {
    position: absolute;
    transform: translateY(-1px); // compensates text top 1px padding
    opacity: 0;
    transition: opacity 0.05s ease-in-out;

    &--small {
      inset: calc(50% - #{math.div(0.5rem, 1.6)}) auto auto calc(50% - #{math.div(0.5rem, 1.6)});
    }
    &--medium {
      inset: calc(50% - #{math.div(0.6rem, 1.6)}) auto auto calc(50% - #{math.div(0.6rem, 1.6)});
    }
    &--large {
      inset: calc(50% - #{math.div(0.7rem, 1.6)}) auto auto calc(50% - #{math.div(0.7rem, 1.6)});
    }

    &--45-deg-turn {
      transform: translateY(0) rotate(45deg);
    }
  }

  &:hover &__plus-or-cross-icon {
    opacity: 1;
  }

  &--remove-mode:hover &__text {
    color: rgba(180, 180, 180, 0);
  }

  &--remove-mode:hover &__cross {
    ::v-deep svg {
      fill: rgba(255, 255, 255, 1);
    }
  }

  &--remove-mode.pill--outlined:hover &__cross {
    ::v-deep svg {
      fill: rgba(53, 73, 94, 1);
    }
  }

  // Size modifiers
  // ==============================================

  &--extra-large {
    height: math.div(3rem, 1.6);
    padding: 0 math.div(1.6rem, 1.6);
    border-radius: $br-heavy;
    border-width: math.div(0.2rem, 1.6);
    // min-width: TBD;
    text-transform: uppercase;
  }

  &--large {
    height: math.div(2.6rem, 1.6);
    padding: 0 math.div(1.2rem, 1.6);
    min-width: math.div(12rem, 1.6);

    &.pill--circle-sides {
      padding: 0 math.div(1.4rem, 1.6);
    }
  }

  &--semi-large {
    height: math.div(2.4rem, 1.6);
    // padding: TBD;
    // min-width: TBD;

    &.pill--circle-sides {
      padding: 0 math.div(1.2rem, 1.6);
    }
  }

  &--circle-sides {
    padding: 0 math.div(0.8rem, 1.6); // REFACTOR - should not be here? Width/padding (width/sizing) should be handled elsewhere
  }

  &--small {
    height: math.div(1.5rem, 1.6);
    padding: 1px 3px 0;
    font-size: math.div(1rem, 1.6);
    // min-width: TBD;
  }

  // Other modifiers
  // ==============================================

  &--inversed {
    fill: $c-dark;
    color: $c-dark;
  }

  &--outlined {
    fill: $c-dark;
    color: $c-dark;
    background-color: $c-bg-light;
    border-color: $c-dark;
  }

  &--clickable {
    cursor: pointer;
  }

  &--circle-sides {
    border-radius: $br-round-corner;
  }

  &--not-fixed {
    min-width: 0;
  }

  // The counter only exists in below sizes
  &--counter {
    font-size: math.div(1rem, 1.6);
    font-weight: $bold-lato;
    padding-right: math.div(0.4rem, 1.6);
    padding-left: math.div(0.3rem, 1.6); // TODO: REVIEW padding, should it be 0.25rem on both sides as default?;
    min-width: 0;

    &.pill--small {
      padding-right: 2px;
      padding-left: 2px;
    }
  }
}
</style>
