<template>
  <div id="certification-section" class="certification-section">
    <h4 class="certification-section__header">Certifications</h4>

    <div class="certification-section__certifications">
      <div
        class="certification certification-section__single-certification"
        v-for="certificate in data"
        :key="certificate.id"
      >
        <a
          class="certification__logo"
          :href="certificate.organization.link"
          target="_blank"
          rel="noopener noreferrer"
          @click="$ga.event('certifications', 'supplier page -> SATRA laboratory accreditation -> logo')"
        >
          <img v-if="certificate.image" :src="$blobUrl + '/' + certificate.image" :alt="certificate.name" />
          <span class="certification__logo-placeholder" v-html="svg.logoPlaceholderIcon"> </span>
        </a>
        <span class="certification__header">{{ certificate.name }}</span>
        <span class="certification__date">
          {{ new Date(certificate.date).toLocaleDateString() }}
        </span>
        <a
          v-if="certificate.file"
          class="link link--dark certification__proof"
          :href="certificate.organization.link"
          target="_blank"
          rel="noopener noreferrer"
          @click="$ga.event('certifications', 'supplier page -> SATRA laboratory accreditation -> details')"
        >
          <span class="certification__proof-icon" v-html="svg.filePdf"></span>
          <span class="certification__proof-text">Proof Letter</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// Icons
import website from 'apps/common/assets/icons/contact/website.svg';
import filePdf from '@icons/file-types/file-pdf.svg';
import logoPlaceholderIcon from '@icons/other/logo-placeholder.svg';
// SATRA
// TODO: REVIEW if this really should be statically imported - it's unnecessary
// data to load for all profiles except SATRA members.
import satraAccreditation from 'apps/common/assets/external-organizations/satra-accreditation.svg';

export default {
  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      satraAccreditation: this.$store.general.satra,
      svg: { website, satraAccreditation, filePdf, logoPlaceholderIcon },
    };
  },

  mounted() {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

.supplierProfile {
  .certification-section {
    &__header {
      @include heading--small;
      text-align: center;
      margin-top: 0;
      padding-top: math.div(4.8rem, 1.6);
    }

    &__certifications {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: math.div(1rem, 1.6);
    }
  }

  .certification-section__single-certification {
  }

  .certification {
    padding: math.div(1.2rem, 1.6);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo {
      height: math.div(7.2rem, 1.6);
      width: math.div(7.2rem, 1.6);
    }

    &__logo-placeholder {
      ::v-deep svg {
        fill: rgba(53, 73, 94, 0.2);
      }
    }

    &__header {
      padding-top: math.div(1.2rem, 1.6);
    }

    &__date {
      @include caption--small;
      padding: 3px 0;
    }

    &__proof {
      display: flex;
      padding-top: math.div(0.4rem, 1.6);
    }

    &__proof-icon {
      display: block;
      height: math.div(1.8rem, 1.6);
      width: math.div(1.8rem, 1.6);
      fill: $c-dark;
      margin-right: math.div(0.6rem, 1.6);
    }

    &__proof-text {
      @include caption--small;
      font-style: italic;
      line-height: math.div(2rem, 1.6);
      text-transform: uppercase;
    }
  }
}
</style>
