<template>
  <!-- Types & Processes -->
  <div class="types-n-processes">
    <div class="types-n-processes__main-title">Footwear Types & Production Processes</div>

    <!-- Form itself -->
    <div class="form-itself types-n-processes__form-itself">
      <div class="form-itself__column">
        <checkbox-custom-with-icon
          v-for="item in footwearTypes"
          :key="item"
          class="form-itself__checkbox-with-icon"
          @check="() => {}"
          @uncheck="() => {}"
          :icon="icons.footwearTypes[camelCase(item)]"
          :text-label="item"
        />
      </div>

      <div class="form-itself__column form-itself__column--checkbox-buttons-list">
        <checkbox-custom-button
          v-for="item in productionProcesses"
          :key="item"
          class="form-itself__checkbox-button"
          @check="() => {}"
          @uncheck="() => {}"
          :additional-icon="icons.typeNProcess[camelCase(item)]"
          :text-label="item"
          size="small"
          clean-design
        />
      </div>
    </div>
    <!-- / Form itself -->

    <cancel-n-action-btn-pair class="types-n-processes__manage-buttons-panel" @cancel="() => {}" @action="() => {}" />
  </div>
  <!-- / Types & Processes -->
</template>

<script>
import camelCase from 'lodash/camelCase';

// Components
import CheckboxCustomWithIcon from '@components/form-elements/checkbox-custom-with-icon.vue';
import CheckboxCustomButton from '@components/form-elements/checkbox-custom-button.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
import footwearTypesIcons from '@lib/icons-imports/product-types/footwear';
import typeNProcessIcons from '@lib/icons-imports/type-n-process';

// TODO: #971 Figure out where this one is used and what to do about it
export default {
  name: 'types-n-processes',

  props: {},

  components: { CheckboxCustomWithIcon, CheckboxCustomButton, CancelNActionBtnPair },

  data() {
    return {
      isSaveInProgress: false,

      footwearTypes: [
        'Shoes',
        'Heeled Shoes',
        'Boots',
        'Sneakers',
        'Ballet Flats',
        'Moccasins',
        'Slippers',
        'Sandals',
        'Flip Flops',
        'Espadrilles',
      ],

      productionProcesses: [
        'Vulcanized',
        'Opanka',
        'Injection',
        'Norwegian',
        'Goodyear Welted',
        'Bologna (Sacchetto)',
        'Stitch Down & Related',
        'Blake & Blake Rapid',
        'Stitch & Turn',
        'Hand Stitched',
        'Cemented',
        'Moccasins',
        'Pegged',
      ],

      icons: {
        interfaceElements: interfaceElementsIcons,
        footwearTypes: footwearTypesIcons,
        typeNProcess: typeNProcessIcons,
      },
      svg: {},
    };
  },

  methods: {
    camelCase,
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

// ============================================================================
// ============================================================================
// Form itself
.form-itself {
  display: flex;

  &__column {
    width: 50%;
    padding-right: math.div(3.5rem, 1.6);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    position: relative;

    &:nth-child(2) {
      padding-right: 0;
      padding-left: math.div(3.5rem, 1.6);

      &::before {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(53, 73, 94, 0.1);
      }
    }

    &--checkbox-buttons-list {
      justify-content: space-between;
    }
  }

  &__checkbox-with-icon {
    width: math.div(8rem, 1.6);
    min-width: math.div(8rem, 1.6);
    max-width: math.div(8rem, 1.6);
    margin: math.div(1rem, 1.6) math.div(0.3rem, 1.6);
  }

  &__checkbox-button {
    width: calc(50% - #{math.div(1.2rem, 1.6)});
    min-width: calc(50% - #{math.div(1.2rem, 1.6)});
    margin-bottom: math.div(0.7rem, 1.6);
  }
}

@media (max-width: $tablet-l-width) {
  .form-itself {
    flex-direction: column;

    &__column {
      width: auto;
      padding: 0;
      margin-bottom: math.div(1.7rem, 1.6);
      justify-content: center;

      &:nth-child(2) {
        padding: math.div(2.9rem, 1.6) 0 0;
        margin-bottom: 0;
        justify-content: space-between;

        &::before {
          content: '';
          width: 100%;
          height: 1px;
          position: absolute;
          inset: 0 auto auto 0;
          font-size: 0;
          line-height: 0;
          background: rgba(53, 73, 94, 0.1);
        }
      }
    }
  }
}

// ============================================================================
// ============================================================================
// Types & Processes
.types-n-processes {
  width: math.div(100rem, 1.6);
  padding: math.div(3.5rem, 1.6);
  box-sizing: border-box;

  &__main-title {
    margin-bottom: math.div(2.5rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__form-itself {
    margin-bottom: math.div(2.3rem, 1.6);
  }

  &__manage-buttons-panel {
  }
}

@media (max-width: $tablet-l-width) {
  .types-n-processes {
    width: 90vw;
    max-width: math.div(50rem, 1.6);
  }
}
</style>
