<template>
  <!-- Inputs section frame -->
  <component :is="tag" class="inputs-section-frame">
    <!-- Photo container -->
    <div class="photo-container inputs-section-frame__photo-container">
      <div class="photo-placeholder photo-container__photo-placeholder">
        <div class="photo-placeholder__icon" v-html="svg.photoPlaceholderIcon"></div>
        <div class="photo-placeholder__text">Select image</div>

        <div class="photo-placeholder__bg-layer"></div>
      </div>
    </div>
    <!-- / Photo container -->

    <div class="inputs-section-frame__form-elements-container">
      <slot />
    </div>

    <div
      v-if="showDeleteBut"
      class="inputs-section-frame__delete-but"
      v-html="icons.interfaceElements.trashCanIcon"
    ></div>

    <div
      v-if="showDragHandle"
      class="inputs-section-frame__drag-n-drop-icon"
      v-html="icons.interfaceElements.dragNDropVertically"
    ></div> </component
  ><!-- / Inputs section frame -->
</template>

<script>
// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
// SVG
import photoPlaceholderIcon from '@icons/other/photo-placeholder-icon.svg';

export default {
  name: 'inputs-section-frame',

  props: {
    tag: { type: String, default: 'div' },
    showDragHandle: { type: Boolean, default: false },
    showDeleteBut: { type: Boolean, default: false },
  },

  data() {
    return {
      icons: {
        interfaceElements: interfaceElementsIcons,
      },
      svg: { photoPlaceholderIcon },
    };
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// ============================================================================
// Photo container
.photo-container {
  width: math.div(8.5rem, 1.6);
  height: math.div(8.5rem, 1.6);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: white;

  &__photo-placeholder {
    width: 100%;
    height: 100%;
  }
  .photo-placeholder {
    border-radius: math.div(20rem, 1.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    cursor: pointer;

    &__bg-layer {
      width: 100%;
      height: 100%;
      border-radius: math.div(20rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      transition: width 0.15s ease, height 0.15s ease, inset 0.15s ease;

      &::before {
        content: '';
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        box-sizing: border-box;
        border: 2px rgba(53, 73, 94, 0.35) dashed;
        border-radius: math.div(20rem, 1.6);
        position: absolute;
        top: -1px;
        left: -1px;
        background: rgba(53, 73, 94, 0.04);
      }
    }

    &:hover .photo-placeholder__bg-layer {
      width: calc(100% + #{math.div(0.6rem, 1.6)});
      height: calc(100% + #{math.div(0.6rem, 1.6)});
      top: -3px;
      left: -3px;
    }

    &__icon {
      width: math.div(1.9rem, 1.6);
      height: math.div(1.4rem, 1.6);
      margin-bottom: 3px;

      ::v-deep svg {
        width: 100%;
        fill: rgb(53, 73, 94);
      }
    }

    &__text {
      width: math.div(5rem, 1.6);
      font-size: math.div(1rem, 1.6);
      line-height: math.div(1.2rem, 1.6);
      text-transform: uppercase;
      text-align: center;
    }
  }
}

// ============================================================================
// ============================================================================
// Inputs section frame
.inputs-section-frame {
  padding: 0 math.div(1.3rem, 1.6) 0 math.div(4.2rem, 1.6);
  box-sizing: border-box;
  position: relative;

  &__photo-container {
    position: absolute;
    top: calc(50% - #{math.div(4.3rem, 1.6)});
    left: 0;
  }

  &__form-elements-container {
    min-height: math.div(12rem, 1.6);
    padding: math.div(1.8rem, 1.6) math.div(5rem, 1.6) math.div(2.1rem, 1.6) math.div(7.5rem, 1.6);
    border: 1px rgba(53, 73, 94, 0.1) solid;
    border-radius: math.div(1rem, 1.6);
  }

  &__delete-but {
    width: math.div(2.7rem, 1.6);
    height: math.div(2.7rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - #{math.div(1.3rem, 1.6)});
    right: 0;
    z-index: 1;
    cursor: pointer;
    user-select: none;

    &::before,
    &::after {
      transition: transform 0.12s ease, background-color 0.12s ease;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: math.div(10rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
      background: white;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: math.div(10rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: rgba(53, 73, 94, 0.4);
    }

    &:hover::before,
    &:hover::after {
      transform: scale(1.23);
    }

    &:hover::after {
      background: #da8282;
    }

    ::v-deep svg {
      width: math.div(1.7rem, 1.6);
      height: math.div(1.7rem, 1.6);
      position: relative;
      z-index: 2;
      fill: white;
    }
  }

  &__drag-n-drop-icon {
    width: math.div(1.7rem, 1.6);
    height: math.div(2.3rem, 1.6);
    position: absolute;
    left: calc(50% - #{math.div(0.8rem, 1.6)});
    bottom: math.div(-1.1rem, 1.6);
    z-index: 3;
    background: white;
    user-select: none;
    cursor: grab;

    ::v-deep svg {
      fill: rgba(53, 73, 94, 0.5);
      transition: fill 0.15s ease;
    }

    &:hover {
      ::v-deep svg {
        fill: rgba(53, 73, 94, 1);
      }
    }
  }
}
</style>
