var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "input-main-component": true,
        "input-main-component--disabled": _vm.isDisabled,
        "input-main-component--error": _vm.isError,
        "input-main-component--search": _vm.isSearch,

        "input-main-component--focus": _vm.isInputOnFocus,

        "input-main-component--mid-code": _vm.variant === "mid-code",
        "input-main-component--mid": _vm.variant === "mid",
        "input-main-component--min-code": _vm.variant === "min-code",

        "input-main-component--position-left": _vm.position === "left",
        "input-main-component--position-right": _vm.position === "right",
      },
      on: {
        mouseenter: function ($event) {
          _vm.isComponentHovered = true
        },
        mouseleave: function ($event) {
          _vm.isComponentHovered = false
        },
      },
    },
    [
      _c(
        "label",
        { staticClass: "input-main-component__text-input-label" },
        [
          _vm.localType === "file" && !_vm.localValue
            ? _c(
                "span",
                {
                  staticClass: "upload-area input-main-component__upload-area",
                },
                [
                  _c("span", {
                    staticClass: "upload-area__icon",
                    domProps: {
                      innerHTML: _vm._s(_vm.svg.uploadCloudArrowIcon),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "upload-area__text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.placeholder || "Upload Document") +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    class: {
                      "upload-area__bg-layer": true,
                      "upload-area__bg-layer--hovered": _vm.isComponentHovered,
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.localType === "fileOrUrl" && !_vm.localValue
            ? _c(
                "span",
                {
                  staticClass:
                    "upload-or-type-url input-main-component__upload-or-type-url",
                  on: {
                    mouseenter: function () {
                      if (!_vm.isUploadSelected && !_vm.isAddUrlSelected) {
                        _vm.isUploadOrAddUrlMenuVisible = true
                      }
                    },
                    mouseleave: function () {
                      if (!_vm.isUploadSelected && !_vm.isAddUrlSelected) {
                        _vm.isUploadOrAddUrlMenuVisible = false
                      }
                    },
                    dragenter: function ($event) {
                      _vm.localType = "file"
                      _vm.isUploadSelected = true
                      _vm.isUploadOrAddUrlMenuVisible = false
                    },
                  },
                },
                [
                  !_vm.isUploadOrAddUrlMenuVisible &&
                  !_vm.isUploadSelected &&
                  !_vm.isAddUrlSelected
                    ? _c("span", { staticClass: "upload-or-type-url__text" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.placeholder || "Add Document") +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isUploadOrAddUrlMenuVisible &&
                  !_vm.isUploadSelected &&
                  !_vm.isAddUrlSelected
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "upload-or-type-buttons upload-or-type-url__upload-or-type-buttons",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "upload-or-type-buttons__but",
                              on: {
                                click: function ($event) {
                                  _vm.isUploadSelected = true
                                  _vm.localType = "file"
                                },
                              },
                            },
                            [
                              _c("span", {
                                staticClass: "upload-or-type-buttons__but-icon",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.svg.uploadCloudArrowIcon
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "upload-or-type-buttons__but-text",
                                },
                                [_vm._v("Upload")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "upload-or-type-buttons__but",
                              on: {
                                click: function ($event) {
                                  _vm.isAddUrlSelected = true
                                },
                              },
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "upload-or-type-buttons__but-icon upload-or-type-buttons__but-icon--external-link",
                                domProps: {
                                  innerHTML: _vm._s(_vm.svg.externalLinkIcon),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "upload-or-type-buttons__but-text",
                                },
                                [_vm._v(" Add Link ")]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isUploadSelected && !_vm.isAddUrlSelected
                    ? _c("span", {
                        class: {
                          "upload-or-type-url__bg-layer": true,
                          "upload-or-type-url__bg-layer--with-divider":
                            _vm.isUploadOrAddUrlMenuVisible &&
                            !_vm.isUploadSelected &&
                            !_vm.isAddUrlSelected,
                          "upload-or-type-url__bg-layer--hovered":
                            _vm.isComponentHovered,
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSearch && !_vm.noMagnifierButton
            ? _c("span", {
                staticClass: "input-main-component__search-icon",
                domProps: { innerHTML: _vm._s(_vm.svg.searchIcon) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.additionalIcon
            ? _c("span", {
                staticClass: "input-main-component__additional-icon",
                domProps: { innerHTML: _vm._s(_vm.additionalIcon) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.localType === "file"
            ? _c("span", {
                staticClass: "input-main-component__clip-icon",
                domProps: { innerHTML: _vm._s(_vm.svg.clipIcon) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.localType === "fileOrUrl" && _vm.isAddUrlSelected
            ? _c("span", {
                staticClass: "input-main-component__external-link-icon",
                domProps: { innerHTML: _vm._s(_vm.svg.externalLinkIcon) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.localType === "file"
            ? _c("span", { staticClass: "input-main-component__file-name" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.retrieveFileName(_vm.localValue) || "Choose file"
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.prefix || _vm.$slots.prefix
            ? _c(
                "span",
                { staticClass: "input-main-component__prefix" },
                [
                  _vm._t("prefix", [
                    _vm._v("\n        " + _vm._s(_vm.prefix) + "\n      "),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isLazy
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.lazy",
                    value: _vm.localValue,
                    expression: "localValue",
                    modifiers: { lazy: true },
                  },
                ],
                ref: "input",
                class: {
                  "input-itself": true,
                  "input-itself--disabled": _vm.isDisabled,
                  "input-itself--hidden": _vm.localType === "file",
                  "input-itself--cursor-pointer": _vm.localType === "file",
                  "input-itself--text-align-center": _vm.textAlignCenter,
                  "input-itself--additional-left-padding": _vm.additionalIcon,
                  "input-itself--additional-right-padding": _vm.isDisabled,
                  "input-itself--mid": _vm.variant === "mid",
                  "input-itself--mid-code": _vm.variant === "mid-code",
                  "input-itself--min-code": _vm.variant === "min-code",
                  "input-itself--with-suffix":
                    !_vm.isInputOnFocus && (_vm.suffix || _vm.$slots.suffix),
                  "input-main-component__input-itself": true,
                },
                attrs: {
                  type: _vm.localType,
                  disabled: _vm.isDisabled,
                  placeholder:
                    _vm.localType === "fileOrUrl"
                      ? "File URL"
                      : _vm.placeholder,
                  autocomplete: _vm.autocompleteOff ? "none" : "on",
                  tabindex: _vm.tabindex ? +_vm.tabindex : 1,
                  min: _vm.min || Number(_vm.min) === 0 ? _vm.min : "",
                },
                domProps: { value: _vm.localValue },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("keyup-enter", $event)
                  },
                  focus: function ($event) {
                    _vm.isInputOnFocus = true
                    _vm.$emit("focus")
                  },
                  blur: function ($event) {
                    _vm.isInputOnFocus = false
                    _vm.$emit("blur")
                  },
                  change: [
                    function ($event) {
                      _vm.localValue = $event.target.value
                    },
                    function ($event) {
                      return _vm.realChange()
                    },
                  ],
                },
              })
            : _vm.localType === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localValue,
                    expression: "localValue",
                  },
                ],
                ref: "input",
                class: {
                  "input-itself": true,
                  "input-itself--disabled": _vm.isDisabled,
                  "input-itself--hidden": _vm.localType === "file",
                  "input-itself--text-align-center": _vm.textAlignCenter,
                  "input-itself--additional-left-padding": _vm.additionalIcon,
                  "input-itself--additional-right-padding": _vm.isDisabled,
                  "input-itself--with-suffix":
                    !_vm.isInputOnFocus && (_vm.suffix || _vm.$slots.suffix),
                  "input-itself--mid": _vm.variant === "mid",
                  "input-itself--mid-code": _vm.variant === "mid-code",
                  "input-itself--min-code": _vm.variant === "min-code",
                  "input-main-component__input-itself": true,
                  "input-main-component__input-itself--smaller-width":
                    _vm.isClearButtonVisible,
                },
                attrs: {
                  disabled: _vm.isDisabled,
                  placeholder:
                    _vm.localType === "fileOrUrl"
                      ? "File URL"
                      : _vm.placeholder,
                  autocomplete: _vm.autocompleteOff ? "none" : "on",
                  tabindex: _vm.tabindex ? +_vm.tabindex : 1,
                  min: _vm.min || Number(_vm.min) === 0 ? _vm.min : "",
                  type: "checkbox",
                },
                domProps: {
                  checked: Array.isArray(_vm.localValue)
                    ? _vm._i(_vm.localValue, null) > -1
                    : _vm.localValue,
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("keyup-enter", $event)
                  },
                  focus: function ($event) {
                    _vm.isInputOnFocus = true
                    _vm.$emit("focus")
                  },
                  blur: function ($event) {
                    _vm.isInputOnFocus = false
                    _vm.$emit("blur")
                  },
                  change: [
                    function ($event) {
                      var $$a = _vm.localValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.localValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.localValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.localValue = $$c
                      }
                    },
                    function ($event) {
                      return _vm.realChange()
                    },
                  ],
                },
              })
            : _vm.localType === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localValue,
                    expression: "localValue",
                  },
                ],
                ref: "input",
                class: {
                  "input-itself": true,
                  "input-itself--disabled": _vm.isDisabled,
                  "input-itself--hidden": _vm.localType === "file",
                  "input-itself--text-align-center": _vm.textAlignCenter,
                  "input-itself--additional-left-padding": _vm.additionalIcon,
                  "input-itself--additional-right-padding": _vm.isDisabled,
                  "input-itself--with-suffix":
                    !_vm.isInputOnFocus && (_vm.suffix || _vm.$slots.suffix),
                  "input-itself--mid": _vm.variant === "mid",
                  "input-itself--mid-code": _vm.variant === "mid-code",
                  "input-itself--min-code": _vm.variant === "min-code",
                  "input-main-component__input-itself": true,
                  "input-main-component__input-itself--smaller-width":
                    _vm.isClearButtonVisible,
                },
                attrs: {
                  disabled: _vm.isDisabled,
                  placeholder:
                    _vm.localType === "fileOrUrl"
                      ? "File URL"
                      : _vm.placeholder,
                  autocomplete: _vm.autocompleteOff ? "none" : "on",
                  tabindex: _vm.tabindex ? +_vm.tabindex : 1,
                  min: _vm.min || Number(_vm.min) === 0 ? _vm.min : "",
                  type: "radio",
                },
                domProps: { checked: _vm._q(_vm.localValue, null) },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("keyup-enter", $event)
                  },
                  focus: function ($event) {
                    _vm.isInputOnFocus = true
                    _vm.$emit("focus")
                  },
                  blur: function ($event) {
                    _vm.isInputOnFocus = false
                    _vm.$emit("blur")
                  },
                  change: [
                    function ($event) {
                      _vm.localValue = null
                    },
                    function ($event) {
                      return _vm.realChange()
                    },
                  ],
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localValue,
                    expression: "localValue",
                  },
                ],
                ref: "input",
                class: {
                  "input-itself": true,
                  "input-itself--disabled": _vm.isDisabled,
                  "input-itself--hidden": _vm.localType === "file",
                  "input-itself--text-align-center": _vm.textAlignCenter,
                  "input-itself--additional-left-padding": _vm.additionalIcon,
                  "input-itself--additional-right-padding": _vm.isDisabled,
                  "input-itself--with-suffix":
                    !_vm.isInputOnFocus && (_vm.suffix || _vm.$slots.suffix),
                  "input-itself--mid": _vm.variant === "mid",
                  "input-itself--mid-code": _vm.variant === "mid-code",
                  "input-itself--min-code": _vm.variant === "min-code",
                  "input-main-component__input-itself": true,
                  "input-main-component__input-itself--smaller-width":
                    _vm.isClearButtonVisible,
                },
                attrs: {
                  disabled: _vm.isDisabled,
                  placeholder:
                    _vm.localType === "fileOrUrl"
                      ? "File URL"
                      : _vm.placeholder,
                  autocomplete: _vm.autocompleteOff ? "none" : "on",
                  tabindex: _vm.tabindex ? +_vm.tabindex : 1,
                  min: _vm.min || Number(_vm.min) === 0 ? _vm.min : "",
                  type: _vm.localType,
                },
                domProps: { value: _vm.localValue },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("keyup-enter", $event)
                  },
                  focus: function ($event) {
                    _vm.isInputOnFocus = true
                    _vm.$emit("focus")
                  },
                  blur: function ($event) {
                    _vm.isInputOnFocus = false
                    _vm.$emit("blur")
                  },
                  change: function ($event) {
                    return _vm.realChange()
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.localValue = $event.target.value
                  },
                },
              }),
          _vm._v(" "),
          _vm.suffix || _vm.$slots.suffix
            ? _c(
                "span",
                { staticClass: "input-main-component__suffix" },
                [
                  _vm._t("suffix", [
                    _vm._v("\n        " + _vm._s(_vm.suffix) + "\n      "),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isExpandButtonVisible
        ? _c("span", {
            class: {
              "input-main-component__arrow-button": true,
              "input-main-component__arrow-button--flip": !_vm._localIsExpanded,
            },
            domProps: { innerHTML: _vm._s(_vm.svg.arrowUpIcon) },
            on: { click: _vm.toggleExpand },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isClearButtonVisible
        ? _c("span", {
            staticClass: "input-main-component__cross-button",
            domProps: { innerHTML: _vm._s(_vm.svg.crossIcon) },
            on: { click: _vm.resetInput },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasText && _vm.localType === "file" && !_vm.noClearButton
        ? _c("span", {
            staticClass: "input-main-component__trash-can-button",
            domProps: { innerHTML: _vm._s(_vm.svg.trashCanOutlinedIcon) },
            on: { click: _vm.resetInput },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isDisabled && _vm.disabledTooltip
        ? _c(
            "fs-tooltip",
            {
              staticClass: "input-main-component__lock-icon-tooltip-wrapper",
              attrs: { text: _vm.disabledTooltip },
            },
            [
              _c("span", {
                class: {
                  "input-main-component__lock-icon": true,
                  "input-main-component__lock-icon--with-tooltip": true,
                },
                domProps: { innerHTML: _vm._s(_vm.svg.lockIcon) },
              }),
            ]
          )
        : _vm.isDisabled
        ? _c("span", {
            staticClass: "input-main-component__lock-icon",
            domProps: { innerHTML: _vm._s(_vm.svg.lockIcon) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }