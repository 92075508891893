var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "capabilities-popper" },
    [
      _c("div", { staticClass: "capabilities-popper__main-title" }, [
        _vm._v("Capabilities"),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "capabilities-popper__sections-list" },
        _vm._l(_vm.capabilitiesSections, function (section, index1) {
          return _c(
            "li",
            {
              key: section.title,
              staticClass:
                "capabilities-list-section capabilities-popper__single-section",
            },
            [
              _c("div", { staticClass: "capabilities-list-section__title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(++index1 + ". " + section.title) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "capabilities-list-section__list" },
                _vm._l(section.capabilitiesList, function (item, index2) {
                  return _c(
                    "li",
                    {
                      key: item,
                      staticClass: "capabilities-list-section__list-item",
                    },
                    [
                      _c("checkbox-custom", {
                        staticClass:
                          "capabilities-list-section__checkbox-component",
                        attrs: {
                          name: "all",
                          checked: false,
                          label: item,
                          plm: "",
                          large: "",
                          standalone: "",
                          id: item + index2,
                        },
                        on: { check: function () {}, uncheck: function () {} },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "capabilities-popper__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }