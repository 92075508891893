import headQuestionIndexFingers from '@icons/people/head-question-index-fingers.svg';
import manClock from '@icons/people/man-clock.svg';
import peopleWithFlag from '@icons/people/people-with-flag.svg';
import twoPeopleBlock from '@icons/people/two-people-block.svg';

export default {
  headQuestionIndexFingers,
  manClock,
  peopleWithFlag,
  twoPeopleBlock,
};
