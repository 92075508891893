<template>
  <div class="checkbox-with-icon">
    <label class="checkbox-with-icon__label-wrapper">
      <input
        class="checkbox-with-icon__checkbox-input"
        v-model="localIsChecked"
        :value="_value"
        v-bind="$attrs"
        v-on="$listeners"
        ref="input"
        @change="emitCheckboxState"
        type="checkbox"
      />

      <span class="checkbox-with-icon__icon-container">
        <span class="checkbox-with-icon__icon" v-html="icon || svg.icoSelectedIcon"> </span>
      </span>

      <span class="checkbox-with-icon__text-label">
        {{ textLabel }}
      </span>
    </label>
  </div>
</template>

<script>
// Icons
// SVG
import icoSelectedIcon from '@icons/other/ico-selected.svg';

export default {
  name: 'custom-checkbox-with-icon',

  props: {
    isChecked: {},
    icon: String,
    textLabel: String,
  },

  data() {
    return {
      localIsChecked: false,

      icons: {},
      svg: { icoSelectedIcon },
    };
  },

  mounted() {
    if (this.localIndeterminate) this.$refs.input.indeterminate = true;
  },

  updated() {},

  computed: {
    _value() {},
  },

  methods: {
    emitCheckboxState() {
      if (this.$refs.input.checked) this.$emit('check');
      else this.$emit('uncheck');
    },
  },

  watch: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

// ============================================================================
// ============================================================================
// Checkbox with icon
.checkbox-with-icon {
  user-select: none;

  &__label-wrapper {
    height: math.div(7rem, 1.6);
    min-height: math.div(7rem, 1.6);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  &__checkbox-input {
    position: absolute;
    opacity: 0;
  }

  &__icon-container {
    width: math.div(4.7rem, 1.6);
    min-width: math.div(4.7rem, 1.6);
    height: math.div(4.7rem, 1.6);
    min-height: math.div(4.7rem, 1.6);
    margin-bottom: math.div(0.7rem, 1.6);
    box-sizing: border-box;
    border: 2px rgba(53, 73, 94, 1) solid;
    border-radius: math.div(1.2rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transform: translateY(0);
    background: #fff;
    opacity: 0.5;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    transition: transform 0.15s ease, background 0.15s ease, opacity 0.15s ease, box-shadow 0.15s ease;
  }

  &:hover {
    .checkbox-with-icon__icon-container {
      transform: translateY(-1px);
      opacity: 1;
      box-shadow: 0 math.div(0.4rem, 1.6) math.div(0.7rem, 1.6) rgba(0, 0, 0, 0.15);
    }
  }

  &__checkbox-input:checked + &__icon-container {
    background: rgba(53, 73, 94, 1);
    opacity: 1;
  }

  &__icon {
    width: 70%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    ::v-deep svg {
      fill: rgba(53, 73, 94, 1);
      transition: fill 0.15s ease;
    }
  }

  &__checkbox-input:checked + &__icon-container &__icon {
    ::v-deep svg {
      fill: #fff;
    }
  }

  &__text-label {
    font-size: math.div(1.1rem, 1.6);
    line-height: math.div(1.5rem, 1.6);
    text-transform: uppercase;
    text-align: center;
    color: rgb(53, 73, 94);
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }

  &:hover {
    .checkbox-with-icon__text-label {
      opacity: 1;
    }
  }

  &__checkbox-input:checked + &__icon-container + &__text-label {
    opacity: 1;
  }
}
</style>
