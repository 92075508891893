var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "speciality-edit-form" },
    [
      _c("div", { staticClass: "speciality-edit-form__main-title" }, [
        _vm._v("Specialities"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-itself speciality-edit-form__form-itself" },
        _vm._l(_vm.specialitiesList, function (speciality) {
          return _c("checkbox-custom-button", {
            key: speciality,
            staticClass: "form-itself__single-speciality",
            attrs: {
              "additional-icon":
                _vm.icons.specialities[speciality.toLowerCase()],
              "text-label": speciality,
              size: "small",
              "clean-design": "",
            },
            on: { check: function () {}, uncheck: function () {} },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "speciality-edit-form__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }