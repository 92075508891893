<template>
  <!-- Contact info edit -->
  <div class="contact-info-edit">
    <div class="contact-info-edit__title">Contact info</div>

    <!-- Form itself -->
    <div class="form-itself contact-info-edit__form-itself">
      <!-- __section -->
      <div class="form-itself__section">
        <div class="form-subtitle form-itself__form-subtitle">Website</div>

        <div class="form-itself__section-content">
          <div class="form-itself__inputs-line-container">
            <input-custom class="form-itself__input" placeholder="Website address" v-model="websiteInput" />
          </div>
        </div>
      </div>
      <!-- / __section -->

      <!-- __section -->
      <div class="form-itself__section">
        <div class="form-subtitle form-itself__form-subtitle">E-mail</div>

        <div class="form-itself__section-content">
          <div
            v-for="(inputValue, index) in emailsInputs"
            :key="index"
            :class="{
              'form-itself__inputs-line-container': 1,
              'form-itself__inputs-line-container--small-bottom-margin': index + 1 === emailsInputs.length,
            }"
          >
            <input-custom class="form-itself__input" placeholder="Email address" v-model="emailsInputs[index]" />

            <div class="remove-icon-but remove-icon-but--smaller form-itself__remove-line-but">
              <div class="remove-icon-but__in-wrap" v-html="svg.crossClearIcon"></div>
            </div>
          </div>

          <div class="add-section-button form-itself__add-section-button">
            <div class="add-section-button__icon" v-html="icons.interfaceElements.addCircle"></div>
            <div class="add-section-button__text">Add E-mail</div>
          </div>
        </div>
      </div>
      <!-- / __section -->

      <!-- __section -->
      <div class="form-itself__section">
        <div class="form-subtitle form-itself__form-subtitle">Phone</div>

        <div class="form-itself__section-content">
          <div
            v-for="(inputValue, index) in phonesInputsGroups"
            :key="index"
            :class="{
              'form-itself__inputs-line-container': 1,
              'form-itself__inputs-line-container--small-bottom-margin': index + 1 === phonesInputsGroups.length,
            }"
          >
            <input-custom class="form-itself__input" placeholder="Office" v-model="phonesInputsGroups[index].type" />

            <select-with-input
              class="form-itself__select-input"
              select-placeholder="Code1"
              :select-width="80"
              :select-options="['+38', '+390', '+400', '+410']"
              :input-value="phonesInputsGroups[index].number"
              input-placeholder="Number"
              @input="() => {}"
            />

            <div class="remove-icon-but remove-icon-but--smaller form-itself__remove-line-but">
              <div class="remove-icon-but__in-wrap" v-html="svg.crossClearIcon"></div>
            </div>
          </div>

          <div class="add-section-button form-itself__add-section-button">
            <div class="add-section-button__icon" v-html="icons.interfaceElements.addCircle"></div>
            <div class="add-section-button__text">Add Phone Number</div>
          </div>
        </div>
      </div>
      <!-- / __section -->

      <!-- __section -->
      <div class="form-itself__section">
        <div class="form-subtitle form-subtitle--no-paddings form-itself__form-subtitle">
          <div class="form-subtitle__left-side">Opening Hours</div>

          <div class="form-subtitle__right-side">
            <select-custom
              class="form-subtitle__timezone-dropdown"
              placeholder="Timezone"
              :options="['GMT+2:00', 'GMT+3:00', 'GMT+4:00', 'GMT+5:00', 'GMT+6:00']"
              @input="() => {}"
              v-model="openingHoursTimezone"
              is-transparent-bg
            />
          </div>
        </div>

        <div
          v-for="(daysSpan, index) in openingHoursInputsGroups"
          :key="index"
          class="form-itself__section-content form-itself__section-content--with-border"
        >
          <!-- __inputs-line-container -->
          <div
            class="form-itself__inputs-line-container form-itself__inputs-line-container--narrow form-itself__inputs-line-container--big-bottom-margin"
          >
            <select-custom
              class="form-itself__select"
              placeholder="Weekday"
              :options="['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
              @input="() => {}"
              v-model="openingHoursInputsGroups[index].weekDayFrom"
            />
            <div class="form-itself__dash">-</div>
            <select-custom
              class="form-itself__select"
              placeholder="Weekday"
              :options="['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
              @input="() => {}"
              v-model="openingHoursInputsGroups[index].weekDayTo"
            />
          </div>
          <!-- / __inputs-line-container -->

          <!-- __inputs-line-container -->
          <div
            v-for="(timeSpan, idx) in daysSpan.timeSpans"
            :key="idx"
            :class="{
              'form-itself__inputs-line-container': 1,
              'form-itself__inputs-line-container--narrower': 1,
              'form-itself__inputs-line-container--medium-bottom-margin': idx + 1 === daysSpan.timeSpans.length,
            }"
          >
            <input-custom
              class="form-itself__input"
              placeholder="From"
              v-model="openingHoursInputsGroups[index].timeSpans[idx].from"
            />
            <div class="form-itself__dash">-</div>
            <input-custom
              class="form-itself__input"
              placeholder="To"
              v-model="openingHoursInputsGroups[index].timeSpans[idx].to"
            />

            <div class="remove-icon-but remove-icon-but--smaller form-itself__remove-line-but">
              <div class="remove-icon-but__in-wrap" v-html="svg.crossClearIcon"></div>
            </div>
          </div>
          <!-- / __inputs-line-container -->

          <div class="add-section-button form-itself__add-section-button form-itself__add-section-button--centered">
            <div class="add-section-button__icon" v-html="icons.interfaceElements.addCircle"></div>
            <div class="add-section-button__text">Add Time Span</div>
          </div>

          <div class="remove-icon-but form-itself__remove-section-but">
            <div class="remove-icon-but__in-wrap" v-html="icons.interfaceElements.trashCanIcon"></div>
          </div>
        </div>
      </div>
      <!-- / __section -->

      <div
        class="add-section-button add-section-button--big form-itself__add-section-button form-itself__add-section-button--centered"
      >
        <div
          class="add-section-button__icon add-section-button__icon--big"
          v-html="icons.interfaceElements.addCircle"
        ></div>
        <div class="add-section-button__text add-section-button__text--big">Add Opening Time</div>
      </div>
    </div>
    <!-- / Form itself -->

    <cancel-n-action-btn-pair
      class="code-of-conduct-form__manage-buttons-panel"
      @cancel="() => {}"
      @action="() => {}"
    />
  </div>
  <!-- / Contact info edit -->
</template>

<script>
// Components
import InputCustom from '@components/form-elements/input-custom.vue';
import SelectWithInput from '@components/form-elements/selects/select-with-input.vue';
import SelectCustom from '@components/form-elements/selects/select-custom.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
// SVG
import crossClearIcon from '@icons/other/cross-clear.svg';

export default {
  name: 'contact-info-edit',

  props: {},

  components: { InputCustom, SelectWithInput, SelectCustom, CancelNActionBtnPair },

  data() {
    return {
      isSaveInProgress: false,

      // Inputs
      websiteInput: '',
      emailsInputs: [''],
      phonesInputsGroups: [{ type: '', numberPrefix: '', number: '' }],
      openingHoursTimezone: '',
      openingHoursInputsGroups: [
        {
          weekDayFrom: '',
          weekDayTo: '',
          timeSpans: [
            { from: '8.00 AM', to: '12.00 AM' },
            { from: '1.00 PM', to: '5.30 PM' },
          ],
        },
      ],

      icons: {
        interfaceElements: interfaceElementsIcons,
      },

      svg: { crossClearIcon },
    };
  },

  computed: {},

  methods: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

// ============================================================================
// ============================================================================
// Add section button
.add-section-button {
  display: inline-flex;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  user-select: none;
  cursor: pointer;

  &::before {
    content: '';
    width: calc(100% + #{math.div(1.3rem, 1.6)} + #{math.div(1.3rem, 1.6)});
    height: 100%;
    position: absolute;
    inset: 0 auto auto math.div(-1.3rem, 1.6);
    z-index: -1;
    background: #fff;
  }

  &::after {
    content: '';
    width: calc(100% + #{math.div(2rem, 1.6)});
    height: calc(100% + #{math.div(2rem, 1.6)});
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &__icon {
    width: math.div(1.7rem, 1.6);
    height: math.div(1.7rem, 1.6);
    margin-right: math.div(1.2rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    transition: transform 0.1s ease;

    ::v-deep svg {
      fill: rgb(53, 73, 94);
    }

    &--big {
      width: math.div(2.4rem, 1.6);
      height: math.div(2.4rem, 1.6);
    }
  }

  &:hover &__icon {
    transform: scale(1.15);
  }

  &__text {
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(1.4rem, 1.6);
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      inset: auto auto -1px 0;
      font-size: 0;
      line-height: 0;
      background: rgba(53, 73, 94, 0);
      transition: background-color 0.1s ease;
    }

    &--big {
      font-size: math.div(1.6rem, 1.6);
      line-height: math.div(1.6rem, 1.6);
    }
  }

  &:hover &__text {
    &::before {
      background: rgba(53, 73, 94, 0.2);
    }
  }
}

// ============================================================================
// ============================================================================
// Remove icon but
.remove-icon-but {
  width: math.div(1.7rem, 1.6);
  min-width: math.div(1.7rem, 1.6);
  height: math.div(1.7rem, 1.6);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  cursor: pointer;
  user-select: none;

  &--smaller {
    width: math.div(1.3rem, 1.6);
    min-width: math.div(1.3rem, 1.6);
    height: math.div(1.3rem, 1.6);
  }

  &::before {
    content: '';
    width: calc(100% + #{math.div(1.8rem, 1.6)});
    height: 100%;
    position: absolute;
    inset: 0 auto auto math.div(-0.9rem, 1.6);
    z-index: -1;
    background: #fff;
  }

  &::after {
    content: '';
    width: calc(100% + #{math.div(2rem, 1.6)});
    height: calc(100% + #{math.div(2rem, 1.6)});
    border-radius: math.div(10rem, 1.6);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.7);
    background: rgba(255, 0, 0, 0.15);
    opacity: 0;
    transition: transform 0.12s ease, opacity 0.12s ease;
  }

  &:hover::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }

  &__in-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      width: calc(100% + #{math.div(2rem, 1.6)});
      height: calc(100% + #{math.div(2rem, 1.6)});
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }
  }

  ::v-deep svg {
    width: 100%;
    height: 100%;
    fill: rgb(53, 73, 94);
    transition: fill 0.12s ease;
  }

  &:hover ::v-deep svg {
    fill: rgba(240, 70, 70, 1);
  }
}

// ============================================================================
// ============================================================================
// Form itself
.form-itself {
  display: flex;
  flex-direction: column;

  &__section {
    margin-bottom: math.div(1.7rem, 1.6);
  }

  &__form-subtitle {
    margin-bottom: math.div(0.7rem, 1.6);
  }
  .form-subtitle {
    padding: 0 math.div(2.4rem, 1.6);
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
    text-transform: uppercase;

    &--no-paddings {
      padding: 0;
    }

    &__left-side {
    }

    &__right-side {
    }

    &__timezone-dropdown {
      width: math.div(12rem, 1.6);
      position: relative;
      right: math.div(-1.2rem, 1.6);
      text-transform: none;
    }
  }

  &__section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    background: #fff;

    &--with-border {
      &::before {
        content: '';
        width: calc(100% + #{math.div(1.8rem, 1.6)} + #{math.div(1.8rem, 1.6)});
        height: calc(100% - #{math.div(1.8rem, 1.6)} - #{math.div(2.4rem, 1.6)});
        position: absolute;
        inset: math.div(1.8rem, 1.6) auto 0 math.div(-1.8rem, 1.6);
        z-index: -2;
        border: 1px rgba(53, 73, 94, 0.15) solid;
        border-radius: math.div(1rem, 1.6);
      }
    }
  }

  &__inputs-line-container {
    width: 100%;
    margin: 0 0 math.div(1rem, 1.6);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    background: #fff;

    &::before {
      content: '';
      width: calc(100% + #{math.div(1.3rem, 1.6)} + #{math.div(1.3rem, 1.6)});
      height: 100%;
      position: absolute;
      inset: 0 0 0 math.div(-1.3rem, 1.6);
      z-index: -1;
      background: #fff;
    }

    ::v-deep > *:last-child {
      margin-right: 0;
    }

    &--small-bottom-margin {
      margin-bottom: math.div(1.5rem, 1.6);
    }

    &--medium-bottom-margin {
      margin-bottom: math.div(2rem, 1.6);
    }

    &--big-bottom-margin {
      margin-bottom: math.div(2.9rem, 1.6);
    }

    &--narrow {
      width: calc(100% - #{math.div(1.3rem, 1.6)} - #{math.div(1.3rem, 1.6)});
    }

    &--narrower {
      width: calc(100% - #{math.div(3rem, 1.6)} - #{math.div(3rem, 1.6)});
    }
  }

  &__dash {
    margin-right: math.div(1rem, 1.6);
  }

  &__input {
    flex-grow: 1;
    margin: 0 math.div(1rem, 1.6) 0 0;
  }

  &__select {
    margin: 0 math.div(1rem, 1.6) 0 0;
  }

  &__select-input {
    flex-grow: 1;
    margin: 0 math.div(1rem, 1.6) 0 0;
  }

  &__remove-line-but {
    margin: 0 math.div(1.5rem, 1.6) 0 math.div(1rem, 1.6);
  }

  &__add-section-button {
    margin: 0 math.div(2rem, 1.6) math.div(1.5rem, 1.6);
    align-self: flex-start;

    &--centered {
      align-self: center;
    }
  }

  &__remove-section-but {
    margin: 0 math.div(2rem, 1.6) math.div(1.5rem, 1.6);
  }
}

// ============================================================================
// ============================================================================
// Contact info edit
.contact-info-edit {
  width: math.div(44rem, 1.6);
  padding: math.div(2.5rem, 1.6) math.div(4.8rem, 1.6) math.div(3.5rem, 1.6);
  box-sizing: border-box;

  &__title {
    margin-bottom: math.div(2.8rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__form-itself {
    margin-bottom: math.div(2rem, 1.6);
  }

  &__manage-buttons-panel {
  }
}
</style>
