<template>
  <div class="location-edit-form">
    <div class="location-edit-form__left-side">
      <div class="location-edit-form__main-title">Location</div>

      <!-- Form itself -->
      <div class="form-itself location-edit-form__form-itself">
        <div class="form-itself__separate-section">
          <div class="form-itself__title">Address</div>
          <input-custom class="form-itself__form-element" placeholder="Address" v-model="address" />
        </div>

        <div class="form-itself__separate-section">
          <div class="form-itself__title">City</div>
          <input-custom class="form-itself__form-element" placeholder="City" v-model="city" />
        </div>

        <div class="form-itself__separate-section">
          <div class="form-itself__title">Country</div>
          <input-country-with-hints
            class="form-itself__form-element"
            placeholder="Country name"
            v-model="country"
            autocomplete-off
          />
        </div>
      </div>
      <!-- / Form itself -->

      <cancel-n-action-btn-pair
        class="location-edit-form__manage-buttons-panel"
        @cancel="() => {}"
        @action="() => {}"
      />
    </div>

    <div class="location-edit-form__right-side">
      <!-- Map box -->
      <div class="map-box location-edit-form__map-box">
        <div class="map-box__usage-description">Drag pin to change your location</div>

        <div class="map-box__map-itself">
          <div class="map-box__map-itself-in-wrap"></div>
        </div>
      </div>
      <!-- / Map box -->
    </div>
  </div>
</template>

<script>
// Components
import InputCustom from '@components/form-elements/input-custom.vue';
import InputCountryWithHints from '@components/form-elements/selects/input-country-with-hints.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';

export default {
  name: 'location-edit',

  components: {
    CancelNActionBtnPair,
    InputCustom,
    InputCountryWithHints,
  },

  data() {
    return {
      isSaveInProgress: false,

      address: '',
      city: '',
      country: '',

      icons: {
        interfaceElements: interfaceElementsIcons,
      },
    };
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// ============================================================================
// Map box
.map-box {
  width: 100%;

  &__usage-description {
    margin-bottom: math.div(1rem, 1.6);
    font-size: math.div(1.4rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
    text-align: center;
  }

  &__map-itself {
    width: 100%;
    padding-top: 100%;
    border-radius: math.div(1rem, 1.6);
    position: relative;
    overflow: hidden;
  }

  &__map-itself-in-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    background: #eee;
  }
}

// ============================================================================
// ============================================================================
// Form itself
.form-itself {
  &__separate-section {
    margin-bottom: math.div(2.6rem, 1.6);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    padding-left: math.div(2.4rem, 1.6);
    margin-bottom: math.div(0.7rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
    text-transform: uppercase;
  }

  &__form-element {
  }
}

// ============================================================================
// ============================================================================
// Location edit form
.location-edit-form {
  width: math.div(80rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(5rem, 1.6);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  &__left-side {
    width: calc(52% - #{math.div(2.4rem, 1.6)});
  }

  &__main-title {
    margin-bottom: math.div(2.7rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__form-itself {
    margin-bottom: math.div(4.7rem, 1.6);
    position: relative;
    z-index: 1;
  }

  &__manage-buttons-panel {
  }

  &__right-side {
    width: calc(48% - #{math.div(2.4rem, 1.6)});
  }

  &__map-box {
  }
}
</style>
