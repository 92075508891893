<template>
  <div>
    <div
      v-if="false"
      style="
        width: 100vw;
        height: 100%;
        padding: 100px 0;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
      "
    >
      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <membership-companies-edit-modal
          :entities="
            JSON.parse(
              '[{&quot;id&quot;:3597,&quot;name&quot;:&quot;Hjfjfja Inc&quot;,&quot;country&quot;:&quot;Antarctica&quot;},{&quot;id&quot;:254,&quot;name&quot;:&quot;ADC LONDON&quot;,&quot;country&quot;:&quot;United Kingdom&quot;},{&quot;id&quot;:3604,&quot;name&quot;:&quot;f&quot;,&quot;country&quot;:&quot;Antigua and Barbuda&quot;}]'
            )
          "
          :save-func="() => {}"
          :product-id="17"
        />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <brands-edit-modal
          :entities="
            JSON.parse(
              '[{&quot;id&quot;:3597,&quot;name&quot;:&quot;Hjfjfja Inc&quot;,&quot;country&quot;:&quot;Antarctica&quot;},{&quot;id&quot;:254,&quot;name&quot;:&quot;ADC LONDON&quot;,&quot;country&quot;:&quot;United Kingdom&quot;},{&quot;id&quot;:3604,&quot;name&quot;:&quot;f&quot;,&quot;country&quot;:&quot;Antigua and Barbuda&quot;}]'
            )
          "
          :save-func="() => {}"
          :product-id="17"
        />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <suppliers-edit-modal
          :entities="
            JSON.parse(
              '[{&quot;id&quot;:3597,&quot;name&quot;:&quot;Hjfjfja Inc&quot;,&quot;country&quot;:&quot;Antarctica&quot;},{&quot;id&quot;:254,&quot;name&quot;:&quot;ADC LONDON&quot;,&quot;country&quot;:&quot;United Kingdom&quot;},{&quot;id&quot;:3604,&quot;name&quot;:&quot;f&quot;,&quot;country&quot;:&quot;Antigua and Barbuda&quot;}]'
            )
          "
          :save-func="() => {}"
          :product-id="17"
        />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <add-production-unit-form />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <location-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <description-edit :save-func="() => {}" value="" />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <single-text-input-form
          title="Supplier Name"
          save-but-name="Save"
          placeholder="Enter Supplier Name"
          @close="() => {}"
          @save="() => {}"
          value=""
          :save-in-progress="false"
        />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <general-information-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <certifications-list-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <size-range-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <specialities-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <gender-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <contact-info-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <code-of-conduct-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <type-n-processes-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <social-media-form-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <new-supplier-form-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <contact-persons-list-edit />
      </div>

      <div style="margin: 30px; border-radius: 12px; background: white; box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15)">
        <capabilities-list-edit />
      </div>

      <div style="width: 100%; height: 100px"></div>
    </div>

    <page-block></page-block>
    <div class="page-container">
      <header
        v-if="supplier.coverPhoto"
        class="supplier-header"
        :style="{ backgroundImage: `url('${imagePath('images/' + supplier.coverPhoto, 1920, 500)}')` }"
      >
        <div class="supplier-header-logos">
          <span class="supplier-header-logos__logo" v-html="svg.fsMember"> </span>
          <span
            v-if="supplier.satra"
            class="supplier-header-logos__logo supplier-header-logos__logo--small"
            v-html="svg.satraMember"
          >
          </span>
        </div>
      </header>
      <div class="column-container">
        <div class="left-column">
          <section class="floating-section">
            <div class="map">
              <supplier-map class="map__map" :supplier="supplier" />
            </div>
            <!-- TODO: FIXME! -->
            <img v-if="logoPath" class="logo" :src="`${$blobUrl}/images/${logoPath}`" alt="logo" />
            <img v-if="logoPath" class="logo__hidden" :src="`${$blobUrl}/images/${logoPath}`" alt="logo" />
            <div class="hq-container">
              <div class="hq-country">
                <img
                  class="hq-country__flag"
                  :src="$store.countries.getFlagPathFromName(supplier.country)"
                  alt="flag"
                />
                <span class="hq-country__country">
                  {{ supplier.country }}
                </span>
              </div>
              <div class="hq-location">
                <div class="hq-location__pin" v-html="svg.pin"></div>
                <span class="hq-location__address">
                  {{ supplier.fullAddress }}
                </span>
              </div>
            </div>

            <!-- Production Units -->
            <div v-if="false" class="production-units floating-section__group">
              <div class="production-units__header">Production Units</div>

              <div class="production-units__units-list">
                <!-- Single production unit -->
                <div v-for="unit in [0, 1, 2]" :key="unit" class="production-single-unit production-units__single-unit">
                  <div class="production-single-unit__logo-wrap">
                    <div class="production-single-unit__logo" v-html="icons.countriesFlags.portugal"></div>
                    <div class="production-single-unit__verification-icon" v-html="icons.miscellaneous.circleA"></div>
                  </div>
                  <div class="production-single-unit__city">Porto,</div>
                  <div class="production-single-unit__country">Portugal</div>
                </div>
              </div>
            </div>

            <div class="floating-section__group">
              <div class="contact-info">
                <!-- Website -->
                <div v-if="supplier.web" class="contact-info__icon" v-html="icons.contact.website"></div>
                <div v-if="supplier.web" class="contact-info__links">
                  <a
                    class="contact-info__link link link--dark"
                    :href="urlAddProtocol(supplier.web)"
                    target="_blank"
                    @click="$ga.event('contacts', 'homepage click')"
                  >
                    {{ urlHideProtocol(supplier.web) }}
                  </a>
                </div>

                <!-- Email -->
                <div v-if="supplier.email" class="contact-info__icon" v-html="icons.contact.email"></div>
                <div v-if="supplier.email" class="contact-info__links">
                  <!-- TODO: Add support for additional e-mails -->
                  <a
                    class="contact-info__link link link--dark"
                    :href="`mailto:${supplier.email}`"
                    @click="$ga.event('contacts', 'email click')"
                  >
                    {{ supplier.email }}
                  </a>
                </div>

                <!-- Phone -->
                <div v-if="supplier.phone" class="contact-info__icon" v-html="icons.contact.phone"></div>
                <div v-if="supplier.phone" class="contact-info__links contact-info__links--with-labels">
                  <!-- TODO: Add support for additional phone numbers -->
                  <span class="contact-info__link-label"> Office </span>
                  <a
                    class="contact-info__link link link--dark"
                    :href="`tel:${supplier.phone}`"
                    @click="$ga.event('contacts', 'phone click')"
                  >
                    {{ supplier.phone }}
                  </a>
                </div>

                <!-- WhatsApp -->
                <div v-if="supplier.whatsApp" class="contact-info__icon" v-html="icons.contact.whatsApp"></div>
                <div v-if="supplier.whatsApp" class="contact-info__links">
                  <a
                    class="contact-info__link link link--dark"
                    :href="`https://wa.me/${supplier.whatsApp.replace(/[^0-9]/, '')}`"
                    @click="$ga.event('contacts', 'WhatsApp click')"
                    >{{ supplier.whatsApp }}
                  </a>
                </div>
                <div v-if="supplier.weChat" class="contact-info__icon" v-html="icons.contact.weChat"></div>

                <!-- WeChat -->
                <div v-if="supplier.weChat" class="contact-info__links">
                  <a
                    class="contact-info__link link link--dark"
                    :href="`weixin://dl/chat?${supplier.weChat}`"
                    @click="$ga.event('contacts', 'WeChat click')"
                  >
                    {{ supplier.weChat }}
                  </a>
                </div>

                <!-- Skype -->
                <div v-if="supplier.skype" class="contact-info__icon" v-html="icons.contact.skype"></div>

                <div v-if="supplier.skype" class="contact-info__links">
                  <a
                    class="contact-info__link link link--dark"
                    :href="`skype:${supplier.skype}?call`"
                    @click="$ga.event('contacts', 'Skype click')"
                  >
                    {{ supplier.skype }}
                  </a>
                </div>

                <!-- Work schedule -->
                <div
                  v-if="supplier.openingHours.length"
                  :class="{
                    'contact-info__icon': 1,
                    'contact-info__icon--clock': 1,
                    'contact-info__icon--vertically-aligned': supplier.openingHours.length === 1,
                  }"
                  v-html="icons.interfaceElements.clock"
                ></div>

                <div class="contact-info__links">
                  <div
                    v-for="schedule in supplier.openingHours"
                    :key="schedule.dayFrom + schedule.dayTo"
                    class="contact-info__working-time-span"
                  >
                    <span class="contact-info__subtitle">
                      {{ schedule.dayFrom + ' - ' + schedule.dayTo }}
                    </span>
                    <span
                      class="contact-info__link-label"
                      v-for="timeSpan in schedule.timeSpans"
                      :key="timeSpan.from + timeSpan.to"
                    >
                      {{
                        formatTimeSpan(timeSpan.from, 'Africa/Luanda' || supplier.ianaTimezone) +
                        ' - ' +
                        formatTimeSpan(timeSpan.to, 'Africa/Luanda' || supplier.ianaTimezone)
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- IE11 grid fix -->
              <component v-if="this.ie" :is="'style'">
                .supplierProfile .contact-info { display: -ms-grid; -ms-grid-columns: 2rem 1rem minmax(0px, 16.5rem);
                -ms-grid-rows:
                <template v-for="row in Number(!!supplier.web) + Number(!!supplier.email) + Number(!!supplier.phone)">
                  {{ (row > 1 ? ' 1.625rem' : '') + ' auto' }} </template
                >; }
                <!-- Body -->
                <template v-for="row in Number(!!supplier.web) + Number(!!supplier.email) + Number(!!supplier.phone)">
                  .supplierProfile .contact-info__icon:nth-child({{ row * 2 - 1 }}) { -ms-grid-column: 1; -ms-grid-row:
                  {{ row * 2 - 1 }}; } .supplierProfile .contact-info__links:nth-child({{ row * 2 }}) { -ms-grid-column:
                  3; -ms-grid-row: {{ row * 2 - 1 }}; }
                </template>
                <!-- Links -->
                .supplierProfile .contact-info__links { display: flex; flex-direction: column; } .supplierProfile
                .contact-info__links--with-labels { display: block; }
              </component>
            </div>
            <div
              v-if="
                ['design-shoes-colection-srl', 'valuni--eurodavil', 'okabashi-brands', 't-k-shoes-factory'].includes(
                  $route.params.name
                )
              "
              class="floating-section__group"
            >
              <div class="contact-persons">
                <!-- TODO: When making this dynamic - make sure to also make below IE11 grid fix dynamic/loop properly -->
                <template v-if="$route.params.name === 'design-shoes-colection-srl'">
                  <div
                    class="contact-persons__image"
                    :style="{ backgroundImage: `url('${$blobUrl}/images/ramona.jpg')` }"
                  ></div>
                  <div class="contact-persons__info-container">
                    <span class="contact-persons__name">Ramona</span>
                    <div class="contact-persons__info-group">
                      <span class="contact-persons__info-icon" v-html="icons.contact.email"></span>
                      <a class="contact-persons__info-link link link--dark" href="mailto:designcolection@yahoo.com"
                        >designcolection@yahoo.com</a
                      >
                    </div>
                    <div class="contact-persons__info-group">
                      <span class="contact-persons__info-icon" v-html="icons.contact.phone"></span>
                      <a class="contact-persons__info-link link link--dark" href="tel:+40 786 189 080"
                        >+40 786 189 080</a
                      >
                    </div>
                  </div>
                </template>
                <template v-if="$route.params.name === 'valuni--eurodavil'">
                  <div
                    class="contact-persons__image"
                    :style="{ backgroundImage: `url('${$blobUrl}/images/david.jpg')` }"
                  ></div>
                  <div class="contact-persons__info-container">
                    <span class="contact-persons__name">David Braga</span>
                    <div class="contact-persons__info-group">
                      <span class="contact-persons__info-icon" v-html="icons.contact.email"></span>
                      <a class="contact-persons__info-link link link--dark" href="mailto:david@valuni.com"
                        >david@valuni.com</a
                      >
                    </div>
                    <div class="contact-persons__info-group">
                      <span class="contact-persons__info-icon" v-html="icons.contact.phone"> </span>
                      <a class="contact-persons__info-link link link--dark" href="tel:+351 913 470 847">
                        +351 913 470 847
                      </a>
                    </div>
                  </div>
                </template>
                <template v-if="$route.params.name === 'okabashi-brands'">
                  <div
                    class="contact-persons__image"
                    :style="{ backgroundImage: `url('${$blobUrl}/images/jenniferk.jpg')` }"
                  ></div>
                  <div class="contact-persons__info-container">
                    <span class="contact-persons__name"> Jennifer Kirksey </span>
                    <div class="contact-persons__info-group">
                      <span class="contact-persons__info-icon" v-html="icons.contact.email"> </span>
                      <a class="contact-persons__info-link link link--dark" href="mailto:jenniferk@okabashi.com"
                        >jenniferk@okabashi.com</a
                      >
                    </div>
                    <div class="contact-persons__info-group">
                      <span class="contact-persons__info-icon" v-html="icons.contact.phone"> </span>
                      <a class="contact-persons__info-link link link--dark" href="tel:+17709451330">+1 770.945.1330</a>
                    </div>
                  </div>
                </template>
                <template v-if="$route.params.name === 't-k-shoes-factory'">
                  <div
                    class="contact-persons__image"
                    :style="{ backgroundImage: `url('${$blobUrl}/images/terry_t.jpg')` }"
                  ></div>
                  <div class="contact-persons__info-container">
                    <span class="contact-persons__name"> Terry Tang </span>
                    <div class="contact-persons__info-group">
                      <span class="contact-persons__info-icon" v-html="icons.contact.email"></span>
                      <a class="contact-persons__info-link link link--dark" href="mailto:terry_t@tandkshoes.com"
                        >terry_t@tandkshoes.com</a
                      >
                    </div>
                    <div class="contact-persons__info-group">
                      <span class="contact-persons__info-icon" v-html="icons.contact.phone"></span>
                      <a class="contact-persons__info-link link link--dark" href="tel:+17709451330"
                        >+86 188-1908-3896</a
                      >
                    </div>
                  </div>
                </template>
                <!-- IE11 grid fix -->
                <component v-if="this.ie" :is="'style'">
                  .supplierProfile .contact-persons { display: -ms-grid; -ms-grid-columns: 4.625rem 1rem minmax(0px,
                  14.25rem); -ms-grid-rows:
                  <template v-for="row in 1"> {{ (row > 1 ? ' 2.625rem' : '') + ' auto' }} </template>; }
                  <!-- Body -->
                  <template v-for="row in 1">
                    .supplierProfile .contact-persons__image:nth-child({{ row * 2 - 1 }}) { -ms-grid-column: 1;
                    -ms-grid-row: {{ row * 2 - 1 }}; } .supplierProfile .contact-persons__info-container:nth-child({{
                      row * 2
                    }}) { -ms-grid-column: 3; -ms-grid-row: {{ row * 2 - 1 }}; }
                  </template>
                </component>
              </div>
            </div>
            <div class="floating-section__group">
              <div class="action-group">
                <fs-button-main
                  v-if="showClaimSupplierButton"
                  class="action-group__button"
                  @click="$ga.event('supplier buttons', 'claim supplier') && claimSupplier"
                >
                  Claim supplier
                </fs-button-main>
                <fs-button-main
                  v-if="showEditSupplierButton"
                  class="action-group__button"
                  @click="$ga.event('supplier buttons', 'edit profile')"
                  :to="{ name: 'supplierEdit', params: { id: supplier.id } }"
                >
                  Edit profile
                </fs-button-main>
                <fs-button-main
                  v-if="$store.user.isBuyer"
                  class="action-group__button"
                  @click="$ga.event('supplier buttons', 'request quotation')"
                  :to="{ name: 'quotationForm' }"
                >
                  Request Quotation
                </fs-button-main>
                <fs-button-main
                  tag="a"
                  class="action-group__button"
                  styling="outlined"
                  @click="$ga.event('supplier buttons', 'contact')"
                  :href="`mailto:${supplier.email}`"
                  >Contact</fs-button-main
                >
              </div>
              <div v-if="social" class="social-media">
                <a
                  v-if="supplier.linkedIn"
                  class="social-media__link"
                  :href="urlAddProtocol(supplier.linkedIn)"
                  @click="$ga.event('contacts', 'linkedIn click')"
                  target="_blank"
                  v-html="icons.social.linkedIn"
                ></a>
                <a
                  v-if="supplier.facebook"
                  class="social-media__link"
                  :href="urlAddProtocol(supplier.facebook)"
                  @click="$ga.event('contacts', 'facebook click')"
                  target="_blank"
                  v-html="icons.social.facebook"
                ></a>
                <a
                  v-if="supplier.instagram"
                  class="social-media__link"
                  :href="urlAddProtocol(supplier.instagram)"
                  @click="$ga.event('contacts', 'instagram click')"
                  target="_blank"
                  v-html="icons.social.instagram"
                ></a>
                <a
                  v-if="supplier.pinterest"
                  class="social-media__link"
                  :href="urlAddProtocol(supplier.pinterest)"
                  @click="$ga.event('contacts', 'pinterest click')"
                  target="_blank"
                  v-html="icons.social.pinterest"
                ></a>
                <a
                  v-if="supplier.twitter"
                  class="social-media__link"
                  :href="urlAddProtocol(supplier.twitter)"
                  @click="$ga.event('contacts', 'twitter click')"
                  target="_blank"
                  v-html="icons.social.twitter"
                ></a>
                <a
                  v-if="supplier.youTube"
                  class="social-media__link"
                  :href="urlAddProtocol(supplier.youTube)"
                  @click="$ga.event('contacts', 'youtube click')"
                  target="_blank"
                  v-html="icons.social.youtube"
                ></a>
              </div>
            </div>
          </section>
          <section class="floating-section">
            <h4 class="floating-section__group-header">Verified by FindSourcing</h4>
            <div class="verifications">
              <span
                class="verifications__checkbox"
                v-html="supplier.isEmailConfirmed ? svg.verifiedCheckmark : svg.verifiedCircle"
              ></span>
              <span class="verifications__label">E-mail address</span>
              <!-- TODO: FIXME: Make me dynamic -->
              <span class="verifications__checkbox" v-html="svg.verifiedCheckmark"></span>
              <span class="verifications__label">Profile information</span>
              <!-- TODO: FIXME: Make me dynamic -->
              <span class="verifications__checkbox" v-html="svg.verifiedCheckmark"></span>
              <span class="verifications__label">Location</span>
            </div>
            <!-- IE11 grid fix -->
            <component v-if="this.ie" :is="'style'">
              .supplierProfile .verifications { display: -ms-grid; -ms-grid-columns: 1.3125rem 1rem minmax(11.625rem,
              14.5rem); -ms-grid-rows: auto 0.625rem auto 0.625rem auto; max-width: 17rem; }
              <!-- Body -->
              <template v-for="row in 3">
                .supplierProfile .verifications__checkbox:nth-child({{ row * 2 - 1 }}) { -ms-grid-column: 1;
                -ms-grid-row: {{ row * 2 - 1 }}; } .supplierProfile .verifications__label:nth-child({{ row * 2 }}) {
                -ms-grid-column: 3; -ms-grid-row: {{ row * 2 - 1 }}; }
              </template>
            </component>
            <div class="supplier-stats">
              <span class="supplier-stats__icon" v-html="svg.responseRate"></span>
              <span class="supplier-stats__label">Response rate</span>
              <!--
                TODO: FIXME: Make me dynamic -
                First we need figure out how to handle this though.
                - Add possibility for suppliers to say that they aren't
                interested a particular request that they've been added to
                - Figure out how to deal with old requests and if there should
                be some kind of better score for being more communicative recently
                vs long time ago.
                -->
              <span class="supplier-stats__value">100%</span>
              <span class="supplier-stats__icon" v-html="svg.requestsHandled"></span>
              <span class="supplier-stats__label">Offers submitted</span>
              <span class="supplier-stats__value">{{ supplier.quotationCount }}</span>
            </div>
            <!-- IE11 grid fix -->
            <component v-if="this.ie" :is="'style'">
              .supplierProfile .supplier-stats { display: -ms-grid; -ms-grid-columns: 1.5rem 1.0rem minmax(5.125rem,
              11.75rem) 1.5rem 3.25rem; -ms-grid-rows: auto 0.375rem auto 0.625rem auto; max-width: 19rem; margin: 0
              auto; }
              <!-- Body -->
              <template v-for="row in 2">
                .supplierProfile .supplier-stats__icon:nth-child({{ row * 3 - 2 }}) { -ms-grid-column: 1; -ms-grid-row:
                {{ row * 2 - 1 }}; } .supplierProfile .supplier-stats__label:nth-child({{ row * 3 - 1 }}) {
                -ms-grid-column: 3; -ms-grid-row: {{ row * 2 - 1 }}; } .supplierProfile
                .supplier-stats__value:nth-child({{ row * 3 }}) { -ms-grid-column: 5; -ms-grid-row: {{ row * 2 - 1 }}; }
              </template>
            </component>

            <!--            <div class="floating-section__group" v-if="brandsWorkingWith.length">-->
            <!--              <h4 class="floating-section__group-header">Brands working with</h4>-->
            <!--              <div class="brands-labels-container">-->
            <!--                <span-->
            <!--                  class="brands-labels-container__brand"-->
            <!--                  v-for="brand of brandsWorkingWith"-->
            <!--                >-->
            <!--                  {{ brand }}-->
            <!--                </span>-->
            <!--              </div>-->
            <!--            </div>-->

            <sustainability-rating-section class="floating-section__group" :supplier="supplier" />
          </section>
        </div>
        <div class="right-column">
          <section class="general-section">
            <h1 class="general-section__name">{{ title }}</h1>
            <div class="general-section__pills general-section__pills--mobile">
              <fs-pill size="extra-large" :fixed="false" class="general-section__pill">Manufacturer</fs-pill>
              <fs-pill v-if="false" ize="extra-large" :fixed="false" class="general-section__pill">Agent</fs-pill>
              <fs-pill v-if="false" size="extra-large" :fixed="false" class="general-section__pill">+</fs-pill>
            </div>

            <div class="general-section__mobile-container">
              <div class="location-container">
                <div class="icon-label">
                  <img
                    class="icon-label__icon icon-label__icon--flag"
                    :src="$store.countries.getFlagPathFromName(supplier.country)"
                  />
                  <span class="icon-label__label">{{ supplier.country }}</span>
                </div>
                <div class="icon-label">
                  <span class="icon-label__icon" v-html="svg.pin" />
                  <span class="icon-label__label">{{ supplier.fullAddress }}</span>
                </div>
              </div>

              <!-- Production units container -->
              <div v-if="false" class="production-units-container">
                <!-- Production Units -->
                <div class="production-units floating-section__group">
                  <div class="production-units__header">Production Units</div>

                  <div class="production-units__units-list">
                    <!-- Single production unit -->
                    <div
                      v-for="unit in [0, 1, 2]"
                      :key="unit"
                      class="production-single-unit production-units__single-unit"
                    >
                      <div class="production-single-unit__logo-wrap">
                        <div class="production-single-unit__logo" v-html="icons.countriesFlags.portugal"></div>
                        <div
                          class="production-single-unit__verification-icon"
                          v-html="icons.miscellaneous.circleA"
                        ></div>
                      </div>
                      <div class="production-single-unit__city">Porto,</div>
                      <div class="production-single-unit__country">Portugal</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Contact Container -->
              <div class="contact-container">
                <a
                  class="general-contact-info"
                  v-if="supplier.web"
                  :href="urlAddProtocol(supplier.web)"
                  target="_blank"
                  @click="$ga.event('contacts', 'homepage click')"
                >
                  <span class="general-contact-info__icon" v-html="icons.contact.website"></span>
                  <span class="general-contact-info__label link link--dark">{{ urlHideProtocol(supplier.web) }}</span>
                </a>
                <a
                  v-if="supplier.email"
                  class="general-contact-info"
                  :href="`mailto:${supplier.email}`"
                  target="_blank"
                  @click="$ga.event('contacts', 'email click')"
                >
                  <span class="general-contact-info__icon" v-html="icons.contact.email"></span>
                  <span class="general-contact-info__label link link--dark">{{ supplier.email }}</span>
                </a>
                <a
                  v-if="supplier.phone"
                  class="general-contact-info"
                  :href="`tel:${supplier.phone}`"
                  target="_blank"
                  @click="$ga.event('contacts', 'phone click')"
                >
                  <span class="general-contact-info__icon" v-html="icons.contact.phone"></span>
                  <span class="general-contact-info__label link link--dark">Office: {{ supplier.phone }}</span>
                </a>
                <a
                  v-if="supplier.whatsApp"
                  class="general-contact-info"
                  :href="`https://wa.me/${supplier.whatsApp.replace(/[^0-9]/, '')}`"
                  target="_blank"
                  @click="$ga.event('contacts', 'WhatsApp click')"
                >
                  <span class="general-contact-info__icon" v-html="icons.contact.whatsApp"></span>
                  <span class="general-contact-info__label link link--dark">{{ supplier.whatsApp }}</span>
                </a>
                <a
                  v-if="supplier.weChat"
                  class="general-contact-info"
                  :href="`weixin://dl/chat?${supplier.weChat}`"
                  target="_blank"
                  @click="$ga.event('contacts', 'WeChat click')"
                >
                  <span class="general-contact-info__icon" v-html="icons.contact.weChat"></span>
                  <span class="general-contact-info__label link link--dark">{{ supplier.weChat }}</span>
                </a>
                <a
                  v-if="supplier.skype"
                  class="general-contact-info"
                  :href="`skype:${supplier.skype}?call`"
                  target="_blank"
                  @click="$ga.event('contacts', 'Skype click')"
                >
                  <span class="general-contact-info__icon" v-html="icons.contact.skype"></span>
                  <span class="general-contact-info__label link link--dark">{{ supplier.skype }}</span>
                </a>
                <div v-if="social" class="general-social-media">
                  <a
                    v-if="supplier.linkedIn"
                    class="general-social-media__link"
                    :href="urlAddProtocol(supplier.linkedIn)"
                    @click="$ga.event('contacts', 'linkedIn click')"
                    target="_blank"
                    v-html="icons.social.linkedIn"
                  ></a>
                  <a
                    v-if="supplier.facebook"
                    class="general-social-media__link"
                    :href="urlAddProtocol(supplier.facebook)"
                    @click="$ga.event('contacts', 'facebook click')"
                    target="_blank"
                    v-html="icons.social.facebook"
                  ></a>
                  <a
                    v-if="supplier.instagram"
                    class="general-social-media__link"
                    :href="urlAddProtocol(supplier.instagram)"
                    @click="$ga.event('contacts', 'instagram click')"
                    target="_blank"
                    v-html="icons.social.instagram"
                  ></a>
                  <a
                    v-if="supplier.pinterest"
                    class="general-social-media__link"
                    :href="urlAddProtocol(supplier.pinterest)"
                    @click="$ga.event('contacts', 'pinterest click')"
                    target="_blank"
                    v-html="icons.social.pinterest"
                  ></a>
                  <a
                    v-if="supplier.twitter"
                    class="general-social-media__link"
                    :href="urlAddProtocol(supplier.twitter)"
                    @click="$ga.event('contacts', 'twitter click')"
                    target="_blank"
                    v-html="icons.social.twitter"
                  ></a>
                  <a
                    v-if="supplier.youTube"
                    class="general-social-media__link"
                    :href="urlAddProtocol(supplier.youTube)"
                    @click="$ga.event('contacts', 'youtube click')"
                    target="_blank"
                    v-html="icons.social.youtube"
                  ></a>
                </div>
              </div>
              <div v-if="false" class="general-action-group">
                <fs-button-main class="general-action-group__button">Request Quotation</fs-button-main>
                <fs-button-main class="general-action-group__button" styling="outlined">Contact</fs-button-main>
              </div>
            </div>
            <div class="general-section__pills_n_tabs">
              <div class="general-section__pills">
                <fs-pill size="extra-large" :fixed="false" class="general-section__pill">Manufacturer</fs-pill>
                <fs-pill v-if="false" size="extra-large" :fixed="false" class="general-section__pill">Agent</fs-pill>
                <fs-pill v-if="false" size="extra-large" :fixed="false" class="general-section__pill">+</fs-pill>
              </div>
              <div v-if="false" class="general-section__tabs">
                <!-- TODO: Later -->
                Bla bla bla
              </div>
            </div>
          </section>
          <div class="tabs__container">
            <footwear-tab v-if="supplier.category == 0" :supplier="supplier"></footwear-tab>
            <components-tab v-if="supplier.category == 1" :supplier="supplier"></components-tab>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imagePath } from '@lib/imgproxy';

import { titleCase } from 'title-case';
import format from 'date-fns/format';
import { zonedTimeToUtc } from 'date-fns-tz';

import checkIfIE from '@lib/checkIfIE';
import formatters from '@lib/formatters';

// Components
import FsButtonMain from '@components/buttons/fs-button-main.vue';
import FsPill from '@components/pills/fs-pill.vue';
const SupplierMap = () => import('@components/map/supplier-map.vue');
import PageBlock from '@components/page-block.vue';
// Edit poppers
import ContactPersonsListEdit from './edit-poppers/contact-persons-list-edit.vue';
import CapabilitiesListEdit from './edit-poppers/capabilities-list-edit.vue';
import NewSupplierFormEdit from './edit-poppers/new-supplier-form-edit.vue';
import SocialMediaFormEdit from './edit-poppers/social-media-form-edit.vue';
import TypeNProcessesEdit from './edit-poppers/types-n-processes-edit.vue';
import CodeOfConductEdit from './edit-poppers/code-of-conduct-edit.vue';
import ContactInfoEdit from './edit-poppers/contact-info-edit.vue';
import GenderEdit from './edit-poppers/gender-edit.vue';
import SpecialitiesEdit from './edit-poppers/specialities-edit.vue';
import SizeRangeEdit from './edit-poppers/size-range-edit.vue';
import CertificationsListEdit from './edit-poppers/certifications-list-edit.vue';
import GeneralInformationEdit from './edit-poppers/general-information-edit.vue';
import LocationEdit from './edit-poppers/location-edit.vue';
// Sections
import SustainabilityRatingSection from './sections/sustainability-rating-section.vue';

import AddProductionUnitForm from './edit-poppers/add-production-unit-form.vue';

import SingleTextInputForm from '@components/single-text-input-form.vue';
import DescriptionEdit from '@components/description-edit.vue';

import SuppliersEditModal from '@components/entitites-lists-with-search/suppliers-edit-modal.vue';
import BrandsEditModal from '@components/entitites-lists-with-search/brands-edit-modal.vue';
import MembershipCompaniesEditModal from '@components/entitites-lists-with-search/membership-companies-edit-modal.vue';

// Tabs
import ComponentsTab from './tabs/components.vue';
import FootwearTab from './tabs/footwear.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
import countriesFlagsIcons from '@lib/icons-imports/countries-flags';
import miscellaneousIcons from '@lib/icons-imports/miscellaneous';
import contactIcons from '@lib/icons-imports/contact';
import socialsIcons from '@lib/icons-imports/socials';
// SVG
// Stats
import starEmpty from '@icons/other/star-empty.svg';
import starFilled from '@icons/other/star-filled.svg';
import starHalf from '@icons/other/star-half.svg';
import verifiedCheckmark from '@icons/other/verified-checkmark.svg';
import verifiedCircle from '@icons/other/verified-circle.svg';
import requestsHandled from '@icons/other/requests-handled.svg';
import responseRate from '@icons/other/response-rate.svg';
// Tabs
import collections from '@icons/other/collections.svg';
import components from '@icons/other/components.svg';
import footwear from '@icons/other/footwear.svg';
import services from '@icons/other/services.svg';
// FS Member
import fsMember from '@icons/other/fs-member.svg';

// SATRA
// TODO: REVIEW if this really should be statically imported - it's unnecessary
// data to load for all profiles except SATRA members.
import satraAccreditation from 'apps/common/assets/external-organizations/satra-accreditation.svg';
import satraMember from 'apps/common/assets/external-organizations/satra-member.svg';

export default {
  load({ store, route }) {
    return store.suppliers.loadBySlug(route.params.name).then((data) => {
      store.head.title = `FindSourcing – ${data.title}`;
      // TODO: Add custom head.description
      store.head.image = data.coverPhoto;
    });
  },
  components: {
    MembershipCompaniesEditModal,
    BrandsEditModal,
    SuppliersEditModal,
    FsButtonMain,
    ComponentsTab,
    FootwearTab,
    FsPill,
    PageBlock,
    SupplierMap,
    // Sections
    SustainabilityRatingSection,

    // Edit poppers
    ContactPersonsListEdit,
    CapabilitiesListEdit,
    NewSupplierFormEdit,
    SocialMediaFormEdit,
    TypeNProcessesEdit,
    CodeOfConductEdit,
    ContactInfoEdit,
    GenderEdit,
    SpecialitiesEdit,
    SizeRangeEdit,
    CertificationsListEdit,
    GeneralInformationEdit,
    LocationEdit,

    AddProductionUnitForm,

    SingleTextInputForm,
    DescriptionEdit,
  },
  data() {
    return {
      descriptionFullyVisible: false,
      descriptionOpen: false,
      ie: false,
      satraAccreditation: this.$store.general.satra,
      icons: {
        interfaceElements: interfaceElementsIcons,
        countriesFlags: countriesFlagsIcons,
        miscellaneous: miscellaneousIcons,
        contact: contactIcons,
        socials: socialsIcons,
      },
      svg: {
        // Stats
        starEmpty,
        starFilled,
        starHalf,
        verifiedCheckmark,
        verifiedCircle,
        requestsHandled,
        responseRate,
        // Tabs
        collections,
        components,
        footwear,
        services,
        // FS Member
        fsMember,
        // SATRA
        satraAccreditation,
        satraMember,
      },
    };
  },
  methods: {
    imagePath,
    format,
    ...formatters,
    zonedTimeToUtc,

    formatTimeSpan(timeString, ianaTimeZone) {
      // 1. convert time string to valid JS date string
      // (just adding today's date and 'T' symbol in the beginning of the string)
      const dateString = new Date(format(new Date(), 'yyyy-MM-dd') + 'T' + timeString);

      if (ianaTimeZone) {
        const dateToUTC = this.zonedTimeToUtc(dateString, ianaTimeZone);

        const usersLocale = Intl.DateTimeFormat().resolvedOptions().locale;
        const formattedTime = new Date(dateToUTC).toLocaleString(usersLocale, {
          hour: 'numeric',
          hour12: true,
          minute: '2-digit',
        });

        return formattedTime;
      } else {
        const hours = dateString.getHours();
        let minutes = dateString.getMinutes().toString();
        // Format minutes value to "XX"
        minutes = minutes.length === 1 ? '0' + minutes : minutes;

        return hours + ':' + minutes;
      }
    },

    async claimSupplier() {
      const { success } = await this.$store.suppliers.claimSupplier(
        this.$store.user.current.userName,
        this.supplier.title
      );

      if (success) {
        this.$notify({
          type: 'success',
          title: 'Your request was sent successfully',
        });
      } else {
        this.$notify({
          type: 'error',
          title: 'Something went wrong',
        });
      }
    },
  },
  computed: {
    // TODO: REFACTOR - copy exists in sections/brands-working-with. Needs to be DRY-ified
    // TODO: REVIEW if this strategy of splitting based on hyphen/comma really works.
    // Long term, we really should turn this into a proper list.
    brandsWorkingWith() {
      if (!this.supplier || !this.supplier.brands) return [];

      const commaCount = (this.supplier.brands.match(/,/g) || []).length;
      const hyphenCount = (this.supplier.brands.match(/-/g) || []).length;

      const regex = hyphenCount > commaCount ? /-/ : /,/;

      return this.supplier.brands
        .split(regex)
        .filter(Boolean)
        .map((brand) => brand.trim()); // filter(Boolean) => filter out empty items
    },
    // TODO: Remove this one when we have it working properly (dynamic, saved in DB)
    logoPath() {
      if (this.$route.params.name === 'valuni--eurodavil') return 'valuni.png';
      if (this.$route.params.name === 'design-shoes-colection-srl') return 'claudio-casanova-logo.jpg';
      if (this.$route.params.name === 'okabashi-brands') return 'okabashi.png';

      return '';
    },
    numberOfOverviewParagraphs() {
      if (!this.supplier || !this.supplier.overview) return 0;

      return (this.supplier.overview.match(/<p/g) || []).length;
    },
    showClaimSupplierButton() {
      // Supplier is found
      if (this.supplier.status == 0) return false;
      // User is logged in
      if (!this.$store.user.loggedIn) return false;
      // User is not admin
      if (this.$store.user.isAdmin) return false;
      // User is not a buyer
      // This can be discussed if this should hold true - but
      // for now let's do it like that to prevent 2 main buttons
      // being shown simultaneously for buyers
      if (this.$store.user.isBuyer) return false;
      // Company is not already connected to any user
      return this.supplier.users.length === 0;
    },
    showEditSupplierButton() {
      // Supplier found
      if (this.supplier.status == 0) return false;
      // User is admin, or
      if (this.$store.user.isAdmin) return true;
      // User is connected to supplier
      return this.$store.user.hasCompany(this.supplier.id);
    },
    social() {
      return (
        !!this.supplier.facebook ||
        !!this.supplier.twitter ||
        !!this.supplier.linkedIn ||
        !!this.supplier.pinterest ||
        !!this.supplier.instagram ||
        !!this.supplier.youTube
      );
    },
    supplier() {
      return this.$store.suppliers.all[this.$route.params.name] || {};
    },
    title() {
      const title = this.supplier.title || '';
      const upperCase = (title.match(/[A-Z]/g) || []).length;
      const lowerCase = (title.match(/[a-z]/g) || []).length;

      if (upperCase > lowerCase) return titleCase(title.toLowerCase());

      return title;
    },
  },

  updated() {},

  mounted() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (error) {}

    this.ie = checkIfIE();

    // this.supplier.openingHours = [
    //   {
    //     company: {},
    //     companyId: 31,
    //     createdAt: "2021-02-05T11:13:29.1133333",
    //     dayFrom: "Monday",
    //     dayTo: "Friday",
    //     id: 1,
    //     timeSpans: [
    //       {
    //         createdAt: "2021-02-05T11:14:25.27",
    //         from: "08:00:00",
    //         id: 1,
    //         to: "17:00:00",
    //         updatedAt: "2021-02-05T11:14:25.27"
    //       }
    //     ],
    //     updatedAt: "2021-02-05T11:13:29.1133333"
    //   }
    // ];
  },
  beforeDestroy() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (error) {}
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

.supplierProfile {
  .page-container {
  }

  // Based on old styling of supplier header image
  .supplier-header {
    position: relative;
    height: math.div(20rem, 1.6);
    margin-top: -$main-padding-top;
    margin-left: -$main-padding-sides;
    margin-right: -$main-padding-sides;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: rgba($c-blue, 0.1);

    @include tablet-min {
      height: math.div(24rem, 1.6);
    }

    @include tablet-landscape-min {
      height: math.div(35rem, 1.6);
    }
  }

  .supplier-header-logos {
    position: absolute;
    right: math.div(1rem, 1.6);
    bottom: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    @include tablet-landscape-min {
      right: math.div(1.8rem, 1.6);
      bottom: math.div(0.8rem, 1.6);
    }

    &__logo {
      height: math.div(9.6rem, 1.6);
      width: math.div(9.6rem, 1.6);
      box-sizing: content-box;
      padding: 0 math.div(0.5rem, 1.6);

      @include desktop-min {
        height: math.div(12.8rem, 1.6);
        width: math.div(12.8rem, 1.6);
      }

      &--small {
        height: math.div(7rem, 1.6);
        width: math.div(7rem, 1.6);

        @include desktop-min {
          height: math.div(9.2rem, 1.6);
          width: math.div(9.2rem, 1.6);
        }
      }
    }
  }

  .column-container {
    display: flex;
    max-width: math.div(164.8rem, 1.6);
    margin: 0 auto;
  }

  .left-column {
    // Adjust for being on top of header;
    margin-top: math.div(-12.4rem, 1.6);
    z-index: 2;
    margin-right: math.div(1.6rem, 1.6);
    min-width: math.div(33.6rem, 1.6);
    flex-basis: math.div(42.4rem, 1.6); // Max-width;
    flex-grow: 0;
    flex-shrink: 2;

    @include tablet-landscape-min {
      // Using percentages will make the margin shrink equally as
      // the boxes. 1% ~ 1rem of the max-width of the container;
      // All margins below become negative at 700px container width;
      margin-left: calc(2% - #{math.div(1.6rem, 1.6)});
      margin-right: calc(5% - #{math.div(4rem, 1.6)});
      min-width: math.div(36.8rem, 1.6);
      flex-shrink: 1;
      // Adjust for being on top of header;
      margin-top: math.div(-22.8rem, 1.6);
    }

    @include mobile-only {
      display: none;
    }
  }

  .right-column {
    margin-left: math.div(1.2rem, 1.6);
    margin-right: math.div(0.8rem, 1.6);
    flex-basis: math.div(108.8rem, 1.6);
    flex-grow: 1;
    flex-shrink: 2;

    @include tablet-landscape-min {
      margin-left: calc(4% - #{math.div(3.2rem, 1.6)});
      margin-right: calc(2% - #{math.div(1.6rem, 1.6)});
    }

    @include mobile-only {
      margin: 0 0;
    }
  }

  // ========================================================================
  // ========================================================================
  // Production Units
  .production-units {
    padding: math.div(1rem, 1.6) 0 math.div(2.7rem, 1.6) !important;
    border: none !important;

    &__header {
      @include heading--small;
      margin-bottom: math.div(2.7rem, 1.6);
      text-align: center;
    }

    &__units-list {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
    }

    &__single-unit {
      margin: 0 math.div(1.2rem, 1.6) math.div(2rem, 1.6);
    }

    .production-single-unit {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;

      &__logo-wrap {
        width: math.div(5.4rem, 1.6);
        height: math.div(3.6rem, 1.6);
        margin-bottom: math.div(1.7rem, 1.6);
        position: relative;
      }

      &__logo {
        height: 100%;
      }

      &__verification-icon {
        width: math.div(2.8rem, 1.6);
        height: math.div(2.8rem, 1.6);
        border-radius: math.div(10rem, 1.6);
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: absolute;
        top: math.div(-1.4rem, 1.6);
        right: math.div(-1.4rem, 1.6);
        background: #fff;
        box-shadow: 0 math.div(0.3rem, 1.6) math.div(0.6rem, 1.6) rgba(0, 0, 0, 0.16);

        svg {
          width: math.div(1.6rem, 1.6);
          height: math.div(1.6rem, 1.6);
          fill: rgb(53, 73, 94);
        }
      }

      &__city {
        margin-bottom: math.div(0.3rem, 1.6);
        font-size: math.div(1.4rem, 1.6);
      }

      &__country {
        font-size: math.div(1.4rem, 1.6);
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: $tablet-p-width) {
    .production-units {
      padding: math.div(0.4rem, 1.6) 0 0 0 !important;
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;

      &__header {
        margin: 0 math.div(1.2rem, 1.6) math.div(0.5rem, 1.6) 0;
        font-size: math.div(1.6rem, 1.6);
      }

      &__units-list {
        justify-content: flex-start;
      }

      &__single-unit {
        margin: 0 math.div(1rem, 1.6) math.div(0.6rem, 1.6) 0;
      }

      .production-single-unit {
        flex-direction: row;

        &__logo-wrap {
          width: math.div(2.2rem, 1.6);
          height: math.div(1.4rem, 1.6);
          margin: 0 math.div(1rem, 1.6) 0 0;
        }

        &__verification-icon {
          width: math.div(1.4rem, 1.6);
          height: math.div(1.4rem, 1.6);
          top: math.div(-0.7rem, 1.6);
          right: math.div(-0.7rem, 1.6);

          svg {
            width: math.div(1rem, 1.6);
            height: math.div(1rem, 1.6);
          }
        }

        &__city {
          margin: 0 math.div(0.4rem, 1.6) 0 0;
        }

        &__country {
          margin: 0;
        }
      }
    }
  }

  // ========================================================================
  // ========================================================================
  .floating-section {
    position: relative;
    background-color: $c-bg-light;
    box-shadow: $bs-high-center;
    margin-bottom: math.div(4.8rem, 1.6);
    padding-bottom: math.div(2.2rem, 1.6);

    &__group {
      border-top: 1px solid $c-border-gray;
      padding: 0 math.div(2rem, 1.6);

      &:last-child {
        padding-top: math.div(3.7rem, 1.6);
      }
    }

    &__group-header {
      @include heading--small;
      text-align: center;
      margin: 0;
      padding-top: math.div(3.6rem, 1.6);
    }
  }

  // Left column content - first group
  .map {
    position: relative;
    padding-bottom: 90%;

    @include tablet-landscape-min {
      padding-bottom: 100%;
    }

    &__container {
      position: relative;
      flex-basis: math.div(28.8rem, 1.6);
      flex-shrink: 0;
      flex-grow: 1;
      max-height: math.div(32rem, 1.6);
      overflow: hidden;
    }

    &__map {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .logo {
    $max-width: 85%;
    $max-height: math.div(16rem, 1.6);

    position: absolute;
    left: 50%;
    max-width: $max-width;
    max-height: $max-height;
    box-shadow: $bs-medium-top;
    transform: translate(-50%, -50%);

    &__hidden {
      display: inline-block;
      max-width: math.div($max-width, 2);
      max-height: math.div($max-height, 2);
      visibility: hidden;
      // To accomodate for changing format due to being sized in between max
      // sizings. Should be fine since we have more top padding on below
      // element, so should never be able to turn into an overlapping scenario;
      margin-bottom: math.div(-1.6rem, 1.6);
    }
  }

  .hq-container {
    width: 100%;
    display: flex;
    padding: math.div(3.6rem, 1.6) math.div(2rem, 1.6) math.div(3rem, 1.6) math.div(2rem, 1.6);
    justify-content: center;
    align-items: center;
    flex-basis: math.div(22.4rem, 1.6);
    flex-grow: 3;

    @include mobile-only {
      flex-wrap: wrap;
      padding: math.div(0.6rem, 1.6) math.div(2rem, 1.6) 0 math.div(2rem, 1.6);
    }
  }

  .hq-country {
    flex-basis: 42%;
    flex-shrink: 4;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: math.div(1.2rem, 1.6) 0;

    @include tablet-landscape-min {
      flex-basis: 45%;
    }

    &__flag {
      max-width: math.div(8.8rem, 1.6);

      @include tablet-landscape-min {
        max-width: math.div(9.6rem, 1.6);
      }
    }

    &__country {
      @include body;
      text-transform: uppercase;
      display: inline-block;
      margin-top: math.div(1rem, 1.6);
    }
  }

  .hq-location {
    flex-basis: auto;
    max-width: 55%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    flex-wrap: wrap;
    min-width: math.div(18.4rem, 1.6);
    padding: math.div(1.2rem, 1.6) 0;

    &__pin {
      height: math.div(3.7rem, 1.6); // IE11 fix: To avoid IE11 setting a default height of 150px;
      width: math.div(2.6rem, 1.6);
    }

    &__address {
      @include body--small;
      text-align: center;
      margin-top: math.div(0.7rem, 1.6);
      max-width: 100%; // IE11 fix: To avoid overflowing;
    }
  }

  .contact-info {
    /* autoprefixer: off */
    display: grid;
    grid-template-columns: (#{math.div(2.4rem, 1.6) + 2 * math.div(0.4rem, 1.6)}) minmax(0, auto);
    grid-column-gap: math.div(1.6rem, 1.6);
    grid-row-gap: math.div(2.6rem, 1.6);
    justify-content: space-evenly;
    justify-items: start;
    align-items: center;
    margin: 0 math.div(0.6rem, 1.6);
    padding: math.div(4.8rem, 1.6) 0;

    &__icon {
      display: flex;
      justify-content: center;
      height: math.div(2.4rem, 1.6);
      width: math.div(2.4rem, 1.6);
      justify-self: center;
      margin: 0 math.div(0.4rem, 1.6);
      fill: $c-dark;

      &--clock {
        align-self: flex-start;
        position: relative;
        top: math.div(2.6rem, 1.6);
      }

      svg {
        width: 100%;
      }

      &--vertically-aligned {
        position: relative;
        top: math.div(1.1rem, 1.6);
      }
    }

    &__links {
      /* autoprefixer: off */
      display: inline-grid;
      grid-template-columns: auto;
      margin: 0 math.div(0.4rem, 1.6);

      &--with-labels {
        /* autoprefixer: off */
        grid-template-columns: min-content auto;
      }
    }

    &__working-time-span {
      display: flex;
      flex-direction: column;

      &:nth-child(n + 2) {
        margin-top: math.div(1.5rem, 1.6);
      }
    }

    &__link {
      @include body;
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: bottom;
    }

    &__link-label {
      @include body;
      display: inline-block;
      vertical-align: bottom;
      padding-right: math.div(1.6rem, 1.6);
    }

    &__subtitle {
      @include body;
      margin-bottom: math.div(0.4rem, 1.6);
      display: inline-block;
      vertical-align: bottom;
      padding-right: math.div(1.6rem, 1.6);
      font-size: math.div(1.4rem, 1.6);
    }
  }

  .contact-persons {
    /* autoprefixer: off */
    display: grid;
    grid-template-columns: (#{math.div(6.6rem, 1.6) + 2 * math.div(0.4rem, 1.6)}) minmax(0, auto);
    grid-column-gap: math.div(1.6rem, 1.6);
    grid-row-gap: math.div(4.2rem, 1.6);
    justify-content: space-evenly;
    justify-items: start;
    align-items: center;
    margin: 0 math.div(0.8rem, 1.6);
    padding: math.div(4.8rem, 1.6) 0;

    &__image {
      display: inline-block;
      background-size: cover;
      background-position: center;
      height: math.div(6.6rem, 1.6);
      width: math.div(6.6rem, 1.6);
      margin: 0 math.div(0.4rem, 1.6);
      border-radius: 50%;
      overflow: hidden;
    }

    &__info-container {
      max-width: 100%;
      padding: 0 math.div(0.4rem, 1.6);
    }

    &__name {
      @include body;
      line-height: 1.25;
      display: inline-block;
      text-transform: uppercase;
      padding-bottom: math.div(0.6rem, 1.6);
    }

    &__info-group {
      display: flex;
      align-items: center;
      margin-right: math.div(0.4rem, 1.6);
    }

    &__info-icon {
      display: flex;
      height: math.div(2rem, 1.6);
      width: math.div(2rem, 1.6);
      margin-right: math.div(1rem, 1.6);
      flex-shrink: 0;
      fill: $c-dark;

      svg {
        flex-grow: 1;
      }
    }

    &__info-link {
      @include body--small;
      display: inline-block;
      flex: 1 1 auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: middle;
      padding-top: math.div(1rem, 1.6);
      padding-bottom: math.div(1rem, 1.6);
    }
  }

  .action-group {
    display: flex;
    flex-direction: column;
    width: math.div(25.2rem, 1.6);
    margin: 0 auto;
    padding-top: math.div(2rem, 1.6);
    padding-bottom: math.div(1.8rem, 1.6);

    &__button {
      margin-top: math.div(2.4rem, 1.6);
    }
  }

  .social-media {
    display: flex;
    justify-content: center;
    padding-top: math.div(4.4rem, 1.6);
    padding-bottom: math.div(4.8rem, 1.6);

    &__link {
      display: inline-block;
      box-sizing: content-box;
      height: math.div(3rem, 1.6);
      width: math.div(3rem, 1.6);
      margin: 0 math.div(1.5rem, 1.6);

      // TODO: Fix on-hover effect to original colors? Check with Christian first
      // We also probably need to rework SVG-files to be able to transition on fill
      svg * {
        fill: $c-dark !important;
      }
    }
  }

  // Left column content - second group
  .verifications {
    /* autoprefixer: off */
    display: grid;
    grid-template-columns: math.div(2.1rem, 1.6) minmax(#{math.div(18.6rem, 1.6)}, auto); // Min here is to ensure a certain width feeling of the whole grid;
    grid-auto-columns: math.div(2.1rem, 1.6) minmax(#{math.div(18.6rem, 1.6)}, auto);
    grid-column-gap: math.div(1.6rem, 1.6);
    grid-row-gap: math.div(1rem, 1.6);
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: math.div(1.2rem, 1.6) 0 0 0;

    &__checkbox {
      position: relative;
      top: 1px; // Adjust for vertical centering of label text not feeling accurately centered;
      display: inline-block;
      height: math.div(2.1rem, 1.6);
      width: math.div(2.1rem, 1.6);
      fill: $c-dark;
    }

    &__label {
      @include body;
      display: inline-block; // IE11 fix for making this element work with grid (needs to be block-ish)
    }
  }

  .supplier-stats {
    /* autoprefixer: off */
    display: grid;
    grid-template-columns: math.div(2.4rem, 1.6) minmax(#{math.div(8.2rem, 1.6)}, auto) auto;
    grid-column-gap: math.div(2.4rem, 1.6);
    grid-row-gap: math.div(0.6rem, 1.6);
    justify-content: center;
    align-items: center;
    padding: math.div(5.6rem, 1.6) 0 math.div(3.6rem, 1.6) 0;

    &__icon {
      position: relative;
      top: 1px; // Adjust for vertical centering of label text not feeling accurately centered;
      display: inline-block;
      height: math.div(2.4rem, 1.6);
      width: math.div(2.4rem, 1.6);
      fill: $c-dark;
    }

    &__label {
      @include body;
      display: inline-block; // IE11 fix for making this element work with grid (needs to be block-ish)
    }

    &__value {
      @include body;
      font-weight: $bold-lato;
      display: inline-block; // IE11 fix for making this element work with grid (needs to be block-ish)
    }
  }

  .brands-labels-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: math.div(0.8rem, 1.6);
    padding-bottom: math.div(3rem, 1.6);

    @include mobile-only {
      padding-bottom: math.div(1.4rem, 1.6);
    }

    &__brand {
      @include body;
      text-transform: uppercase;
      padding: math.div(0.4rem, 1.6) math.div(1.2rem, 1.6);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  // General section (right column - above tabs)
  .general-section {
    margin-left: -100vw;
    margin-right: -100vw;
    padding-top: math.div(1.6rem, 1.6);
    padding-left: 100vw;
    padding-right: 100vw;
    background-color: $c-bg-gray-darker;

    &__name {
      @include heading--huge;
      margin: 0;
    }

    &__pills_n_tabs {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: flex-end;
      flex-wrap: wrap;

      @include mobile-only {
        display: none;
      }
    }

    // TODO: REVIEW/REFACTOR Should perhaps use fs-pill-container as base for more DRY code?
    &__pills {
      margin: 0 math.div(-0.4rem, 1.6); // Undo pill-margin;
      min-height: math.div(5rem, 1.6);
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;

      &--mobile {
        @include tablet-min {
          display: none;
        }
      }

      &__pill {
        margin: 0 math.div(0.4rem, 1.6);
      }
    }

    &__tabs {
      height: math.div(5rem, 1.6);
    }

    &__mobile-container {
      padding-bottom: math.div(1.6rem, 1.6);

      @include tablet-min {
        display: none;
      }
    }
  }

  // Mobile content input general section
  // TODO: REFACTOR, this section command contact-container is very similar.
  // Perhaps they should be merged into a unified base structure.
  .location-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 math.div(-1.4rem, 1.6);
    padding-top: math.div(0.4rem, 1.6);
  }

  .icon-label {
    display: flex;
    align-items: center;
    padding: math.div(0.7rem, 1.6) math.div(1.5rem, 1.6) math.div(0.6rem, 1.6);

    &__icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      box-sizing: content-box;
      height: math.div(2.2rem, 1.6);
      width: math.div(2.2rem, 1.6);
      padding: 0 1px;
      margin-right: math.div(1.4rem, 1.6);

      > svg {
        flex-grow: 1;
      }

      &--flag {
        height: math.div(2.4rem, 1.6);
        width: math.div(2.4rem, 1.6);
        padding: 0;
      }
    }

    &__label {
      font-weight: 400;
      line-height: 1;
    }
  }

  .contact-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 math.div(-1.5rem, 1.6);
    padding-bottom: math.div(0.2rem, 1.6);
  }

  .general-contact-info {
    display: flex;
    align-items: center;
    padding: math.div(0.7rem, 1.6) math.div(1.5rem, 1.6) math.div(0.6rem, 1.6);

    &__icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      box-sizing: content-box;
      height: math.div(2.2rem, 1.6);
      width: math.div(2.2rem, 1.6);
      padding: 0 1px;
      margin-right: math.div(1.4rem, 1.6);
      fill: $c-dark;

      > svg {
        flex-grow: 1;
      }
    }

    &__label {
      font-weight: 400;
      line-height: 1;
      white-space: nowrap;
    }
  }

  .general-social-media {
    display: flex;
    align-items: center;
    padding: math.div(0.7rem, 1.6) (#{math.div(1.5rem, 1.6)} - #{math.div(0.8rem, 1.6)}) math.div(0.6rem, 1.6);

    &__link {
      display: inline-block;
      box-sizing: content-box;
      height: math.div(2.2rem, 1.6);
      width: math.div(2.2rem, 1.6);
      margin: math.div(-0.6rem, 1.6) 0;
      padding: math.div(0.6rem, 1.6) math.div(0.8rem, 1.6);

      // TODO: Fix on-hover effect to original colors? Check with Christian first
      // We also probably need to rework SVG-files to be able to transition on fill
      svg * {
        fill: $c-dark !important;
      }
    }
  }

  .general-action-group {
    display: flex;
    flex-wrap: wrap;
    padding: math.div(0.7rem, 1.6) 0 1px;
    margin: 0 math.div(-0.8rem, 1.6);

    &__button {
      margin: math.div(0.7rem, 1.6) math.div(0.8rem, 1.6);
    }
  }
}
</style>
