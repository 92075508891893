export const genders = {
  1: {
    name: 'Men',
    icon: 'men',
  },
  2: {
    name: 'Women',
    icon: 'ladies',
  },
  4: {
    name: 'Kids',
    icon: 'boysGirls',
  },
  8: {
    name: 'Babies',
    icon: 'babies',
  },
};

export const genderKeys = Object.keys(genders).map((genderKey) => parseInt(genderKey));

export const genderList = genderKeys.map((genderKey) => {
  return {
    ...genders[genderKey],
    key: genderKey,
  };
});

export const keysToGenders = (keys) => {
  // All genders - let's combine them into one
  if (keys.length > 3) {
    return [
      {
        key: 0,
        name: 'All',
        icon: genders[4],
      },
    ];
  }

  return keys.map(keyToGender);
};

export const keyToGender = (key) => {
  return {
    key,
    ...genders[key],
  };
};
