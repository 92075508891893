var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "add-content-placeholder\n           add-content-placeholder--" +
        _vm.size,
    },
    [
      _c(
        "div",
        {
          class:
            "add-content-placeholder__in-wrap\n             add-content-placeholder__in-wrap--" +
            _vm.size,
        },
        [
          _c(
            "div",
            {
              class:
                "add-content-placeholder__icon-slot\n               add-content-placeholder__icon-slot--" +
                _vm.size,
              style: _vm.iconSize ? _vm.iconSize : {},
            },
            [
              _vm._t("icon", [
                ["medium", "large"].includes(_vm.size)
                  ? _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.svg.plusCircleFilledIcon),
                      },
                    })
                  : _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.svg.plusIcon) },
                    }),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _vm.label
            ? _c(
                "div",
                {
                  class:
                    "add-content-placeholder__text-label\n               add-content-placeholder__text-label--" +
                    _vm.size,
                },
                [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "add-content-placeholder__bg-layer" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }