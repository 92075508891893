<template>
  <div class="general-info-form">
    <div class="general-info-form__main-title">General Information</div>

    <!-- Form itself -->
    <div class="form-itself general-info-form__form-itself">
      <div class="form-itself__section">
        <!-- Form elements with title -->
        <div class="form-elements-with-title form-itself__form-elements-with-title">
          <div class="form-elements-with-title__title">Founded</div>

          <select-custom
            class="form-elements-with-title__form-element"
            placeholder="Select year"
            value="1989"
            :options="['1989', '1990', '1991', '1992', '1993']"
            v-model="foundedYear"
            @input="() => {}"
          />
        </div>
        <!-- / Form elements with title -->

        <!-- Form elements with title -->
        <div class="form-elements-with-title form-itself__form-elements-with-title">
          <div class="form-elements-with-title__title">Pairs / Day</div>

          <input-custom
            class="form-elements-with-title__form-element"
            placeholder="Pairs / Day"
            v-model="pairsPerDay"
          />
        </div>
        <!-- / Form elements with title -->

        <!-- Form elements with title -->
        <div
          class="form-elements-with-title form-itself__form-elements-with-title form-itself__form-elements-with-title--full-width"
        >
          <div class="form-elements-with-title__title">OAR-ID</div>

          <input-custom class="form-elements-with-title__form-element" placeholder="OAR-ID" v-model="oarId" />
        </div>
        <!-- / Form elements with title -->
      </div>

      <div class="form-itself__section form-itself__section--with-border">
        <div class="form-itself__section-title">
          <div class="form-itself__section-title-in-wrap">Minimum Order Quantity</div>
        </div>

        <!-- Form elements with title -->
        <div class="form-elements-with-title form-itself__form-elements-with-title">
          <div class="form-elements-with-title__title">Total</div>

          <input-custom
            class="form-elements-with-title__form-element"
            placeholder="0"
            suffix="pairs"
            v-model="totalPairs"
          />

          <fs-slider
            class="form-elements-with-title__slider"
            ref="slider"
            :dot-size="18"
            v-model="totalPairs"
            :min="0"
            :max="200"
            :piecewise="false"
            :disabled="false"
            width="100%"
            :tooltip="false"
            @callback="() => {}"
          />
        </div>
        <!-- / Form elements with title -->

        <!-- Form elements with title -->
        <div class="form-elements-with-title form-itself__form-elements-with-title">
          <div class="form-elements-with-title__title">Per Color</div>

          <input-custom
            class="form-elements-with-title__form-element"
            placeholder="0"
            suffix="pairs"
            v-model="perColorPairs"
          />

          <fs-slider
            class="form-elements-with-title__slider"
            ref="slider"
            :dot-size="18"
            v-model="perColorPairs"
            :min="0"
            :max="200"
            :piecewise="false"
            :disabled="false"
            width="100%"
            :tooltip="false"
            @callback="() => {}"
          />
        </div>
        <!-- / Form elements with title -->
      </div>

      <div class="form-itself__section form-itself__section--with-border">
        <div class="form-itself__section-title">
          <div class="form-itself__section-title-in-wrap">Employees</div>
        </div>

        <!-- Form elements with title -->
        <div class="form-elements-with-title form-itself__form-elements-with-title">
          <div class="form-elements-with-title__title">Total Employees</div>

          <input-custom class="form-elements-with-title__form-element" placeholder="0" v-model="totalEmployees" />
        </div>
        <!-- / Form elements with title -->

        <!-- Form elements with title -->
        <div class="form-elements-with-title form-itself__form-elements-with-title">
          <div class="form-elements-with-title__title">Line Workers</div>

          <input-custom class="form-elements-with-title__form-element" placeholder="0" v-model="lineWorkers" />
        </div>
        <!-- / Form elements with title -->

        <!-- Form elements with title -->
        <div class="form-elements-with-title form-itself__form-elements-with-title">
          <div class="form-elements-with-title__title">Female</div>

          <input-custom
            class="form-elements-with-title__form-element"
            placeholder="0"
            suffix="%"
            v-model="femaleEmployees"
          />

          <fs-slider
            class="form-elements-with-title__slider"
            ref="slider"
            :dot-size="18"
            v-model="femaleEmployees"
            :min="0"
            :max="100"
            :piecewise="false"
            :disabled="false"
            width="100%"
            :tooltip="false"
            @callback="() => {}"
          />
        </div>
        <!-- / Form elements with title -->

        <!-- Form elements with title -->
        <div class="form-elements-with-title form-itself__form-elements-with-title">
          <div class="form-elements-with-title__title">Migrant</div>

          <input-custom
            class="form-elements-with-title__form-element"
            placeholder="0"
            suffix="%"
            v-model="migrantEmployees"
          />

          <fs-slider
            class="form-elements-with-title__slider"
            ref="slider"
            :dot-size="18"
            v-model="migrantEmployees"
            :min="0"
            :max="100"
            :piecewise="false"
            :disabled="false"
            width="100%"
            :tooltip="false"
            @callback="() => {}"
          />
        </div>
        <!-- / Form elements with title -->
      </div>
    </div>
    <!-- / Form itself -->

    <cancel-n-action-btn-pair class="general-info-form__manage-buttons-panel" @cancel="() => {}" @action="() => {}" />
  </div>
</template>

<script>
// Components
import FsSlider from '@components/form-elements/fs-slider.vue';
import InputCustom from '@components/form-elements/input-custom.vue';
import SelectCustom from '@components/form-elements/selects/select-custom.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';

export default {
  name: 'general-information-edit',

  components: {
    FsSlider,
    InputCustom,
    SelectCustom,
    CancelNActionBtnPair,
  },

  data() {
    return {
      isSaveInProgress: false,

      // Form data
      foundedYear: '',
      pairsPerDay: 0,
      oarId: '',
      totalPairs: 0,
      perColorPairs: 0,
      totalEmployees: 0,
      lineWorkers: 0,
      femaleEmployees: 0,
      migrantEmployees: 0,

      icons: {
        interfaceElements: interfaceElementsIcons,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// ============================================================================
// Form elements with title
.form-elements-with-title {
  &__title {
    padding-left: math.div(2.5rem, 1.6);
    margin-bottom: math.div(0.6rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
    text-transform: uppercase;
  }

  &__form-element {
    margin-bottom: math.div(2.2rem, 1.6);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__slider {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 0 !important;
    box-sizing: border-box;
    margin-bottom: math.div(-0.2rem, 1.6);

    // REVIEW: Unclear why this is needed, but otherwise the dot places itself too much at the top compared to other sliders
    // Maybe becuase it's not a range?
    ::v-deep .vue-slider-dot {
      .vue-slider-dot-handle {
        top: math.div(0.2rem, 1.6);
      }
    }
  }
}

// ============================================================================
// ============================================================================
// Form itself
.form-itself {
  &__section {
    margin-bottom: math.div(3.2rem, 1.6);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &--with-border {
      position: relative;
      z-index: 0;

      &::before {
        content: '';
        width: calc(100% + #{math.div(3.6rem, 1.6)});
        height: calc(100% - #{math.div(0.7rem, 1.6)});
        border: 1px rgba(53, 73, 94, 0.1) solid;
        border-radius: math.div(1rem, 1.6);
        position: absolute;
        inset: math.div(0.7rem, 1.6) auto auto math.div(-1.8rem, 1.6);
        z-index: -1;
      }
    }
  }

  &__section-title {
    width: 100%;
    margin-bottom: math.div(2.6rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  &__section-title-in-wrap {
    padding: 0 math.div(1.2rem, 1.6);
    font-size: math.div(1.5rem, 1.6);
    line-height: math.div(1.5rem, 1.6);
    color: rgba(53, 73, 94, 0.6);
    white-space: nowrap;
    text-align: center;
    background: #fff;
  }

  &__form-elements-with-title {
    width: calc(50% - #{math.div(1.2rem, 1.6)});
    margin-bottom: math.div(2.6rem, 1.6);

    &:last-child {
      margin-bottom: 0;
    }

    &--full-width {
      width: 100%;
    }
  }

  &__slider-container {
  }

  &__slider {
  }
}

// ============================================================================
// ============================================================================
// General info form
.general-info-form {
  width: math.div(44rem, 1.6);
  padding: math.div(3.8rem, 1.6) math.div(4.7rem, 1.6);
  box-sizing: border-box;

  &__main-title {
    margin-bottom: math.div(2.5rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__form-itself {
    margin-bottom: math.div(5rem, 1.6);
  }

  &__manage-buttons-panel {
  }
}
</style>
