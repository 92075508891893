var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.supplier.apiccapsId || _vm.supplier.satra
    ? _c("div", { staticClass: "membership-section" }, [
        _c("h4", { staticClass: "membership-section__header" }, [
          _vm._v("Membership Organizations"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "membership-section__membership-container" }, [
          _vm.supplier.apiccapsId
            ? _c(
                "a",
                {
                  staticClass: "membership-section__membership",
                  attrs: {
                    href: "https://www.apiccaps.pt",
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$ga.event(
                        "partners",
                        "APICCAPS click - supplier page"
                      )
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "membership-section__membership-image",
                    attrs: {
                      src: "common/assets/external-organizations/apiccaps.png",
                      alt: "APICCAPS member",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.supplier.satra
            ? _c(
                "a",
                {
                  staticClass: "membership-section__membership",
                  attrs: {
                    href: "https://www.satra.com",
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$ga.event(
                        "partners",
                        "SATRA click - supplier page"
                      )
                    },
                  },
                },
                [
                  _c("span", {
                    staticClass: "membership-section__membership-svg",
                    domProps: { innerHTML: _vm._s(_vm.svg.satraMember) },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.supplier.ciceg
            ? _c(
                "a",
                {
                  staticClass: "membership-section__membership",
                  attrs: {
                    href: "https://www.ciceg.org",
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$ga.event(
                        "partners",
                        "CICEG click - supplier page"
                      )
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "membership-section__membership-image",
                    attrs: {
                      src: "common/assets/external-organizations/ciceg.png",
                      alt: "CICEG member",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }