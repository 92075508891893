var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "articles-n-checkboxes": true,
        "articles-n-checkboxes--1-narrow-elements":
          (_vm.tagsMode || _vm.seasonsMode) && _vm.entities.length === 1,
        "articles-n-checkboxes--2-narrow-elements":
          (_vm.tagsMode || _vm.seasonsMode) && _vm.entities.length === 2,
        "articles-n-checkboxes--3-narrow-elements":
          (_vm.tagsMode || _vm.seasonsMode) && _vm.entities.length === 3,

        "articles-n-checkboxes--1-wide-element":
          _vm.suppliersMode && _vm.entities.length === 1,
        "articles-n-checkboxes--2-wide-elements":
          _vm.suppliersMode && _vm.entities.length === 2,
        "articles-n-checkboxes--3-wide-elements":
          _vm.suppliersMode && _vm.entities.length === 3,

        "articles-n-checkboxes--1-line":
          _vm.suppliersMode && _vm.articles.length === 1,
        "articles-n-checkboxes--2-lines":
          _vm.suppliersMode && _vm.articles.length === 2,
        "articles-n-checkboxes--3-lines":
          _vm.suppliersMode && _vm.articles.length === 3,
        "articles-n-checkboxes--4-lines":
          _vm.suppliersMode && _vm.articles.length === 4,
        "articles-n-checkboxes--5-lines":
          _vm.suppliersMode && _vm.articles.length === 5,
        "articles-n-checkboxes--6-lines":
          _vm.suppliersMode && _vm.articles.length === 6,

        "articles-n-checkboxes--1-line-with-short-header":
          (_vm.tagsMode || _vm.seasonsMode) && _vm.articles.length === 1,
        "articles-n-checkboxes--2-lines-with-short-header":
          (_vm.tagsMode || _vm.seasonsMode) && _vm.articles.length === 2,
        "articles-n-checkboxes--3-lines-with-short-header":
          (_vm.tagsMode || _vm.seasonsMode) && _vm.articles.length === 3,
        "articles-n-checkboxes--4-lines-with-short-header":
          (_vm.tagsMode || _vm.seasonsMode) && _vm.articles.length === 4,
        "articles-n-checkboxes--5-lines-with-short-header":
          (_vm.tagsMode || _vm.seasonsMode) && _vm.articles.length === 5,
        "articles-n-checkboxes--6-lines-with-short-header":
          (_vm.tagsMode || _vm.seasonsMode) && _vm.articles.length === 6,
      },
    },
    [
      _c(
        "section",
        {
          class: {
            "scrollable-table": true,
            "scrollable-table--short-header": _vm.tagsMode || _vm.seasonsMode,
            "articles-n-checkboxes__scrollable-table": true,
          },
        },
        [
          _c("transition", { attrs: { name: "simple-fade-in-out-fast" } }, [
            _vm.entities.length > 4 && _vm.isLeftCurtainGradientVisible
              ? _c("div", {
                  staticClass: "scrollable-table__left-curtain-gradient",
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "simple-fade-in-out-fast" } }, [
            _vm.entities.length > 4 && _vm.isRightCurtainGradientVisible
              ? _c("div", {
                  staticClass: "scrollable-table__right-curtain-gradient",
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "simple-fade-in-out-fast" } }, [
            _vm.articles.length > 6 && _vm.isBottomCurtainGradientVisible
              ? _c("div", {
                  staticClass: "scrollable-table__bottom-curtain-gradient",
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "simple-fade-in-out-fast" } }, [
            _vm.entities.length > 4 && _vm.isLeftCurtainGradientVisible
              ? _c("div", {
                  staticClass:
                    "scrollable-table__arrow-but scrollable-table__arrow-but--left",
                  domProps: {
                    innerHTML: _vm._s(_vm.icons.interfaceElements.arrowLeft),
                  },
                  on: {
                    click: function ($event) {
                      _vm.$refs.scrollableContainer.scrollLeft -= 100
                    },
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "simple-fade-in-out-fast" } }, [
            _vm.entities.length > 4 && _vm.isRightCurtainGradientVisible
              ? _c("div", {
                  staticClass:
                    "scrollable-table__arrow-but scrollable-table__arrow-but--right",
                  domProps: {
                    innerHTML: _vm._s(_vm.icons.interfaceElements.arrowRight),
                  },
                  on: {
                    click: function ($event) {
                      _vm.$refs.scrollableContainer.scrollLeft += 100
                    },
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "main-titles-section": true,
                "scrollable-table__main-titles-section": true,
              },
            },
            [
              _c("div", { staticClass: "main-titles-section__main-title" }, [
                _vm._v("Select Articles"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "main-titles-section__sub-title" }, [
                _vm._v(
                  "\n        Select which Articles to add each new\n        " +
                    _vm._s(
                      ["Tag", "Season", "Supplier"][
                        [
                          _vm.tagsMode,
                          _vm.seasonsMode,
                          _vm.suppliersMode,
                        ].findIndex(function (item) {
                          return item
                        })
                      ]
                    ) +
                    "\n        to\n      "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "scrollableContainer",
              staticClass: "scrollable-table__scrollable-container",
              on: {
                scroll: function ($event) {
                  _vm.defineCurtainsVisibilityWithThrottle($event)
                  _vm.setTableLeftScroll($event)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "scrollable-table__entities-row" },
                _vm._l(_vm.entities, function (entity, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: {
                        "entity-teaser": true,
                        "scrollable-table__entity-teaser": true,
                        "scrollable-table__entity-teaser--narrow":
                          _vm.tagsMode || _vm.seasonsMode,
                      },
                    },
                    [
                      _vm.suppliersMode
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "supplier-snippet entity-teaser__supplier-snippet scrollable-table__header-cell scrollable-table__header-cell--supplier",
                            },
                            [
                              _c("img", {
                                staticClass: "supplier-snippet__flag",
                                attrs: {
                                  src: _vm.$store.countries.getFlagPathFromName(
                                    entity.country
                                  ),
                                  alt: entity.country,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "supplier-snippet__supplier-name",
                                },
                                [_vm._v(_vm._s(entity.name))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tagsMode
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "tag-snippet entity-teaser__tag-snippet scrollable-table__header-cell scrollable-table__header-cell--tag",
                            },
                            [
                              _c(
                                "fs-pill-tag",
                                {
                                  staticClass: "tag-snippet__tag-pill",
                                  attrs: { color: entity.color, clickable: "" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(entity.name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.seasonsMode
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "season-snippet entity-teaser__season-snippet scrollable-table__header-cell scrollable-table__header-cell--season",
                            },
                            [
                              _c(
                                "fs-pill-season",
                                {
                                  staticClass: "season-snippet__season-pill",
                                  attrs: { color: entity.color, clickable: "" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(entity.name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "entity-teaser__checkbox-wrap" },
                        [
                          _c("checkbox-custom", {
                            ref: "articleCheckbox-" + entity,
                            refInFor: true,
                            staticClass: "entity-teaser__checkbox",
                            attrs: {
                              large: "",
                              standalone: "",
                              plm: "",
                              indeterminate: _vm.defineIsFewColumnItemsChecked(
                                entity.id
                              ),
                              checked: _vm.defineIsAllColumnItemsChecked(
                                entity.id
                              ),
                            },
                            on: {
                              check: function ($event) {
                                _vm.tickedCheckboxes = Array.from(
                                  new Set(
                                    _vm.tickedCheckboxes.concat(
                                      _vm.articles.map(function (article) {
                                        return (
                                          "entity:" +
                                          entity.id +
                                          "-article:" +
                                          article.id
                                        )
                                      })
                                    )
                                  )
                                )
                              },
                              uncheck: function ($event) {
                                _vm.tickedCheckboxes =
                                  _vm.tickedCheckboxes.filter(function (item) {
                                    return !new RegExp(
                                      "entity:" + entity.id + "[w:-]+"
                                    ).test(item)
                                  })
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "articles-column scrollable-table__articles-column",
                },
                _vm._l(_vm.articles, function (article, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: {
                        "article-line": true,
                        "article-line--hovered":
                          _vm.hoveredTableLineId === "" + article.id + index,
                        "articles-column__article-line": true,
                      },
                      on: {
                        mouseenter: function ($event) {
                          _vm.hoveredTableLineId = "" + article.id + index
                        },
                        mouseleave: function ($event) {
                          _vm.hoveredTableLineId = null
                        },
                      },
                    },
                    [
                      article.images.length
                        ? _c("photo-gallery", {
                            staticClass: "article-line__image",
                            attrs: {
                              images: article.images,
                              "save-func": function () {},
                              "simplified-view": "",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("fs-pill-color", {
                        staticClass: "article-line__color",
                        attrs: { color: article.color },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "article-line__number" }, [
                        _vm._v(_vm._s(article.articleNumber)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "article-line__bom" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              (article.principleMaterialIds.length &&
                                _vm.$store.componentTypes.getSubTypeFromId(
                                  article.principleMaterialIds[0].fullName
                                )) ||
                                "-"
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "article-line__genders" }, [
                        article.genders.includes(1)
                          ? _c("div", {
                              staticClass: "article-line__gender-icon",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.icons.gender[_vm.genders[1].icon]
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        article.genders.includes(2)
                          ? _c("div", {
                              staticClass: "article-line__gender-icon",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.icons.gender[_vm.genders[2].icon]
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        article.genders.includes(4)
                          ? _c("div", {
                              staticClass: "article-line__gender-icon",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.icons.gender[_vm.genders[4].icon]
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        article.genders.includes(8)
                          ? _c("div", {
                              staticClass:
                                "article-line__gender-icon article-line__gender-icon--babies",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.icons.gender[_vm.genders[8].icon]
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("fs-pill-status", {
                        staticClass: "article-line__status",
                        attrs: { status: article.status, size: "medium" },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "scrollable-table__checkboxes-list" },
                _vm._l(_vm.articles, function (article, index1) {
                  return _c(
                    "div",
                    {
                      key: index1,
                      staticClass:
                        "checkboxes-line scrollable-table__checkboxes-line",
                    },
                    _vm._l(_vm.entities, function (checkbox, index2) {
                      return _c(
                        "div",
                        {
                          key: index2,
                          class: {
                            "checkboxes-line__checkbox-wrap": true,
                            "checkboxes-line__checkbox-wrap--narrow":
                              _vm.tagsMode || _vm.seasonsMode,
                            "checkboxes-line__checkbox-wrap--hovered":
                              _vm.hoveredTableLineId ===
                              "" + article.id + index1,
                          },
                          on: {
                            mouseenter: function ($event) {
                              _vm.hoveredTableLineId = "" + article.id + index1
                            },
                            mouseleave: function ($event) {
                              _vm.hoveredTableLineId = null
                            },
                          },
                        },
                        [
                          _c("checkbox-custom", {
                            ref: "articleCheckbox-" + checkbox.id,
                            refInFor: true,
                            staticClass: "checkboxes-line__checkbox",
                            attrs: {
                              large: "",
                              standalone: "",
                              plm: "",
                              checked: false,
                              value:
                                "entity:" +
                                checkbox.id +
                                "-article:" +
                                article.id,
                            },
                            model: {
                              value: _vm.tickedCheckboxes,
                              callback: function ($$v) {
                                _vm.tickedCheckboxes = $$v
                              },
                              expression: "tickedCheckboxes",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "articles-n-checkboxes__manage-buttons-panel",
        attrs: { "is-save-in-progress": _vm.isSaveInProgress },
        on: {
          cancel: function ($event) {
            _vm.fsPopperClose($event)
            _vm.$emit("cancel", $event)
          },
          action: _vm.save,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }