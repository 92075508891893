<template>
  <!-- Social media form -->
  <div class="social-media-form">
    <div class="social-media-form__main-title">Social Media</div>

    <!-- Form itself -->
    <div class="form-itself social-media-form__form-itself">
      <div v-for="item in socialsList" :key="item" class="form-itself__section">
        <div class="form-itself__subtitle">
          {{ item }}
        </div>

        <input-custom
          :class="{ 'form-itself__text-input': 1, 'form-itself__text-input--empty': !inputsValues[item.toLowerCase()] }"
          :placeholder="`Add ${item} Account`"
          :additional-icon="icons.socials[item.toLowerCase() + 'Monochrome']"
          v-model="inputsValues[item.toLowerCase()]"
        />
      </div>
    </div>
    <!-- / Form itself -->

    <cancel-n-action-btn-pair class="social-media-form__manage-buttons-panel" @cancel="() => {}" @action="() => {}" />
  </div>
  <!-- / Social media form -->
</template>

<script>
// Components
import InputCustom from '@components/form-elements/input-custom.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
import socialsIcons from '@lib/icons-imports/socials';

export default {
  name: 'social-media-form',

  components: {
    InputCustom,
    CancelNActionBtnPair,
  },

  data() {
    return {
      isSaveInProgress: false,

      socialsList: ['Facebook', 'LinkedIn', 'Instagram', 'Twitter'],
      inputsValues: {},

      icons: {
        interfaceElements: interfaceElementsIcons,
        socials: socialsIcons,
      },
      svg: {},
    };
  },

  mounted() {
    // Set reactive data properties for each social network text input
    for (let i = 0; i < this.socialsList.length; i++) {
      this.$set(this.inputsValues, this.socialsList[i].toLowerCase(), '');
    }
  },

  updated() {
    // console.log('%c this.inputsValues: ','background:greenyellow;color:black;', this.inputsValues);
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// ============================================================================
// Form itself
.form-itself {
  &__section {
    margin-bottom: math.div(2.7rem, 1.6);
  }

  &__subtitle {
    margin-bottom: math.div(0.7rem, 1.6);
    padding-left: math.div(2.5rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.2rem, 1.6);
    text-transform: uppercase;
    color: rgba(53, 73, 94, 0.5);
  }

  &__text-input {
    ::v-deep .input-main-component__additional-icon {
      opacity: 1;
      transition: opacity 0.15s ease;
    }

    &--empty ::v-deep .input-main-component__additional-icon {
      opacity: 0.5;
    }

    ::v-deep .input-main-component__additional-icon svg {
      fill: rgb(53, 73, 94) !important;
    }
  }
}

// ============================================================================
// ============================================================================
// Manage buttons panel
.manage-buttons-panel {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  &__manage-but {
    width: math.div(13.5rem, 1.6);
    margin: 0 math.div(0.8rem, 1.6);
  }
}

// ============================================================================
// ============================================================================
// Social media form
.social-media-form {
  width: math.div(44rem, 1.6);
  padding: math.div(3.8rem, 1.6) math.div(4.7rem, 1.6);
  box-sizing: border-box;

  &__main-title {
    margin-bottom: math.div(2.5rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__form-itself {
    margin-bottom: math.div(5rem, 1.6);
  }

  &__manage-buttons-panel {
  }
}
</style>
