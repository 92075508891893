var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          class: {
            pill: true,
            "pill--circle-sides": _vm.circleSides,
            "pill--clickable": _vm.clickable || _vm.hoverMode,
            "pill--counter": _vm.counter,
            "pill--not-fixed": !_vm.fixed,
            "pill--inversed": _vm.inversed,
            "pill--outlined": _vm._outlined,

            "pill--small": _vm.size === "small",
            "pill--medium": _vm.size === "medium",
            "pill--semi-large": _vm.size === "semi-large",
            "pill--large": _vm.size === "large",
            "pill--extra-large": _vm.size === "extra-large",

            "pill--add-mode": _vm.hoverMode === "add",
            "pill--remove-mode": _vm.hoverMode === "remove",
          },
          style: _vm.style,
        },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.hoverMode
        ? _c("span", {
            class: {
              "plus-or-cross-icon": true,
              "pill__plus-or-cross-icon": true,
              "plus-or-cross-icon--small        pill__plus-or-cross-icon--small":
                _vm.size === "small",
              "plus-or-cross-icon--medium       pill__plus-or-cross-icon--medium":
                _vm.size === "medium" || _vm.size === "semi-large",
              "plus-or-cross-icon--large        pill__plus-or-cross-icon--large":
                _vm.size === "large" || _vm.size === "extra-large",
              "plus-or-cross-icon--45-deg-turn  pill__plus-or-cross-icon--45-deg-turn":
                _vm.hoverMode === "remove",
            },
            domProps: { innerHTML: _vm._s(_vm.icons.singles.addIcon) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("pre"),
      _vm._v(" "),
      _c("span", { staticClass: "pill__text" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm._t("post"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }