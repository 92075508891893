<template>
  <popover-container name="pageblock" :overlayClose="false" :escClose="false" v-if="!$store.user.loggedIn">
    <popover container="pageblock" name="logIn" default>
      <LogIn mode="popup" :email.sync="email" :password.sync="password" :toggled.sync="toggled"></LogIn>
    </popover>
    <popover container="pageblock" name="signUp">
      <SignUp mode="popup" :email.sync="email" :password.sync="password" :toggled.sync="toggled"></SignUp>
    </popover>
    <popover container="pageblock" name="forgotPassword">
      <ForgotPassword mode="popup" :email.sync="email"></ForgotPassword>
    </popover>
  </popover-container>
</template>

<script>
const LogIn = () => import('./../account/log-in.vue');
const SignUp = () => import('./../account/sign-up.vue');
const ForgotPassword = () => import('./../account/forgot-password.vue');
export default {
  components: { LogIn, SignUp, ForgotPassword },
  data() {
    return {
      email: '',
      password: '',
      toggled: false,
    };
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

.popover.popover-pageblock {
  .popover {
    &__container {
      z-index: 1;
    }

    &__overlay {
      background: rgba($c-light, 0.7);
    }
  }
}
</style>
