<template>
  <div :class="{ 'sustainability-rating': 1, 'sustainability-rating--desktop-hidden': desktopHidden }">
    <div class="sustainability-rating__header">Sustainability Rating</div>

    <!-- Rating Scale -->
    <div class="rating-scale sustainability-rating__rating-scale">
      <div class="rating-scale__scale">
        <div
          :class="{
            'rating-scale__scale-division': 1,
            'rating-scale__scale-division--active':
              index < sustainabilityPropsWithAvailability.filter((itm) => itm.available).length,
          }"
          v-for="(division, index) in sustainabilityPropsWithAvailability"
          :key="index"
        ></div>
      </div>

      <div class="rating-scale__counter">
        {{
          sustainabilityPropsWithAvailability.filter((itm) => itm.available).length +
          '/' +
          sustainabilityPropsWithAvailability.length
        }}
      </div>
    </div>

    <!-- Benefits List -->
    <div class="benefits-list sustainability-rating__benefits-list">
      <!-- Single Benefit -->
      <div
        v-for="(property, index) in sustainabilityPropsWithAvailability"
        :key="property.title"
        :class="{
          'single-benefit': 1,
          'single-benefit--inactive': !property.available,
          'benefits-list__single-benefit': 1,
        }"
      >
        <div class="single-benefit__icon" v-html="svg[property.icon + 'Icon']"></div>

        <div class="single-benefit__description">
          <div class="single-benefit__name">
            <div
              v-if="property.infoText"
              class="single-benefit__info-but"
              v-html="icons.miscellaneous.info"
              @click.prevent="$refs[`sustainabilityPropInfo-${index}`][0].toggle($event)"
            ></div>

            {{ property.title }}
          </div>

          <div v-if="property.subscript" class="single-benefit__subscript">
            {{ property.subscript }}
          </div>
        </div>

        <fs-popper
          v-if="property.infoText"
          :ref="`sustainabilityPropInfo-${index}`"
          animation="fade"
          arrow
          :modifiers="[
            { name: 'flip', enabled: false },
            { name: 'preventOverflow', options: { padding: 16 } },
          ]"
          :offset="[0, 30]"
          placement="top"
          wrapper-class="popper-wrapper
                          supplierProfile"
          style="width: 300px"
        >
          <a class="popover-close" @click.prevent="fsPopperClose">+</a>
          {{ property.infoText }}
        </fs-popper>

        <div
          v-if="property.available"
          class="single-benefit__availability-icon"
          v-html="icons.miscellaneous.checkMark"
        ></div>

        <div
          v-else
          class="single-benefit__availability-icon single-benefit__availability-icon--cross"
          v-html="icons.interfaceElements.crossClear"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { FsPopper, fsPopperClose } from '@components/poppers/fs-popper.vue';

// Icons
import miscellaneousIcons from '@lib/icons-imports/miscellaneous';
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
// SVG
import chatCloudInfoIcon from '@icons/miscellaneous/chat-cloud-info.svg';
import magnifierEyeIcon from '@icons/miscellaneous/magnifier-eye.svg';
import listHammerIcon from '@icons/miscellaneous/list-hammer.svg';
import circleAIcon from '@icons/miscellaneous/circle-a.svg';
import certificateIcon from '@icons/miscellaneous/certificate.svg';
import chipsIcon from '@icons/miscellaneous/chips.svg';

export default {
  props: {
    desktopHidden: Boolean,
    supplier: Object,
  },

  components: {
    FsPopper,
  },

  data() {
    return {
      sustainabilityProps: [
        {
          id: 0,
          title: 'Working with verified high standard brands',
          available: false,
          subscript: '',
          icon: 'chips',
          infoText:
            'High rating score on Good On You portal, which helps to choose fashion brands that have a positive impact on people, the planet and animals',
        },

        { id: 1, title: 'CoC Commitment', available: false, subscript: '', icon: 'listHammer', infoText: '' },

        {
          id: 2,
          title: 'Open Apparel Registry Profile',
          available: false,
          subscript: '',
          icon: 'circleA',
          infoText:
            'Supplier contributed to the transparent supply chain by providing information about their facility to neutral, non-profit organisation - OAR',
        },

        { id: 3, title: 'Supplier Transparency', available: false, subscript: '', icon: 'magnifierEye', infoText: '' },

        {
          id: 4,
          title: 'Certifications & Initiatives',
          available: false,
          subscript: '',
          icon: 'certificate',
          infoText: '',
        },

        {
          id: 5,
          title: 'Additional Company Information',
          available: false,
          subscript: '',
          icon: 'chatCloudInfo',
          infoText: '',
        },
      ],

      icons: {
        miscellaneous: miscellaneousIcons,
        interfaceElements: interfaceElementsIcons,
      },

      svg: {
        chatCloudInfoIcon,
        magnifierEyeIcon,
        listHammerIcon,
        circleAIcon,
        certificateIcon,
        chipsIcon,
      },
    };
  },

  mounted() {},

  methods: {
    fsPopperClose,
  },

  computed: {
    sustainabilityPropsWithAvailability() {
      return this.sustainabilityProps.map((item) => {
        if (item.title == 'Working With Verified high standard brands') {
          item.available = this.supplier.supplierBrands.reduce((acc, item) => {
            if (acc) return acc;
            return item.verified;
          }, false);
        } else if (item.title == 'CoC Commitment') {
          const okModules = this.$store.suppliers.codeOfConduct.map((module) => {
            // Each module is ok if more than half of features have been checked
            return (
              module.features.filter((feature) => Boolean(this.supplier[feature.key])).length >
              Math.floor(module.features.length / 2)
            );
          });

          item.available = !okModules.some((isModuleOk) => !isModuleOk); // All modules are ok
        } else if (item.title == 'Open Apparel Registry Profile') {
          item.available = !!this.supplier.oarid;
        } else if (item.title == 'Supplier Transparency') {
          item.available = this.supplier.suppliers.length >= 3;
        } else if (item.title == 'Certifications & Initiatives') {
          item.available = this.supplier.certificates.length >= 1;
        } else if (item.title == 'Additional Company Information') {
          item.available = !!(
            this.supplier.email.length &&
            this.supplier.phone &&
            this.supplier.founded &&
            this.supplier.employees &&
            this.supplier.lineWorkers &&
            this.supplier.femaleEmployees !== null &&
            this.supplier.immigrantEmployees !== null &&
            this.supplier.fullAddress &&
            (this.supplier.coordinates || this.supplier.oarid) &&
            this.supplier.country
          );
        }

        return item;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

// ============================================================================
// ============================================================================
// Single Benefit
.single-benefit {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;

  &--inactive {
    opacity: 0.25;
  }

  &__icon {
    width: math.div(3rem, 1.6);
    min-width: math.div(3rem, 1.6);
    height: math.div(3rem, 1.6);
    min-height: math.div(3rem, 1.6);
    margin-right: math.div(2.2rem, 1.6);
    padding-top: math.div(0.4rem, 1.6);

    ::v-deep svg {
      fill: rgb(53, 73, 94);
    }
  }

  &__description {
    flex-grow: 1;
  }

  &__name {
    width: 75%;
    margin-bottom: math.div(0.3rem, 1.6);
    position: relative;
    line-height: math.div(2.2rem, 1.6);
  }

  &__info-but {
    width: math.div(1.6rem, 1.6);
    height: math.div(1.6rem, 1.6);
    position: absolute;
    top: math.div(-0.7rem, 1.6);
    right: math.div(-0.7rem, 1.6);
    cursor: pointer;
    user-select: none;

    ::v-deep svg {
      fill: rgb(53, 73, 94);
    }

    &::before {
      content: '';
      width: calc(100% + #{math.div(1rem, 1.6)});
      height: calc(100% + #{math.div(1rem, 1.6)});
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__subscript {
    font-size: math.div(1rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
    text-transform: uppercase;
  }

  &__availability-icon {
    width: math.div(2.2rem, 1.6);
    height: math.div(2.2rem, 1.6);
    padding-top: math.div(0.4rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    &--cross {
      ::v-deep svg {
        width: math.div(1.8rem, 1.6);
        height: math.div(1.8rem, 1.6);
      }
    }

    ::v-deep svg {
      fill: rgb(53, 73, 94);
    }
  }
}

// ============================================================================
// ============================================================================
// Benefits List
.benefits-list {
  &__single-benefit {
    margin-bottom: math.div(2.2rem, 1.6);
  }
}

// ============================================================================
// ============================================================================
// Rating Scale
.rating-scale {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  &__scale {
    width: math.div(23.5rem, 1.6);
    height: math.div(0.7rem, 1.6);
    margin-bottom: math.div(1.2rem, 1.6);
    display: flex;
    justify-content: space-between;
  }

  &__scale-division {
    height: 100%;
    margin-right: math.div(0.4rem, 1.6);
    flex-grow: 1;
    background: rgba(53, 73, 94, 0.25);

    &:first-child {
      border-radius: math.div(10rem, 1.6) 0 0 math.div(10rem, 1.6);
    }

    &:last-child {
      margin-right: 0;
      border-radius: 0 math.div(10rem, 1.6) math.div(10rem, 1.6) 0;
    }

    &--active {
      background: rgba(53, 73, 94, 1);
    }
  }

  &__counter {
    font-size: math.div(1.2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
  }
}

// ============================================================================
// ============================================================================
// Sustainability Rating
.sustainability-rating {
  &--desktop-hidden {
    display: none;
  }

  @include mobile-only {
    padding: math.div(3rem, 1.6) 0 math.div(2rem, 1.6);

    &--desktop-hidden {
      display: block;
    }
  }

  &__header {
    @include heading--small;
    margin-bottom: math.div(1.8rem, 1.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  &__rating-scale {
    margin-bottom: math.div(3.5rem, 1.6);
  }

  &__benefits-list {
    padding: 0 math.div(2.5rem, 1.6);
  }
}
</style>
