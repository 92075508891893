import calendarCheck from '@icons/miscellaneous/calendar-check.svg';
import boxGeoMarker from '@icons/miscellaneous/box-geo-marker.svg';
import listWithBoxes from '@icons/miscellaneous/list-width-boxes.svg';
import lockLocked from '@icons/miscellaneous/lock-locked.svg';
import lockUnlocked from '@icons/miscellaneous/lock-unlocked.svg';
import tags from '@icons/other/tags.svg';
import info from '@icons/other/info.svg';
import clip from '@icons/miscellaneous/clip.svg';
import checkMark from '@icons/other/check-mark-icon.svg';

import chatCloudInfo from '@icons/miscellaneous/chat-cloud-info.svg';
import certificate from '@icons/miscellaneous/certificate.svg';
import magnifierEye from '@icons/miscellaneous/magnifier-eye.svg';
import circleA from '@icons/miscellaneous/circle-a.svg';
import listHammer from '@icons/miscellaneous/list-hammer.svg';
import chips from '@icons/miscellaneous/chips.svg';

import officeBuilding from '@icons/miscellaneous/office-building.svg';
import employees from '@icons/miscellaneous/employees.svg';

import nothingFound from '@icons/miscellaneous/nothing-found.svg';
import blocked from '@icons/miscellaneous/blocked.svg';

import tariffFilled from '@icons/miscellaneous/tariff-filled.svg';
import tariffLined from '@icons/miscellaneous/tariff-lined.svg';
import weightFilled from '@icons/miscellaneous/weight-filled.svg';
import weightLined from '@icons/miscellaneous/weight-lined.svg';

import shoeMeasurement from '@icons/miscellaneous/shoe-measurement.svg';

import eanCode from '@icons/miscellaneous/ean-code.svg';
import checkBadge from '@icons/miscellaneous/check-badge.svg';
import eanCodeExport from '@icons/miscellaneous/ean-code-export.svg';
import listEye from '@icons/miscellaneous/list-eye.svg';

import noResults from '@icons/miscellaneous/no-results.svg';

export default {
  calendarCheck,
  boxGeoMarker,
  listWithBoxes,
  lockLocked,
  lockUnlocked,
  tags,
  info,
  clip,
  checkMark,

  chatCloudInfo,
  certificate,
  magnifierEye,
  circleA,
  listHammer,
  chips,

  officeBuilding,
  employees,

  nothingFound,
  blocked,

  tariffFilled,
  tariffLined,
  weightFilled,
  weightLined,

  shoeMeasurement,

  eanCode,
  checkBadge,
  eanCodeExport,
  listEye,

  noResults,
};
