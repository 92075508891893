<template>
  <div class="gender-edit-form">
    <div class="gender-edit-form__main-title">Gender</div>

    <!-- Form itself -->
    <div class="form-itself gender-edit-form__form-itself">
      <checkbox-custom-with-icon
        v-for="gender in gendersList"
        :key="gender"
        class="form-itself__single-gender"
        @check="() => {}"
        @uncheck="() => {}"
        :icon="icons.gender[lodashCamelCase(gender)]"
        :text-label="gender"
      />
    </div>
    <!-- / Form itself -->

    <cancel-n-action-btn-pair class="gender-edit-form__manage-buttons-panel" @cancel="() => {}" @action="() => {}" />
  </div>
</template>

<script>
import lodashCamelCase from 'lodash/camelCase';

// Components
import CheckboxCustomWithIcon from '@components/form-elements/checkbox-custom-with-icon.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
import genderIcons from '@lib/icons-imports/gender';

export default {
  name: 'gender-edit',

  components: { CheckboxCustomWithIcon, CancelNActionBtnPair },

  data() {
    return {
      gendersList: ['Men', 'Ladies', 'Boys & Girls', 'Babies'],

      isSaveInProgress: false,

      icons: {
        interfaceElements: interfaceElementsIcons,
        gender: genderIcons,
      },
    };
  },

  methods: {
    lodashCamelCase,
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

// ============================================================================
// ============================================================================
// Form itself
.form-itself {
  width: math.div(18rem, 1.6);
  display: flex;
  flex-wrap: wrap;

  &__single-gender {
    width: 50%;
    margin-bottom: math.div(1.8rem, 1.6);
  }
}

// ==========================================================================
// ==========================================================================
// Gender edit form
.gender-edit-form {
  width: math.div(36.5rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(3.2rem, 1.6);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  &__main-title {
    margin-bottom: math.div(2.8rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__form-itself {
    margin-bottom: math.div(2rem, 1.6);
  }

  &__manage-buttons-panel {
  }
}
</style>
