var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "rootContainer",
      class: "generic-scrollable-block",
      style: { height: _vm.height },
    },
    [
      _c("transition", { attrs: { name: "simple-fade-in-out" } }, [
        _vm.showShadows && _vm.isTopShadowVisible
          ? _c(
              "div",
              {
                class: {
                  "generic-scrollable-block__shadow": true,
                  "generic-scrollable-block__shadow--top": true,
                  "generic-scrollable-block__shadow--color-white":
                    _vm.shadowsColor === "white",
                  "generic-scrollable-block__shadow--color-light-gray":
                    _vm.shadowsColor === "light-gray",
                  "generic-scrollable-block__shadow--color-dark-gray":
                    _vm.shadowsColor === "dark-gray",
                },
              },
              [_vm._v("\n       \n    ")]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "simple-fade-in-out" } }, [
        _vm.showShadows &&
        _vm.isContentExceedParentSize &&
        _vm.isBottomShadowVisible
          ? _c(
              "div",
              {
                class: {
                  "generic-scrollable-block__shadow": true,
                  "generic-scrollable-block__shadow--bottom": true,
                  "generic-scrollable-block__shadow--color-white":
                    _vm.shadowsColor === "white",
                  "generic-scrollable-block__shadow--color-light-gray":
                    _vm.shadowsColor === "light-gray",
                  "generic-scrollable-block__shadow--color-dark-gray":
                    _vm.shadowsColor === "dark-gray",
                },
              },
              [_vm._v("\n       \n    ")]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "scrollableContainer",
          class: {
            "generic-scrollable-block__inner": true,
            "generic-scrollable-block__inner--no-scrollbar": _vm.noScrollbar,
            "generic-scrollable-block__inner--no-bottom-margin":
              _vm.noBottomMargin || !_vm.showShadows,
            "generic-scrollable-block__inner--with-scrollbar":
              _vm.isScrollBarShowed,
          },
          on: {
            scroll: function ($event) {
              return _vm.toggleShadows($event)
            },
          },
        },
        [
          _c(
            "div",
            {
              ref: "contentWrapper",
              staticClass: "generic-scrollable-block__content-wrapper",
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }