var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "certification-section",
      attrs: { id: "certification-section" },
    },
    [
      _c("h4", { staticClass: "certification-section__header" }, [
        _vm._v("Certifications"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "certification-section__certifications" },
        _vm._l(_vm.data, function (certificate) {
          return _c(
            "div",
            {
              key: certificate.id,
              staticClass:
                "certification certification-section__single-certification",
            },
            [
              _c(
                "a",
                {
                  staticClass: "certification__logo",
                  attrs: {
                    href: certificate.organization.link,
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$ga.event(
                        "certifications",
                        "supplier page -> SATRA laboratory accreditation -> logo"
                      )
                    },
                  },
                },
                [
                  certificate.image
                    ? _c("img", {
                        attrs: {
                          src: _vm.$blobUrl + "/" + certificate.image,
                          alt: certificate.name,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "certification__logo-placeholder",
                    domProps: {
                      innerHTML: _vm._s(_vm.svg.logoPlaceholderIcon),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "certification__header" }, [
                _vm._v(_vm._s(certificate.name)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "certification__date" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(new Date(certificate.date).toLocaleDateString()) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              certificate.file
                ? _c(
                    "a",
                    {
                      staticClass: "link link--dark certification__proof",
                      attrs: {
                        href: certificate.organization.link,
                        target: "_blank",
                        rel: "noopener noreferrer",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$ga.event(
                            "certifications",
                            "supplier page -> SATRA laboratory accreditation -> details"
                          )
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "certification__proof-icon",
                        domProps: { innerHTML: _vm._s(_vm.svg.filePdf) },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "certification__proof-text" }, [
                        _vm._v("Proof Letter"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }