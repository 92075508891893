var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-textarea",
      class:
        (_vm.isDisabled && "custom-textarea--disabled") +
        "\n            " +
        (_vm.isError && "custom-textarea--error") +
        "\n            " +
        (_vm.isFocus && "custom-textarea--active"),
    },
    [
      _c("label", { staticClass: "custom-textarea__textarea-label" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.val,
              expression: "val",
            },
          ],
          ref: "input",
          class:
            "custom-textarea__textarea\n              " +
            (_vm.maxSymbols &&
              _vm.count > 0 &&
              "custom-textarea__textarea--bottom-counter-padding"),
          style: _vm.computedStyles,
          attrs: {
            disabled: _vm.isDisabled,
            placeholder: _vm.placeholder,
            maxlength: _vm.maxSymbols,
          },
          domProps: { value: _vm.val },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.val = $event.target.value
              },
              function ($event) {
                return _vm.update()
              },
            ],
            focus: function ($event) {
              _vm.isFocus = true
              _vm.$emit("focus", $event)
              _vm.resize
            },
            blur: function ($event) {
              _vm.isFocus = false
              _vm.$emit("blur", $event)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _vm.maxSymbols && _vm.count > 0
        ? _c("span", { staticClass: "custom-textarea__counter" }, [
            _vm._v(
              " " + _vm._s(_vm.count) + "/" + _vm._s(_vm.maxSymbols) + " "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isDisabled
        ? _c("span", {
            staticClass: "custom-textarea__lock",
            domProps: { innerHTML: _vm._s(_vm.svg.lock) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }