// Functions was copied from here https://www.webtoolkit.info/javascript_color_conversion.html
// TODO: REVIEW - long term we should probably move to using npm lib 'color-convert' instead, to have standardized functions for all conversions - instead our semi-homegrown solution
import lodashIsFinite from 'lodash/isFinite';
import lodashIsEqual from 'lodash/isEqual';
import lodashOmit from 'lodash/omit';

// ============================================================================
// ============================================================================
// Calculate Color Brightness
export const calculateColorBrightness = (color) => {
  // convert "rgb(255,255,255)" notation to {r:255,g:255,b:255} object
  if (typeof color === 'string' && color.trim().startsWith('rgb(')) {
    const [r, g, b] = color.match(/\d+/g); // See https://stackoverflow.com/a/3752026/856559 for reference
    color = { r: +r, g: +g, b: +b };
  }

  const colorProfile = defineColorProfile(color);

  if (colorProfile !== 'rgb' && colorProfile !== 'rgbHex3' && colorProfile !== 'rgbHex6') {
    console.error('Unknown color profile ', colorProfile);
    return null;
  }

  if (colorProfile === 'rgbHex3' || colorProfile === 'rgbHex6') {
    color = convertHEXtoRGB(color);
  }

  // 3rd option of accepted answer here: https://stackoverflow.com/questions/596216/formula-to-determine-brightness-of-rgb-color
  return Math.round(Math.sqrt(color.r * color.r * 0.299 + color.g * color.g * 0.587 + color.b * color.b * 0.114));

  // Old one - unclear source
  // return Math.round(Math.sqrt(color.r * color.r * .241 + color.g * color.g * .691 + color.b * color.b * .068));
};

// ===========================================================================
// ===========================================================================
// Define Color Profile
export const defineColorProfile = (color) => {
  // Formats: f0f, #f0f, ff00ff, #ff00ff, {r:10,g:10,b:10}, {h:10,s:10,l:10}, {h:10,s:10,v:10}, {c:10,m:10,y:10,k:10}

  const isObject = typeof color === 'object';
  let isHexRgb;
  let isHexCodeLength3;
  let isHexCodeLength6;

  // ==================================
  // Check if color is a valid hex rgb
  if (typeof color !== 'string') {
    isHexRgb = false;
  } else {
    color = color.trim().replace(/#/, '');
    let normalizedColor;
    if (color.length === 3)
      normalizedColor = color
        .split('')
        .map((color) => color + color)
        .join('');
    isHexRgb = normalizedColor ? !!normalizedColor.match(/[0-9a-f]{6}/i) : !!color.match(/[0-9a-f]{6}/i);
  }

  // Define hex code length
  let refinedString;
  if (isHexRgb) {
    refinedString = color.trim().replace(/#/, '');
    if (refinedString.length === 3) isHexCodeLength3 = true;
    if (refinedString.length === 6) isHexCodeLength6 = true;
  }
  // ==================================

  const r = color.r !== undefined && color.r !== null && color.r !== '';
  const g = color.g !== undefined && color.g !== null && color.g !== '';
  const b = color.b !== undefined && color.b !== null && color.b !== '';

  const h = color.h !== undefined && color.h !== null && color.h !== '';
  const s = color.s !== undefined && color.s !== null && color.s !== '';
  const l = color.l !== undefined && color.l !== null && color.l !== '';
  const v = color.v !== undefined && color.v !== null && color.v !== '';

  const c = color.c !== undefined && color.c !== null && color.c !== '';
  const m = color.m !== undefined && color.m !== null && color.m !== '';
  const y = color.y !== undefined && color.y !== null && color.y !== '';
  const k = color.k !== undefined && color.k !== null && color.k !== '';

  if (isHexRgb && isHexCodeLength3) return 'rgbHex3';
  if (isHexRgb && isHexCodeLength6) return 'rgbHex6';
  if (isObject && r && g && b) return 'rgb';
  if (isObject && h && s && l) return 'hsl';
  if (isObject && h && s && v) return 'hsv';
  if (isObject && c && m && y && k) return 'cmyk';

  console.error('Unknown color profile for color: ', color);
  return null;
};

// ============================================================================
// ============================================================================
// RGB to HSV
export const convertRGBtoHSV = (r, g, b) => {
  const HSV = function (h, s, v) {
    if (h <= 0) {
      h = 0;
    }
    if (s <= 0) {
      s = 0;
    }
    if (v <= 0) {
      v = 0;
    }

    if (h > 360) {
      h = 360;
    }
    if (s > 100) {
      s = 100;
    }
    if (v > 100) {
      v = 100;
    }

    this.h = h;
    this.s = s;
    this.v = v;
  };

  let result = new HSV(0, 0, 0);

  r = r / 255;
  g = g / 255;
  b = b / 255;

  let minVal = Math.min(r, g, b);
  let maxVal = Math.max(r, g, b);
  let delta = maxVal - minVal;

  result.v = maxVal;

  if (delta === 0) {
    result.h = 0;
    result.s = 0;
  } else {
    result.s = delta / maxVal;
    let del_R = ((maxVal - r) / 6 + delta / 2) / delta;
    let del_G = ((maxVal - g) / 6 + delta / 2) / delta;
    let del_B = ((maxVal - b) / 6 + delta / 2) / delta;

    if (r === maxVal) {
      result.h = del_B - del_G;
    } else if (g === maxVal) {
      result.h = 1 / 3 + del_R - del_B;
    } else if (b === maxVal) {
      result.h = 2 / 3 + del_G - del_R;
    }

    if (result.h < 0) {
      result.h += 1;
    }
    if (result.h > 1) {
      result.h -= 1;
    }
  }

  result.h = Math.round(result.h * 360);
  result.s = Math.round(result.s * 100);
  result.v = Math.round(result.v * 100);

  return result;
};

// ============================================================================
// ============================================================================
// HSV to RGB
export const convertHSVtoRGB = (h, s, v) => {
  const RGB = function (r, g, b) {
    if (r <= 0) {
      r = 0;
    }
    if (g <= 0) {
      g = 0;
    }
    if (b <= 0) {
      b = 0;
    }

    if (r > 255) {
      r = 255;
    }
    if (g > 255) {
      g = 255;
    }
    if (b > 255) {
      b = 255;
    }

    this.r = r;
    this.g = g;
    this.b = b;
  };

  let result = new RGB(0, 0, 0);

  h = h / 360;
  s = s / 100;
  v = v / 100;

  let var_1, var_2, var_3, var_b, var_g, var_h, var_i, var_r;

  if (s === 0) {
    result.r = v * 255;
    result.g = v * 255;
    result.v = v * 255;
  } else {
    var_h = h * 6;
    var_i = Math.floor(var_h);
    var_1 = v * (1 - s);
    var_2 = v * (1 - s * (var_h - var_i));
    var_3 = v * (1 - s * (1 - (var_h - var_i)));

    if (var_i === 0) {
      var_r = v;
      var_g = var_3;
      var_b = var_1;
    } else if (var_i === 1) {
      var_r = var_2;
      var_g = v;
      var_b = var_1;
    } else if (var_i === 2) {
      var_r = var_1;
      var_g = v;
      var_b = var_3;
    } else if (var_i === 3) {
      var_r = var_1;
      var_g = var_2;
      var_b = v;
    } else if (var_i === 4) {
      var_r = var_3;
      var_g = var_1;
      var_b = v;
    } else {
      var_r = v;
      var_g = var_1;
      var_b = var_2;
    }

    result.r = var_r * 255;
    result.g = var_g * 255;
    result.b = var_b * 255;

    result.r = Math.round(result.r);
    result.g = Math.round(result.g);
    result.b = Math.round(result.b);
  }

  return result;
};

// ============================================================================
// ============================================================================
// CMYK to RGB
export const convertCMYKtoRGB = (c, m, y, k) => {
  const RGB = function (r, g, b) {
    if (r <= 0) {
      r = 0;
    }
    if (g <= 0) {
      g = 0;
    }
    if (b <= 0) {
      b = 0;
    }

    if (r > 255) {
      r = 255;
    }
    if (g > 255) {
      g = 255;
    }
    if (b > 255) {
      b = 255;
    }

    this.r = r;
    this.g = g;
    this.b = b;
  };

  let result = new RGB(0, 0, 0);

  c = c / 100;
  m = m / 100;
  y = y / 100;
  k = k / 100;

  result.r = 1 - Math.min(1, c * (1 - k) + k);
  result.g = 1 - Math.min(1, m * (1 - k) + k);
  result.b = 1 - Math.min(1, y * (1 - k) + k);

  result.r = Math.round(result.r * 255);
  result.g = Math.round(result.g * 255);
  result.b = Math.round(result.b * 255);

  return result;
};

// ============================================================================
// ============================================================================
// RGB to CMYK
export const convertRGBtoCMYK = (r, g, b) => {
  const CMYK = function (c, m, y, k) {
    if (c <= 0) {
      c = 0;
    }
    if (m <= 0) {
      m = 0;
    }
    if (y <= 0) {
      y = 0;
    }
    if (k <= 0) {
      k = 0;
    }

    if (c > 100) {
      c = 100;
    }
    if (m > 100) {
      m = 100;
    }
    if (y > 100) {
      y = 100;
    }
    if (k > 100) {
      k = 100;
    }

    this.c = c;
    this.m = m;
    this.y = y;
    this.k = k;
  };

  let result = new CMYK(0, 0, 0, 0);

  r = r / 255;
  g = g / 255;
  b = b / 255;

  result.k = Math.min(1 - r, 1 - g, 1 - b);
  result.c = (1 - r - result.k) / (1 - result.k);
  result.m = (1 - g - result.k) / (1 - result.k);
  result.y = (1 - b - result.k) / (1 - result.k);

  if (result.k === 1) {
    result.c = 0;
    result.m = 0;
    result.y = 0;
  }

  result.c = Math.round(result.c * 100);
  result.m = Math.round(result.m * 100);
  result.y = Math.round(result.y * 100);
  result.k = Math.round(result.k * 100);

  return result;
};

// ============================================================================
// ===========================================================================
// HEX to RGB
export const convertHEXtoRGB = (colorString) => {
  colorString = colorString.trim().replace(/#/, '');
  if (colorString.length === 3)
    colorString = colorString
      .split('')
      .map((symbol) => symbol + symbol)
      .join('');

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorString);

  if (result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  }

  return null;
};

// ============================================================================
// ============================================================================
// RGB to HEX
export const convertRGBtoHEX = (r, g, b) => {
  const colorCodeToHex = (color) => {
    const hex = color.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  const rgbToHex = (red, green, blue) => {
    return '#' + colorCodeToHex(Math.round(red)) + colorCodeToHex(Math.round(green)) + colorCodeToHex(Math.round(blue));
  };

  // Object format
  if (r.hasOwnProperty('r')) return rgbToHex(r.r, r.g, r.b);

  // Regular format
  return rgbToHex(r, g, b);
};

// ============================================================================
// ============================================================================
// Validate RGB value
export const validRgbValue = (x) => {
  return lodashIsFinite(x) && x > -1 && x < 256;
};

// ============================================================================
// ============================================================================
// Validate RGB valueS
export const validRgbValues = (rgb) => {
  return rgb && validRgbValue(rgb.r) && validRgbValue(rgb.g) && validRgbValue(rgb.b);
};

// ============================================================================
// ============================================================================
// RGB to CSS value
export const rgbToCssValue = (r, g, b) => {
  // Object format
  if (r.hasOwnProperty('r')) {
    g = r.g;
    b = r.b;
    r = r.r;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

// ============================================================================
// ============================================================================
// Color data structure => BaseColor data structure
export const colorToBaseColor = (color) => color?.colorPaletteColor || color?.customColor;

export const copyBaseColorProperties = (target, source) => {
  target.name = source.name;
  target.code = source.code;
  target.c = source.c;
  target.m = source.m;
  target.y = source.y;
  target.k = source.k;
  target.r = source.r;
  target.g = source.g;
  target.b = source.b;

  return target;
};

export const createColorSimple = () => {
  // Null values to ensure Vue reactivity works as it should
  return {
    colorPaletteColor: null,
    customColor: createBaseColor(),
    group: null,
  };
};

export const createBaseColor = () => {
  return {
    name: 'White',
    code: '',
    // White (better for the color picker, since otherwise the whole color wheel is black)
    c: 0,
    m: 0,
    y: 0,
    k: 0,
    r: 255,
    g: 255,
    b: 255,
  };
};

// // Functions was copied from here https://www.webtoolkit.info/javascript_color_conversion.html
//
// // ============================================================================
// // ============================================================================
// // RGB to HSV
// export const convertRGBtoHSV = (r,g,b) => {
//
//   const HSV = function(h,s,v) {
//     if (h <= 0) { h = 0; }
//     if (s <= 0) { s = 0; }
//     if (v <= 0) { v = 0; }
//
//     if (h > 360) { h = 360; }
//     if (s > 100) { s = 100; }
//     if (v > 100) { v = 100; }
//
//     this.h = h;
//     this.s = s;
//     this.v = v;
//   }
//
//   let result = new HSV(0,0,0);
//
//   r = r / 255;
//   g = g / 255;
//   b = b / 255;
//
//   let minVal = Math.min(r,g,b);
//   let maxVal = Math.max(r,g,b);
//   let delta = maxVal - minVal;
//
//   result.v = maxVal;
//
//   if (delta === 0) {
//     result.h = 0;
//     result.s = 0;
//   } else {
//     result.s = delta / maxVal;
//     let del_R = (((maxVal - r) / 6) + (delta / 2)) / delta;
//     let del_G = (((maxVal - g) / 6) + (delta / 2)) / delta;
//     let del_B = (((maxVal - b) / 6) + (delta / 2)) / delta;
//
//     if (r === maxVal) { result.h = del_B - del_G; }
//     else if (g === maxVal) { result.h = (1 / 3) + del_R - del_B; }
//     else if (b === maxVal) { result.h = (2 / 3) + del_G - del_R; }
//
//     if (result.h < 0) { result.h += 1; }
//     if (result.h > 1) { result.h -= 1; }
//   }
//
//   result.h = Math.round(result.h * 360);
//   result.s = Math.round(result.s * 100);
//   result.v = Math.round(result.v * 100);
//
//   return result;
// };
//
//
//
// // ============================================================================
// // ============================================================================
// // HSV to RGB
// export const convertHSVtoRGB = (h,s,v) => {
//
//   const RGB = function(r,g,b) {
//     if (r <= 0) { r = 0; }
//     if (g <= 0) { g = 0; }
//     if (b <= 0) { b = 0; }
//
//     if (r > 255) { r = 255; }
//     if (g > 255) { g = 255; }
//     if (b > 255) { b = 255; }
//
//     this.r = r;
//     this.g = g;
//     this.b = b;
//   }
//
//   let result = new RGB(0, 0, 0);
//
//   h = h / 360;
//   s = s / 100;
//   v = v / 100;
//
//   let var_1, var_2, var_3, var_b, var_g, var_h, var_i, var_r;
//
//   if (s === 0) {
//     result.r = v * 255;
//     result.g = v * 255;
//     result.v = v * 255;
//
//   } else {
//     var_h = h * 6;
//     var_i = Math.floor(var_h);
//     var_1 = v * (1 - s);
//     var_2 = v * (1 - s * (var_h - var_i));
//     var_3 = v * (1 - s * (1 - (var_h - var_i)));
//
//     if (var_i === 0) { var_r = v; var_g = var_3; var_b = var_1 }
//     else if (var_i === 1) { var_r = var_2; var_g = v; var_b = var_1 }
//     else if (var_i === 2) { var_r = var_1; var_g = v; var_b = var_3 }
//     else if (var_i === 3) { var_r = var_1; var_g = var_2; var_b = v; }
//     else if (var_i === 4) { var_r = var_3; var_g = var_1; var_b = v; }
//     else {var_r = v; var_g = var_1; var_b = var_2}
//
//     result.r = var_r * 255;
//     result.g = var_g * 255;
//     result.b = var_b * 255;
//
//     result.r = Math.round(result.r);
//     result.g = Math.round(result.g);
//     result.b = Math.round(result.b);
//   }
//
//   return result;
// };
//
//
//
// // ============================================================================
// // ============================================================================
// // CMYK to RGB
// export const convertCMYKtoRGB = (c,m,y,k) => {
//
//   const RGB = function(r,g,b) {
//     if (r <= 0) { r = 0; }
//     if (g <= 0) { g = 0; }
//     if (b <= 0) { b = 0; }
//
//     if (r > 255) { r = 255; }
//     if (g > 255) { g = 255; }
//     if (b > 255) { b = 255; }
//
//     this.r = r;
//     this.g = g;
//     this.b = b;
//   }
//
//   let result = new RGB(0, 0, 0);
//
//   c = c / 100;
//   m = m / 100;
//   y = y / 100;
//   k = k / 100;
//
//   result.r = 1 - Math.min( 1, c * ( 1 - k ) + k );
//   result.g = 1 - Math.min( 1, m * ( 1 - k ) + k );
//   result.b = 1 - Math.min( 1, y * ( 1 - k ) + k );
//
//   result.r = Math.round( result.r * 255 );
//   result.g = Math.round( result.g * 255 );
//   result.b = Math.round( result.b * 255 );
//
//   return result;
// };
//
//
//
// // ============================================================================
// // ============================================================================
// // RGB to CMYK
// export const convertRGBtoCMYK = (r,g,b) => {
//
//   const CMYK = function(c,m,y,k) {
//     if (c <= 0) { c = 0; }
//     if (m <= 0) { m = 0; }
//     if (y <= 0) { y = 0; }
//     if (k <= 0) { k = 0; }
//
//     if (c > 100) { c = 100; }
//     if (m > 100) { m = 100; }
//     if (y > 100) { y = 100; }
//     if (k > 100) { k = 100; }
//
//     this.c = c;
//     this.m = m;
//     this.y = y;
//     this.k = k;
//   }
//
//   let result = new CMYK(0,0,0,0);
//
//   r = r / 255;
//   g = g / 255;
//   b = b / 255;
//
//   result.k = Math.min( 1 - r, 1 - g, 1 - b );
//   result.c = ( 1 - r - result.k ) / ( 1 - result.k );
//   result.m = ( 1 - g - result.k ) / ( 1 - result.k );
//   result.y = ( 1 - b - result.k ) / ( 1 - result.k );
//
//   if ( result.k===1 ) {
//     result.c = 0;
//     result.m = 0;
//     result.y = 0;
//   }
//
//   result.c = Math.round( result.c * 100 );
//   result.m = Math.round( result.m * 100 );
//   result.y = Math.round( result.y * 100 );
//   result.k = Math.round( result.k * 100 );
//
//   return result;
// };
//
//
//
// // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
//
// // ============================================================================
// // ============================================================================
// // RGB to HEX
// export const convertRGBtoHEX = (r,g,b) => {
//   const colorCodeToHex = color => {
//     const hex = color.toString(16);
//     return hex.length === 1 ? '0' + hex : hex;
//   }
//
//   const rgbToHex = (red, green, blue) => {
//     return '#' + colorCodeToHex(red) + colorCodeToHex(green) + colorCodeToHex(blue);
//   }
//
//   return rgbToHex(r, g, b);
// };
//
//
//
// // ============================================================================
// // ============================================================================
// // HEX to RGB
// export const convertHEXtoRGB = hex => {
//   // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
//   const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
//   hex = hex.replace(shorthandRegex, function(m, r, g, b) {
//     return r + r + g + g + b + b;
//   });
//
//   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//
//   return result
//     ? {r: parseInt(result[1],16), g: parseInt(result[2],16), b: parseInt(result[3],16)}
//     : null;
// };

export function parseColor(color) {
  if (!color) {
    return 'transparent';
  }

  if (typeof color === 'string') {
    return color;
  }

  // ColorSimple
  if (color?.colorPaletteColor || color?.customColor) {
    const baseColor = colorToBaseColor(color);

    if (baseColor) return convertRGBtoHEX(baseColor);
  }

  // ColorSuperSimple
  return convertRGBtoHEX(color);
}

export function isColorsEqual(color1, color2) {
  if (!color1 || !color2) {
    return false;
  }

  return (
    color1.colorPaletteColor?.id === color2.colorPaletteColor?.id &&
    lodashIsEqual(lodashOmit(color1.customColor, ['code']), lodashOmit(color2.customColor, ['code'])) &&
    (color1.customColor?.code || color2.customColor?.code ? color1.customColor.code === color2.customColor.code : true)
  );
}
