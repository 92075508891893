var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "product-gallery": true,
        "product-gallery--centered-content": _vm.simplifiedView,
      },
    },
    [
      _vm.simplifiedView
        ? _c(
            "div",
            {
              staticClass:
                "simplified-view-container product-gallery__simplified-view-container",
            },
            [
              _vm.filesToUpload
                ? _c(
                    "div",
                    {
                      staticClass:
                        "loading-overlay simplified-view-container__loading-overlay",
                    },
                    [
                      _c("div", {
                        class: {
                          "loading-overlay__progress-icon": true,
                          "loading-overlay__progress-icon--spinning": true,
                        },
                        domProps: {
                          innerHTML: _vm._s(_vm.svg.loadingSpinnerIcon),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("transition", { attrs: { name: "simple-fade-in-out" } }, [
                !_vm.filesToUpload && _vm.isDragNDropAreaShowed
                  ? _c(
                      "div",
                      {
                        class: {
                          "drag-n-drop-overlay": true,
                          "drag-n-drop-overlay--opaque": true,
                          "drag-n-drop-overlay--hovered":
                            _vm.isDragNDropAreaHovered,
                          "simplified-view-container__drag-n-drop-overlay": true,
                        },
                        on: {
                          drop: function ($event) {
                            $event.preventDefault()
                            return (function ($event) {
                              return _vm.handleAdditionalImagesDrop($event)
                            })($event)
                          },
                          dragover: function ($event) {
                            $event.preventDefault()
                            _vm.isDragNDropAreaHovered = true
                          },
                          dragleave: function ($event) {
                            $event.preventDefault()
                            _vm.isDragNDropAreaHovered = false
                          },
                        },
                      },
                      [
                        _c("div", {
                          staticClass:
                            "drag-n-drop-overlay__icon drag-n-drop-overlay__icon--smaller drag-n-drop-overlay__icon--no-bottom-margin",
                          domProps: {
                            innerHTML: _vm._s(_vm.svg.uploadArrowCloudIcon),
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "simplified-view-container__main-image-wrap": true,
                    "simplified-view-container__main-image-wrap--transparent":
                      !_vm.filesToUpload && _vm.isDragNDropAreaShowed,
                  },
                },
                [
                  _vm.images[0]
                    ? _c("img", {
                        staticClass: "simplified-view-container__main-image",
                        style: { opacity: _vm.images[0] ? 1 : 0.25 },
                        attrs: {
                          src: _vm.$blobUrl + "/" + _vm.images[0].url,
                          alt: "Product Photo",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.editPhotosModal.toggle($event)
                          },
                        },
                      })
                    : _c("add-content-placeholder", {
                        staticClass: "simplified-view-container__placeholder",
                        attrs: { label: "Image" },
                      }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "product-gallery__in-wrap" },
            [
              !_vm.images || _vm.images.length === 0
                ? _c(
                    "div",
                    { staticClass: "product-gallery__photo-placeholder" },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.placeholderImage,
                          alt: "Shoe photos",
                          draggable: "false",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("transition", { attrs: { name: "simple-fade-in-out" } }, [
                !_vm.filesToUpload && _vm.images.length === 0
                  ? _c(
                      "label",
                      {
                        class: {
                          "add-photo-drag-area": true,
                          "add-photo-drag-area--hovered":
                            _vm.isDragNDropAreaHovered,
                          "product-gallery__add-photo-drag-area": true,
                        },
                        attrs: { for: "uploadPhotosInput" },
                        on: {
                          drop: function ($event) {
                            $event.preventDefault()
                            return _vm.uploadImages($event)
                          },
                          dragover: function ($event) {
                            $event.preventDefault()
                            _vm.isDragNDropAreaHovered = true
                          },
                          dragleave: function ($event) {
                            $event.preventDefault()
                            _vm.isDragNDropAreaHovered = false
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "add-photo-drag-area__icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.svg.uploadArrowCloudIcon),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "add-photo-drag-area__title" },
                          [_vm._v(" Drag Images To Upload ")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "add-photo-drag-area__or" }, [
                          _vm._v(" or "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass:
                              "add-photo-drag-area__add-files-but-label",
                            attrs: { for: "uploadPhotosInput" },
                            on: {
                              drop: function ($event) {
                                $event.preventDefault()
                                return _vm.uploadImages($event)
                              },
                              dragover: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c(
                              "fs-button",
                              {
                                staticClass:
                                  "add-photo-drag-area__add-files-but",
                                attrs: { secondary: "" },
                              },
                              [
                                _c("fs-button-icon", {
                                  attrs: {
                                    icon: _vm.svg.photoPlaceholderIcon,
                                    width: "1rem",
                                    height: "1rem",
                                    top: "-1px",
                                    color: "dark-blue",
                                  },
                                }),
                                _vm._v(
                                  "\n            Browse images\n          "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "add-photo-drag-area__add-files-input",
                          attrs: {
                            type: "file",
                            id: "uploadPhotosInput",
                            multiple: "",
                          },
                          on: {
                            change: function ($event) {
                              $event.preventDefault()
                              return _vm.uploadImages($event)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "add-photo-drag-area__bg-layer",
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { name: "simple-fade-in-out" } }, [
                !_vm.filesToUpload && _vm.isDragNDropAreaShowed
                  ? _c(
                      "div",
                      {
                        class: {
                          "drag-n-drop-overlay": true,
                          "drag-n-drop-overlay--hovered":
                            _vm.isDragNDropAreaHovered,
                          "product-gallery__drag-n-drop-overlay": true,
                        },
                        on: {
                          drop: function ($event) {
                            $event.preventDefault()
                            return (function ($event) {
                              return _vm.handleAdditionalImagesDrop($event)
                            })($event)
                          },
                          dragover: function ($event) {
                            $event.preventDefault()
                            _vm.isDragNDropAreaHovered = true
                          },
                          dragleave: function ($event) {
                            $event.preventDefault()
                            _vm.isDragNDropAreaHovered = false
                          },
                        },
                      },
                      [
                        _c("div", {
                          staticClass: "drag-n-drop-overlay__icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.svg.uploadArrowCloudIcon),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "drag-n-drop-overlay__text" },
                          [_vm._v(" Drop Images to upload ")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.images.length > 0
                ? _c(
                    "div",
                    {
                      class: {
                        "product-gallery__main-photo-wrap1": true,
                        "product-gallery__main-photo-wrap1--blurred":
                          _vm.isDragNDropAreaShowed,
                      },
                      on: {
                        dragover: function ($event) {
                          $event.preventDefault()
                          return _vm.showDragNDropOverlay($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "product-gallery__main-photo-wrap2" },
                        [
                          _c(
                            "transition-group",
                            {
                              attrs: {
                                tag: "div",
                                name: "simple-fade-in-out-long",
                              },
                            },
                            _vm._l(_vm.images, function (photo, index) {
                              return _vm.images.length > 0 &&
                                index === _vm.activeSlideNumber
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "touch",
                                          rawName: "v-touch:swipe.left",
                                          value: function () {
                                            return _vm.switchMainPhoto("next")
                                          },
                                          expression:
                                            "() => switchMainPhoto('next')",
                                          arg: "swipe",
                                          modifiers: { left: true },
                                        },
                                        {
                                          name: "touch",
                                          rawName: "v-touch:swipe.right",
                                          value: function () {
                                            return _vm.switchMainPhoto(
                                              "previous"
                                            )
                                          },
                                          expression:
                                            "() => switchMainPhoto('previous')",
                                          arg: "swipe",
                                          modifiers: { right: true },
                                        },
                                      ],
                                      key: photo.id,
                                      staticClass:
                                        "product-gallery__main-photo-wrap3",
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "product-gallery__main-photo",
                                        attrs: {
                                          draggable: "false",
                                          src:
                                            _vm.$blobUrl +
                                            "/" +
                                            _vm.images[_vm.activeSlideNumber]
                                              .url,
                                          alt: "Product Photo",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e()
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.images.length > 0
                ? _c(
                    "div",
                    {
                      class: {
                        "product-gallery__bottom-panel": true,
                        "product-gallery__bottom-panel--blurred":
                          _vm.isDragNDropAreaShowed,
                      },
                      on: {
                        dragover: function ($event) {
                          $event.preventDefault()
                          return _vm.showDragNDropOverlay($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: {
                            "product-gallery__previews-list-wrap1": true,
                            "product-gallery__previews-list-wrap1--white-gradients":
                              _vm.whiteBackground,
                            "product-gallery__previews-list-wrap1--full-width":
                              _vm.verticalLayout,
                          },
                        },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "simple-fade-in-out" } },
                            [
                              _vm.showLeftArr
                                ? _c("div", {
                                    staticClass:
                                      "product-gallery__slider-arrow product-gallery__slider-arrow--left",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.svg.caretLeft),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.scrollSlider("toTheRight")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "transition",
                            { attrs: { name: "simple-fade-in-out" } },
                            [
                              _vm.showRightArr &&
                              _vm.images.length - _vm.previewsNumber > 0
                                ? _c("div", {
                                    staticClass:
                                      "product-gallery__slider-arrow product-gallery__slider-arrow--right",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.svg.caretRight),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.scrollSlider("toTheLeft")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "touch",
                                  rawName: "v-touch:swipe.left",
                                  value: function () {
                                    return _vm.scrollSlider("toTheLeft")
                                  },
                                  expression: "() => scrollSlider('toTheLeft')",
                                  arg: "swipe",
                                  modifiers: { left: true },
                                },
                                {
                                  name: "touch",
                                  rawName: "v-touch:swipe.right",
                                  value: function () {
                                    return _vm.scrollSlider("toTheRight")
                                  },
                                  expression:
                                    "() => scrollSlider('toTheRight')",
                                  arg: "swipe",
                                  modifiers: { right: true },
                                },
                              ],
                              class: {
                                "product-gallery__previews-list-wrap2": true,
                                "product-gallery__previews-list-wrap2--high":
                                  _vm.previewsNumber === 3,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "product-gallery__previews-list-wrap3",
                                },
                                [
                                  _c(
                                    "ul",
                                    {
                                      class: {
                                        "product-gallery__previews-list": true,
                                        "product-gallery__previews-list--3-slides":
                                          _vm.previewsNumber === 3,
                                      },
                                      style: {
                                        transform:
                                          "translateX(" +
                                          _vm.sliderScrollPosition * -100 +
                                          "%)",
                                      },
                                    },
                                    _vm._l(_vm.images, function (photo, index) {
                                      return _vm.images.length > 0
                                        ? _c(
                                            "li",
                                            {
                                              key: photo.id,
                                              staticClass:
                                                "product-gallery__single-preview",
                                              on: {
                                                click: function ($event) {
                                                  _vm.activeSlideNumber = index
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  class: {
                                                    "product-gallery__single-preview-in-wrap": true,
                                                    "product-gallery__single-preview-in-wrap--active":
                                                      _vm.activeSlideNumber ===
                                                      index,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "product-gallery__single-preview-img",
                                                    attrs: {
                                                      src:
                                                        _vm.$blobUrl +
                                                        "/" +
                                                        photo.url,
                                                      alt: "Product Photo",
                                                      draggable: "false",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e()
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: {
                            "product-gallery__add-edit-buttons": true,
                            "product-gallery__add-edit-buttons--under-previews-positioned":
                              _vm.verticalLayout,
                          },
                        },
                        [
                          _vm.images.length === 0
                            ? _c("div", {
                                staticClass: "product-gallery__add-but",
                                attrs: { title: "Add Photos" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.svg.plusIcon),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.uploadPhotosModal.toggle(
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "fs-popper-pr-edit",
                            {
                              ref: "uploadPhotosModal",
                              attrs: { offset: [0, -300] },
                            },
                            [
                              _c("edit-photos-modal", {
                                attrs: {
                                  "simple-mode": "",
                                  images: _vm.images,
                                  "save-func": _vm.saveFunc,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.images.length > 0
                            ? _c("div", {
                                staticClass: "product-gallery__edit-but",
                                attrs: { title: "Edit Photos List" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.svg.editFilled),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.editPhotosModal.toggle(
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "simple-fade-in-out" } },
        [
          _vm.filesToUpload
            ? _c("edit-photos-modal", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.simplifiedView,
                    expression: "!simplifiedView",
                  },
                ],
                staticClass: "product-gallery__initial-upload-progress-list",
                attrs: {
                  "simple-mode": "",
                  "max-height": 360,
                  "files-to-upload": _vm.filesToUpload,
                  "save-func": _vm.saveFunc,
                  images: _vm.images,
                },
                on: {
                  "upload-finish": function ($event) {
                    _vm.filesToUpload = null
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fs-popper-pr-edit",
        { ref: "editPhotosModal", attrs: { offset: [0, -300] } },
        [
          _c("edit-photos-modal", {
            attrs: { images: _vm.images, "save-func": _vm.saveFunc },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }