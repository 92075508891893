<template>
  <div slot="popover" class="text-input-form">
    <h3 v-if="title" class="text-input-form__title">{{ title }}</h3>

    <label v-if="label" class="text-input-form__label">{{ label }}</label>
    <input-custom
      class="text-input-form__text-input"
      :placeholder="placeholder"
      v-model.trim="newValue"
      @keyup-enter="
        ($event) => {
          if (newValue && !isSaveBtnDisabled) {
            $emit('save', newValue);
            fsPopperClose($event);
          }
        }
      "
      ref="textInput"
    />

    <cancel-n-action-btn-pair
      class="text-input-form__buttons-section"
      :action-btn-name="saveBtnName"
      :action-btn-icon="saveBtnIcon"
      @cancel="
        $emit('close');
        fsPopperClose($event);
      "
      @action="$emit('save', newValue)"
      :is-disabled="isSaveBtnDisabled"
      :is-save-in-progress="saveInProgress"
    />
  </div>
</template>

<script>
import { fsPopperClose } from '@components/poppers/fs-popper.vue';

// Components
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';
import InputCustom from '@components/form-elements/input-custom.vue';

export default {
  props: {
    placeholder: { type: String, default: () => 'Enter new value' },
    value: { type: String },
    saveBtnName: { type: String, default: () => 'Save' },
    saveBtnIcon: { type: String },
    title: String,
    label: String,
    validationFunc: { type: Function },
    saveInProgress: Boolean,
  },

  components: { InputCustom, CancelNActionBtnPair },

  data() {
    return {
      newValue: this.value || '',
    };
  },

  computed: {
    isSaveBtnDisabled() {
      return (this.validationFunc ? !this.validationFunc(this.newValue) : false) || this.newValue === '';
    },
  },

  mounted() {
    // setTimeout is needed to avoid auto scrolling of the page to the initial position of the popper
    // (initial position of all poppers is somewhere in the bottom of the page and it changes after opening of the popper.
    // That's how popper component works)
    setTimeout(() => this.$refs.textInput.focus(), 100);
  },

  methods: {
    fsPopperClose,
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@import 'common/styles/variables';

// ==========================================================================
// ==========================================================================
// Text input form
.text-input-form {
  width: math.div(41rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(4rem, 1.6) math.div(3rem, 1.6);
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;

  &__title {
    margin: 0 0 math.div(1.6rem, 1.6) 0;
    font: 400 #{math.div(2rem, 1.6)}/#{math.div(2.4rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__label {
    margin: 0 0 math.div(0.6rem, 1.6) math.div(2.4rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.4rem, 1.6);
    text-transform: uppercase;
    color: rgba($c-dark, 0.5);
  }

  &__text-input {
    width: 100%;
    margin-bottom: math.div(2.3rem, 1.6);
  }

  &__buttons-section {
    display: flex;
    justify-content: center;
  }

  &__button {
    width: math.div(13.5rem, 1.6);
    margin: 0 math.div(1.2rem, 1.6);
    opacity: 1;
    transition: opacity 0.15s ease;

    &--unavailable {
      opacity: 0.3;
    }
  }
}
</style>
