<template>
  <div
    :class="`add-content-placeholder
             add-content-placeholder--${size}`"
  >
    <div
      :class="`add-content-placeholder__in-wrap
               add-content-placeholder__in-wrap--${size}`"
    >
      <div
        :class="`add-content-placeholder__icon-slot
                 add-content-placeholder__icon-slot--${size}`"
        :style="iconSize ? iconSize : {}"
      >
        <slot name="icon">
          <div v-if="['medium', 'large'].includes(size)" v-html="svg.plusCircleFilledIcon"></div>
          <div v-else v-html="svg.plusIcon"></div>
        </slot>
      </div>
      <div
        v-if="label"
        :class="`add-content-placeholder__text-label
                 add-content-placeholder__text-label--${size}`"
      >
        {{ label }}
      </div>
    </div>

    <div class="add-content-placeholder__bg-layer"></div>
  </div>
</template>

<script>
// Icons
import plusCircleFilledIcon from '@icons/other/plus-circle-filled-icon.svg';
import plusIcon from '@icons/other/plus-icon.svg';

export default {
  name: 'add-content-placeholder',

  props: {
    label: String,

    iconSize: Object,

    size: {
      type: String,
      validator: (size) => ['micro', 'tiny', 'small', 'medium', 'large'].includes(size),
      default: 'medium',
    },
  },

  data() {
    return {
      svg: {
        plusCircleFilledIcon,
        plusIcon,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// ============================================================================
// Add content placeholder
.add-content-placeholder {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  font-size: math.div(1.4rem, 1.6);
  line-height: math.div(1.4rem, 1.6);
  cursor: pointer;
  user-select: none;

  &--micro {
    height: math.div(2rem, 1.6);
    font-size: math.div(1rem, 1.6);
    line-height: math.div(1rem, 1.6);
  }

  &--tiny {
    height: math.div(2.1rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.2rem, 1.6);
  }

  &--small {
    height: math.div(2.3rem, 1.6);
  }
  &--medium {
  }
  &--large {
    font-size: math.div(1.6rem, 1.6);
    line-height: math.div(2rem, 1.6);
  }

  &__in-wrap {
    padding: math.div(1.4rem, 1.6) math.div(1.8rem, 1.6) math.div(1.2rem, 1.6);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    text-align: center;
    white-space: nowrap;

    &--micro {
      min-width: math.div(4.1rem, 1.6);
      padding: 1px math.div(1.5rem, 1.6) 0;
    }

    &--tiny {
      min-width: math.div(3.8rem, 1.6);
      padding: 1px math.div(1.3rem, 1.6) 0;
    }

    &--small {
      min-width: math.div(4.1rem, 1.6);
      padding: 1px math.div(1.5rem, 1.6) 0;
    }
  }

  &__icon-slot {
    width: math.div(2rem, 1.6);
    min-width: math.div(2rem, 1.6);
    height: math.div(2rem, 1.6);
    min-height: math.div(2rem, 1.6);
    margin-bottom: math.div(0.1rem, 1.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &--micro {
      width: math.div(0.6rem, 1.6);
      min-width: math.div(0.6rem, 1.6);
      height: math.div(0.6rem, 1.6);
      min-height: math.div(0.6rem, 1.6);
    }

    &--tiny,
    &--small {
      width: math.div(0.8rem, 1.6);
      min-width: math.div(0.8rem, 1.6);
      height: math.div(0.8rem, 1.6);
      min-height: math.div(0.8rem, 1.6);
    }

    &--large {
      width: math.div(2.3rem, 1.6);
      min-width: math.div(2.3rem, 1.6);
      height: math.div(2.3rem, 1.6);
      min-height: math.div(2.3rem, 1.6);
      top: 0;
    }

    ::v-deep div,
    ::v-deep svg {
      width: 100%;
      height: 100%;
      fill: rgb(53, 73, 94);
    }
  }

  &__icon {
  }

  &__text-label {
    margin-left: math.div(1rem, 1.6);
    margin-bottom: math.div(0.1rem, 1.6);

    &--micro {
      margin-left: math.div(0.3rem, 1.6);
    }

    &--tiny {
      margin-left: math.div(0.4rem, 1.6);
    }

    &--small {
      margin-left: math.div(0.6rem, 1.6);
    }
  }

  &__bg-layer {
    width: 100%;
    height: 100%;
    border-radius: math.div(0.4rem, 1.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    background: rgb(247, 247, 247);
    transition: background 0.1s ease;

    &::before {
      content: '';
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border: 1px rgb(146, 156, 167) dashed;
      border-radius: math.div(0.4rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &:hover &__bg-layer {
    background: rgb(252, 252, 252);
  }
}
</style>
