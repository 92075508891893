var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cancel-n-action-btn-pair" },
    [
      _c(
        "fs-button",
        {
          class: {
            "cancel-n-action-btn-pair__separate-but": true,
            "cancel-n-action-btn-pair__separate-but--narrow": _vm.isTertiary,
          },
          attrs: {
            size: _vm.buttonsSize,
            secondary: !_vm.isTertiary,
            tertiary: _vm.isTertiary,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.$emit("cancel", $event)
            },
          },
        },
        [
          _vm.$slots.cancel
            ? _vm._t("cancel")
            : [
                _c("fs-button-icon", {
                  attrs: {
                    icon: _vm.icons.interfaceElements.cancel,
                    width: "1rem",
                    height: "1rem",
                    top: "-1px",
                    color: "dark-blue",
                  },
                }),
                _vm._v("\n      Cancel\n    "),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "fs-button",
        {
          class: {
            "cancel-n-action-btn-pair__separate-but": true,
            "cancel-n-action-btn-pair__separate-but--narrow": _vm.isTertiary,
          },
          attrs: {
            loading: _vm.isSaveInProgress,
            disabled: _vm.isDisabled,
            size: _vm.buttonsSize,
            tertiary: _vm.isTertiary,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("action", $event)
            },
          },
        },
        [
          _vm.$slots.action
            ? _vm._t("action")
            : [
                _c("fs-button-icon", {
                  attrs: {
                    icon: _vm.actionBtnIcon
                      ? _vm.actionBtnIcon
                      : _vm.icons.interfaceElements.save,
                    width: "1rem",
                    height: "1rem",
                    top: "-1px",
                    color: _vm.isTertiary ? "" : "white",
                  },
                }),
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$slots.action ? _vm.$slots.action : _vm.actionBtnName
                    ) +
                    "\n    "
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }