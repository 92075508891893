<template>
  <div class="verified-by-findsourcing">
    <h4 class="verified-by-findsourcing__header">Verified by FindSourcing</h4>
    <div class="verified-by-findsourcing__container">
      <div class="verifications verified-by-findsourcing__group">
        <span
          class="verifications__checkbox"
          v-html="supplier.isEmailConfirmed ? svg.verifiedCheckmark : svg.verifiedCircle"
        ></span>
        <span class="verifications__label">E-mail address</span>
        <!-- TODO: FIXME: Make me dynamic -->
        <span class="verifications__checkbox" v-html="svg.verifiedCheckmark"></span>
        <span class="verifications__label">Profile information</span>
        <!-- TODO: FIXME: Make me dynamic -->
        <span class="verifications__checkbox" v-html="svg.verifiedCheckmark"></span>
        <span class="verifications__label">Location</span>
      </div>
      <div class="supplier-stats verified-by-findsourcing__group">
        <span class="supplier-stats__icon" v-html="svg.responseRate"></span>
        <span class="supplier-stats__label">Response rate</span>
        <span class="supplier-stats__value">100%</span>
        <span class="supplier-stats__icon" v-html="svg.requestsHandled"></span>
        <span class="supplier-stats__label">Offers submitted</span>
        <span class="supplier-stats__value">{{ supplier.quotationCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// Icons
import verifiedCheckmark from 'apps/common/assets/icons/other/verified-checkmark.svg';
import verifiedCircle from 'apps/common/assets/icons/other/verified-circle.svg';
import requestsHandled from 'apps/common/assets/icons/other/requests-handled.svg';
import responseRate from 'apps/common/assets/icons/other/response-rate.svg';

export default {
  props: {
    supplier: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      svg: {
        // Stats
        verifiedCheckmark,
        verifiedCircle,
        requestsHandled,
        responseRate,
      },
    };
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

.supplierProfile {
  // TODO: REFACTOR - fix below mess
  // NOTE! This is quite messy, but depends on styling on the left column of the page
  // In other words, this is NOT all styling needed for his component.
  // ONLY Mobile
  .verified-by-findsourcing {
    padding: 0.5rem 0;

    @include tablet-min {
      display: none;
    }

    &__header {
      @include heading--small;
      text-align: center;
      padding-bottom: 0.5rem;
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
    }

    &__group {
      flex-basis: 21rem;
      padding: 0.75rem;
      margin: 0;
    }
  }
}
</style>
