var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      false
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100vw",
                height: "100%",
                padding: "100px 0",
                "box-sizing": "border-box",
                display: "flex",
                "flex-wrap": "wrap",
                "justify-content": "center",
                "align-items": "flex-start",
                "align-content": "flex-start",
                position: "absolute",
                top: "0",
                left: "0",
                "z-index": "100",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [
                  _c("membership-companies-edit-modal", {
                    attrs: {
                      entities: JSON.parse(
                        '[{"id":3597,"name":"Hjfjfja Inc","country":"Antarctica"},{"id":254,"name":"ADC LONDON","country":"United Kingdom"},{"id":3604,"name":"f","country":"Antigua and Barbuda"}]'
                      ),
                      "save-func": function () {},
                      "product-id": 17,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [
                  _c("brands-edit-modal", {
                    attrs: {
                      entities: JSON.parse(
                        '[{"id":3597,"name":"Hjfjfja Inc","country":"Antarctica"},{"id":254,"name":"ADC LONDON","country":"United Kingdom"},{"id":3604,"name":"f","country":"Antigua and Barbuda"}]'
                      ),
                      "save-func": function () {},
                      "product-id": 17,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [
                  _c("suppliers-edit-modal", {
                    attrs: {
                      entities: JSON.parse(
                        '[{"id":3597,"name":"Hjfjfja Inc","country":"Antarctica"},{"id":254,"name":"ADC LONDON","country":"United Kingdom"},{"id":3604,"name":"f","country":"Antigua and Barbuda"}]'
                      ),
                      "save-func": function () {},
                      "product-id": 17,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("add-production-unit-form")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("location-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [
                  _c("description-edit", {
                    attrs: { "save-func": function () {}, value: "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [
                  _c("single-text-input-form", {
                    attrs: {
                      title: "Supplier Name",
                      "save-but-name": "Save",
                      placeholder: "Enter Supplier Name",
                      value: "",
                      "save-in-progress": false,
                    },
                    on: { close: function () {}, save: function () {} },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("general-information-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("certifications-list-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("size-range-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("specialities-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("gender-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("contact-info-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("code-of-conduct-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("type-n-processes-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("social-media-form-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("new-supplier-form-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("contact-persons-list-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "30px",
                    "border-radius": "12px",
                    background: "white",
                    "box-shadow": "0 20px 20px rgba(0, 0, 0, 0.15)",
                  },
                },
                [_c("capabilities-list-edit")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { width: "100%", height: "100px" } }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("page-block"),
      _vm._v(" "),
      _c("div", { staticClass: "page-container" }, [
        _vm.supplier.coverPhoto
          ? _c(
              "header",
              {
                staticClass: "supplier-header",
                style: {
                  backgroundImage:
                    "url('" +
                    _vm.imagePath(
                      "images/" + _vm.supplier.coverPhoto,
                      1920,
                      500
                    ) +
                    "')",
                },
              },
              [
                _c("div", { staticClass: "supplier-header-logos" }, [
                  _c("span", {
                    staticClass: "supplier-header-logos__logo",
                    domProps: { innerHTML: _vm._s(_vm.svg.fsMember) },
                  }),
                  _vm._v(" "),
                  _vm.supplier.satra
                    ? _c("span", {
                        staticClass:
                          "supplier-header-logos__logo supplier-header-logos__logo--small",
                        domProps: { innerHTML: _vm._s(_vm.svg.satraMember) },
                      })
                    : _vm._e(),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "column-container" }, [
          _c("div", { staticClass: "left-column" }, [
            _c("section", { staticClass: "floating-section" }, [
              _c(
                "div",
                { staticClass: "map" },
                [
                  _c("supplier-map", {
                    staticClass: "map__map",
                    attrs: { supplier: _vm.supplier },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.logoPath
                ? _c("img", {
                    staticClass: "logo",
                    attrs: {
                      src: _vm.$blobUrl + "/images/" + _vm.logoPath,
                      alt: "logo",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.logoPath
                ? _c("img", {
                    staticClass: "logo__hidden",
                    attrs: {
                      src: _vm.$blobUrl + "/images/" + _vm.logoPath,
                      alt: "logo",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "hq-container" }, [
                _c("div", { staticClass: "hq-country" }, [
                  _c("img", {
                    staticClass: "hq-country__flag",
                    attrs: {
                      src: _vm.$store.countries.getFlagPathFromName(
                        _vm.supplier.country
                      ),
                      alt: "flag",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "hq-country__country" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.supplier.country) +
                        "\n              "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "hq-location" }, [
                  _c("div", {
                    staticClass: "hq-location__pin",
                    domProps: { innerHTML: _vm._s(_vm.svg.pin) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "hq-location__address" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.supplier.fullAddress) +
                        "\n              "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              false
                ? _c(
                    "div",
                    { staticClass: "production-units floating-section__group" },
                    [
                      _c("div", { staticClass: "production-units__header" }, [
                        _vm._v("Production Units"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "production-units__units-list" },
                        _vm._l([0, 1, 2], function (unit) {
                          return _c(
                            "div",
                            {
                              key: unit,
                              staticClass:
                                "production-single-unit production-units__single-unit",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "production-single-unit__logo-wrap",
                                },
                                [
                                  _c("div", {
                                    staticClass: "production-single-unit__logo",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.icons.countriesFlags.portugal
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass:
                                      "production-single-unit__verification-icon",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.icons.miscellaneous.circleA
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "production-single-unit__city" },
                                [_vm._v("Porto,")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "production-single-unit__country",
                                },
                                [_vm._v("Portugal")]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "floating-section__group" },
                [
                  _c("div", { staticClass: "contact-info" }, [
                    _vm.supplier.web
                      ? _c("div", {
                          staticClass: "contact-info__icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.icons.contact.website),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.web
                      ? _c("div", { staticClass: "contact-info__links" }, [
                          _c(
                            "a",
                            {
                              staticClass: "contact-info__link link link--dark",
                              attrs: {
                                href: _vm.urlAddProtocol(_vm.supplier.web),
                                target: "_blank",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "homepage click"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.urlHideProtocol(_vm.supplier.web)
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.email
                      ? _c("div", {
                          staticClass: "contact-info__icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.icons.contact.email),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.email
                      ? _c("div", { staticClass: "contact-info__links" }, [
                          _c(
                            "a",
                            {
                              staticClass: "contact-info__link link link--dark",
                              attrs: { href: "mailto:" + _vm.supplier.email },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "email click"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.supplier.email) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.phone
                      ? _c("div", {
                          staticClass: "contact-info__icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.icons.contact.phone),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.phone
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "contact-info__links contact-info__links--with-labels",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "contact-info__link-label" },
                              [_vm._v(" Office ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "contact-info__link link link--dark",
                                attrs: { href: "tel:" + _vm.supplier.phone },
                                on: {
                                  click: function ($event) {
                                    return _vm.$ga.event(
                                      "contacts",
                                      "phone click"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.supplier.phone) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.whatsApp
                      ? _c("div", {
                          staticClass: "contact-info__icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.icons.contact.whatsApp),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.whatsApp
                      ? _c("div", { staticClass: "contact-info__links" }, [
                          _c(
                            "a",
                            {
                              staticClass: "contact-info__link link link--dark",
                              attrs: {
                                href:
                                  "https://wa.me/" +
                                  _vm.supplier.whatsApp.replace(/[^0-9]/, ""),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "WhatsApp click"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.supplier.whatsApp) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.weChat
                      ? _c("div", {
                          staticClass: "contact-info__icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.icons.contact.weChat),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.weChat
                      ? _c("div", { staticClass: "contact-info__links" }, [
                          _c(
                            "a",
                            {
                              staticClass: "contact-info__link link link--dark",
                              attrs: {
                                href: "weixin://dl/chat?" + _vm.supplier.weChat,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "WeChat click"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.supplier.weChat) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.skype
                      ? _c("div", {
                          staticClass: "contact-info__icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.icons.contact.skype),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.skype
                      ? _c("div", { staticClass: "contact-info__links" }, [
                          _c(
                            "a",
                            {
                              staticClass: "contact-info__link link link--dark",
                              attrs: {
                                href: "skype:" + _vm.supplier.skype + "?call",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "Skype click"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.supplier.skype) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.openingHours.length
                      ? _c("div", {
                          class: {
                            "contact-info__icon": 1,
                            "contact-info__icon--clock": 1,
                            "contact-info__icon--vertically-aligned":
                              _vm.supplier.openingHours.length === 1,
                          },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.icons.interfaceElements.clock
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "contact-info__links" },
                      _vm._l(_vm.supplier.openingHours, function (schedule) {
                        return _c(
                          "div",
                          {
                            key: schedule.dayFrom + schedule.dayTo,
                            staticClass: "contact-info__working-time-span",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "contact-info__subtitle" },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      schedule.dayFrom + " - " + schedule.dayTo
                                    ) +
                                    "\n                  "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(schedule.timeSpans, function (timeSpan) {
                              return _c(
                                "span",
                                {
                                  key: timeSpan.from + timeSpan.to,
                                  staticClass: "contact-info__link-label",
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.formatTimeSpan(
                                          timeSpan.from,
                                          "Africa/Luanda" ||
                                            _vm.supplier.ianaTimezone
                                        ) +
                                          " - " +
                                          _vm.formatTimeSpan(
                                            timeSpan.to,
                                            "Africa/Luanda" ||
                                              _vm.supplier.ianaTimezone
                                          )
                                      ) +
                                      "\n                  "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  this.ie
                    ? _c(
                        "style",
                        { tag: "component" },
                        [
                          _vm._v(
                            "\n              .supplierProfile .contact-info { display: -ms-grid; -ms-grid-columns: 2rem 1rem minmax(0px, 16.5rem);\n              -ms-grid-rows:\n              "
                          ),
                          _vm._l(
                            Number(!!_vm.supplier.web) +
                              Number(!!_vm.supplier.email) +
                              Number(!!_vm.supplier.phone),
                            function (row) {
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      (row > 1 ? " 1.625rem" : "") + " auto"
                                    ) +
                                    " "
                                ),
                              ]
                            }
                          ),
                          _vm._v("; }\n              "),
                          _vm._v(" "),
                          _vm._l(
                            Number(!!_vm.supplier.web) +
                              Number(!!_vm.supplier.email) +
                              Number(!!_vm.supplier.phone),
                            function (row) {
                              return [
                                _vm._v(
                                  "\n                .supplierProfile .contact-info__icon:nth-child(" +
                                    _vm._s(row * 2 - 1) +
                                    ") { -ms-grid-column: 1; -ms-grid-row:\n                " +
                                    _vm._s(row * 2 - 1) +
                                    "; } .supplierProfile .contact-info__links:nth-child(" +
                                    _vm._s(row * 2) +
                                    ") { -ms-grid-column:\n                3; -ms-grid-row: " +
                                    _vm._s(row * 2 - 1) +
                                    "; }\n              "
                                ),
                              ]
                            }
                          ),
                          _vm._v(" "),
                          _vm._v(
                            "\n              .supplierProfile .contact-info__links { display: flex; flex-direction: column; } .supplierProfile\n              .contact-info__links--with-labels { display: block; }\n            "
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              [
                "design-shoes-colection-srl",
                "valuni--eurodavil",
                "okabashi-brands",
                "t-k-shoes-factory",
              ].includes(_vm.$route.params.name)
                ? _c("div", { staticClass: "floating-section__group" }, [
                    _c(
                      "div",
                      { staticClass: "contact-persons" },
                      [
                        _vm.$route.params.name === "design-shoes-colection-srl"
                          ? [
                              _c("div", {
                                staticClass: "contact-persons__image",
                                style: {
                                  backgroundImage:
                                    "url('" +
                                    _vm.$blobUrl +
                                    "/images/ramona.jpg')",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "contact-persons__info-container",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "contact-persons__name" },
                                    [_vm._v("Ramona")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "contact-persons__info-group",
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "contact-persons__info-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.contact.email
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "contact-persons__info-link link link--dark",
                                          attrs: {
                                            href: "mailto:designcolection@yahoo.com",
                                          },
                                        },
                                        [_vm._v("designcolection@yahoo.com")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "contact-persons__info-group",
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "contact-persons__info-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.contact.phone
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "contact-persons__info-link link link--dark",
                                          attrs: {
                                            href: "tel:+40 786 189 080",
                                          },
                                        },
                                        [_vm._v("+40 786 189 080")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$route.params.name === "valuni--eurodavil"
                          ? [
                              _c("div", {
                                staticClass: "contact-persons__image",
                                style: {
                                  backgroundImage:
                                    "url('" +
                                    _vm.$blobUrl +
                                    "/images/david.jpg')",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "contact-persons__info-container",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "contact-persons__name" },
                                    [_vm._v("David Braga")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "contact-persons__info-group",
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "contact-persons__info-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.contact.email
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "contact-persons__info-link link link--dark",
                                          attrs: {
                                            href: "mailto:david@valuni.com",
                                          },
                                        },
                                        [_vm._v("david@valuni.com")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "contact-persons__info-group",
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "contact-persons__info-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.contact.phone
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "contact-persons__info-link link link--dark",
                                          attrs: {
                                            href: "tel:+351 913 470 847",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      +351 913 470 847\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$route.params.name === "okabashi-brands"
                          ? [
                              _c("div", {
                                staticClass: "contact-persons__image",
                                style: {
                                  backgroundImage:
                                    "url('" +
                                    _vm.$blobUrl +
                                    "/images/jenniferk.jpg')",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "contact-persons__info-container",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "contact-persons__name" },
                                    [_vm._v(" Jennifer Kirksey ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "contact-persons__info-group",
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "contact-persons__info-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.contact.email
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "contact-persons__info-link link link--dark",
                                          attrs: {
                                            href: "mailto:jenniferk@okabashi.com",
                                          },
                                        },
                                        [_vm._v("jenniferk@okabashi.com")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "contact-persons__info-group",
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "contact-persons__info-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.contact.phone
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "contact-persons__info-link link link--dark",
                                          attrs: { href: "tel:+17709451330" },
                                        },
                                        [_vm._v("+1 770.945.1330")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$route.params.name === "t-k-shoes-factory"
                          ? [
                              _c("div", {
                                staticClass: "contact-persons__image",
                                style: {
                                  backgroundImage:
                                    "url('" +
                                    _vm.$blobUrl +
                                    "/images/terry_t.jpg')",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "contact-persons__info-container",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "contact-persons__name" },
                                    [_vm._v(" Terry Tang ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "contact-persons__info-group",
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "contact-persons__info-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.contact.email
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "contact-persons__info-link link link--dark",
                                          attrs: {
                                            href: "mailto:terry_t@tandkshoes.com",
                                          },
                                        },
                                        [_vm._v("terry_t@tandkshoes.com")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "contact-persons__info-group",
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "contact-persons__info-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.contact.phone
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "contact-persons__info-link link link--dark",
                                          attrs: { href: "tel:+17709451330" },
                                        },
                                        [_vm._v("+86 188-1908-3896")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        this.ie
                          ? _c(
                              "style",
                              { tag: "component" },
                              [
                                _vm._v(
                                  "\n                .supplierProfile .contact-persons { display: -ms-grid; -ms-grid-columns: 4.625rem 1rem minmax(0px,\n                14.25rem); -ms-grid-rows:\n                "
                                ),
                                _vm._l(1, function (row) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (row > 1 ? " 2.625rem" : "") + " auto"
                                        ) +
                                        " "
                                    ),
                                  ]
                                }),
                                _vm._v("; }\n                "),
                                _vm._v(" "),
                                _vm._l(1, function (row) {
                                  return [
                                    _vm._v(
                                      "\n                  .supplierProfile .contact-persons__image:nth-child(" +
                                        _vm._s(row * 2 - 1) +
                                        ") { -ms-grid-column: 1;\n                  -ms-grid-row: " +
                                        _vm._s(row * 2 - 1) +
                                        "; } .supplierProfile .contact-persons__info-container:nth-child(" +
                                        _vm._s(row * 2) +
                                        ") { -ms-grid-column: 3; -ms-grid-row: " +
                                        _vm._s(row * 2 - 1) +
                                        "; }\n                "
                                    ),
                                  ]
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "floating-section__group" }, [
                _c(
                  "div",
                  { staticClass: "action-group" },
                  [
                    _vm.showClaimSupplierButton
                      ? _c(
                          "fs-button-main",
                          {
                            staticClass: "action-group__button",
                            on: {
                              click: function ($event) {
                                _vm.$ga.event(
                                  "supplier buttons",
                                  "claim supplier"
                                ) && _vm.claimSupplier
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                Claim supplier\n              "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showEditSupplierButton
                      ? _c(
                          "fs-button-main",
                          {
                            staticClass: "action-group__button",
                            attrs: {
                              to: {
                                name: "supplierEdit",
                                params: { id: _vm.supplier.id },
                              },
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$ga.event(
                                  "supplier buttons",
                                  "edit profile"
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                Edit profile\n              "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$store.user.isBuyer
                      ? _c(
                          "fs-button-main",
                          {
                            staticClass: "action-group__button",
                            attrs: { to: { name: "quotationForm" } },
                            on: {
                              click: function ($event) {
                                return _vm.$ga.event(
                                  "supplier buttons",
                                  "request quotation"
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                Request Quotation\n              "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "fs-button-main",
                      {
                        staticClass: "action-group__button",
                        attrs: {
                          tag: "a",
                          styling: "outlined",
                          href: "mailto:" + _vm.supplier.email,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$ga.event("supplier buttons", "contact")
                          },
                        },
                      },
                      [_vm._v("Contact")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.social
                  ? _c("div", { staticClass: "social-media" }, [
                      _vm.supplier.linkedIn
                        ? _c("a", {
                            staticClass: "social-media__link",
                            attrs: {
                              href: _vm.urlAddProtocol(_vm.supplier.linkedIn),
                              target: "_blank",
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.social.linkedIn),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$ga.event(
                                  "contacts",
                                  "linkedIn click"
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.supplier.facebook
                        ? _c("a", {
                            staticClass: "social-media__link",
                            attrs: {
                              href: _vm.urlAddProtocol(_vm.supplier.facebook),
                              target: "_blank",
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.social.facebook),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$ga.event(
                                  "contacts",
                                  "facebook click"
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.supplier.instagram
                        ? _c("a", {
                            staticClass: "social-media__link",
                            attrs: {
                              href: _vm.urlAddProtocol(_vm.supplier.instagram),
                              target: "_blank",
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.social.instagram),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$ga.event(
                                  "contacts",
                                  "instagram click"
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.supplier.pinterest
                        ? _c("a", {
                            staticClass: "social-media__link",
                            attrs: {
                              href: _vm.urlAddProtocol(_vm.supplier.pinterest),
                              target: "_blank",
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.social.pinterest),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$ga.event(
                                  "contacts",
                                  "pinterest click"
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.supplier.twitter
                        ? _c("a", {
                            staticClass: "social-media__link",
                            attrs: {
                              href: _vm.urlAddProtocol(_vm.supplier.twitter),
                              target: "_blank",
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.social.twitter),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$ga.event(
                                  "contacts",
                                  "twitter click"
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.supplier.youTube
                        ? _c("a", {
                            staticClass: "social-media__link",
                            attrs: {
                              href: _vm.urlAddProtocol(_vm.supplier.youTube),
                              target: "_blank",
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.social.youtube),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$ga.event(
                                  "contacts",
                                  "youtube click"
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "section",
              { staticClass: "floating-section" },
              [
                _c("h4", { staticClass: "floating-section__group-header" }, [
                  _vm._v("Verified by FindSourcing"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "verifications" }, [
                  _c("span", {
                    staticClass: "verifications__checkbox",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.supplier.isEmailConfirmed
                          ? _vm.svg.verifiedCheckmark
                          : _vm.svg.verifiedCircle
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "verifications__label" }, [
                    _vm._v("E-mail address"),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "verifications__checkbox",
                    domProps: { innerHTML: _vm._s(_vm.svg.verifiedCheckmark) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "verifications__label" }, [
                    _vm._v("Profile information"),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "verifications__checkbox",
                    domProps: { innerHTML: _vm._s(_vm.svg.verifiedCheckmark) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "verifications__label" }, [
                    _vm._v("Location"),
                  ]),
                ]),
                _vm._v(" "),
                this.ie
                  ? _c(
                      "style",
                      { tag: "component" },
                      [
                        _vm._v(
                          "\n            .supplierProfile .verifications { display: -ms-grid; -ms-grid-columns: 1.3125rem 1rem minmax(11.625rem,\n            14.5rem); -ms-grid-rows: auto 0.625rem auto 0.625rem auto; max-width: 17rem; }\n            "
                        ),
                        _vm._v(" "),
                        _vm._l(3, function (row) {
                          return [
                            _vm._v(
                              "\n              .supplierProfile .verifications__checkbox:nth-child(" +
                                _vm._s(row * 2 - 1) +
                                ") { -ms-grid-column: 1;\n              -ms-grid-row: " +
                                _vm._s(row * 2 - 1) +
                                "; } .supplierProfile .verifications__label:nth-child(" +
                                _vm._s(row * 2) +
                                ") {\n              -ms-grid-column: 3; -ms-grid-row: " +
                                _vm._s(row * 2 - 1) +
                                "; }\n            "
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "supplier-stats" }, [
                  _c("span", {
                    staticClass: "supplier-stats__icon",
                    domProps: { innerHTML: _vm._s(_vm.svg.responseRate) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "supplier-stats__label" }, [
                    _vm._v("Response rate"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "supplier-stats__value" }, [
                    _vm._v("100%"),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "supplier-stats__icon",
                    domProps: { innerHTML: _vm._s(_vm.svg.requestsHandled) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "supplier-stats__label" }, [
                    _vm._v("Offers submitted"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "supplier-stats__value" }, [
                    _vm._v(_vm._s(_vm.supplier.quotationCount)),
                  ]),
                ]),
                _vm._v(" "),
                this.ie
                  ? _c(
                      "style",
                      { tag: "component" },
                      [
                        _vm._v(
                          "\n            .supplierProfile .supplier-stats { display: -ms-grid; -ms-grid-columns: 1.5rem 1.0rem minmax(5.125rem,\n            11.75rem) 1.5rem 3.25rem; -ms-grid-rows: auto 0.375rem auto 0.625rem auto; max-width: 19rem; margin: 0\n            auto; }\n            "
                        ),
                        _vm._v(" "),
                        _vm._l(2, function (row) {
                          return [
                            _vm._v(
                              "\n              .supplierProfile .supplier-stats__icon:nth-child(" +
                                _vm._s(row * 3 - 2) +
                                ") { -ms-grid-column: 1; -ms-grid-row:\n              " +
                                _vm._s(row * 2 - 1) +
                                "; } .supplierProfile .supplier-stats__label:nth-child(" +
                                _vm._s(row * 3 - 1) +
                                ") {\n              -ms-grid-column: 3; -ms-grid-row: " +
                                _vm._s(row * 2 - 1) +
                                "; } .supplierProfile\n              .supplier-stats__value:nth-child(" +
                                _vm._s(row * 3) +
                                ") { -ms-grid-column: 5; -ms-grid-row: " +
                                _vm._s(row * 2 - 1) +
                                "; }\n            "
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("sustainability-rating-section", {
                  staticClass: "floating-section__group",
                  attrs: { supplier: _vm.supplier },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "right-column" }, [
            _c("section", { staticClass: "general-section" }, [
              _c("h1", { staticClass: "general-section__name" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "general-section__pills general-section__pills--mobile",
                },
                [
                  _c(
                    "fs-pill",
                    {
                      staticClass: "general-section__pill",
                      attrs: { size: "extra-large", fixed: false },
                    },
                    [_vm._v("Manufacturer")]
                  ),
                  _vm._v(" "),
                  false
                    ? _c(
                        "fs-pill",
                        {
                          staticClass: "general-section__pill",
                          attrs: { ize: "extra-large", fixed: false },
                        },
                        [_vm._v("Agent")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  false
                    ? _c(
                        "fs-pill",
                        {
                          staticClass: "general-section__pill",
                          attrs: { size: "extra-large", fixed: false },
                        },
                        [_vm._v("+")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "general-section__mobile-container" }, [
                _c("div", { staticClass: "location-container" }, [
                  _c("div", { staticClass: "icon-label" }, [
                    _c("img", {
                      staticClass: "icon-label__icon icon-label__icon--flag",
                      attrs: {
                        src: _vm.$store.countries.getFlagPathFromName(
                          _vm.supplier.country
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-label__label" }, [
                      _vm._v(_vm._s(_vm.supplier.country)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-label" }, [
                    _c("span", {
                      staticClass: "icon-label__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.pin) },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-label__label" }, [
                      _vm._v(_vm._s(_vm.supplier.fullAddress)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                false
                  ? _c("div", { staticClass: "production-units-container" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "production-units floating-section__group",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "production-units__header" },
                            [_vm._v("Production Units")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "production-units__units-list" },
                            _vm._l([0, 1, 2], function (unit) {
                              return _c(
                                "div",
                                {
                                  key: unit,
                                  staticClass:
                                    "production-single-unit production-units__single-unit",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "production-single-unit__logo-wrap",
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "production-single-unit__logo",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.countriesFlags.portugal
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass:
                                          "production-single-unit__verification-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.miscellaneous.circleA
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "production-single-unit__city",
                                    },
                                    [_vm._v("Porto,")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "production-single-unit__country",
                                    },
                                    [_vm._v("Portugal")]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "contact-container" }, [
                  _vm.supplier.web
                    ? _c(
                        "a",
                        {
                          staticClass: "general-contact-info",
                          attrs: {
                            href: _vm.urlAddProtocol(_vm.supplier.web),
                            target: "_blank",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$ga.event("contacts", "homepage click")
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "general-contact-info__icon",
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.contact.website),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "general-contact-info__label link link--dark",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.urlHideProtocol(_vm.supplier.web))
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.supplier.email
                    ? _c(
                        "a",
                        {
                          staticClass: "general-contact-info",
                          attrs: {
                            href: "mailto:" + _vm.supplier.email,
                            target: "_blank",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$ga.event("contacts", "email click")
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "general-contact-info__icon",
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.contact.email),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "general-contact-info__label link link--dark",
                            },
                            [_vm._v(_vm._s(_vm.supplier.email))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.supplier.phone
                    ? _c(
                        "a",
                        {
                          staticClass: "general-contact-info",
                          attrs: {
                            href: "tel:" + _vm.supplier.phone,
                            target: "_blank",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$ga.event("contacts", "phone click")
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "general-contact-info__icon",
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.contact.phone),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "general-contact-info__label link link--dark",
                            },
                            [_vm._v("Office: " + _vm._s(_vm.supplier.phone))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.supplier.whatsApp
                    ? _c(
                        "a",
                        {
                          staticClass: "general-contact-info",
                          attrs: {
                            href:
                              "https://wa.me/" +
                              _vm.supplier.whatsApp.replace(/[^0-9]/, ""),
                            target: "_blank",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$ga.event("contacts", "WhatsApp click")
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "general-contact-info__icon",
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.contact.whatsApp),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "general-contact-info__label link link--dark",
                            },
                            [_vm._v(_vm._s(_vm.supplier.whatsApp))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.supplier.weChat
                    ? _c(
                        "a",
                        {
                          staticClass: "general-contact-info",
                          attrs: {
                            href: "weixin://dl/chat?" + _vm.supplier.weChat,
                            target: "_blank",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$ga.event("contacts", "WeChat click")
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "general-contact-info__icon",
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.contact.weChat),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "general-contact-info__label link link--dark",
                            },
                            [_vm._v(_vm._s(_vm.supplier.weChat))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.supplier.skype
                    ? _c(
                        "a",
                        {
                          staticClass: "general-contact-info",
                          attrs: {
                            href: "skype:" + _vm.supplier.skype + "?call",
                            target: "_blank",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$ga.event("contacts", "Skype click")
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "general-contact-info__icon",
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.contact.skype),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "general-contact-info__label link link--dark",
                            },
                            [_vm._v(_vm._s(_vm.supplier.skype))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.social
                    ? _c("div", { staticClass: "general-social-media" }, [
                        _vm.supplier.linkedIn
                          ? _c("a", {
                              staticClass: "general-social-media__link",
                              attrs: {
                                href: _vm.urlAddProtocol(_vm.supplier.linkedIn),
                                target: "_blank",
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.icons.social.linkedIn),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "linkedIn click"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.supplier.facebook
                          ? _c("a", {
                              staticClass: "general-social-media__link",
                              attrs: {
                                href: _vm.urlAddProtocol(_vm.supplier.facebook),
                                target: "_blank",
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.icons.social.facebook),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "facebook click"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.supplier.instagram
                          ? _c("a", {
                              staticClass: "general-social-media__link",
                              attrs: {
                                href: _vm.urlAddProtocol(
                                  _vm.supplier.instagram
                                ),
                                target: "_blank",
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.icons.social.instagram),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "instagram click"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.supplier.pinterest
                          ? _c("a", {
                              staticClass: "general-social-media__link",
                              attrs: {
                                href: _vm.urlAddProtocol(
                                  _vm.supplier.pinterest
                                ),
                                target: "_blank",
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.icons.social.pinterest),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "pinterest click"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.supplier.twitter
                          ? _c("a", {
                              staticClass: "general-social-media__link",
                              attrs: {
                                href: _vm.urlAddProtocol(_vm.supplier.twitter),
                                target: "_blank",
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.icons.social.twitter),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "twitter click"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.supplier.youTube
                          ? _c("a", {
                              staticClass: "general-social-media__link",
                              attrs: {
                                href: _vm.urlAddProtocol(_vm.supplier.youTube),
                                target: "_blank",
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.icons.social.youtube),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$ga.event(
                                    "contacts",
                                    "youtube click"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                false
                  ? _c(
                      "div",
                      { staticClass: "general-action-group" },
                      [
                        _c(
                          "fs-button-main",
                          { staticClass: "general-action-group__button" },
                          [_vm._v("Request Quotation")]
                        ),
                        _vm._v(" "),
                        _c(
                          "fs-button-main",
                          {
                            staticClass: "general-action-group__button",
                            attrs: { styling: "outlined" },
                          },
                          [_vm._v("Contact")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "general-section__pills_n_tabs" }, [
                _c(
                  "div",
                  { staticClass: "general-section__pills" },
                  [
                    _c(
                      "fs-pill",
                      {
                        staticClass: "general-section__pill",
                        attrs: { size: "extra-large", fixed: false },
                      },
                      [_vm._v("Manufacturer")]
                    ),
                    _vm._v(" "),
                    false
                      ? _c(
                          "fs-pill",
                          {
                            staticClass: "general-section__pill",
                            attrs: { size: "extra-large", fixed: false },
                          },
                          [_vm._v("Agent")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    false
                      ? _c(
                          "fs-pill",
                          {
                            staticClass: "general-section__pill",
                            attrs: { size: "extra-large", fixed: false },
                          },
                          [_vm._v("+")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                false
                  ? _c("div", { staticClass: "general-section__tabs" }, [
                      _vm._v("\n              Bla bla bla\n            "),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tabs__container" },
              [
                _vm.supplier.category == 0
                  ? _c("footwear-tab", { attrs: { supplier: _vm.supplier } })
                  : _vm._e(),
                _vm._v(" "),
                _vm.supplier.category == 1
                  ? _c("components-tab", { attrs: { supplier: _vm.supplier } })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }