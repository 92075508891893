import email from '@icons/contact/email.svg';
import phone from '@icons/contact/phone.svg';
import skype from '@icons/contact/skype.svg';
import website from '@icons/contact/website.svg';
import wechat from '@icons/contact/wechat.svg';
import whatsapp from '@icons/contact/whatsapp.svg';

export default {
  email,
  phone,
  skype,
  website,
  wechat,
  whatsapp,
};
