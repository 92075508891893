var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._g(
      _vm._b({ tag: "component" }, "component", _vm.$attrs, false),
      _vm.events
    ),
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "FsPopper",
        _vm._g(
          _vm._b(
            _vm._b(
              {
                ref: "popper",
                class: _vm.popperClass,
                style: _vm.popperStyle,
                attrs: {
                  animation: _vm.animation,
                  beforeClose: _vm.beforeClose,
                  defaultClass: _vm.defaultClass,
                  modifiers: _vm.modifiers,
                  offset: _vm.offset,
                  onFirstUpdate: _vm.onFirstUpdate,
                  show: _vm.show,
                  placement: _vm.placement,
                  tag: _vm.popperTag,
                  selector: _vm.selector,
                  strategy: _vm.strategy,
                },
              },
              "FsPopper",
              _vm._d({}, [_vm.parentScopeId, ""])
            ),
            "FsPopper",
            _vm.popperAttrs,
            false
          ),
          _vm.popperListeners
        ),
        [_vm._t("popper")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }