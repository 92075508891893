<template>
  <div class="cancel-n-action-btn-pair">
    <fs-button
      :class="{
        'cancel-n-action-btn-pair__separate-but': true,
        'cancel-n-action-btn-pair__separate-but--narrow': isTertiary,
      }"
      @click.native="$emit('cancel', $event)"
      :size="buttonsSize"
      :secondary="!isTertiary"
      :tertiary="isTertiary"
    >
      <slot v-if="$slots.cancel" name="cancel"></slot>
      <template v-else>
        <fs-button-icon
          :icon="icons.interfaceElements.cancel"
          width="1rem"
          height="1rem"
          top="-1px"
          color="dark-blue"
        />
        Cancel
      </template>
    </fs-button>

    <fs-button
      :class="{
        'cancel-n-action-btn-pair__separate-but': true,
        'cancel-n-action-btn-pair__separate-but--narrow': isTertiary,
      }"
      @click="$emit('action', $event)"
      :loading="isSaveInProgress"
      :disabled="isDisabled"
      :size="buttonsSize"
      :tertiary="isTertiary"
    >
      <slot v-if="$slots.action" name="action"></slot>
      <template v-else>
        <fs-button-icon
          :icon="actionBtnIcon ? actionBtnIcon : icons.interfaceElements.save"
          width="1rem"
          height="1rem"
          top="-1px"
          :color="isTertiary ? '' : 'white'"
        />
        {{ $slots.action ? $slots.action : actionBtnName }}
      </template>
    </fs-button>
  </div>
</template>

<script>
// Components
import FsButton from '@components/buttons/fs-button.vue';
import FsButtonIcon from '@components/fs-button-icon.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';

export default {
  name: 'cancel-n-action-btn-pair',

  props: {
    isSaveInProgress: { type: Boolean, default: false },
    actionBtnName: { type: String, default: 'Save' },
    actionBtnIcon: String,
    isDisabled: { type: Boolean, default: false },
    buttonsSize: {
      type: String,
      validator: (size) => ['nano', 'micro', 'tiny', 'extra-small', 'small', 'medium', 'large', 'huge'].includes(size),
      default: 'medium',
    },
    isTertiary: { type: Boolean, default: false },
  },

  components: {
    FsButton,
    FsButtonIcon,
  },

  data() {
    return {
      icons: {
        interfaceElements: interfaceElementsIcons,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// ============================================================================
// Cancel & Action buttons pair
.cancel-n-action-btn-pair {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  &__separate-but {
    min-width: math.div(13.5rem, 1.6);
    margin: 0 math.div(1.6rem, 1.6) 0 0;

    &--narrow {
      min-width: math.div(7.5rem, 1.6);
      max-width: math.div(7.5rem, 1.6);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
