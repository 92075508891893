var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "client-only",
    [
      _c(
        "portal",
        { attrs: { selector: _vm.selector } },
        [
          _vm.isWrapperOpen
            ? _c(
                _vm.wrapperTag,
                _vm._b(
                  {
                    directives: [
                      {
                        name: "resize",
                        rawName: "v-resize",
                        value: _vm.throttledResize,
                        expression: "throttledResize",
                      },
                    ],
                    ref: "popper",
                    tag: "component",
                    class: _vm.wrapperClass,
                    on: {
                      "popper-close": function ($event) {
                        $event.stopPropagation()
                        return _vm.close({
                          type: "popper-close-event",
                          event: $event,
                        })
                      },
                    },
                  },
                  "component",
                  _vm._d({}, [_vm.parentScopeId, ""])
                ),
                [
                  _c(
                    "transition",
                    {
                      attrs: { name: _vm.animation, appear: "" },
                      on: {
                        enter: _vm.enter,
                        "before-leave": _vm.beforeLeave,
                        "after-leave": _vm.afterLeave,
                      },
                    },
                    [
                      !_vm.arrow
                        ? [
                            _vm.isOpen
                              ? _c(
                                  _vm.tag,
                                  _vm._g(
                                    _vm._b(
                                      _vm._b(
                                        {
                                          tag: "component",
                                          class:
                                            _vm.classProp +
                                            "\n                      " +
                                            (_vm.noStyling &&
                                              "popper--empty-styling"),
                                          style: _vm.styleProp,
                                        },
                                        "component",
                                        _vm._d({}, [_vm.parentScopeId, ""])
                                      ),
                                      "component",
                                      _vm.$attrs,
                                      false
                                    ),
                                    _vm.$listeners
                                  ),
                                  [_vm._t("default")],
                                  2
                                )
                              : _vm._e(),
                          ]
                        : [
                            _vm.isOpen
                              ? _c(
                                  _vm.arrowWrapperTag,
                                  {
                                    tag: "component",
                                    class: _vm.arrowWrapperClass,
                                  },
                                  [
                                    _c(
                                      _vm.tag,
                                      _vm._g(
                                        _vm._b(
                                          _vm._b(
                                            {
                                              tag: "component",
                                              class:
                                                _vm.classProp +
                                                "\n                        " +
                                                (_vm.noStyling &&
                                                  "popper--empty-styling"),
                                              style: _vm.styleProp,
                                            },
                                            "component",
                                            _vm._d({}, [_vm.parentScopeId, ""])
                                          ),
                                          "component",
                                          _vm.$attrs,
                                          false
                                        ),
                                        _vm.$listeners
                                      ),
                                      [_vm._t("default")],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm._t("arrow", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "popper-arrow-inner-wrapper",
                                          attrs: { "data-popper-arrow": "" },
                                        },
                                        [_c("div", { class: _vm.arrowClass })]
                                      ),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }