<template>
  <client-only>
    <portal :selector="selector">
      <component
        :is="wrapperTag"
        ref="popper"
        :class="wrapperClass"
        :[parentScopeId]="''"
        v-if="isWrapperOpen"
        v-resize="throttledResize"
        @popper-close.stop="close({ type: 'popper-close-event', event: $event })"
      >
        <transition :name="animation" @enter="enter" @before-leave="beforeLeave" @after-leave="afterLeave" appear>
          <template v-if="!arrow">
            <component
              :is="tag"
              :class="`${classProp}
                        ${noStyling && 'popper--empty-styling'}`"
              :style="styleProp"
              :[parentScopeId]="''"
              v-bind="$attrs"
              v-on="$listeners"
              v-if="isOpen"
            >
              <slot></slot>
            </component>
          </template>
          <template v-else>
            <component :is="arrowWrapperTag" :class="arrowWrapperClass" v-if="isOpen">
              <component
                :is="tag"
                :class="`${classProp}
                          ${noStyling && 'popper--empty-styling'}`"
                :style="styleProp"
                :[parentScopeId]="''"
                v-bind="$attrs"
                v-on="$listeners"
              >
                <slot></slot>
              </component>
              <slot name="arrow">
                <div data-popper-arrow class="popper-arrow-inner-wrapper">
                  <div :class="arrowClass"></div>
                </div>
              </slot>
            </component>
          </template>
        </transition>
      </component>
    </portal>
  </client-only>
</template>

<script>
// The excessive element nesting is unfortunately required:
//
// A) Main wrapper needs to be there to avoid elements jumping around, which happens
// due to popper "disengaging" before the transition is done. This is also why we
// there're separate triggers for wrapper (isWrapperOpen) and the popper (isOpen).
// This is also best practice for popper.
//
// B) When we have arrows, we might do CSS tricks that puts part of the arrow
// underneath the popper. If we transition the arrow and popper separately with
// opactiy, then when we're half-way you'll see that the two elements overlap. To
// avoid this, we need to wrap them and transition the wrapper instead, since then
// no opacity (or anything else) is transitioned on the actual popper (and arrow).
//
// C) For the arrow (which is completely overrideable since it's slot default
// content), we wrap it to be able to use transform (since it's used by popper on
// the data-popper-arrow element).

import ClientOnly from 'vue-client-only';
import { createPopper } from '@popperjs/core';
import throttle from 'raf-throttle';
import isElement from 'lodash/isElement';
import isFunction from 'lodash/isFunction';
import isNull from 'lodash/isNull';
import resizeDetection from '@lib/resize-detection';
import { Portal } from '@linusborg/vue-simple-portal';

// TODO: Rework API into a named parameters API where we separate event and $el into separate parameters
export function fsPopperClose($elOrEvent) {
  const $el = isElement($elOrEvent) ? $elOrEvent : $elOrEvent.currentTarget || $elOrEvent.target; // Fallback on .target to be able to handle bubbled component events (component events lack currentTarget since they're not proper DOM elements)

  $el.dispatchEvent(new Event('popper-close', { bubbles: true }));
}

export function getPopperChildren(component) {
  const popperChildren = [];

  component.$children.forEach((childComponent) => {
    // Add child if it's a popper component AND it's open
    if (childComponent.FsPopperInstance && childComponent.$refs?.popper) popperChildren.push(childComponent);

    // Check and add child's children (and children's children, ...)
    popperChildren.push(...getPopperChildren(childComponent));
  });

  return popperChildren;
}

export const FsPopper = {
  name: 'FsPopper',

  inheritAttrs: false,

  props: {
    isCloseOnEsc: { type: Boolean, default: false },
    animation: {
      // Built in animations: none, fade, slide-fade, fall-from-top-with-fade
      type: String,
      default: 'fall-from-top-with-fade',
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    arrowClass: {
      type: [Array, Object, String],
      default: 'popper-arrow',
    },
    arrowWrapperClass: {
      type: [Array, Object, String],
      default: 'popper-arrow-wrapper',
    },
    arrowWrapperTag: {
      type: String,
      default: 'div', // Must NOT be a Vue component (but a DOM element tag)
    },
    beforeClose: {
      type: Function,
      default: () => {},
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
    },
    closeOnOutsideClickCheck: {
      type: Function,
    },
    defaultClass: {
      type: String,
      default: 'popper',
    },
    flip: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/flip/
      type: [Boolean, Number, Object, String], // Mix of flip.enabled (Boolean), flip.padding (Number | Object) and flip.rootBoundary (String)
      default: null,
    },
    modifiers: {
      type: Array,
    },
    offset: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/offset/
      type: [Array, Function],
    },
    onFirstUpdate: {
      type: Function,
    },
    show: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      // Placements taken straight from: https://popper.js.org/docs/v2/constructors/#options
      validator: (placement) =>
        [
          'auto',
          'auto-start',
          'auto-end',
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'right',
          'right-start',
          'right-end',
          'left',
          'left-start',
          'left-end',
        ].includes(placement),
      default: 'bottom',
    },
    preventOverflowAltAxis: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/preventOverflow/
      type: [Boolean],
    },
    preventOverflowMainAxis: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/preventOverflow/
      type: [Boolean],
      default: true,
    },
    preventOverflowPadding: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/preventOverflow/
      type: [Number, Object],
    },
    preventOverflowRootBoundary: {
      // Modifier quick-option - see Popper docs here https://popper.js.org/docs/v2/modifiers/preventOverflow/
      type: [String],
    },
    reference: {
      type: [Function, Object, String],
    },
    selector: {
      type: String,
    },
    // See Popper docs for options: https://popper.js.org/docs/v2/constructors/#options
    strategy: {
      type: String,
      // Strategies taken straight from: https://popper.js.org/docs/v2/constructors/#options
      validator: (strategy) => ['absolute', 'fixed'].includes(strategy),
      default: 'absolute',
    },
    tag: {
      type: String,
      default: 'div',
    },
    updateOnResize: {
      type: Boolean,
      default: true,
    },
    wrapperClass: {
      type: [Array, Object, String],
      default: 'popper-wrapper',
    },
    wrapperTag: {
      type: String,
      default: 'div', // Must NOT be a Vue component (but a DOM element tag)
    },
    noStyling: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ClientOnly,
    Portal,
  },

  directives: {
    resize: resizeDetection,
  },

  data() {
    return {
      currentTarget: null,
      isOpen: false,
      isWrapperOpen: false,
      pointerDownElement: null,
      popperInstance: null,

      currentPlacement: null,
    };
  },

  computed: {
    // Merge in tag class
    classProp() {
      if (typeof this.inheritedClass === 'string')
        return this.inheritedClass + (this.defaultClass ? ` ${this.defaultClass}` : ``);
      if (Array.isArray(this.inheritedClass))
        return this.inheritedClass.concat(this.defaultClass ? [this.defaultClass] : []);
      if (!this.defaultClass) return this.inheritedClass;

      const classes = { ...this.inheritedClass };
      classes[this.defaultClass] = true;

      return classes;
    },
    // Since class and style in Vue 2 (fixed in Vue 3) isn't part of $attrs, we need special treatment to get this to work
    inheritedClass() {
      return this.$vnode.data.staticClass || this.$vnode.data.class || '';
    },
    styleProp() {
      return this.$vnode.data.staticStyle || this.$vnode.data.style;
    },
    // Workaround for passing scopeId to div.popper (it't not technically the root, but to allow for parent to style with scoped styles - as is expected - it makes sense to add it)
    parentScopeId() {
      const $renderingComponent = this.$slots?.default?.[0]?.context;

      return $renderingComponent?.$options?._scopeId || null;
    },
    popperOptions() {
      const popperOptions = {};
      const { modifiers, onFirstUpdate, placement, strategy } = this;

      popperOptions.modifiers = modifiers || [];
      if (onFirstUpdate) popperOptions.onFirstUpdate = onFirstUpdate;
      if (placement) popperOptions.placement = placement;
      if (strategy) popperOptions.strategy = strategy;

      // Modifier quick-option 'flip'
      if (!isNull(this.flip)) {
        if (typeof this.flip === 'boolean') {
          popperOptions.modifiers.push({
            name: 'flip',
            enabled: this.flip,
          });
        } else {
          const options = {};

          if (typeof this.flip === 'string') {
            options.rootBoundary = this.flip;
          } else {
            // Number/Object
            options.padding = this.flip;
          }

          popperOptions.modifiers.push({
            name: 'flip',
            options,
          });
        }
      }

      // Modifier quick-option 'offset'
      if (this.offset) {
        popperOptions.modifiers.push({
          name: 'offset',
          options: {
            offset: this.offset,
          },
        });
      }

      // Modifier quick-option 'preventOverflow'
      if (
        this.preventOverflowAltAxis ||
        !this.preventOverflowMainAxis ||
        this.preventOverflowPadding ||
        this.preventOverflowRootBoundary
      ) {
        const options = {};

        if (this.preventOverflowAltAxis) options.altAxis = this.preventOverflowAltAxis;
        if (!this.preventOverflowMainAxis) options.mainAxis = this.preventOverflowMainAxis;
        if (this.preventOverflowPadding) options.padding = this.preventOverflowPadding;
        if (this.preventOverflowRootBoundary) options.rootBoundary = this.preventOverflowRootBoundary;

        popperOptions.modifiers.push({
          name: 'preventOverflow',
          options,
        });
      }

      popperOptions.modifiers.push({
        name: 'popperListener',
        enabled: true,
        phase: 'main',
        fn: this.popperListener,
      });

      return popperOptions;
    },
    popperReference() {
      if (this.currentTarget) return this.currentTarget;

      if (!this.reference) {
        console.warn('FsPopper: No reference for popper found!');
        console.log(this);
        return;
      }

      if (isFunction(this.reference)) {
        return this.reference();
      }

      return this.reference;
    },
  },

  methods: {
    popperListener({ state }) {
      if (this.currentPlacement !== state.placement) {
        this.currentPlacement = state.placement;
        this.$emit('placement-changed', state.placement);
      }
    },
    handleEscPress(event) {
      if (event.key !== 'Escape') {
        return;
      }

      if (!getPopperChildren(this).length) {
        this.close();
      }
    },

    enter() {
      if (this.isCloseOnEsc) {
        document.addEventListener('keydown', this.handleEscPress);
      }

      this.generatePopper();

      if (this.closeOnOutsideClick) {
        this.bindCloseTimeout = setTimeout(() => {
          // To avoid having it trigger immediately (on the click/event that triggered the popover)
          this.bindCloseOnOutsideClick();
        });
      }

      this.$emit('open');
    },
    beforeLeave() {
      document.removeEventListener('keydown', this.handleEscPress);
      this.unbindCloseOnOutsideClick();
    },
    afterLeave() {
      this.isWrapperOpen = this.isOpen;
      this.$emit('fully-closed');
      this.destroyPopper();
    },
    bindCloseOnOutsideClick() {
      // Needs to be triggered before other events, since other events may change the DOM,
      // incorrectly break the parent chain (and thus fail the clickWithin test) and cause
      // a close to happen even though it shouldn't.
      //
      // If one needs to handle this in a different fashion, it's best to add beforeClose
      // check, or disable close-on-outside-click altogether.
      document.addEventListener('click', this.wrappedDocumentClick, { capture: true });
      document.addEventListener('pointerdown', this.wrappedPointerDown);
    },
    unbindCloseOnOutsideClick() {
      clearTimeout(this.bindCloseTimeout);
      document.removeEventListener('click', this.wrappedDocumentClick);
      document.removeEventListener('pointerdown', this.wrappedPointerDown);
    },
    documentPointerDown(event) {
      this.pointerDownElement = event.target;
    },
    documentClick(event) {
      // We're in the middle of opening/closing - make it a no-op to avoid issues
      if (this.isOpen !== this.isWrapperOpen || !this.$refs.popper) return;

      // Use pointer down element if it exists (it should), to take into consideration
      // the element where the click started rather than where it ended (which is where
      // the event.target of a click event is set to).
      // Fallback also helps with browsers not supporting pointerdown (Safari <13)
      const $target = this.pointerDownElement || event.target;
      this.pointerDownElement = null; // Reset to avoid mistakenly re-using an old value

      function clickWithinHelper($target, withinElement) {
        return withinElement.contains($target) || withinElement === $target;
      }

      // Click inside popper
      if (clickWithinHelper($target, this.$refs.popper)) return;

      for (let i = 0, popperChildrenComponents = getPopperChildren(this); i < popperChildrenComponents.length; i++) {
        const popperChildComponent = popperChildrenComponents[i];

        if (clickWithinHelper($target, popperChildComponent.$refs.popper)) return;
      }

      // User extra check (useful for non-popper sub-components outside the popper DOM structure)
      if (this.closeOnOutsideClickCheck) {
        if (this.closeOnOutsideClickCheck($target, event)) return;
      }

      // Otherwise, trigger close
      // Delay close in order to capture other events first (that might trigger toggling, and thus triggering a close, open again scenario)
      requestAnimationFrame(() => {
        this.close({ type: 'click-outside' });
      });
    },
    open(event) {
      // currentTarget is what we want - but Safari 9 and below doesn't support it, thus fallback on target
      if (event?.currentTarget || event?.target) {
        this.currentTarget = event.currentTarget || event.target;
      }

      this.isOpen = true;
    },
    async close(args) {
      // Break if promise returns true (truthy)
      if (await Promise.resolve(this.beforeClose(args))) return;

      this.isOpen = false;
      // Cleanup
      this.currentTarget = null;

      this.$emit('close');
    },
    toggle(event) {
      if (this.isOpen) {
        this.close({ type: 'toggle', event });
      } else {
        this.open(event);
      }
    },
    generatePopper() {
      if (this.popperInstance) {
        console.warn('FsPopper: Trying to open an already open popper');
        return;
      }

      this.popperInstance = createPopper(this.popperReference, this.$refs.popper, this.popperOptions);
    },
    destroyPopper() {
      if (!this.popperInstance) return;

      this.popperInstance.destroy();
      this.popperInstance = null;
    },
    resize() {
      if (!this.popperInstance || !this.updateOnResize) return;

      this.popperInstance.update();
    },
  },
  watch: {
    closeOnOutsideClick(val, oldVal) {
      if (Boolean(val) === Boolean(oldVal)) return;

      if (val) {
        this.bindCloseOnOutsideClick();
      } else {
        this.unbindCloseOnOutsideClick();
      }
    },
    show(val) {
      if (val !== this.isOpen) this.toggle();
    },
    isOpen(val) {
      if (val) this.isWrapperOpen = true;

      this.$emit('update:isOpen', val);
    },
  },
  created() {
    this.FsPopperInstance = true; // Used to identify the component as a FsPopper instance
    this.wrappedDocumentClick = this.documentClick.bind(this);
    this.wrappedPointerDown = this.documentPointerDown.bind(this);
    this.throttledResize = throttle(this.resize);
  },
  destroyed() {
    // If something removes the popper right away (e.g. parent disappears),
    // we still want to ensure proper cleanup.
    this.unbindCloseOnOutsideClick();
    this.destroyPopper();
  },
};

export default FsPopper;
</script>

<style scoped lang="scss">
@use 'sass:math';

// Animations
// ==========

// Fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// Slide
.slide-fade-enter-active {
  transition: top 0.4s ease, opacity 0.4s ease;
}
.slide-fade-leave-active {
  transition: top 0.4s cubic-bezier(1, 0.5, 0.8, 1), opacity 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  top: math.div(-1rem, 1.6);
  opacity: 0;
}

// Slide fade fast
.slide-fade-fast-enter-active {
  transition: top 0.12s ease, opacity 0.12s ease;
}
.slide-fade-fast-leave-active {
  transition: top 0.12s cubic-bezier(1, 0.5, 0.8, 1), opacity 0.12s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-fast-enter,
.slide-fade-fast-leave-to {
  top: math.div(-1rem, 1.6);
  opacity: 0;
}

//.slide-fade-2-enter {transform: translateX(-100%);}
//.slide-fade-2-enter-active {transition: transform .15s;}
//.slide-fade-2-enter-to {transform: translateX(0);}
//
//.slide-fade-2-leave {transform: translateX(0);}
//.slide-fade-2-leave-active {transition: transform .15s;}
//.slide-fade-2-leave-to {transform: translateX(-100%);}

// ==========================================================================
// ==========================================================================
// Popper styles
.popper {
  border-radius: math.div(1rem, 1.6);
  background: #fff;
  box-shadow: 0 0 math.div(3rem, 1.6) rgba(0, 0, 0, 0.1);

  &--empty-styling {
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }
}

/* Fixes a render issue in Safari, when having absolute
positioned content within a popper,
and a Vue update triggered in the mount function.  */
.popper-wrapper {
  position: absolute;
}
</style>
