// We could use ResizeObserver here instead, but there are 2 downsides:
// 1) Triggered while in transition (animating) - which is typically not
// what we're looking for (and which can cause unexpected effects/"issues")
// 2) Browser-compatibility (IE11/Edge/Safare <13.1)

// One benefit of using ResizeObserver is that it triggers when something
// else triggers a resize - but that's rarely (no case yet?) what we're
// looking for. When that happens, we can easily add a choice using an arg.

import { windowResizeAdd, windowResizeRemove } from 'apps/public/lib/window-resize';

const _targets = [];

function _getTargetFromEl(el) {
  return _targets.find((target) => target.el === el);
}

function _onResize() {
  _targets.forEach(_check);
}

function _check(target) {
  const { el, value, instance } = target;
  const { childNodes, scrollHeight } = el;

  if (!target.value) return;

  // No point in doing anything with 0-1 children
  if (childNodes.length < 2) {
    el.style.height = '';
    return;
  }

  const childNodesArray = [...childNodes].filter((childNode) => Boolean(childNode.getBoundingClientRect));
  let boundingClientRects = childNodesArray.map((childNode) => childNode.getBoundingClientRect());

  // Step 1: Find out whether it's wise to manage height at all
  if (el.style.height) {
    // TODO: Optimize - we've obviously done this before, let's check if there's any point in trying anything first (or perhaps we should just let it be?)

    // Otherwise, reset height - and update values
    el.style.height = '';
    boundingClientRects = childNodesArray.map((childNode) => childNode.getBoundingClientRect());
  }

  const maxBottom = boundingClientRects.reduce((bottom, node) => (bottom > node.bottom ? bottom : node.bottom), 0);
  const originalHeight = el.getBoundingClientRect().height;

  el.style.height = originalHeight - 1 + 'px';

  boundingClientRects = childNodesArray.map((childNode) => childNode.getBoundingClientRect());
  const newMaxBottom = boundingClientRects.reduce((bottom, node) => (bottom > node.bottom ? bottom : node.bottom), 0);

  if (newMaxBottom >= maxBottom) {
    // No point in going further - no height gained
    el.style.height = '';
    return;
  }

  // Step 2 - optimize height
  const containerBottom = el.getBoundingClientRect().bottom;
  const computedStyle = window.getComputedStyle(el);
  const borderBottom = parseFloat(computedStyle.getPropertyValue('border-bottom-width'));
  const borderTop = parseFloat(computedStyle.getPropertyValue('border-top-width'));
  const paddingBottom = parseFloat(computedStyle.getPropertyValue('padding-bottom'));
  const paddingTop = parseFloat(computedStyle.getPropertyValue('padding-top'));
  const diff = containerBottom - newMaxBottom - (paddingBottom + paddingTop + borderBottom + borderTop);

  el.style.height = originalHeight - 1 - diff + 'px';
}

function bind(el, binding, vnode) {
  const target = {
    el,
    instance: vnode.context,
    value: binding.value ?? true,
  };

  _targets.push(target);

  if (_targets.length === 1) windowResizeAdd(_onResize);
}

function inserted(el) {
  const target = _getTargetFromEl(el);
  _check(target);
}

function unbind(el) {
  if (_targets.length === 1) windowResizeRemove(_onResize);

  const target = _getTargetFromEl(el);
  _targets.splice(_targets.indexOf(target), 1);
}

export default {
  bind,
  inserted,
  unbind,
};
