var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-input" },
    [
      _c("select-custom", {
        staticClass: "select-input__select",
        style: {
          width: (_vm.selectWidth || _vm.calculatedSelectWidth) + "px",
          maxWidth: (_vm.selectWidth || _vm.calculatedSelectWidth) + "px",
        },
        attrs: {
          placeholder: _vm.selectPlaceholder,
          name: _vm.selectName,
          id: _vm.selectId,
          options: _vm.selectOptions,
          "is-disabled": _vm.isSelectDisabled,
          "is-error": _vm.isSelectError,
        },
      }),
      _vm._v(" "),
      _c("input-custom", {
        staticClass: "select-input__text-input",
        attrs: {
          "is-search": _vm.isInputSearch,
          placeholder: _vm.inputPlaceholder,
          value: _vm.inputValue,
          type: _vm.inputType,
          "is-disabled": _vm.isInputDisabled,
          "is-error": _vm.isInputError,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }