<template>
  <div class="add-unit-form">
    <div class="add-unit-form__main-title">Add Production Unit</div>

    <!-- __frames-row-wrap -->
    <div class="add-unit-form__frames-row-wrap">
      <!-- __frames-row -->
      <div
        :class="{
          'add-unit-form__frames-row': 1,
          'add-unit-form__frames-row--position-0': visibleFrame === 0,
          'add-unit-form__frames-row--position-1': visibleFrame === 1,
          'add-unit-form__frames-row--position-2': visibleFrame === 2,
        }"
        :style="{ maxHeight: ($refs[`frame${visibleFrame}`] && $refs[`frame${visibleFrame}`].clientHeight) + 'px' }"
      >
        <!-- Separate frame -->
        <div
          :class="{
            'separate-frame': 1,
            'add-unit-form__separate-frame': 1,
            'add-unit-form__separate-frame--invisible': visibleFrame !== 0,
          }"
          ref="frame0"
        >
          <div class="separate-frame__description-n-input">
            <div class="separate-frame__description">
              You are about to create a multi-unit Company. In order to proceed, please input name of Mother Company.
            </div>

            <input-custom
              class="separate-frame__text-input"
              placeholder="Enter Mother Company Name"
              v-model="motherCompanyName"
            />
          </div>

          <cancel-n-action-btn-pair
            class="separate-frame__manage-buttons-panel"
            action-btn-name="Proceed"
            :action-btn-icon="svg.arrowCircleRightIcon"
            @cancel="() => {}"
            @action="visibleFrame = 1"
          />
        </div>
        <!-- / Separate frame -->

        <!-- Separate frame -->
        <div
          :class="{
            'separate-frame': 1,
            'add-unit-form__separate-frame': 1,
            'add-unit-form__separate-frame--invisible': visibleFrame !== 1,
          }"
          ref="frame1"
        >
          <div class="separate-frame__description-n-input">
            <div class="separate-frame__description">
              Thanks! We have now adjusted Supplier Name to &lt;Mother Company Name&gt; and created a new Unit. Please
              name this new Unit.
            </div>

            <input-custom
              class="separate-frame__text-input"
              placeholder="Enter Mother Company Name"
              v-model="motherCompanyName"
            />
          </div>

          <cancel-n-action-btn-pair
            class="separate-frame__manage-buttons-panel"
            @cancel="() => {}"
            @action="visibleFrame = 2"
          />
        </div>
        <!-- / Separate frame -->

        <!-- Separate frame -->
        <div
          :class="{
            'separate-frame': 1,
            'add-unit-form__separate-frame': 1,
            'add-unit-form__separate-frame--invisible': visibleFrame !== 2,
          }"
          ref="frame2"
        >
          <div class="separate-frame__description-n-input">
            <input-custom
              class="separate-frame__text-input"
              placeholder="Enter Mother Company Name"
              v-model="motherCompanyName"
            />
          </div>

          <cancel-n-action-btn-pair
            class="separate-frame__manage-buttons-panel"
            @cancel="() => {}"
            @action="visibleFrame = 0"
          />
        </div>
        <!-- / Separate frame -->
      </div>
      <!-- / __frames-row -->
    </div>
    <!-- / __frames-row-wrap -->
  </div>
</template>

<script>
// Components
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';
import InputCustom from '@components/form-elements/input-custom.vue';

// Icons
// SVG
import arrowCircleRightIcon from '@icons/other/arrow-circle-right.svg';

export default {
  name: 'add-production-unit',

  components: {
    CancelNActionBtnPair,
    InputCustom,
  },

  data() {
    return {
      visibleFrame: 0,

      isSaveInProgress: false,

      svg: {
        arrowCircleRightIcon,
      },

      motherCompanyName: '',
      unitName: '',
    };
  },

  methods: {},

  updated() {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// ============================================================================
// Separate frame
.separate-frame {
  &__description-n-input {
    margin-bottom: math.div(4rem, 1.6);
  }

  &__description {
    margin-bottom: math.div(1.5rem, 1.6);
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(2rem, 1.6);
  }

  &__text-input {
  }

  &__manage-buttons-panel {
  }
}

// ============================================================================
// ============================================================================
// Add unit form
.add-unit-form {
  width: math.div(42rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(3rem, 1.6);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  &__main-title {
    margin: 0 0 math.div(2.7rem, 1.6) 0;
    font: 400 #{math.div(2rem, 1.6)}/#{math.div(2.4rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__frames-row-wrap {
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
  }
  &__frames-row {
    width: 100%;
    max-width: 100%;
    max-height: math.div(50rem, 1.6);
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    transition: max-height 0.2s ease, transform 0.2s ease;

    &--position-0 {
      transform: translateX(0);
    }
    &--position-1 {
      transform: translateX(-100%);
    }
    &--position-2 {
      transform: translateX(-200%);
    }
  }

  &__separate-frame {
    width: 100%;
    min-width: 100%;
    padding: 0 math.div(2rem, 1.6);
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s ease;

    &--invisible {
      opacity: 0;
    }
  }
}
</style>
