<template>
  <!-- Certifications form -->
  <div class="certifications-form">
    <div class="certifications-form__main-title">Certifications</div>

    <!-- Certifications list -->
    <ul class="certifications-list certifications-form__certifications-list">
      <!-- Separate certification form -->
      <inputs-section-frame
        v-for="(person, index) in certificateData"
        :key="person.fullName"
        tag="li"
        class="certifications-list__separate-certification-form"
        show-drag-handle
        show-delete-but
      >
        <!-- Form elements list -->
        <div class="form-elements-list">
          <div class="form-elements-list__form-element-wrap">
            <input-custom
              class="form-elements-list__text-input"
              placeholder="Certification Name"
              v-model.trim="certificateData[index].name"
              no-clear-button
            />
          </div>

          <div class="form-elements-list__form-element-wrap">
            <input-custom
              class="form-elements-list__text-input"
              placeholder="Organization"
              v-model.trim="certificateData[index].organization"
              no-clear-button
            />
          </div>

          <div class="form-elements-list__form-element-wrap">
            <date-picker
              class="form-elements-list__text-input"
              v-model="certificateData[index].date"
              format="YYYY-MM-DD"
              type="date"
              placeholder="Select date"
              show-week-number
              value-type="format"
            >
            </date-picker>
          </div>

          <div class="form-elements-list__form-element-wrap">
            <input-custom
              class="form-elements-list__text-input"
              placeholder="Add Proof letter"
              type="fileOrUrl"
              is-file
            />
          </div>
        </div>
        <!-- / Form elements list --> </inputs-section-frame
      ><!-- / Separate certification form -->
    </ul>
    <!-- / Certifications list -->

    <fs-button secondary class="certifications-form__add-person-but">
      <fs-button-icon
        :icon="icons.interfaceElements.plusInCircleIcon"
        width="1.5rem/1.6"
        height="1.5rem/1.6"
        top="0"
        color="dark-blue"
      />
      Add Certification
    </fs-button>

    <cancel-n-action-btn-pair class="certifications-form__manage-buttons-panel" @cancel="() => {}" @action="() => {}" />
  </div>
  <!-- / Certifications form -->
</template>

<script>
// Components
import FsButton from '@components/buttons/fs-button.vue';
import FsButtonIcon from '@components/fs-button-icon.vue';
import InputCustom from '@components/form-elements/input-custom.vue';
import InputsSectionFrame from '@components/inputs-section-frame.vue';
import DatePicker from 'vue2-datepicker';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
import socialsIcons from '@lib/icons-imports/socials';
// SVG
import photoPlaceholderIcon from '@icons/other/photo-placeholder-icon.svg';

export default {
  name: 'certifications-list',

  props: {},

  components: {
    FsButton,
    FsButtonIcon,
    InputCustom,
    InputsSectionFrame,
    DatePicker,
    CancelNActionBtnPair,
  },

  data() {
    return {
      certificateData: [{ name: '', organization: '', date: null }],

      isSaveInProgress: false,

      icons: {
        interfaceElements: interfaceElementsIcons,
        socials: socialsIcons,
      },

      svg: { photoPlaceholderIcon },
    };
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/datepicker';

// ============================================================================
// ============================================================================
// Form elements list
.form-elements-list {
  &__form-element-wrap {
    height: math.div(3.6rem, 1.6);
    margin-bottom: math.div(1.2rem, 1.6);
    display: flex;
    position: relative;
  }

  &__form-element-icon {
    width: math.div(1.5rem, 1.6);
    height: math.div(1.5rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    top: math.div(1rem, 1.6);
    left: math.div(1.6rem, 1.6);
    z-index: 2;
    pointer-events: none;

    ::v-deep svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text-input {
    flex-grow: 1;
  }
}

// ============================================================================
// ============================================================================
// Persons list
.certifications-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__separate-certification-form {
    margin-bottom: math.div(3.5rem, 1.6);
  }
}

// ============================================================================
// ============================================================================
// Certifications form
.certifications-form {
  width: math.div(55rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(5rem, 1.6);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  &__main-title {
    margin-bottom: math.div(2.7rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__certifications-list {
    width: 100%;
  }

  &__add-person-but {
    margin-bottom: math.div(3.5rem, 1.6);
    border-color: white !important;
    outline: none;
    font-weight: 400;
    text-transform: none;
    transition: border-color 0.15s ease, opacity 0.15s ease;

    &:hover {
      border-color: rgb(53, 73, 94) !important;
    }
  }

  &__manage-buttons-panel {
  }
}
</style>
