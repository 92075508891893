import { render, staticRenderFns } from "./types-n-processes-edit.vue?vue&type=template&id=12d4a968&scoped=true&"
import script from "./types-n-processes-edit.vue?vue&type=script&lang=js&"
export * from "./types-n-processes-edit.vue?vue&type=script&lang=js&"
import style0 from "./types-n-processes-edit.vue?vue&type=style&index=0&id=12d4a968&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12d4a968",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/Frontend-old/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12d4a968')) {
      api.createRecord('12d4a968', component.options)
    } else {
      api.reload('12d4a968', component.options)
    }
    module.hot.accept("./types-n-processes-edit.vue?vue&type=template&id=12d4a968&scoped=true&", function () {
      api.rerender('12d4a968', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/pages/supplier/edit-poppers/types-n-processes-edit.vue"
export default component.exports