<template>
  <div :class="{ 'entities-list-with-search': 1, 'entities-list-with-search--short': createNewEntityMode }">
    <fs-popper ref="popperWhichArticlesToAlter" placement="top">
      <which-articles-to-alter
        :articles="$store.articles.productArticles[productId]"
        :entities="localEntities.filter((entity) => entityIdsNeedingArticleIds.includes(entity.id))"
        :entities-ids-not-requiring-updating="
          localEntities.map((entity) => entity.id).filter((entityId) => !entityIdsNeedingArticleIds.includes(entityId))
        "
        :save-func="saveFunc"
        suppliers-mode
        @cancel="saveInProgress = false"
        @close="
          $refs.popperWhichArticlesToAlter.toggle($event);
          saveInProgress = false;
          $emit('save-finish');
          fsPopperClose($el);
        "
      />
    </fs-popper>

    <div v-if="!createNewEntityMode" class="entities-list-with-search__active-entities-section">
      <h3 class="entities-list-with-search__title">Membership Organizations</h3>

      <div class="find-or-create-new-entity entities-list-with-search__find-or-create-new-entity">
        <input-custom
          class="find-or-create-new-entity__search-input"
          v-model="searchQuery"
          @change="debouncedSearch"
          placeholder="Add New Supplier"
          is-search
          @reset-input="searchQuery = ''"
        />

        <transition name="fall-from-top-with-fade">
          <search-suggestions-list
            v-if="searchQuery"
            class="find-or-create-new-entity__suggestions-list"
            :items-list="suggestedItemsList"
            :existing-items-ids-list="localEntities.map((item) => item.id)"
            :create-btn-text="`Add new Supplier \u201C${searchQuery}\u201D`"
            :search-query="searchQuery"
            :is-skeleton-visible="isSearchInProgress"
            @create-new="
              showCreateNewEntityForm(searchQuery);
              searchQuery = '';
            "
            @select-item="selectEntity"
          />
        </transition>
      </div>

      <!-- Entities list with search -->
      <scrollable-block class="entities-list-with-search__existing-entities-list">
        <draggable
          v-model="localEntities"
          v-bind="dragOptions"
          @start="dragNdropState = true"
          @end="dragNdropState = false"
          :component-data="draggableComponentData"
        >
          <transition-group type="transition" :name="!dragNdropState ? 'flip-list' : null">
            <div
              class="single-entity entities-list-with-search__single-entity"
              v-for="(entity, index) in localEntities"
              :key="entity.id"
            >
              <img
                class="single-entity__icon"
                :src="$store.countries.getFlagPathFromName(entity.country)"
                :alt="entity.country"
              />

              <div class="single-entity__name-n-subscript">
                <span class="single-entity__name">
                  {{ entity.name }}
                </span>

                <span class="single-entity__subscript">
                  {{ entity.country }}
                </span>
              </div>

              <div class="single-entity__manage-buts-wrap">
                <div
                  :class="{
                    'single-entity__manage-button': 1,
                    'single-entity__manage-button--star': 1,
                    'single-entity__manage-button--always-visible': starredEntityId === entity.id,
                  }"
                  @click="markEntityWithStar(entity.id, index)"
                  v-html="starredEntityId === entity.id ? svg.starFilledIcon : svg.starEmptyIcon"
                ></div>

                <div
                  class="single-entity__manage-button single-entity__manage-button--delete-btn"
                  @click="remove(entity)"
                  v-html="svg.trashCanIcon"
                ></div>
              </div>
            </div>
          </transition-group>
        </draggable> </scrollable-block
      ><!-- / Entities list with search -->

      <cancel-n-action-btn-pair
        class="entities-list-with-search__manage-buttons-panel"
        @cancel="
          ($event) => {
            fsPopperClose($event);
            $emit('cancel', $event);
          }
        "
        @action="save($event)"
        :is-save-in-progress="saveInProgress"
      />
    </div>

    <div v-else class="entities-list-with-search__add-new-entity-section">
      <h3 class="entities-list-with-search__title">Add new supplier</h3>

      <!-- New entity form -->
      <div class="new-entity-form entities-list-with-search__new-entity-form">
        <div class="new-entity-form__subtitle">Supplier name</div>

        <input-custom
          class="new-entity-form__form-element"
          v-model="newEntityName"
          :is-error="newEntityFormError"
          @input="
            newEntityFormError = false;
            newEntityFormErrorMsg = '';
          "
        />

        <div v-if="newEntityFormErrorMsg" class="new-entity-form__error-message">{{ newEntityFormErrorMsg }}</div>

        <div class="new-entity-form__subtitle">Country</div>

        <input-country-with-hints
          class="entities-list-with-search__search-form"
          placeholder="Country name"
          v-model="newEntitySubscript"
          autocomplete-off
        />
      </div>
      <!-- / New entity form -->

      <cancel-n-action-btn-pair
        class="entities-list-with-search__manage-buttons-panel"
        @cancel="createNewEntityMode = false"
        @action="createNewEntity"
        :is-save-in-progress="saveInProgress"
        :is-disabled="!(newEntityName && newEntitySubscript)"
        action-btn-name="Add"
      />
    </div>
  </div>
</template>

<script>
import lodashDebounce from 'lodash/debounce';
import { fsPopperClose } from '@components/poppers/fs-popper.vue';
import Draggable from 'vuedraggable';
import ScrollableBlock from '@components/scrollable-block.vue';

// Components
import InputCountryWithHints from '@components/form-elements/selects/input-country-with-hints.vue';
import FsPopper from '@components/poppers/fs-popper.vue';
import WhichArticlesToAlter from '@components/which-articles-to-alter.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';
import InputCustom from '@components/form-elements/input-custom.vue';
import SearchSuggestionsList from '@components/search-suggestions-list/index.vue';

// Icons
import closeCircleOutlineIcon from '@icons/other/close-circle-outline-icon.svg';
import saveFloppyIcon from '@icons/other/save-floppy-icon.svg';
import trashCanIcon from '@icons/other/trash-can-icon.svg';
import addCirclePlusIcon from '@icons/other/add-circle-plus.svg';
import starEmptyIcon from '@icons/other/star-empty.svg';
import starFilledIcon from '@icons/other/star-filled.svg';
import arrowCircleLeftIcon from '@icons/other/arrow-circle-left.svg';

export default {
  name: 'membership-companies-edit-modal',

  components: {
    InputCountryWithHints,
    Draggable,
    ScrollableBlock,
    FsPopper,
    WhichArticlesToAlter,
    CancelNActionBtnPair,
    InputCustom,
    SearchSuggestionsList,
  },

  props: {
    entities: {
      type: Array,
      default: () => [],
    },
    saveFunc: {
      type: Function,
      required: true,
    },
    productId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dragNdropState: false,

      draggableComponentData: {
        props: {
          type: 'transition',
          name: 'flip-list',
        },
      },

      starredEntityId: null,

      dragOptions: {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'single-entity--drag-drop-ghost',
      },

      createNewEntityMode: false,

      newEntityName: '',
      newEntitySubscript: '',
      newEntityFormError: false,
      newEntityFormErrorMsg: '',

      saveInProgress: false,

      entityIdsNeedingArticleIds: [],

      searchQuery: '',
      searchResult: [],
      isSearchInProgress: false,

      debouncedSearch: null,

      //icons
      svg: {
        closeCircleOutlineIcon,
        saveFloppyIcon,
        trashCanIcon,
        addCirclePlusIcon,
        starEmptyIcon,
        starFilledIcon,
        arrowCircleLeftIcon,
      },
      localEntities: [...this.entities],
    };
  },

  created() {
    this.debouncedSearch = lodashDebounce(this.search, 200, { leading: true, trailing: true });
  },

  mounted() {
    this.setStarredEntityId();
  },

  updated() {},

  computed: {
    suggestedItemsList() {
      const suggestedItemsList = this.searchResult.map((item) => ({
        id: item.id,
        name: item.name,
        subscript: item.country,
        imageSrc: $store.countries.getFlagPathFromName(item.country),
      }));

      return suggestedItemsList;
    },
  },

  methods: {
    fsPopperClose,

    async search() {
      const searchTerm = this.searchQuery.trim();

      if (searchTerm === '') {
        this.searchResult = [];
        return;
      }

      this.isSearchInProgress = true;
      const { suppliers } = await this.$store.suppliers.simpleSearch(searchTerm, 10);
      this.isSearchInProgress = false;

      this.searchResult = suppliers;
    },

    selectEntity(selectedItem) {
      const formattedEntityObj = {
        id: selectedItem.id,
        name: selectedItem.name,
        country: selectedItem.subscript,
      };

      this.localEntities.push(formattedEntityObj);
      this.searchQuery = '';
    },

    markEntityWithStar(entityId, index) {
      if (index === 0) return;

      this.starredEntityId = this.starredEntityId === entityId ? null : entityId;

      // Move starred entity to the top
      if (this.starredEntityId !== null && this.starredEntityId !== undefined) {
        const starredEntityObj = { ...this.localEntities.find((item) => item.id === entityId) };
        this.localEntities = this.localEntities.filter((item) => item.id !== entityId);
        this.localEntities = [starredEntityObj, ...this.localEntities];
      }
    },

    async save(event) {
      this.saveInProgress = true;

      const localEntitiesFormatted = this.localEntities.map((entity) => ({ supplierId: entity.id }));
      const saveFunctionResult = await this.saveFunc(localEntitiesFormatted);

      const entityIdsNeedingArticleIds = saveFunctionResult?.entityIdsNeedingArticleIds;

      // Open popper WhichArticlesToAlter
      if (entityIdsNeedingArticleIds?.length) {
        this.entityIdsNeedingArticleIds = [...entityIdsNeedingArticleIds];
        this.$refs.popperWhichArticlesToAlter.toggle(event);

        return;
      }

      this.saveInProgress = false;

      this.$emit('save-finish');

      this.fsPopperClose(this.$el);
    },

    async createNewEntity() {
      this.saveInProgress = true;

      const newEntity = {
        name: this.newEntityName,
        codeAlpha3: this.$store.countries.codeAlpha3FromName(this.newEntityCountry),
      };

      const { supplier: addedSupplier, nameTaken } = await this.$store.suppliers.simpleAdd(newEntity);

      if (addedSupplier) {
        this.localEntities.push(addedSupplier);

        this.newEntityName = '';
        this.newEntityCountry = '';

        this.saveInProgress = false;
        this.createNewEntityMode = false;
      } else if (nameTaken) {
        // TODO: Put this console.error to notification for user
        console.error(`Supplier name "${this.newEntityName}" already taken.`);

        this.newEntityFormError = true;
        this.newEntityFormErrorMsg = 'This supplier name is already taken';

        this.saveInProgress = false;
      }
    },

    showCreateNewEntityForm(entityName) {
      this.createNewEntityMode = true;
      this.newEntityName = entityName;
    },

    remove(entity) {
      this.localEntities = this.localEntities.filter((s) => s !== entity);
    },

    setStarredEntityId() {
      if (this.localEntities[0]?.id !== this.starredEntityId) {
        this.starredEntityId = this.localEntities[0]?.id;
      }
    },
  },

  watch: {
    localEntities() {
      this.setStarredEntityId();
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ==========================================================================
// ==========================================================================
// New entity form
.new-entity-form {
  &__subtitle {
    padding: 0 0 0 math.div(1.5rem, 1.6);
    margin-bottom: math.div(0.5rem, 1.6);
    text-transform: uppercase;
    font-size: math.div(1.2rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
  }

  &__form-element {
    margin-bottom: math.div(2rem, 1.6);
  }

  &__error-message {
    margin-top: math.div(-1.8rem, 1.6);
    margin-bottom: math.div(1.5rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    color: rgb(255, 90, 90);
    font-style: italic;
  }
}

// ==========================================================================
// ==========================================================================
// Single entity
.single-entity {
  padding: math.div(0.7rem, 1.6) math.div(1.5rem, 1.6);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  transition: 0.3s;
  cursor: grab;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: math.div(0.5rem, 1.6);
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: rgba(53, 73, 94, 0);
    transition: background-color 0.1s ease;
  }

  &:hover::before {
    background: rgba(53, 73, 94, 0.07);
  }

  &--drag-drop-ghost {
    opacity: 0.5;
  }

  &--clickable {
    cursor: pointer;
  }

  &__icon {
    float: left;
    width: math.div(3.4rem, 1.6);
    margin: math.div(0.8rem, 1.6) math.div(1.9rem, 1.6) math.div(0.8rem, 1.6) 0;
  }

  &__name-n-subscript {
    flex: 1;
    width: calc(100% - #{math.div(10rem, 1.6)});
    max-width: calc(100% - #{math.div(10rem, 1.6)});
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  &__name {
    width: calc(100% - #{math.div(1.5rem, 1.6)});
    max-width: calc(100% - #{math.div(1.5rem, 1.6)});
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__subscript {
    display: block;
    opacity: 0.5;
    text-transform: uppercase;
    font-size: math.div(1.2rem, 1.6);
  }

  &__manage-buts-wrap {
    display: flex;
  }

  &__manage-button {
    width: math.div(2rem, 1.6);
    height: math.div(2rem, 1.6);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    user-select: none;

    &::before {
      content: '';
      width: math.div(3.5rem, 1.6);
      height: math.div(3.5rem, 1.6);
      position: absolute;
      top: calc(50% - #{math.div(1.7rem, 1.6)});
      left: calc(50% - #{math.div(1.7rem, 1.6)});
      z-index: 1;
      cursor: pointer;
    }

    &::after {
      content: '';
      width: math.div(4rem, 1.6);
      height: math.div(4rem, 1.6);
      border-radius: math.div(10rem, 1.6);
      position: absolute;
      top: calc(50% - #{math.div(2rem, 1.6)});
      left: calc(50% - #{math.div(2rem, 1.6)});
      z-index: -1;
      transform: scale(0.75);
      background: rgba(0, 0, 0, 0.05);
      opacity: 0;
      transition: transform 0.15s ease, opacity 0.15s ease;
    }

    &:hover {
      &::after {
        transform: scale(1);
        opacity: 1;
      }
    }

    ::v-deep svg {
      width: math.div(1.7rem, 1.6);
      height: math.div(1.7rem, 1.6);
      fill: rgba(52, 73, 94, 1);
      transition: fill 0.15s ease;
    }

    &--star {
      margin-right: math.div(1rem, 1.6);
      opacity: 0;
      transition: opacity 0.1s ease;

      &::after {
        background: rgba(44, 151, 223, 0.13);
      }

      ::v-deep svg {
        width: math.div(1.9rem, 1.6);
        height: math.div(1.9rem, 1.6);
      }

      &:hover {
        ::v-deep svg {
          fill: #2c97df;
        }
      }
    }

    &--delete-btn {
      &::after {
        background: rgba(255, 0, 0, 0.15);
      }
    }

    &--delete-btn:hover {
      ::v-deep svg {
        fill: rgba(240, 70, 70, 1);
      }
    }

    &--always-visible {
      opacity: 1 !important;
    }
  }

  &:first-child &__manage-button--star,
  &:first-child &__manage-button--star::before,
  &:first-child &__manage-button--star::after {
    cursor: default;
  }
  &:first-child &__manage-button--star::before,
  &:first-child &__manage-button--star::after {
    opacity: 0 !important;
  }

  &:hover .single-entity__manage-button--star {
    opacity: 1;
  }
}

// ==========================================================================
// ==========================================================================
// Find or create new entity
.find-or-create-new-entity {
  position: relative;
  z-index: 2;

  &__search-input {
  }

  &__suggestions-list {
    width: 100%;
    position: absolute;
    inset: math.div(4rem, 1.6) auto auto 0;
  }
}

// ==========================================================================
// ==========================================================================
// Entities list with search
.entities-list-with-search {
  width: math.div(43.5rem, 1.6);
  min-height: math.div(54rem, 1.6);
  margin: 0;
  padding: math.div(3.4rem, 1.6) math.div(2.7rem, 1.6) math.div(3.7rem, 1.6);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &--short {
    min-height: math.div(31rem, 1.6);
  }

  &__active-entities-section {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__add-new-entity-section {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__new-entity-form {
    margin-bottom: math.div(1rem, 1.6);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  &__title {
    margin: 1px 0 math.div(1.9rem, 1.6);
    font-size: math.div(2rem, 1.6);
    line-height: math.div(2rem, 1.6);
    font-weight: normal;
    text-align: center;
  }

  &__search-form {
    position: relative;
    z-index: 3;
  }

  &__existing-entities-list {
    height: math.div(32rem, 1.6);
    min-height: math.div(32rem, 1.6);
    max-height: math.div(32rem, 1.6);
    padding: 0 math.div(0.2rem, 1.6);
    margin: math.div(0.8rem, 1.6) 0;

    ::v-deep .generic-scrollable-block__inner {
      width: calc(100% + 10px) !important;
      padding-right: 0;
    }

    ::v-deep .generic-scrollable-block__inner--with-scrollbar {
      padding-right: 10px;
    }
  }

  &__single-entity {
    margin: 0;
  }

  &__manage-buttons-panel {
    margin: auto math.div(-1.9rem, 1.6) 0;
  }
}
</style>
