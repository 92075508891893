import archived from '@icons/statuses/archived.svg';
import idea from '@icons/statuses/idea.svg';
import inDevelopment from '@icons/statuses/in-development.svg';
import readyToOrder from '@icons/statuses/ready-to-order.svg';

export default {
  archived,
  archive: archived,
  idea,
  inDevelopment,
  readyToOrder,
};
