var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gender-edit-form" },
    [
      _c("div", { staticClass: "gender-edit-form__main-title" }, [
        _vm._v("Gender"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-itself gender-edit-form__form-itself" },
        _vm._l(_vm.gendersList, function (gender) {
          return _c("checkbox-custom-with-icon", {
            key: gender,
            staticClass: "form-itself__single-gender",
            attrs: {
              icon: _vm.icons.gender[_vm.lodashCamelCase(gender)],
              "text-label": gender,
            },
            on: { check: function () {}, uncheck: function () {} },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "gender-edit-form__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }