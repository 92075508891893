<template>
  <!-- Description edit form -->
  <div class="description-edit-form">
    <h3 class="description-edit-form__title">Description</h3>

    <text-area-custom
      class="description-edit-form__textarea"
      :min-height="80"
      :max-height="186"
      :max-symbols="1000"
      autosize
      v-model="localDescription"
      placeholder="Write a description"
      ref="textareaDescription"
    />
    <div class="description-edit-form__btn-row">
      <fs-button
        secondary
        @click.native="
          ($event) => {
            fsPopperClose($event);
            $emit('cancel', $event);
          }
        "
      >
        <fs-button-icon :icon="svg.closeCircleOutlineIcon" width="1rem" height="1rem" top="-1px" color="dark-blue" />
        Cancel
      </fs-button>

      <fs-button @click.native="save" :loading="saving">
        <fs-button-icon :icon="svg.saveFloppyIcon" width="1rem" height="1rem" top="-1px" color="white" />
        Save
      </fs-button>
    </div>
    <!-- / btn-row -->
  </div>
  <!-- / Description edit form -->
</template>

<script>
import { fsPopperClose } from '@components/poppers/fs-popper.vue';

// Components
import FsButton from '@components/buttons/fs-button.vue';
import FsButtonIcon from '@components/fs-button-icon.vue';

// Icons
import closeCircleOutlineIcon from '@icons/other/close-circle-outline-icon.svg';
import saveFloppyIcon from '@icons/other/save-floppy-icon.svg';
// forms
import TextAreaCustom from 'apps/public/pages/components/form-elements/textarea-custom.vue';

export default {
  components: {
    FsButton,
    FsButtonIcon,
    TextAreaCustom,
  },

  props: {
    value: String,
    saveFunc: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      localDescription: this.value,
      saving: false,
      svg: {
        closeCircleOutlineIcon,
        saveFloppyIcon,
      },
    };
  },

  mounted() {
    // setTimeout is needed to avoid auto scrolling of the page to the initial position of the popper
    // (initial position of all poppers is somewhere in the bottom of the page and it changes after opening of the popper.
    // That's how popper component works)
    setTimeout(() => this.$refs.textareaDescription.focus(), 100);
  },

  methods: {
    fsPopperClose,

    async save() {
      this.saving = true;

      await this.saveFunc(this.localDescription);

      this.saving = false;
      this.$emit('save-finish');

      fsPopperClose(this.$el);
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// Description edit form ------------------------------------------------------
.description-edit-form {
  display: flex;
  flex-direction: column;
  width: math.div(65rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(4rem, 1.6) math.div(3rem, 1.6);

  &__title {
    margin: math.div(-0.5rem, 1.6) 0 math.div(1.8rem, 1.6);
    font-size: math.div(2rem, 1.6);
    line-height: math.div(2rem, 1.6);
    font-weight: normal;
    text-align: center;
  }

  &__textarea {
    margin-bottom: math.div(2rem, 1.6);
  }

  &__btn-row {
    margin: math.div(0.5rem, 1.6) math.div(12.5rem, 1.6) 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    & > ::v-deep * {
      width: math.div(13.5rem, 1.6);
    }
  }
}
</style>
