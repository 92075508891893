var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox-mobilelike" }, [
    _c(
      "label",
      {
        class: {
          "checkbox-mobilelike__label-wrapper": true,
          "checkbox-mobilelike__label-wrapper--tiny": _vm.size === "tiny",
          "checkbox-mobilelike__label-wrapper--small": _vm.size === "small",
        },
      },
      [
        _c(
          "input",
          _vm._g(
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localChecked,
                    expression: "localChecked",
                  },
                ],
                ref: "input",
                staticClass: "checkbox-mobilelike__checkbox-input",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.localChecked)
                    ? _vm._i(_vm.localChecked, null) > -1
                    : _vm.localChecked,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.localChecked,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.localChecked = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.localChecked = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.localChecked = $$c
                    }
                  },
                },
              },
              "input",
              _vm.$attrs,
              false
            ),
            _vm.$listeners
          )
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            class: {
              "checkbox-imitation": true,
              "checkbox-imitation--tiny": _vm.size === "tiny",
              "checkbox-imitation--small": _vm.size === "small",
              "checkbox-imitation--gray": _vm.color === "gray",
              "checkbox-imitation--inactive": !_vm.localChecked,
              "checkbox-imitation--active": _vm.localChecked,
              "checkbox-mobilelike__checkbox-imitation": true,
            },
          },
          [
            _c("transition", { attrs: { name: "simple-fade-in-out" } }, [
              _vm.localChecked
                ? _c("span", {
                    class: {
                      "checkbox-imitation__icon": true,
                      "checkbox-imitation__icon--tiny": _vm.size === "tiny",
                      "checkbox-imitation__icon--small": _vm.size === "small",
                      "checkbox-imitation__icon--checkmark": true,
                    },
                    domProps: { innerHTML: _vm._s(_vm.icons.checkMarkIcon) },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "simple-fade-in-out" } }, [
              !_vm.localChecked
                ? _c("span", {
                    class: {
                      "checkbox-imitation__icon": true,
                      "checkbox-imitation__icon--tiny": _vm.size === "tiny",
                      "checkbox-imitation__icon--small": _vm.size === "small",
                      "checkbox-imitation__icon--cross": true,
                    },
                    domProps: { innerHTML: _vm._s(_vm.icons.icoCrossIcon) },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("span", {
              class: {
                "checkbox-imitation__handler": true,
                "checkbox-imitation__handler--tiny": _vm.size === "tiny",
                "checkbox-imitation__handler--small": _vm.size === "small",
                "checkbox-imitation__handler--checked-position":
                  _vm.localChecked,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }