<template>
  <div class="size-range-edit-form">
    <div class="size-range-edit-form__main-title">Size Range</div>

    <!-- Size selection form -->
    <div class="size-selection-form size-range-edit-form__size-selection-form">
      <div class="size-slider size-selection-form__size-slider">
        <label class="size-slider__text-input-label">
          <input type="text" v-model.trim="sliderSizeRange[0]" class="size-slider__text-input" @keyup.enter="save" />
        </label>

        <div class="size-slider__slider-wrap">
          <fs-slider
            class="size-slider__slider"
            :min="minSize"
            :max="maxSize"
            width="100%"
            tooltip-dir="bottom"
            tooltip="newer"
            :formatter="(value) => value"
            v-model="sliderSizeRange"
            :key="priceSliderRerenderKey"
          />
        </div>

        <label class="size-slider__text-input-label">
          <input type="text" v-model.trim="sliderSizeRange[1]" class="size-slider__text-input" @keyup.enter="save" />
        </label>
      </div>
    </div>
    <!-- / Size selection form -->

    <cancel-n-action-btn-pair
      class="size-range-edit-form__manage-buttons-panel"
      @cancel="() => {}"
      @action="() => {}"
    />
  </div>
</template>

<script>
// Components
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';
import FsSlider from '@components/form-elements/fs-slider.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
import typeNProcessIcons from '@lib/icons-imports/type-n-process';

export default {
  name: 'size-range-edit',

  components: {
    CancelNActionBtnPair,
    FsSlider,
  },

  data() {
    return {
      minSize: 14,
      maxSize: 50,

      lowestSize: 14,
      biggestSize: 50,

      sliderSizeRange: [14, 50],

      priceSliderRerenderKey: 0,

      selectedMeasureSystem: 'EU, Full Sizes',

      specialitiesList: [
        'Dance',
        'Medical',
        'Riding',
        'Sports',
        'Waterproof',
        'Golf',
        'Orthopedic',
        'Safety',
        'Trekking',
      ],

      isSaveInProgress: false,

      icons: {
        interfaceElements: interfaceElementsIcons,
        typeNProcess: typeNProcessIcons,
      },
    };
  },

  mounted() {
    // Rerender all vue sliders otherwise they won't work correctly
    setTimeout(() => (this.priceSliderRerenderKey = Math.random()), 300);
  },

  methods: {
    save() {},
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

// ==========================================================================
// ==========================================================================
// Size selection form
.size-selection-form {
  &__size-slider {
    margin-bottom: math.div(1.5rem, 1.6);
  }
  .size-slider {
    width: math.div(31rem, 1.6);
    display: flex;
    justify-content: space-between;

    &__text-input-label {
      width: math.div(4.8rem, 1.6);
      height: math.div(2.6rem, 1.6);
    }

    &__text-input {
      width: math.div(4.8rem, 1.6);
      height: math.div(2.6rem, 1.6);
      padding: 0 math.div(1rem, 1.6);
      box-sizing: border-box;
      border-radius: math.div(10rem, 1.6);
      border: none;
      font-size: math.div(1.6rem, 1.6);
      line-height: math.div(1.6rem, 1.6);
      color: $c-dark;
      text-align: center;
      background: rgba(53, 73, 94, 0.07);
    }

    &__slider-wrap {
      padding: 0 math.div(0.2rem, 1.6);
      flex-grow: 1;
    }

    &__slider {
    }
  }

  &__measure-sys-menu {
  }
}

// ==========================================================================
// ==========================================================================
// Speciality edit form
.size-range-edit-form {
  width: math.div(38.5rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(3.2rem, 1.6);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  &__main-title {
    margin-bottom: math.div(2.8rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__size-selection-form {
    margin-bottom: math.div(5rem, 1.6);
  }

  &__manage-buttons-panel {
  }
}
</style>
