<template>
  <div id="footwear-all-units" class="footwear-all-units tabs__tab-content">
    <h3 class="content-header">Our Footwear Production</h3>

    <company-in-brief :supplier="supplier" />

    <verified-by-fs :supplier="supplier" />

    <sustainability-rating-section desktop-hidden :supplier="supplier" />

    <brands-working-with :supplier="supplier" />

    <div class="manufacturing-capabilities">
      <div v-if="supplier.footwearTypes && supplier.footwearTypes.length" class="manufacturing-capabilities__group">
        <h4 class="manufacturing-capabilities__header">Footwear Types</h4>
        <div class="footwear-types">
          <template v-for="(article, index) in supplier.footwearTypes">
            <a
              :key="`${article.id}-type`"
              class="footwear-type"
              href="#"
              @click.prevent="$refs['type-popper'][index].toggle($event)"
            >
              <div class="footwear-type__icon-container">
                <span class="footwear-type__icon" v-html="svg.footwearTypes[camelCase(article.slug)]"></span>
              </div>
              <span class="footwear-type__label">{{ article.title }}</span>
            </a>

            <fs-popper
              ref="type-popper"
              :key="`${article.id}-popper`"
              animation="fade"
              arrow
              :modifiers="[
                { name: 'flip', enabled: false },
                { name: 'preventOverflow', options: { padding: 16 } },
              ]"
              :offset="[0, 30]"
              placement="top"
              wrapper-class="popper-wrapper supplierProfile"
            >
              <a class="popover-close" @click.prevent="fsPopperClose">+</a>
              <div class="supplier-popover">
                <h5 class="supplier-popover__header">{{ article.title }}</h5>
                <span
                  class="supplier-popover__icon"
                  v-html="svg.footwearTypesNoWhitespace[camelCase(article.slug)]"
                ></span>
                <div class="supplier-popover__text-container">
                  <p class="supplier-popover__text">{{ article.description || '-' }}</p>
                  <router-link
                    class="supplier-popover__read-more link"
                    :to="{ name: 'article', params: { category: article.categorySlug, slug: article.slug } }"
                    >Read more</router-link
                  >
                </div>
                <span v-if="false" class="supplier-popover__line"></span>
                <span v-if="false" class="supplier-popover__sub-header">Processes used</span>
                <div v-if="false" class="sp-tags">
                  <a class="sp-tags__tag link link--dark" href="#">Vulcanization</a>
                  <a class="sp-tags__tag link link--dark" href="#">Moccasins</a>
                  <a class="sp-tags__tag link link--dark" href="#">Cementing</a>
                </div>
                <div v-if="false" class="sp-field">
                  <span class="sp-field__label">Minimum Order Quantity</span>
                  <span class="sp-field__value">50 Pairs</span>
                </div>
              </div>
            </fs-popper>
          </template>
        </div>
      </div>
      <div v-if="supplier.processes && supplier.processes.length" class="manufacturing-capabilities__group">
        <h4 class="manufacturing-capabilities__header">Processes</h4>
        <div class="processes">
          <template v-for="(article, index) in supplier.processes">
            <fs-pill
              :key="`${article.id}-pill`"
              class="processes__pill"
              size="extra-large"
              outlined
              :fixed="false"
              tag="a"
              @click.prevent="$refs['process-popper'][index].toggle($event)"
              href="#"
              >{{ article.title }}</fs-pill
            >
            <fs-popper
              ref="process-popper"
              :key="`${article.id}-popper`"
              animation="fade"
              arrow
              :modifiers="[
                { name: 'flip', enabled: false },
                { name: 'preventOverflow', options: { padding: 16 } },
              ]"
              :offset="[0, 30]"
              placement="top"
              wrapper-class="popper-wrapper supplierProfile"
            >
              <a class="popover-close" @click.prevent="fsPopperClose">+</a>
              <div class="supplier-popover">
                <h5 class="supplier-popover__header">{{ article.title }}</h5>
                <div class="supplier-popover__text-container">
                  <p class="supplier-popover__text">{{ article.description || '-' }}</p>
                  <router-link
                    class="supplier-popover__read-more link"
                    :to="{ name: 'article', params: { category: article.categorySlug, slug: article.slug } }"
                    >Read more</router-link
                  >
                </div>
              </div>
            </fs-popper>
          </template>
        </div>
        <h4 class="manufacturing-capabilities__header" v-if="supplier.lastingMethods && supplier.lastingMethods.length">
          Lasting Methods
        </h4>
        <div v-if="supplier.lastingMethods && supplier.lastingMethods.length" class="processes">
          <fs-pill
            class="processes__pill"
            size="extra-large"
            outlined
            :fixed="false"
            v-for="method in supplier.lastingMethods"
            :key="method.id"
          >
            {{ method.title }}
          </fs-pill>
        </div>
      </div>
    </div>
    <div class="manufacturing-quick-facts">
      <div class="manufacturing-quick-facts__group">
        <h4 class="manufacturing-quick-facts__group-header">Gender</h4>
        <div class="manufacturing-quick-facts__group-value">
          <div v-if="supplier.men" class="mqf-icon-value">
            <span class="mqf-icon-value__icon" v-html="svg.gender.men"></span>
            <span class="mqf-icon-value__label">Men</span>
          </div>
          <div v-if="supplier.ladies" class="mqf-icon-value">
            <span class="mqf-icon-value__icon" v-html="svg.gender.ladies"></span>
            <span class="mqf-icon-value__label">Ladies</span>
          </div>
          <div v-if="supplier.kids" class="mqf-icon-value">
            <span class="mqf-icon-value__icon" v-html="svg.gender.boysGirls"></span>
            <span class="mqf-icon-value__label">Boys &amp; Girls</span>
          </div>
          <div v-if="supplier.babies" class="mqf-icon-value">
            <span class="mqf-icon-value__icon" v-html="svg.gender.babies"></span>
            <span class="mqf-icon-value__label">Babies</span>
          </div>
          <div
            v-if="!supplier.men && !supplier.ladies && !supplier.boysGirls && !supplier.babies"
            class="mqf-icon-value"
          >
            <span class="mqf-icon-value__icon">-</span>
            <span class="mqf-icon-value__label">Unknown</span>
          </div>
        </div>
      </div>
      <div class="manufacturing-quick-facts__group">
        <h4 class="manufacturing-quick-facts__group-header">Specialities</h4>
        <div class="manufacturing-quick-facts__group-value">
          <!-- TODO: Add popup with additional info about each speciality? Either some generic stuff, or perhaps something that the supplier could add themselves? -->
          <div
            class="mqf-icon-value"
            v-for="speciality in specialities.slice(0, specialitiesExpanded ? 99 : 3)"
            :key="speciality.id"
          >
            <span class="mqf-icon-value__icon" v-html="svg.specialities[camelCase(speciality.title)]"></span>
            <span class="mqf-icon-value__label">{{ speciality.title }}</span>
          </div>
          <a
            v-if="specialities.length > 3 && !specialitiesExpanded"
            class="mqf-additional-values"
            href="#"
            @click.prevent="expandSpecialities"
          >
            +{{ specialities.length - 3 }}
          </a>
          <div v-if="!specialities.length" class="mqf-icon-value">
            <span class="mqf-icon-value__icon">-</span>
            <span class="mqf-icon-value__label">No specialities</span>
          </div>
        </div>
      </div>
      <div class="manufacturing-quick-facts__group">
        <h4 class="manufacturing-quick-facts__group-header">Size Range</h4>
        <div class="manufacturing-quick-facts__group-value">
          <div v-if="supplier.sizeRangeFrom && supplier.sizeRangeTo" class="mqf-value">
            {{ supplier.sizeRangeFrom }}-{{ supplier.sizeRangeTo }}
            <div class="mqf-value__info-wrapper">
              <a class="mqf-value__info" href="#" @click.prevent="$refs.sizeInfo.toggle($event)" v-html="svg.info"></a>
            </div>

            <fs-popper
              ref="sizeInfo"
              animation="fade"
              arrow
              :modifiers="[
                { name: 'flip', enabled: false },
                { name: 'preventOverflow', options: { padding: 16 } },
              ]"
              :offset="[0, 30]"
              placement="top"
              wrapper-class="popper-wrapper supplierProfile"
            >
              <a class="popover-close" @click.prevent="fsPopperClose">+</a>
              <div class="supplier-popover supplier-popover--wide">
                <h5 class="supplier-popover__header">Size Range</h5>
                <div class="sp-quick-list">
                  <div class="sp-quick-list__item">
                    <span class="sp-quick-list__label">EU</span>
                    <span class="sp-quick-list__value">{{ supplier.sizeRangeFrom }}-{{ supplier.sizeRangeTo }}</span>
                  </div>
                  <div class="sp-quick-list__item">
                    <span class="sp-quick-list__label">UK</span>
                    <span class="sp-quick-list__value"
                      >{{ sizeRanges.uk.from.prettySize }}-{{ sizeRanges.uk.to.prettySize }}</span
                    >
                  </div>
                  <div class="sp-quick-list__item">
                    <span class="sp-quick-list__label">US</span>
                    <span class="sp-quick-list__value"
                      >{{ sizeRanges.us.from.prettySize }}-{{ sizeRanges.us.to.prettySize }}</span
                    >
                  </div>
                  <div class="sp-quick-list__item">
                    <span class="sp-quick-list__label">JP (cm)</span>
                    <span class="sp-quick-list__value">{{ sizeRanges.cm.from.size }}-{{ sizeRanges.cm.to.size }}</span>
                  </div>
                </div>
                <div class="supplier-popover__text-container">
                  <p class="supplier-popover__text supplier-popover__text--center">
                    Sizes are converted according to ISO standard. Contact the supplier to get exact sizes handled.
                  </p>
                </div>
                <span v-if="false" class="supplier-popover__line"></span>
                <span v-if="false" class="supplier-popover__sub-header">Processes used</span>
              </div>
            </fs-popper>
          </div>
          <div class="mqf-value" v-else>-</div>
        </div>
      </div>
    </div>

    <div class="capabilities footwear-all-units__capabilities">
      <h4 class="capabilities__header">Capabilities</h4>
      <!-- TODO: Rework so we base it on DB instead-->
      <div class="capabilities-flow">
        <div
          class="capabilities-flow__step"
          :class="{
            'capabilities-flow__step--inactive-from':
              !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Pre-Development'),
            'capabilities-flow__step--inactive-to':
              !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Development'),
          }"
        >
          <span
            class="capabilities-flow__number"
            :class="{
              'capabilities-flow__number--inactive':
                !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Pre-Development'),
            }"
            >1</span
          >
          <div class="capabilities-flow__content">
            <h5
              class="capabilities-flow__header"
              :class="{
                'capabilities-flow__header--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Pre-Development'),
              }"
            >
              Pre-development
            </h5>
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Design Inspiration'),
              }"
              >Design Inspiration</span
            >
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Component Sourcing'),
              }"
              >Component Sourcing</span
            >
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Material Sourcing'),
              }"
              >Material Sourcing</span
            >
          </div>
        </div>
        <div
          class="capabilities-flow__step"
          :class="{
            'capabilities-flow__step--inactive-from':
              !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Development'),
            'capabilities-flow__step--inactive-to':
              !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Production'),
          }"
        >
          <span
            class="capabilities-flow__number"
            :class="{
              'capabilities-flow__number--inactive':
                !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Development'),
            }"
            >2</span
          >
          <div class="capabilities-flow__content">
            <h5
              class="capabilities-flow__header"
              :class="{
                'capabilities-flow__header--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Development'),
              }"
            >
              Development
            </h5>
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Risk Assessment'),
              }"
              >Risk Assessment</span
            >
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Technical Design'),
              }"
              >Technical Design</span
            >
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Digital Prototyping'),
              }"
              >Digital Prototyping</span
            >
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Sample Making'),
              }"
              >Sample Making</span
            >
          </div>
        </div>
        <div
          class="capabilities-flow__step"
          :class="{
            'capabilities-flow__step--inactive-from':
              !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Production'),
            'capabilities-flow__step--inactive-to':
              !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Logistic'),
          }"
        >
          <span
            class="capabilities-flow__number"
            :class="{
              'capabilities-flow__number--inactive':
                !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Production'),
            }"
            >3</span
          >
          <div class="capabilities-flow__content">
            <h5
              class="capabilities-flow__header"
              :class="{
                'capabilities-flow__header--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Production'),
              }"
            >
              Production
            </h5>
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Cutting'),
              }"
              >Cutting</span
            >
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Stitching'),
              }"
              >Stitching</span
            >
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Assembly'),
              }"
              >Assembly</span
            >
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Quality Control'),
              }"
              >Quality Control</span
            >
          </div>
        </div>
        <div class="capabilities-flow__step">
          <span
            class="capabilities-flow__number"
            :class="{
              'capabilities-flow__number--inactive':
                !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Logistic'),
            }"
            >4</span
          >
          <div class="capabilities-flow__content">
            <h5
              class="capabilities-flow__header"
              :class="{
                'capabilities-flow__header--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.category === 'Logistic'),
              }"
            >
              Logistics
            </h5>
            <span
              class="capabilities-flow__capability"
              :class="{
                'capabilities-flow__capability--inactive':
                  !supplier.capabilities || !supplier.capabilities.some((c) => c.title === 'Shipping and Logistics'),
              }"
              >Shipping and Logistics</span
            >
          </div>
        </div>
      </div>
    </div>

    <brands-section
      class="footwear-all-units__brands-section"
      :brands-list="(supplier.brands || '').split(', ')"
      :new-brands-list="supplier.supplierBrands"
    />

    <code-of-conduct class="footwear-all-units__code-of-conduct" :supplier="supplier" />

    <supply-chain
      v-if="supplier.suppliers.length"
      class="footwear-all-units__supply-chain"
      :suppliers="supplier.suppliers"
    />

    <supplier-gallery v-if="supplier.gallery.length" :supplier="supplier" />

    <certifications-section v-if="supplier.certificates.length" :data="supplier.certificates" />
    <membership-section :supplier="supplier" />
  </div>
</template>

<script>
import { FsPopper, fsPopperClose } from '@components/poppers/fs-popper.vue';

import { convertSizeRange, iso } from 'shoe-size-converter';

import camelCase from 'lodash/camelCase';

import FsPill from 'apps/public/pages/components/pills/fs-pill.vue';

import BrandsWorkingWith from './../sections/brands-working-with.vue';
import CertificationsSection from '../sections/certifications.vue';
import CompanyInBrief from './../sections/company-in-brief.vue';
import MembershipSection from './../sections/membership.vue';
const SupplierGallery = () => import('./../sections/gallery.vue');
import VerifiedByFs from './../sections/verified-by-fs.vue';
import BrandsSection from './../sections/brands.vue';
import CodeOfConduct from './../sections/code-of-conduct.vue';
import SupplyChain from './../sections/supply-chain.vue';

// Icons
// General
import info from '@icons/other/info.svg';
// Footwear Types
import balletFlats from '@icons/footwear-types/ballet-flats.svg';
import boots from '@icons/footwear-types/boots.svg';
import espadrilles from '@icons/footwear-types/espadrilles.svg';
import flipFlops from '@icons/footwear-types/flip-flops.svg';
import heeledShoes from '@icons/footwear-types/heeled-shoes.svg';
import moccasins from '@icons/footwear-types/moccasins.svg';
import sandals from '@icons/footwear-types/sandals.svg';
import shoes from '@icons/footwear-types/shoes.svg';
import slippers from '@icons/footwear-types/slippers.svg';
import sneakers from '@icons/footwear-types/sneakers.svg';
// Footwear Types - no whitespace
import balletFlatsNW from '@icons/footwear-types/no-whitespace/ballet-flats.svg';
import bootsNW from '@icons/footwear-types/no-whitespace/boots.svg';
import espadrillesNW from '@icons/footwear-types/no-whitespace/espadrilles.svg';
import flipFlopsNW from '@icons/footwear-types/no-whitespace/flip-flops.svg';
import heeledShoesNW from '@icons/footwear-types/no-whitespace/heeled-shoes.svg';
import moccasinsNW from '@icons/footwear-types/no-whitespace/moccasins.svg';
import sandalsNW from '@icons/footwear-types/no-whitespace/sandals.svg';
import shoesNW from '@icons/footwear-types/no-whitespace/shoes.svg';
import slippersNW from '@icons/footwear-types/no-whitespace/slippers.svg';
import sneakersNW from '@icons/footwear-types/no-whitespace/sneakers.svg';
// Gender
import babies from '@icons/gender/babies.svg';
import boysGirls from '@icons/gender/boys-girls.svg';
import ladies from '@icons/gender/ladies.svg';
import men from '@icons/gender/men.svg';
// Specialities - Material expertise
import leather from '@icons/material-expertise/leather.svg';
import synthetic from '@icons/material-expertise/synthetic.svg';
import textile from '@icons/material-expertise/textile.svg';
// Specialities - Niches
import dance from '@icons/niches/dance.svg';
import golf from '@icons/niches/golf.svg';
import medical from '@icons/niches/medical.svg';
import orthopedic from '@icons/niches/orthopedic.svg';
import riding from '@icons/niches/riding.svg';
import safety from '@icons/niches/safety.svg';
import sports from '@icons/niches/sports.svg';
import trekkingOutdoor from '@icons/niches/trekking-outdoor.svg';
import waterproof from '@icons/niches/waterproof.svg';
import SustainabilityRatingSection from 'pages/supplier/sections/sustainability-rating-section.vue';

export default {
  props: {
    supplier: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    SustainabilityRatingSection,
    BrandsWorkingWith,
    CertificationsSection,
    CompanyInBrief,
    FsPill,
    MembershipSection,
    SupplierGallery,
    VerifiedByFs,
    FsPopper,
    BrandsSection,
    CodeOfConduct,
    SupplyChain,
  },

  data() {
    return {
      specialitiesExpanded: false,
      svg: {
        // General
        info,
        footwearTypes: {
          balletFlats,
          boots,
          espadrilles,
          flipFlops,
          heeledShoes,
          moccasins,
          sandals,
          shoes,
          slippers,
          sneakers,
        },
        footwearTypesNoWhitespace: {
          balletFlats: balletFlatsNW,
          boots: bootsNW,
          espadrilles: espadrillesNW,
          flipFlops: flipFlopsNW,
          heeledShoes: heeledShoesNW,
          moccasins: moccasinsNW,
          sandals: sandalsNW,
          shoes: shoesNW,
          slippers: slippersNW,
          sneakers: sneakersNW,
        },
        gender: {
          babies,
          boysGirls,
          ladies,
          men,
        },
        specialities: {
          // Material expertise
          leather,
          synthetic,
          textile,
          // Niches
          dance,
          golf,
          medical,
          orthopedic,
          riding,
          safety,
          sports,
          trekkingOutdoor,
          waterproof,
        },
      },
    };
  },
  computed: {
    materialExpertises() {
      if (!this.supplier) return [];

      const materials = [];

      if (this.supplier.materialExpertiseLeather) materials.push({ id: 'leather', title: 'Leather' });
      if (this.supplier.materialExpertiseTextile) materials.push({ id: 'textile', title: 'Textile' });
      if (this.supplier.materialExpertiseSynthetic) materials.push({ id: 'synthetic', title: 'Synthetic' });

      return materials;
    },
    niches() {
      if (!this.supplier) return [];

      return this.supplier.niche || [];
    },
    sizeRanges() {
      if (!this.supplier || !this.supplier.sizeRangeFrom || !this.supplier.sizeRangeTo) return {};

      const systems = {
        ...iso,
        uk: {
          ...iso.uk,
          round: 1,
        },
        us: {
          ...iso.us,
          round: 1,
        },
        au: {
          ...iso.au,
          round: 1,
        },
        cm: {
          ...iso.cm,
          round: 1,
        },
      };

      return convertSizeRange(
        { size: this.supplier.sizeRangeFrom, system: 'eu' },
        { size: this.supplier.sizeRangeTo, system: 'eu' },
        systems
      );
    },
    specialities() {
      const specialities = this.niches.slice();

      // Add in materialExpertises every other entry (starting from the first position)
      this.materialExpertises.forEach((material, index) => specialities.splice(index * 2, 0, material));

      return specialities;
    },
  },
  methods: {
    camelCase,
    fsPopperClose,
    expandSpecialities() {
      this.specialitiesExpanded = true;
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

.supplierProfile {
  // Tabs content (right column)
  .content-header {
    @include heading--large;
    text-align: center;
    padding-bottom: 0.625rem;
  }

  .manufacturing-capabilities {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0.5rem;
    margin-left: -1rem;
    margin-right: -1rem;

    @include tablet-landscape-min {
      margin: 0;
      flex-wrap: nowrap;
    }

    &__group {
      flex-basis: 23.5rem;
      flex-shrink: 0;
      flex-grow: 1;
      padding: 0 1rem;

      @include tablet-landscape-min {
        flex-basis: 32rem;
        flex-shrink: 1;
      }

      @include mobile-xs-only {
        flex-basis: math.div(
          27.2rem,
          1.6
        ); // Enough to ensure minimum wrapping, which should suffice down to 266px width (i.e. enough);
      }
    }

    &__header {
      @include heading--small;
      text-align: center;
      padding-bottom: math.div(2rem, 1.6);
    }
  }

  .footwear-types {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .footwear-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 7.125rem;
    padding-bottom: 1rem;

    &__icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.75rem;
      width: 3.75rem;
      border: 0.125rem solid $c-dark;
      border-radius: 0.625rem;
      box-shadow: $bs-high-bottom;
      transition: transform 0.2s ease, border 0.2s ease, box-shadow 0.2s ease; // TODO: REVIEW/REFACTOR - should be standardized somewhere;
    }

    &__icon {
      height: 3.1875rem;
      width: 3.1875rem;
      fill: $c-dark;
      transition: fill 0.2s ease; // TODO: REVIEW/REFACTOR - should be standardized somewhere;
    }

    &__label {
      @include link;
      font-weight: 400;
      color: $c-dark;
      display: inline-block;
      text-transform: uppercase;
      text-align: center;
      padding-top: 0.625rem;
    }

    &:hover {
      .footwear-type__icon-container {
        border-color: $c-dark-light;
        box-shadow: $bs-higher-bottom;
        transform: translateY(-1px);
      }

      .footwear-type__icon {
        fill: $c-dark-light;
      }
    }
  }

  .processes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -0.75rem;

    &__pill {
      margin: 0 0.75rem 1.25rem 0.75rem;
      box-shadow: $bs-high-bottom;
      transition: transform 0.2s ease, color 0.2s ease, background 0.2s ease, border 0.2s ease, box-shadow 0.2s ease; // TODO: REVIEW/REFACTOR - should be standardized somewhere;

      &:hover {
        background-color: $c-dark-light;
        border-color: $c-dark-light;
        box-shadow: $bs-higher-bottom;
        color: $c-light;
        transform: translateY(-1px);
      }
    }
  }

  .manufacturing-quick-facts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: $c-dark;
    color: $c-light;
    margin-top: 4.25rem;
    padding: 1.625rem 0 0.25rem 0;

    @include tablet-landscape-min {
      padding: 1.375rem 0 0 0;
    }

    &__group {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: auto;
      min-width: 12rem;
      padding: 0 1rem 1.875rem 1rem;
    }

    &__group-header {
      @include heading--mini;
      font-size: 1.125em;
      margin-top: 0;
      padding: 0 0.8125rem;
    }

    &__group-value {
      display: flex;
      flex-wrap: wrap;
      max-width: 19rem;
      align-items: center;
      justify-content: center;
      margin: 0 -0.8125rem;
    }
  }

  .mqf-icon-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    padding: 0.75rem 0.8125rem 0 0.8125rem;

    &__icon {
      display: inline-block;
      text-align: center;
      height: 1.5rem;
      width: 1.5rem;
      fill: $c-light;
    }

    &__label {
      @include caption--small;
      font-size: 0.625em;
      padding: 0.1875rem 0;
    }
  }

  .mqf-additional-values {
    @include caption--small;
    color: $c-light;
    font-size: 0.625em;
    flex-shrink: 0;
    height: 1.5rem;
    width: 1.5rem;
    border: 0.125rem solid $c-light;
    border-radius: 50% 50%;
    text-align: center;
    line-height: 1.25rem;
    margin: 0.75rem 0.8125rem 0.25rem 0.75rem;
  }

  .mqf-value {
    @include body;
    font-size: 1.5em;
    padding-top: 0.5rem;

    // TODO: REFACTOR - merge with info (below). This is a rest from using v-popover,
    // where we couldn't control this and the wrapper was added automatically.
    &__info-wrapper {
      line-height: 1rem;
      position: relative;
      top: -0.875rem;
      right: -0.3125rem;
      margin-right: 0.3125rem;
      display: inline-block;
      height: 1rem;
      width: 1rem;
    }

    &__info {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      fill: $c-light;
    }
  }

  .capabilities {
    padding-bottom: 2rem;

    &__header {
      @include heading--small;
      text-align: center;
      margin-top: 0;
      padding-top: 2.625rem;
    }
  }

  // Very much based content common/styles/quotation-intro.scss -> .process-flow (and also the home.vue page)
  .capabilities-flow {
    $icon-size: 2.5rem;

    // TODO: REVIEW/REFACTOR this. Specifically, it is unclear if this
    // really is the color which mathes $c-dark, it even seems to be
    // different based on where you test it. We need it to be a color
    // though since otherwise, for the numbers, the lines will shine
    // through.
    $c-dark-opacity-37: #b5bcc4;

    padding-top: 1.625rem;
    max-width: 23rem;
    margin: 0 auto;

    @include desktop-min {
      display: flex;
      max-width: 60rem;
    }

    &__step {
      position: relative;
      width: 100%;
      display: flex;
      text-align: center;
      padding-right: $icon-size;

      @include desktop-min {
        display: block;
        padding-right: 0;
      }

      &:not(:last-child) {
        padding-bottom: 2.5rem;

        @include desktop-min {
          padding-bottom: 0;
          margin-right: 2rem;
        }

        // TODO/REVIEW: Should we have dotted lines instead? Could probably be made using this technique: https://stackoverflow.com/questions/34888571/drawing-dotted-lines-and-straight-lines-as-background-of-div
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: $icon-size;
          // Half of icon size - half of border-size;
          left: calc(#{math.div($icon-size, 2)} - (3px / 2));
          width: 3px;
          // 100% - height;
          height: calc(100% - #{$icon-size});
          background: $c-dark;

          @include desktop-min {
            width: calc(100% - #{math.div(5.3rem, 1.6)});
            // 100% - width + margin + extra margin for rounded exit;
            width: calc(100% - #{($icon-size - 2rem)} + 2px);
            height: 3px;
            top: calc(#{math.div($icon-size, 2)} - (3px / 2));
            left: 50%;
            margin-left: math.div($icon-size, 2);
          }
        }
      }

      &--inactive-from {
        &:not(:last-child) {
          &::after {
            background-image: linear-gradient(to bottom, $c-dark-opacity-37 10%, $c-dark 90%);

            @include desktop-min {
              background-image: linear-gradient(to right, $c-dark-opacity-37 10%, $c-dark 90%);
            }
          }
        }
      }

      &--inactive-to {
        &:not(:last-child) {
          &::after {
            background-image: linear-gradient(to bottom, $c-dark 10%, $c-dark-opacity-37 90%);

            @include desktop-min {
              background-image: linear-gradient(to right, $c-dark 10%, $c-dark-opacity-37 90%);
            }
          }
        }
      }

      &--inactive-from.capabilities-flow__step--inactive-to {
        &:not(:last-child) {
          &::after {
            background: $c-dark-opacity-37;
          }
        }
      }
    }

    &__number {
      @include heading--tiny;
      font-weight: $bold-montserrat;
      color: $c-light;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: $icon-size;
      height: $icon-size;
      border-radius: 50%;
      background-color: $c-dark;
      // box-shadow: $bs-high-light; // TODO: FIXME!!!!!;
      z-index: 2;
      flex-shrink: 0;

      &--inactive {
        background-color: $c-dark-opacity-37;
      }
    }

    &__content {
      width: 100%;
    }

    &__header {
      @include heading--tiny;
      font-weight: $bold-montserrat;
      text-transform: uppercase;
      margin-top: 0;
      padding: 0.375rem 0 0.875rem 0;

      @include desktop-min {
        padding: 1.25rem 0 1.25rem 0;
      }

      &--inactive {
        color: $c-dark-opacity-37;
      }
    }

    &__capability {
      @include body;
      display: block;
      line-height: 1.75;

      @include desktop-min {
        line-height: 1.375;
      }

      &--inactive {
        color: $c-dark-opacity-37;
      }
    }
  }

  .gallery-section {
    &__header {
      @include heading--small;
      text-align: center;
      margin-top: 0;
      padding-top: 3.125rem;
    }
  }

  .gallery {
  }

  .supplier-popover {
    width: 14.375rem;
    padding-bottom: 1.125rem;

    &--wide {
      width: 16.5rem;
    }

    &__header {
      @include heading--small;
      text-align: center;
      margin-top: 0;
      padding-top: 1.125rem;
    }

    &__icon {
      display: block;
      width: 7rem;
      margin: 0 auto;
      fill: $c-dark;
      padding-top: 1.375rem;
      padding-bottom: 0.75rem;
    }

    &__text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0.125rem 1.4375rem 0 1.4375rem; // Equals 11.5rem width for standard sized popover (14.375rem);
    }

    &__text {
      @include body--small;
      width: 100%;

      &--center {
        text-align: center;
      }
    }

    &__read-more {
      @include link;
      display: inline-block;
      padding: 0 0.875rem;
    }

    &__line {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $c-border-gray;
      margin-top: 1.375rem;
    }

    &__sub-header {
      @include body--small;
      display: block;
      text-align: center;
      padding-top: 2rem;
    }

    &__tags-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 0.625rem;
    }

    &__tag {
      @include body;
      text-transform: uppercase;
      padding: 0.1875rem 0.5rem;
    }
  }

  .sp-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 0.625rem;

    &__tag {
      @include body;
      text-transform: uppercase;
      padding: 0.1875rem 0.5rem;
    }
  }

  .sp-field {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 1.5rem;

    &__label {
      @include body--small;
      width: 7rem;
      padding: 0 0.25rem;
    }

    &__value {
      @include body;
      font-size: 1.25em;
      line-height: 1.25;
      padding: 0 0.25rem;
    }
  }

  .sp-quick-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 1.5rem;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    &__label {
      @include body;
      text-transform: uppercase;
      text-align: center;
      padding: 0.1875rem 0.5625rem;
      border-bottom: 1px solid $c-border-gray;
    }

    &__value {
      @include body;
      text-transform: uppercase;
      text-align: center;
      padding: 0.125rem 0.5625rem;
    }
  }
}

// --------------------------------------------------
// -------------------- Tooltips --------------------
// --------------------------------------------------
// TODO: REVIEW - this seems to be a little bit of mess between the general(?) toolip class command the popover class
// TODO: Turn into scoped CSS to avoid doing this type of trickery/manual scoping
.supplierProfile {
  &.popper-wrapper {
    z-index: 10000; // TODO: REVIEW if this is a good value - all z-indicies should probably be placed in a unified location as variables for better structure?
  }

  .popper-arrow-wrapper {
    border-radius: $br-heavy;
    box-shadow: $bs-high-center;
  }

  .popper {
    position: relative;
    background: $c-bg-light;
    padding: 1.25rem;
    border-radius: $br-heavy;
    z-index: 1;
    box-shadow: none;
  }

  .popover-close {
    position: absolute;
    top: -(1.1875rem - 0.5rem);
    right: -(1.1875rem - 0.5rem);
    display: inline-block;
    height: 2.375rem;
    width: 2.375rem;
    border-radius: 1.1875rem;
    background-color: $c-dark;
    color: $c-light;
    // Since this is not really for text - let's not use our text mixins';
    font-family: 'Montserrat', sans-serif;
    font-size: 2.25em;
    font-weight: $bold-montserrat;
    text-align: center;
    line-height: 2.375rem;
    transform: rotate(45deg);
    cursor: pointer;
  }

  // Arrow & arrow placement
  $size: 1.125rem; // From box and outwards
  $actualSize: math.div(
    $size * 2,
    1.42
  ); // Double the size to get width/height, since only half is visible, and then account for skew'ing - which makes the actual distance the hypothenuse (~1.42 times large for 90 degrees)

  .popper-arrow {
    // Generic
    border-color: $c-bg-light;
    width: $actualSize;
    height: $actualSize;
    position: relative;
    background-color: $c-bg-light;
    box-shadow: $bs-high-center;
    // Bottom position specific
    transform: scaleX(1.5) rotate(-45deg);
    top: math.div(-$actualSize, 2);
  }
}

// ==========================================================================
// ==========================================================================
// Footwear All Units
.footwear-all-units {
  &__capabilities {
    padding-bottom: math.div(5.5rem, 1.6) !important;
    margin-bottom: math.div(4.4rem, 1.6);
    border-bottom: 1px rgba(53, 73, 94, 0.1) solid;
  }

  &__brands-section {
    padding-bottom: math.div(3.5rem, 1.6);
    margin-bottom: math.div(5rem, 1.6);
    border-bottom: 1px rgba(53, 73, 94, 0.1) solid;
  }

  &__code-of-conduct {
    margin-bottom: math.div(6.7rem, 1.6);
  }

  &__supply-chain {
  }
}
</style>
