var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "social-media-form" },
    [
      _c("div", { staticClass: "social-media-form__main-title" }, [
        _vm._v("Social Media"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-itself social-media-form__form-itself" },
        _vm._l(_vm.socialsList, function (item) {
          return _c(
            "div",
            { key: item, staticClass: "form-itself__section" },
            [
              _c("div", { staticClass: "form-itself__subtitle" }, [
                _vm._v("\n        " + _vm._s(item) + "\n      "),
              ]),
              _vm._v(" "),
              _c("input-custom", {
                class: {
                  "form-itself__text-input": 1,
                  "form-itself__text-input--empty":
                    !_vm.inputsValues[item.toLowerCase()],
                },
                attrs: {
                  placeholder: "Add " + item + " Account",
                  "additional-icon":
                    _vm.icons.socials[item.toLowerCase() + "Monochrome"],
                },
                model: {
                  value: _vm.inputsValues[item.toLowerCase()],
                  callback: function ($$v) {
                    _vm.$set(_vm.inputsValues, item.toLowerCase(), $$v)
                  },
                  expression: "inputsValues[item.toLowerCase()]",
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "social-media-form__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }