var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit-photos-modal",
      style: {
        maxHeight: _vm.maxHeight ? _vm.maxHeight + "px" : "auto",
        minHeight: _vm.maxHeight ? _vm.maxHeight + "px" : "auto",
      },
    },
    [
      _c(
        "div",
        {
          class: {
            "edit-photos-modal__uploads-side": true,
            "edit-photos-modal__uploads-side--all-corners-rounded":
              _vm.simpleMode,
          },
        },
        [
          _c("div", { staticClass: "edit-photos-modal__content-section" }, [
            _vm.imagesInProgressOrUploaded.length > 0
              ? _c(
                  "div",
                  {
                    class: {
                      "upload-progress-list": true,
                      "upload-progress-list--preloader": _vm.saving,
                      "edit-photos-modal__upload-photos-list": true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "upload-progress-list__main-title" },
                      [_vm._v("Uploads")]
                    ),
                    _vm._v(" "),
                    _c(
                      "scrollable-block",
                      {
                        staticClass:
                          "upload-progress-list__files-progress-list-wrapper",
                        attrs: { "shadows-color": "light-gray" },
                      },
                      [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "upload-progress-list__files-progress-list",
                          },
                          _vm._l(
                            _vm.imagesInProgressOrUploaded,
                            function (imageInProgress) {
                              return _c(
                                "li",
                                {
                                  key: imageInProgress.name,
                                  staticClass:
                                    "single-file-upload-line upload-progress-list__single-file-progress",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "single-file-upload-line__header",
                                    },
                                    [
                                      imageInProgress.progress < 100
                                        ? _c("div", {
                                            class:
                                              "single-file-upload-line__progress-icon\n                              single-file-upload-line__progress-icon--spinning\n                              single-file-upload-line__progress-icon--spinning" +
                                              Math.floor(Math.random() * 7 + 1),
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.svg.loadingSpinnerIcon
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      imageInProgress.progress === 100
                                        ? _c("div", {
                                            staticClass:
                                              "single-file-upload-line__progress-icon",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.svg.checkMarkCircleIcon
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "single-file-upload-line__file-name",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(imageInProgress.name) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "single-file-upload-line__file-size",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              Math.round(
                                                imageInProgress.size / 1024
                                              )
                                            ) + " KB"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "single-file-upload-line__progress-bar",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "single-file-upload-line__progress-bar-scale",
                                          style: {
                                            width:
                                              imageInProgress.progress + "%",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                   \n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "single-file-upload-line__progress-percentage",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(imageInProgress.progress) +
                                              "%"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "label",
                  {
                    class: {
                      "upload-drag-drop-area": true,
                      "upload-drag-drop-area--hovered":
                        _vm.isDragNDropAreaHovered,
                      "edit-photos-modal__upload-drag-drop-area": true,
                    },
                    attrs: { for: _vm.inputFileId },
                    on: {
                      drop: function ($event) {
                        $event.preventDefault()
                        return _vm.uploadEventHandler($event)
                      },
                      dragover: function ($event) {
                        $event.preventDefault()
                        _vm.isDragNDropAreaHovered = true
                      },
                      dragleave: function ($event) {
                        $event.preventDefault()
                        _vm.isDragNDropAreaHovered = false
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "upload-drag-drop-area__drop-zone" },
                      [
                        _c("span", {
                          staticClass: "upload-drag-drop-area__icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.svg.uploadArrowCloudIcon),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "upload-drag-drop-area__title" },
                          [_vm._v(" Drag Images To Upload ")]
                        ),
                        _vm._v(" "),
                        _vm._m(0),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "upload-drag-drop-area__or-word" },
                      [_vm._v(" or ")]
                    ),
                  ]
                ),
          ]),
          _vm._v(" "),
          !_vm.imagesInProgressOrUploaded.length
            ? _c(
                "div",
                { staticClass: "edit-photos-modal__bottom-buttons-panel" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "edit-photos-modal__add-files-but-label",
                      attrs: { for: _vm.inputFileId },
                      on: {
                        drop: function ($event) {
                          $event.preventDefault()
                          return _vm.uploadEventHandler($event)
                        },
                        dragover: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "fs-button",
                        {
                          staticClass: "edit-photos-modal__add-files-but",
                          attrs: { secondary: "" },
                        },
                        [
                          _c("fs-button-icon", {
                            attrs: {
                              icon: _vm.svg.photoPlaceholderIcon,
                              width: "1rem",
                              height: "1rem",
                              top: "-1px",
                              color: "dark-blue",
                            },
                          }),
                          _vm._v("\n          Browse images\n        "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "edit-photos-modal__add-files-input",
                    attrs: { type: "file", id: _vm.inputFileId, multiple: "" },
                    on: {
                      change: function ($event) {
                        $event.preventDefault()
                        return _vm.uploadEventHandler($event)
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      !_vm.simpleMode
        ? _c("div", { staticClass: "edit-photos-modal__photos-list-side" }, [
            _c(
              "div",
              {
                staticClass: "edit-photos-modal__content-section",
                on: {
                  dragstart: function ($event) {
                    $event.stopPropagation()
                  },
                  dragend: function ($event) {
                    $event.stopPropagation()
                  },
                  dragover: function ($event) {
                    $event.stopPropagation()
                  },
                  dragleave: function ($event) {
                    $event.stopPropagation()
                  },
                  drop: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("div", { staticClass: "edit-photos-modal__main-title" }, [
                  _vm._v("\n        Product Images\n        "),
                  _c("div", { staticClass: "edit-photos-modal__title-note" }, [
                    _c("div", {
                      staticClass: "edit-photos-modal__title-note-icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.moveAllSidesIcon) },
                    }),
                    _vm._v("\n          Drag to rearrange\n        "),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "scrollable-block",
                  { staticClass: "edit-photos-modal__images-table-wrapper" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "edit-photos-modal__images-table",
                        attrs: { tabindex: "0" },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save($event)
                          },
                        },
                      },
                      [
                        _c(
                          "draggable",
                          _vm._b(
                            {
                              attrs: {
                                "component-data": _vm.draggableComponentData,
                              },
                              on: {
                                start: function ($event) {
                                  _vm.dragNdropState = true
                                },
                                end: function ($event) {
                                  _vm.dragNdropState = false
                                },
                              },
                              model: {
                                value: _vm.localImages,
                                callback: function ($$v) {
                                  _vm.localImages = $$v
                                },
                                expression: "localImages",
                              },
                            },
                            "draggable",
                            _vm.dragOptions,
                            false
                          ),
                          [
                            _c(
                              "transition-group",
                              {
                                attrs: {
                                  type: "transition",
                                  name: !_vm.dragNdropState
                                    ? "flip-list"
                                    : null,
                                },
                              },
                              _vm._l(_vm.localImages, function (image, index) {
                                return _c(
                                  "div",
                                  {
                                    key: image.editId,
                                    class: {
                                      "uploaded-image-cell": true,
                                      "uploaded-image-cell--deleted":
                                        image.deleted,
                                      "uploaded-image-cell--with-gray-border":
                                        index === 0 && !image.deleted,
                                      "edit-photos-modal__single-image-cell": true,
                                    },
                                  },
                                  [
                                    !image.deleted
                                      ? _c("div", {
                                          staticClass:
                                            "uploaded-image-cell__delete-button",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.svg.trashCanIcon
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleDeleted(image)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    image.deleted
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "uploaded-image-cell__restore-button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleDeleted(image)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  restore\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass:
                                        "uploaded-image-cell__move-cross-icon",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.svg.moveAllSidesIcon
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    index === 0 && !image.deleted
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "uploaded-image-cell__main-image-badge",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "uploaded-image-cell__main-image-badgeIcon",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.svg.starOutlinedIcon
                                                ),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "uploaded-image-cell__main-image-badgeText",
                                              },
                                              [_vm._v("Main Image")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "uploaded-image-cell__photo-container",
                                        style: {
                                          backgroundImage:
                                            "url(" +
                                            _vm.$blobUrl +
                                            "/" +
                                            encodeURIComponent(image.url) +
                                            ")",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                   \n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "uploaded-image-cell__file-name",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.refineFileName(image.url)
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "edit-photos-modal__bottom-buttons-panel" },
              [
                _c(
                  "fs-button",
                  {
                    staticClass: "edit-photos-modal__bottom-button",
                    attrs: { secondary: "" },
                    nativeOn: {
                      click: function ($event) {
                        return (function ($event) {
                          _vm.fsPopperClose($event)
                          _vm.$emit("cancel", $event)
                        })($event)
                      },
                    },
                  },
                  [
                    _c("fs-button-icon", {
                      attrs: {
                        icon: _vm.svg.closeCircleOutlineButton,
                        width: "1rem",
                        height: "1rem",
                        top: "-1px",
                        color: "dark-blue",
                      },
                    }),
                    _vm._v("\n        Cancel\n      "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "fs-button",
                  {
                    staticClass: "edit-photos-modal__bottom-button",
                    attrs: { loading: _vm.saving },
                    on: { click: _vm.save },
                  },
                  [
                    _c("fs-button-icon", {
                      attrs: {
                        icon: _vm.svg.saveFloppyIcon,
                        width: "1rem",
                        height: "1rem",
                        top: "-1px",
                        color: "white",
                      },
                    }),
                    _vm._v("\n        Save\n      "),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "upload-drag-drop-area__description" }, [
      _c("p", [
        _vm._v("\n              - Optimal resolution is 1500x1000px"),
        _c("br"),
        _vm._v("\n              - Maximum file size 5 mb\n            "),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n              - Optimal Main Image:"),
        _c("br"),
        _vm._v(
          "\n              PNG-file, transparent background\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }