var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "entities-list-with-search": 1,
        "entities-list-with-search--short": _vm.createNewEntityMode,
      },
    },
    [
      _c(
        "fs-popper",
        { ref: "popperWhichArticlesToAlter", attrs: { placement: "top" } },
        [
          _c("which-articles-to-alter", {
            attrs: {
              articles: _vm.$store.articles.productArticles[_vm.productId],
              entities: _vm.localEntities.filter(function (entity) {
                return _vm.entityIdsNeedingArticleIds.includes(entity.id)
              }),
              "entities-ids-not-requiring-updating": _vm.localEntities
                .map(function (entity) {
                  return entity.id
                })
                .filter(function (entityId) {
                  return !_vm.entityIdsNeedingArticleIds.includes(entityId)
                }),
              "save-func": _vm.saveFunc,
              "suppliers-mode": "",
            },
            on: {
              cancel: function ($event) {
                _vm.saveInProgress = false
              },
              close: function ($event) {
                _vm.$refs.popperWhichArticlesToAlter.toggle($event)
                _vm.saveInProgress = false
                _vm.$emit("save-finish")
                _vm.fsPopperClose(_vm.$el)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.createNewEntityMode
        ? _c(
            "div",
            {
              staticClass: "entities-list-with-search__active-entities-section",
            },
            [
              _c("h3", { staticClass: "entities-list-with-search__title" }, [
                _vm._v("Membership Organizations"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "find-or-create-new-entity entities-list-with-search__find-or-create-new-entity",
                },
                [
                  _c("input-custom", {
                    staticClass: "find-or-create-new-entity__search-input",
                    attrs: { placeholder: "Add New Supplier", "is-search": "" },
                    on: {
                      change: _vm.debouncedSearch,
                      "reset-input": function ($event) {
                        _vm.searchQuery = ""
                      },
                    },
                    model: {
                      value: _vm.searchQuery,
                      callback: function ($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "transition",
                    { attrs: { name: "fall-from-top-with-fade" } },
                    [
                      _vm.searchQuery
                        ? _c("search-suggestions-list", {
                            staticClass:
                              "find-or-create-new-entity__suggestions-list",
                            attrs: {
                              "items-list": _vm.suggestedItemsList,
                              "existing-items-ids-list": _vm.localEntities.map(
                                function (item) {
                                  return item.id
                                }
                              ),
                              "create-btn-text":
                                "Add new Supplier “" + _vm.searchQuery + "”",
                              "search-query": _vm.searchQuery,
                              "is-skeleton-visible": _vm.isSearchInProgress,
                            },
                            on: {
                              "create-new": function ($event) {
                                _vm.showCreateNewEntityForm(_vm.searchQuery)
                                _vm.searchQuery = ""
                              },
                              "select-item": _vm.selectEntity,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "scrollable-block",
                {
                  staticClass:
                    "entities-list-with-search__existing-entities-list",
                },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        attrs: { "component-data": _vm.draggableComponentData },
                        on: {
                          start: function ($event) {
                            _vm.dragNdropState = true
                          },
                          end: function ($event) {
                            _vm.dragNdropState = false
                          },
                        },
                        model: {
                          value: _vm.localEntities,
                          callback: function ($$v) {
                            _vm.localEntities = $$v
                          },
                          expression: "localEntities",
                        },
                      },
                      "draggable",
                      _vm.dragOptions,
                      false
                    ),
                    [
                      _c(
                        "transition-group",
                        {
                          attrs: {
                            type: "transition",
                            name: !_vm.dragNdropState ? "flip-list" : null,
                          },
                        },
                        _vm._l(_vm.localEntities, function (entity, index) {
                          return _c(
                            "div",
                            {
                              key: entity.id,
                              staticClass:
                                "single-entity entities-list-with-search__single-entity",
                            },
                            [
                              _c("img", {
                                staticClass: "single-entity__icon",
                                attrs: {
                                  src: _vm.$store.countries.getFlagPathFromName(
                                    entity.country
                                  ),
                                  alt: entity.country,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "single-entity__name-n-subscript",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "single-entity__name" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(entity.name) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "single-entity__subscript" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(entity.country) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "single-entity__manage-buts-wrap",
                                },
                                [
                                  _c("div", {
                                    class: {
                                      "single-entity__manage-button": 1,
                                      "single-entity__manage-button--star": 1,
                                      "single-entity__manage-button--always-visible":
                                        _vm.starredEntityId === entity.id,
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.starredEntityId === entity.id
                                          ? _vm.svg.starFilledIcon
                                          : _vm.svg.starEmptyIcon
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.markEntityWithStar(
                                          entity.id,
                                          index
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass:
                                      "single-entity__manage-button single-entity__manage-button--delete-btn",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.svg.trashCanIcon),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.remove(entity)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("cancel-n-action-btn-pair", {
                staticClass: "entities-list-with-search__manage-buttons-panel",
                attrs: { "is-save-in-progress": _vm.saveInProgress },
                on: {
                  cancel: function ($event) {
                    _vm.fsPopperClose($event)
                    _vm.$emit("cancel", $event)
                  },
                  action: function ($event) {
                    return _vm.save($event)
                  },
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "entities-list-with-search__add-new-entity-section",
            },
            [
              _c("h3", { staticClass: "entities-list-with-search__title" }, [
                _vm._v("Add new supplier"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "new-entity-form entities-list-with-search__new-entity-form",
                },
                [
                  _c("div", { staticClass: "new-entity-form__subtitle" }, [
                    _vm._v("Supplier name"),
                  ]),
                  _vm._v(" "),
                  _c("input-custom", {
                    staticClass: "new-entity-form__form-element",
                    attrs: { "is-error": _vm.newEntityFormError },
                    on: {
                      input: function ($event) {
                        _vm.newEntityFormError = false
                        _vm.newEntityFormErrorMsg = ""
                      },
                    },
                    model: {
                      value: _vm.newEntityName,
                      callback: function ($$v) {
                        _vm.newEntityName = $$v
                      },
                      expression: "newEntityName",
                    },
                  }),
                  _vm._v(" "),
                  _vm.newEntityFormErrorMsg
                    ? _c(
                        "div",
                        { staticClass: "new-entity-form__error-message" },
                        [_vm._v(_vm._s(_vm.newEntityFormErrorMsg))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "new-entity-form__subtitle" }, [
                    _vm._v("Country"),
                  ]),
                  _vm._v(" "),
                  _c("input-country-with-hints", {
                    staticClass: "entities-list-with-search__search-form",
                    attrs: {
                      placeholder: "Country name",
                      "autocomplete-off": "",
                    },
                    model: {
                      value: _vm.newEntitySubscript,
                      callback: function ($$v) {
                        _vm.newEntitySubscript = $$v
                      },
                      expression: "newEntitySubscript",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("cancel-n-action-btn-pair", {
                staticClass: "entities-list-with-search__manage-buttons-panel",
                attrs: {
                  "is-save-in-progress": _vm.saveInProgress,
                  "is-disabled": !(_vm.newEntityName && _vm.newEntitySubscript),
                  "action-btn-name": "Add",
                },
                on: {
                  cancel: function ($event) {
                    _vm.createNewEntityMode = false
                  },
                  action: _vm.createNewEntity,
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }