var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "code-of-conduct-form" },
    [
      _c("div", { staticClass: "code-of-conduct-form__main-title" }, [
        _vm._v("Code of Conduct"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-itself code-of-conduct-form__form-itself" },
        _vm._l(
          [
            { title: "social", states: _vm.socialStates },
            { title: "environmental", states: _vm.environmentalStates },
          ],
          function (column) {
            return _c(
              "div",
              {
                key: column.title,
                staticClass: "form-itself__separate-column",
              },
              [
                _c("div", { staticClass: "form-itself__column-header" }, [
                  _c("div", { staticClass: "form-itself__title" }, [
                    _vm._v(
                      "\n          " + _vm._s(column.title) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "form-itself__points-counter": 1,
                        "form-itself__points-counter--green":
                          column.states.filter(function (item) {
                            return item.isActive
                          }).length > 2,
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            column.states.filter(function (item) {
                              return item.isActive
                            }).length
                          ) +
                          "\n          out of\n          " +
                          _vm._s(column.states.length) +
                          "\n          points\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "form-itself__states-list" },
                  _vm._l(column.states, function (state) {
                    return _c(
                      "li",
                      {
                        key: state.title,
                        class: {
                          "separate-state": 1,
                          "separate-state--green-border":
                            _vm.expandedState === state.title,
                          "separate-state--red-bg": !_vm.activeStates.includes(
                            state.title
                          ),
                          "separate-state--red-border":
                            !_vm.activeStates.includes(state.title) &&
                            _vm.expandedState === state.title,
                          "form-itself__separate-state": true,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "separate-state__header",
                            on: {
                              click: function ($event) {
                                return _vm.expandState(state)
                              },
                            },
                          },
                          [
                            state.hasOwnProperty("description") ||
                            state.hasOwnProperty("comment") ||
                            state.hasOwnProperty("file")
                              ? _c("div", {
                                  class: {
                                    "separate-state__caret-arr-but": 1,
                                    "separate-state__caret-arr-but--turned-to-top":
                                      _vm.expandedState === state.title,
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.svg.caretDownIcon),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", {
                              class: {
                                "separate-state__icon": 1,
                                "separate-state__icon--red":
                                  !_vm.activeStates.includes(state.title),
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.icons.codeOfConduct[state.icon]
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "separate-state__title" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(state.title) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("fs-switch", {
                              staticClass: "separate-state__checkbox",
                              attrs: {
                                value: _vm.activeStates.includes(state.title),
                              },
                              on: {
                                check: function ($event) {
                                  return _vm.activeStates.push(state.title)
                                },
                                uncheck: function ($event) {
                                  _vm.activeStates = _vm.activeStates.filter(
                                    function (item) {
                                      return item !== state.title
                                    }
                                  )
                                },
                              },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (function () {})($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: {
                              "separate-state__content": 1,
                              "separate-state__content--expanded":
                                _vm.expandedState === state.title,
                            },
                          },
                          [
                            state.hasOwnProperty("description")
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "separate-state__description",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(state.description) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            state.hasOwnProperty("comment")
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "comment-form separate-state__comment-form",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "comment-form__title" },
                                      [_vm._v("Your Comment")]
                                    ),
                                    _vm._v(" "),
                                    _c("text-area-custom", {
                                      attrs: {
                                        "min-height": 70,
                                        "max-height": 75,
                                      },
                                      model: {
                                        value:
                                          _vm.commentsTextareasValues[
                                            _vm.lodashCamelCase(state.title)
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.commentsTextareasValues,
                                            _vm.lodashCamelCase(state.title),
                                            $$v
                                          )
                                        },
                                        expression:
                                          "commentsTextareasValues[lodashCamelCase(state.title)]",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            state.hasOwnProperty("file")
                              ? _c("input-custom", {
                                  staticClass: "separate-state__file",
                                  attrs: {
                                    placeholder: "Upload Document",
                                    type: "file",
                                    "is-file": "",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          }
        ),
        0
      ),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "code-of-conduct-form__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }