var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-persons-form" },
    [
      _c("div", { staticClass: "contact-persons-form__main-title" }, [
        _vm._v("Contact Person"),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "persons-list contact-persons-form__persons-list" },
        _vm._l(_vm.personsData, function (person, index) {
          return _c(
            "inputs-section-frame",
            {
              key: person.fullName,
              staticClass: "persons-list__separate-person-form",
              attrs: {
                tag: "li",
                "show-drag-handle": "",
                "show-delete-but": "",
              },
            },
            [
              _c("div", { staticClass: "form-elements-list" }, [
                _c(
                  "div",
                  { staticClass: "form-elements-list__form-element-wrap" },
                  [
                    _c("input-custom", {
                      staticClass: "form-elements-list__text-input",
                      attrs: {
                        placeholder: "Full Name",
                        "no-clear-button": "",
                      },
                      model: {
                        value: _vm.personsData[index].fullName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.personsData[index],
                            "fullName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "personsData[index].fullName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-elements-list__form-element-wrap" },
                  [
                    _c("input-custom", {
                      staticClass: "form-elements-list__text-input",
                      attrs: { placeholder: "Email", "no-clear-button": "" },
                      model: {
                        value: _vm.personsData[index].email,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.personsData[index],
                            "email",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "personsData[index].email",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-elements-list__form-element-wrap" },
                  [
                    _c("select-with-input", {
                      staticClass: "form-elements-list__select-input",
                      attrs: {
                        "select-placeholder": "Code",
                        "select-options": ["+38", "+39", "+40", "+41"],
                        "input-placeholder": "Phone Number",
                      },
                      model: {
                        value: _vm.personsData[index].phoneNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.personsData[index],
                            "phoneNumber",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "personsData[index].phoneNumber",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-elements-list__form-element-wrap" },
                  [
                    _c("input-custom", {
                      staticClass: "form-elements-list__text-input",
                      attrs: {
                        placeholder: "Skype",
                        "no-clear-button": "",
                        "additional-icon": _vm.icons.socials.skype,
                      },
                      model: {
                        value: _vm.personsData[index].skype,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.personsData[index],
                            "skype",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "personsData[index].skype",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-elements-list__form-element-wrap" },
                  [
                    _c("input-custom", {
                      staticClass: "form-elements-list__text-input",
                      attrs: {
                        placeholder: "LinkedIn profile",
                        "no-clear-button": "",
                        "additional-icon": _vm.icons.socials.linkedin,
                      },
                      model: {
                        value: _vm.personsData[index].linkedIn,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.personsData[index],
                            "linkedIn",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "personsData[index].linkedIn",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-elements-list__form-element-wrap" },
                  [
                    _c("input-custom", {
                      staticClass: "form-elements-list__text-input",
                      attrs: {
                        placeholder: "WhatsApp",
                        "no-clear-button": "",
                        "additional-icon": _vm.icons.socials.whatsapp,
                      },
                      model: {
                        value: _vm.personsData[index].whatsApp,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.personsData[index],
                            "whatsApp",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "personsData[index].whatsApp",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-elements-list__form-element-wrap" },
                  [
                    _c("input-custom", {
                      staticClass: "form-elements-list__text-input",
                      attrs: {
                        placeholder: "WeChat",
                        "no-clear-button": "",
                        "additional-icon": _vm.icons.socials.wechat,
                      },
                      model: {
                        value: _vm.personsData[index].weChat,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.personsData[index],
                            "weChat",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "personsData[index].weChat",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "fs-button",
        {
          staticClass: "contact-persons-form__add-person-but",
          attrs: { secondary: "" },
        },
        [
          _c("fs-button-icon", {
            attrs: {
              icon: _vm.icons.interfaceElements.plusInCircleIcon,
              width: "1.5rem/1.6",
              height: "1.5rem/1.6",
              top: "0",
              color: "dark-blue",
            },
          }),
          _vm._v("\n    Add Contact Person\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "contact-persons-form__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }