import balletFlats from '@icons/product-types/balletFlats.svg';
import boots from '@icons/product-types/boots.svg';
import espadrilles from '@icons/product-types/espadrilles.svg';
import flipFlops from '@icons/product-types/flipFlops.svg';
import heeledShoes from '@icons/product-types/heeledShoes.svg';
import moccasins from '@icons/product-types/moccasins.svg';
import sandals from '@icons/product-types/sandals.svg';
import shoes from '@icons/product-types/shoes.svg';
import slippers from '@icons/product-types/slippers.svg';
import sneakers from '@icons/product-types/sneakers.svg';

export default {
  balletFlats,
  boots,
  espadrilles,
  flipFlops,
  heeledShoes,
  moccasins,
  sandals,
  shoes,
  slippers,
  sneakers,
};
