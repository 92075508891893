<template>
  <!-- Capabilities popper -->
  <div class="capabilities-popper">
    <div class="capabilities-popper__main-title">Capabilities</div>

    <ul class="capabilities-popper__sections-list">
      <!-- Capabilities list section -->
      <li
        v-for="(section, index1) in capabilitiesSections"
        :key="section.title"
        class="capabilities-list-section capabilities-popper__single-section"
      >
        <div class="capabilities-list-section__title">
          {{ ++index1 + '. ' + section.title }}
        </div>
        <ul class="capabilities-list-section__list">
          <li
            v-for="(item, index2) in section.capabilitiesList"
            :key="item"
            class="capabilities-list-section__list-item"
          >
            <checkbox-custom
              class="capabilities-list-section__checkbox-component"
              name="all"
              @check="() => {}"
              @uncheck="() => {}"
              :checked="false"
              :label="item"
              plm
              large
              standalone
              :id="item + index2"
            />
          </li>
        </ul>
      </li>
      <!-- / Capabilities list section -->
    </ul>

    <cancel-n-action-btn-pair class="capabilities-popper__manage-buttons-panel" @cancel="() => {}" @action="() => {}" />
  </div>
  <!-- / Capabilities popper -->
</template>

<script>
// Components
import CheckboxCustom from '@components/form-elements/checkbox-custom.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';

export default {
  name: 'capabilities-lis',

  components: {
    CancelNActionBtnPair,
    CheckboxCustom,
  },

  data() {
    return {
      isSaveInProgress: false,

      capabilitiesSections: [
        {
          title: 'Pre-development',
          capabilitiesList: ['Design Inspiration', 'Component Sourcing', 'Material Sourcing'],
        },
        { title: 'Development', capabilitiesList: ['Technical Design', 'Sample Making'] },
        { title: 'Production', capabilitiesList: ['Cutting', 'Stitching', 'Assembly', 'Quality Control'] },
        { title: 'Logistic', capabilitiesList: ['Shipping and Logistics'] },
      ],

      icons: { interfaceElements: interfaceElementsIcons },
    };
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

// ============================================================================
// ============================================================================
// Capabilities list section
.capabilities-list-section {
  &__title {
    margin-bottom: math.div(1.1rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1.6rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
    text-transform: uppercase;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__list-item {
    padding-left: math.div(0.4rem, 1.6);
    display: flex;
    justify-content: flex-start;
  }

  &__checkbox-component {
    margin: math.div(-0.6rem, 1.6) 0;
    //::v-deep .selection-box__label {
    //  padding-top: math.div(.3rem, 1.6);
    //  padding-bottom: math.div(.3rem, 1.6);
    //}
  }
}

// ============================================================================
// ============================================================================
// Capabilities popper
.capabilities-popper {
  width: math.div(108rem, 1.6);
  padding: math.div(3.5rem, 1.6) math.div(5rem, 1.6);
  box-sizing: border-box;

  &__main-title {
    margin-bottom: math.div(2.2rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__sections-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__single-section {
    width: 25%;
    margin-bottom: math.div(3rem, 1.6);
  }

  &__manage-buttons-panel {
  }
}

@media (max-width: $desktop-width) {
  .capabilities-popper {
    width: math.div(60rem, 1.6);

    &__sections-list {
    }

    &__single-section {
      width: 50%;
    }
  }
}

@media (max-width: $tablet-p-width) {
  .capabilities-popper {
    width: calc(100vw - #{math.div(3.6rem, 1.6)});
    max-width: calc(100vw - #{math.div(3.6rem, 1.6)});
    padding-left: math.div(2.6rem, 1.6);
    padding-right: math.div(2.6rem, 1.6);

    &__sections-list {
    }

    &__single-section {
      width: 100%;
    }
  }
}
</style>
