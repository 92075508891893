<template>
  <!-- New supplier form -->
  <div class="new-supplier-form">
    <div class="new-supplier-form__main-title">Create new Supplier</div>

    <!-- Form itself -->
    <div class="form-itself new-supplier-form__form-itself">
      <div class="form-itself__separate-section form-itself__separate-section--company-name">
        <input-custom class="form-itself__company-name-input" placeholder="Enter Company Name" />
      </div>

      <div class="form-itself__separate-section">
        <div class="section-subtitle form-itself__section-subtitle">
          What are you producing? <span class="section-subtitle__red-asterix">*</span>
        </div>

        <div class="form-itself__section-content">
          <checkbox-custom-with-icon
            v-for="item in whatYouProducing"
            :key="item"
            @check="() => {}"
            @uncheck="() => {}"
            :icon="{ footwear: svg.shoesIcon, components: svg.componentsIcon }[item.toLowerCase()]"
            :text-label="item"
          />
        </div>
      </div>

      <div class="form-itself__separate-section">
        <div class="section-subtitle form-itself__section-subtitle">
          What describes you the best? <span class="section-subtitle__red-asterix">*</span>
        </div>

        <div class="form-itself__section-content">
          <checkbox-custom-button
            v-for="item in whatDescribesYou"
            :key="item"
            @check="() => {}"
            @uncheck="() => {}"
            :text-label="item"
          />
        </div>
      </div>
    </div>
    <!-- / Form itself -->

    <cancel-n-action-btn-pair class="new-supplier-form__manage-buttons-panel" @cancel="() => {}" @action="() => {}" />
  </div>
  <!-- / New supplier form -->
</template>

<script>
// Components
import InputCustom from '@components/form-elements/input-custom.vue';
import CheckboxCustomWithIcon from '@components/form-elements/checkbox-custom-with-icon.vue';
import CheckboxCustomButton from '@components/form-elements/checkbox-custom-button.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
// SVG
import shoesIcon from '@icons/suppliers-specialization/shoes.svg';
import componentsIcon from '@icons/suppliers-specialization/components.svg';

export default {
  name: 'new-supplier-form',

  components: { InputCustom, CheckboxCustomWithIcon, CheckboxCustomButton, CancelNActionBtnPair },

  data() {
    return {
      isSaveInProgress: false,

      whatYouProducing: ['Footwear', 'Components'],
      whatDescribesYou: ['Manufacturer', 'Agent', 'Consultant', 'Trading Company'],

      icons: { interfaceElements: interfaceElementsIcons },
      svg: { shoesIcon, componentsIcon },
    };
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// ============================================================================
// Form itself
.form-itself {
  &__separate-section {
    margin-bottom: math.div(1.9rem, 1.6);

    &--company-name {
      margin-bottom: math.div(3.2rem, 1.6);
    }
  }

  &__section-subtitle {
    margin-bottom: math.div(1.3rem, 1.6);
  }
  .section-subtitle {
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(2rem, 1.6);
    text-align: center;

    &__red-asterix {
      color: red;
    }
  }

  &__section-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    ::v-deep > .checkbox-with-icon {
      margin: 0 math.div(0.8rem, 1.6) math.div(1rem, 1.6);
    }

    ::v-deep > .checkbox-button {
      margin: 0 math.div(0.4rem, 1.6) math.div(1rem, 1.6);
    }
  }

  &__company-name-input {
  }
}

// ============================================================================
// ============================================================================
// New supplier form
.new-supplier-form {
  width: math.div(42rem, 1.6);
  padding: math.div(3.5rem, 1.6);
  box-sizing: border-box;

  &__main-title {
    margin-bottom: math.div(2.5rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.9rem, 1.6)} 'Montserrat', sans-serif;
    text-align: center;
  }

  &__form-itself {
    margin-bottom: math.div(5.3rem, 1.6);
  }

  &__manage-buttons-panel {
  }
}
</style>
