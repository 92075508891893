<template>
  <div :class="{ 'product-gallery': true, 'product-gallery--centered-content': simplifiedView }">
    <!-- Simplified View Container -->
    <div v-if="simplifiedView" class="simplified-view-container product-gallery__simplified-view-container">
      <div v-if="filesToUpload" class="loading-overlay simplified-view-container__loading-overlay">
        <div
          :class="{ 'loading-overlay__progress-icon': true, 'loading-overlay__progress-icon--spinning': true }"
          v-html="svg.loadingSpinnerIcon"
        ></div>
      </div>

      <transition name="simple-fade-in-out">
        <!-- Drag & Drop overlay -->
        <div
          v-if="!filesToUpload && isDragNDropAreaShowed"
          :class="{
            'drag-n-drop-overlay': true,
            'drag-n-drop-overlay--opaque': true,
            'drag-n-drop-overlay--hovered': isDragNDropAreaHovered,
            'simplified-view-container__drag-n-drop-overlay': true,
          }"
          @drop.prevent="($event) => handleAdditionalImagesDrop($event)"
          @dragover.prevent="isDragNDropAreaHovered = true"
          @dragleave.prevent="isDragNDropAreaHovered = false"
        >
          <div
            class="drag-n-drop-overlay__icon drag-n-drop-overlay__icon--smaller drag-n-drop-overlay__icon--no-bottom-margin"
            v-html="svg.uploadArrowCloudIcon"
          ></div>
        </div>
        <!-- / Drag & Drop overlay -->
      </transition>

      <div
        :class="{
          'simplified-view-container__main-image-wrap': true,
          'simplified-view-container__main-image-wrap--transparent': !filesToUpload && isDragNDropAreaShowed,
        }"
      >
        <img
          v-if="images[0]"
          :src="$blobUrl + '/' + images[0].url"
          alt="Product Photo"
          :style="{ opacity: images[0] ? 1 : 0.25 }"
          class="simplified-view-container__main-image"
          @click="$refs.editPhotosModal.toggle($event)"
        />

        <add-content-placeholder v-else class="simplified-view-container__placeholder" label="Image" />
      </div>
    </div>

    <div v-else class="product-gallery__in-wrap">
      <div v-if="!images || images.length === 0" class="product-gallery__photo-placeholder">
        <img :src="placeholderImage" alt="Shoe photos" draggable="false" />
      </div>

      <transition name="simple-fade-in-out">
        <label
          v-if="!filesToUpload && images.length === 0"
          for="uploadPhotosInput"
          :class="{
            'add-photo-drag-area': true,
            'add-photo-drag-area--hovered': isDragNDropAreaHovered,
            'product-gallery__add-photo-drag-area': true,
          }"
          @drop.prevent="uploadImages"
          @dragover.prevent="isDragNDropAreaHovered = true"
          @dragleave.prevent="isDragNDropAreaHovered = false"
        >
          <!--          <loading-overlay-->
          <!--            v-if="this.uploadInProgress"-->
          <!--            class="add-photo-drag-area__loading-overlay"-->
          <!--          />-->

          <span class="add-photo-drag-area__icon" v-html="svg.uploadArrowCloudIcon"></span>

          <span class="add-photo-drag-area__title"> Drag Images To Upload </span>

          <span class="add-photo-drag-area__or"> or </span>

          <label
            for="uploadPhotosInput"
            class="add-photo-drag-area__add-files-but-label"
            @drop.prevent="uploadImages"
            @dragover.prevent
          >
            <fs-button secondary class="add-photo-drag-area__add-files-but">
              <fs-button-icon
                :icon="svg.photoPlaceholderIcon"
                width="1rem"
                height="1rem"
                top="-1px"
                color="dark-blue"
              />
              Browse images
            </fs-button>
          </label>

          <input
            type="file"
            id="uploadPhotosInput"
            class="add-photo-drag-area__add-files-input"
            multiple
            @change.prevent="uploadImages"
          />

          <span class="add-photo-drag-area__bg-layer"> </span>
        </label>
      </transition>

      <transition name="simple-fade-in-out">
        <!-- Drag & Drop overlay -->
        <div
          v-if="!filesToUpload && isDragNDropAreaShowed"
          :class="{
            'drag-n-drop-overlay': true,
            'drag-n-drop-overlay--hovered': isDragNDropAreaHovered,
            'product-gallery__drag-n-drop-overlay': true,
          }"
          @drop.prevent="($event) => handleAdditionalImagesDrop($event)"
          @dragover.prevent="isDragNDropAreaHovered = true"
          @dragleave.prevent="isDragNDropAreaHovered = false"
        >
          <div class="drag-n-drop-overlay__icon" v-html="svg.uploadArrowCloudIcon"></div>

          <span class="drag-n-drop-overlay__text"> Drop Images to upload </span>
        </div>
        <!-- / Drag & Drop overlay -->
      </transition>

      <div
        v-if="images.length > 0"
        :class="{
          'product-gallery__main-photo-wrap1': true,
          'product-gallery__main-photo-wrap1--blurred': isDragNDropAreaShowed,
        }"
        @dragover.prevent="showDragNDropOverlay"
      >
        <div class="product-gallery__main-photo-wrap2">
          <transition-group tag="div" name="simple-fade-in-out-long">
            <div
              v-if="images.length > 0 && index === activeSlideNumber"
              v-for="(photo, index) in images"
              :key="photo.id"
              class="product-gallery__main-photo-wrap3"
              v-touch:swipe.left="() => switchMainPhoto('next')"
              v-touch:swipe.right="() => switchMainPhoto('previous')"
            >
              <img
                class="product-gallery__main-photo"
                draggable="false"
                :src="$blobUrl + '/' + images[activeSlideNumber].url"
                alt="Product Photo"
              />
            </div>
          </transition-group>
        </div>
      </div>

      <div
        v-if="images.length > 0"
        :class="{
          'product-gallery__bottom-panel': true,
          'product-gallery__bottom-panel--blurred': isDragNDropAreaShowed,
        }"
        @dragover.prevent="showDragNDropOverlay"
      >
        <div
          :class="{
            'product-gallery__previews-list-wrap1': true,
            'product-gallery__previews-list-wrap1--white-gradients': whiteBackground,
            'product-gallery__previews-list-wrap1--full-width': verticalLayout,
          }"
        >
          <transition name="simple-fade-in-out">
            <div
              v-if="showLeftArr"
              @click="scrollSlider('toTheRight')"
              class="product-gallery__slider-arrow product-gallery__slider-arrow--left"
              v-html="svg.caretLeft"
            ></div>
          </transition>
          <transition name="simple-fade-in-out">
            <div
              v-if="showRightArr && images.length - previewsNumber > 0"
              @click="scrollSlider('toTheLeft')"
              class="product-gallery__slider-arrow product-gallery__slider-arrow--right"
              v-html="svg.caretRight"
            ></div>
          </transition>

          <div
            :class="{
              'product-gallery__previews-list-wrap2': true,
              'product-gallery__previews-list-wrap2--high': previewsNumber === 3,
            }"
            v-touch:swipe.left="() => scrollSlider('toTheLeft')"
            v-touch:swipe.right="() => scrollSlider('toTheRight')"
          >
            <div class="product-gallery__previews-list-wrap3">
              <ul
                :class="{
                  'product-gallery__previews-list': true,
                  'product-gallery__previews-list--3-slides': previewsNumber === 3,
                }"
                :style="{ transform: `translateX(${sliderScrollPosition * -100}%)` }"
              >
                <li
                  v-if="images.length > 0"
                  v-for="(photo, index) in images"
                  :key="photo.id"
                  @click="activeSlideNumber = index"
                  class="product-gallery__single-preview"
                >
                  <div
                    :class="{
                      'product-gallery__single-preview-in-wrap': true,
                      'product-gallery__single-preview-in-wrap--active': activeSlideNumber === index,
                    }"
                  >
                    <img
                      class="product-gallery__single-preview-img"
                      :src="$blobUrl + '/' + photo.url"
                      alt="Product Photo"
                      draggable="false"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          :class="{
            'product-gallery__add-edit-buttons': true,
            'product-gallery__add-edit-buttons--under-previews-positioned': verticalLayout,
          }"
        >
          <div
            v-if="images.length === 0"
            class="product-gallery__add-but"
            v-html="svg.plusIcon"
            @click="$refs.uploadPhotosModal.toggle($event)"
            title="Add Photos"
          ></div>

          <!-- Upload Photos Modal -->
          <fs-popper-pr-edit ref="uploadPhotosModal" :offset="[0, -300]">
            <edit-photos-modal simple-mode :images="images" :save-func="saveFunc" />
          </fs-popper-pr-edit>

          <div
            v-if="images.length > 0"
            class="product-gallery__edit-but"
            v-html="svg.editFilled"
            @click="$refs.editPhotosModal.toggle($event)"
            title="Edit Photos List"
          ></div>
        </div>
      </div>
    </div>

    <transition name="simple-fade-in-out">
      <edit-photos-modal
        v-if="filesToUpload"
        v-show="!simplifiedView"
        class="product-gallery__initial-upload-progress-list"
        simple-mode
        :max-height="360"
        :files-to-upload="filesToUpload"
        :save-func="saveFunc"
        :images="images"
        @upload-finish="filesToUpload = null"
      />
    </transition>

    <!-- Edit Photos Modal -->
    <fs-popper-pr-edit ref="editPhotosModal" :offset="[0, -300]">
      <edit-photos-modal :images="images" :save-func="saveFunc" />
    </fs-popper-pr-edit>
  </div>
</template>

<script>
import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import { v4 as uuidv4 } from 'uuid';

// Components
import AddContentPlaceholder from '@components/add-content-placeholder.vue';
import EditPhotosModal from './edit-photos-modal.vue';
import FsButton from '@components/buttons/fs-button.vue';
import FsButtonIcon from '@components/fs-button-icon.vue';
import { fsPopperClose } from '@components/poppers/fs-popper.vue';
import FsPopperPrEdit from '@components/poppers/fs-popper-pr-edit.vue';

// Images
import shoePlaceholder from '@images/shoe-placeholder.jpg';

// Icons
import editFilled from '@icons/other/edit-filled.svg';
import plusIcon from '@icons/other/plus-icon.svg';
import caretLeft from '@icons/other/arrow-left.svg';
import caretRight from '@icons/other/arrow-right.svg';
import plusCircleFilledIcon from '@icons/other/plus-circle-filled-icon.svg';
import uploadArrowCloudIcon from '@icons/other/upload-arrow-cloud-icon.svg';
import photoPlaceholderIcon from '@icons/other/photo-placeholder-icon.svg';
import loadingSpinnerIcon from '@icons/other/loading-spinner-icon.svg';

Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  touchHoldTolerance: 400,
  swipeTolerance: 100,
  longTapTimeInterval: 400,
});

export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    previewsNumber: {
      type: Number,
      default: 4,
    },
    verticalLayout: Boolean,
    whiteBackground: Boolean,
    saveFunc: {
      type: Function,
      required: true,
    },
    simplifiedView: Boolean,
  },

  components: { AddContentPlaceholder, EditPhotosModal, FsButton, FsButtonIcon, FsPopperPrEdit },

  data() {
    return {
      filesToUpload: null,

      updateInProgress: false,

      activeSlideNumber: 0,

      isDragNDropAreaShowed: false,
      isDragNDropAreaHovered: false,
      isDragNDropAreaShowedTimeout: false,

      sliderScrollPosition: 0,
      showLeftArr: false,
      showRightArr: true,

      eventThrottlingTimestamp1: 0,
      eventThrottlingTimestamp2: 0,

      placeholderImage: shoePlaceholder,

      svg: {
        editFilled,
        plusIcon,
        caretLeft,
        caretRight,
        plusCircleFilledIcon,
        uploadArrowCloudIcon,
        photoPlaceholderIcon,
        loadingSpinnerIcon,
      },
    };
  },

  mounted() {
    this.addDocumentDragListeners();
  },

  beforeDestroy() {
    this.removeDocumentDragListeners();
  },

  watch: {
    images() {
      this.activeSlideNumber = 0;
      this.sliderScrollPosition = 0;
      this.showLeftArr = false;
      this.showRightArr = this.sliderScrollPosition !== this.images.length - this.previewsNumber;
    },
  },

  methods: {
    removeDocumentDragListeners() {
      document.removeEventListener('dragstart', this.showDragNDropOverlay);
      document.removeEventListener('dragend', this.hideDragNDropOverlay);
      document.removeEventListener('dragover', this.showDragNDropOverlay);
      document.removeEventListener('dragleave', this.hideDragNDropOverlay);
      document.removeEventListener('drop', this.hideDragNDropOverlay);
    },

    addDocumentDragListeners() {
      document.addEventListener('dragstart', this.showDragNDropOverlay);
      document.addEventListener('dragend', this.hideDragNDropOverlay);
      document.addEventListener('dragover', this.showDragNDropOverlay);
      document.addEventListener('dragleave', this.hideDragNDropOverlay);
      document.addEventListener('drop', this.hideDragNDropOverlay);
    },

    showDragNDropOverlay(event) {
      // Overlay is not needed on empty gallery because in this state gallery already have drag&drop area
      if (!this.images.length && !this.simplifiedView) {
        return;
      }

      clearTimeout(this.isDragNDropAreaShowedTimeout);
      this.isDragNDropAreaShowed = true;
      this.isDragNDropAreaShowedTimeout = setTimeout(() => (this.isDragNDropAreaShowed = false), 1000);
    },

    hideDragNDropOverlay() {
      // All this setTimeout/clearTimeout things is needed to avoid fast toggle of the "this.isDragNDropAreaShowed" data property and Drag&Drop gallery overlay blinking
      clearTimeout(this.isDragNDropAreaShowedTimeout);
      this.isDragNDropAreaShowedTimeout = setTimeout(() => (this.isDragNDropAreaShowed = false), 200);
    },

    handleAdditionalImagesDrop(event) {
      if (!event.dataTransfer.files.length) return;

      this.uploadImages(event);
      this.hideDragNDropOverlay();
    },

    fsPopperClose,
    async uploadImages(event) {
      const files = event.dataTransfer?.files || event.target.files;

      if (!files || !files.length) return;

      this.filesToUpload = files;
    },

    scrollSlider(scrollDirection, toExactPosition) {
      // ========================
      // Throttling
      if (this.eventThrottlingTimestamp1 !== 0 && Date.now() - this.eventThrottlingTimestamp1 < 200) return;
      this.eventThrottlingTimestamp1 = Date.now();
      // ========================

      const totalSlides = this.images.length;
      const visibleSlides = this.previewsNumber; // number of previews in slider's "viewport"
      const maxScrollPosition = totalSlides - visibleSlides;

      // Set scroll position
      if (scrollDirection === 'toTheLeft' && this.sliderScrollPosition < maxScrollPosition) {
        this.sliderScrollPosition += 1;
      } else if (scrollDirection === 'toTheRight' && this.sliderScrollPosition > 0) {
        this.sliderScrollPosition -= 1;

        // Pull and return bounce effect
      } else if (
        scrollDirection === 'toTheLeft' &&
        this.sliderScrollPosition === maxScrollPosition &&
        totalSlides >= visibleSlides
      ) {
        this.sliderScrollPosition = maxScrollPosition + 0.35;
        setTimeout(() => (this.sliderScrollPosition = maxScrollPosition), 100);
        return;
      } else if (scrollDirection === 'toTheRight' && this.sliderScrollPosition === 0 && totalSlides >= visibleSlides) {
        this.sliderScrollPosition = -0.35;
        setTimeout(() => (this.sliderScrollPosition = 0), 100);
        return;
      }

      // Scroll to exact scroll position
      if (toExactPosition !== undefined && toExactPosition !== null) {
        if (toExactPosition > maxScrollPosition) {
          this.sliderScrollPosition = maxScrollPosition;
        } else if (toExactPosition <= 0) {
          this.sliderScrollPosition = 0;
        } else {
          this.sliderScrollPosition = toExactPosition;
        }
      }

      // Toggle arrows
      this.showLeftArr = this.sliderScrollPosition > 0;
      this.showRightArr = this.sliderScrollPosition !== maxScrollPosition;
    },

    // Scroll photos previews list so the active thumbnail would be in viewport
    scrollActiveThumbnailToViewPort() {
      const viewPortSize = this.previewsNumber; // number of slides in viewport
      const viewPortIndexes = this.images
        .map((item, index) => index)
        .slice(this.sliderScrollPosition, this.sliderScrollPosition + viewPortSize);
      const isActiveThumbnailInsideViewport = viewPortIndexes.includes(this.activeSlideNumber);
      const lastViewportIndex = viewPortIndexes[viewPortIndexes.length - 1];

      if (!isActiveThumbnailInsideViewport) {
        let targetSlideNumber = this.activeSlideNumber;
        if (targetSlideNumber === lastViewportIndex + 1) targetSlideNumber = this.sliderScrollPosition + 1;

        this.scrollSlider(null, targetSlideNumber);
      }
    },

    switchMainPhoto(direction) {
      // ========================
      // Throttling
      if (this.eventThrottlingTimestamp2 !== 0 && Date.now() - this.eventThrottlingTimestamp2 < 200) return;
      this.eventThrottlingTimestamp2 = Date.now();
      // ========================

      let newActiveSlideNumber = this.activeSlideNumber;

      if (direction === 'next') {
        newActiveSlideNumber += 1;
      } else if (direction === 'previous') {
        newActiveSlideNumber -= 1;
      }

      if (newActiveSlideNumber > this.images.length - 1) {
        newActiveSlideNumber = this.images.length - 1;
      } else if (newActiveSlideNumber < 0) {
        newActiveSlideNumber = 0;
      }

      this.activeSlideNumber = newActiveSlideNumber;

      this.scrollActiveThumbnailToViewPort();
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ==========================================================================
// ==========================================================================
// Product Gallery Container
.simplified-view-container {
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;

  &__main-image-wrap {
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.1s ease;

    &--transparent {
      opacity: 0;
    }
  }

  &__main-image {
    max-height: 100%;
  }

  &__loading-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 5;
  }
  .loading-overlay {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);

    &__progress-icon {
      width: math.div(3rem, 1.6);
      height: math.div(3rem, 1.6);
      position: absolute;
      inset: calc(50% - #{math.div(1.5rem, 1.6)}) auto auto calc(50% - #{math.div(1.5rem, 1.6)});

      ::v-deep svg {
        fill: rgba(53, 73, 94, 1);
      }

      &--spinning {
        ::v-deep svg {
          animation: spinning;
          animation-duration: 0.7s;
          animation-iteration-count: infinite;
          animation-timing-function: step-end;
        }
      }
    }

    @keyframes spinning {
      0% {
        transform: rotate(0);
      }
      11% {
        transform: rotate(40deg);
      }
      22% {
        transform: rotate(80deg);
      }
      33% {
        transform: rotate(120deg);
      }
      44% {
        transform: rotate(160deg);
      }
      55% {
        transform: rotate(200deg);
      }
      66% {
        transform: rotate(240deg);
      }
      77% {
        transform: rotate(280deg);
      }
      88% {
        transform: rotate(320deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__drag-n-drop-overlay {
    width: 100%;
    min-width: 120px;
    height: calc(100% - 5px);
    min-height: 80px;
    position: absolute;
    inset: 50% auto auto 50%;
    z-index: 9;
    transform: translate(-50%, -50%);
  }
}

// ============================================================================
// Drag & Drop overlay
.drag-n-drop-overlay {
  box-sizing: border-box;
  border: 1px rgba(53, 73, 94, 0.5) dashed;
  border-radius: math.div(1rem, 1.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: math.div(2rem, 1.6);
  font-family: 'Montserrat', sans-serif;
  background-color: rgba(250, 250, 250, 0.7);
  backdrop-filter: blur(3px);
  transition: background-color 0.1s ease;

  &--opaque {
    background-color: rgba(250, 250, 250, 1);
  }

  &--hovered {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &__icon {
    width: math.div(5.5rem, 1.6);
    height: math.div(5.1rem, 1.6);
    margin-bottom: math.div(0.6rem, 1.6);
    pointer-events: none;

    ::v-deep svg {
      fill: rgba(53, 73, 94, 1);
    }

    &--smaller {
      width: math.div(3.7rem, 1.6);
      height: math.div(3.5rem, 1.6);
    }

    &--no-bottom-margin {
      margin-bottom: 0;
    }
  }

  &__text {
    pointer-events: none;
  }
}

// ==========================================================================
// ==========================================================================
// Product Gallery Container
.product-gallery {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;

  &--disabled {
    pointer-events: none;
  }

  &--centered-content {
    padding: 0 !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }

  &__drag-n-drop-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 9;
  }

  &__in-wrap {
    width: 100%;
    max-width: math.div(105rem, 1.6);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    position: relative;
  }

  &__photo-placeholder {
    width: 100%;
    padding-top: 39%;
    margin-bottom: math.div(20rem, 1.6);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;

    ::v-deep img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      mix-blend-mode: darken;
      opacity: 0.25;
    }
  }

  &__initial-upload-progress-list {
    position: absolute;
    top: math.div(1rem, 1.6);
    left: calc(50% - #{math.div(17rem, 1.6)});
    z-index: 11;
  }

  &__add-photo-drag-area {
    position: absolute !important;
    bottom: 10%;
  }

  .add-photo-drag-area {
    width: math.div(37rem, 1.6);
    max-width: 100%;
    height: math.div(22rem, 1.6);
    padding-bottom: math.div(0.3rem, 1.6);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    opacity: 1;
    user-select: none;
    transition: opacity 0.15s ease;

    &--hovered,
    &:hover {
      opacity: 0.7;
    }

    &__loading-overlay {
      width: calc(100% - #{math.div(1rem, 1.6)});
      height: calc(100% - #{math.div(1rem, 1.6)});
      border-radius: math.div(1.2rem, 1.6);
      position: absolute;
      top: math.div(0.5rem, 1.6);
      left: math.div(0.5rem, 1.6);
      z-index: 2;
    }

    &__icon {
      width: math.div(5.4rem, 1.6);
      height: math.div(5rem, 1.6);
      margin-bottom: math.div(1.1rem, 1.6);

      ::v-deep svg {
        fill: rgb(53, 73, 94);
      }
    }

    &__title {
      margin-bottom: math.div(0.4rem, 1.6);
      font-size: math.div(2rem, 1.6);
      font-family: 'Montserrat', sans-serif;
    }

    &__or {
      margin-bottom: math.div(1rem, 1.6);
      font-size: math.div(1.2rem, 1.6);
      color: rgba(53, 73, 94, 0.5);
    }

    &__add-file-but {
    }

    &__bg-layer {
      width: 100%;
      height: 100%;
      border-radius: math.div(1rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      overflow: hidden;
      transition: width 0.15s ease, height 0.15s ease, top 0.15s ease, left 0.15s ease;

      &::before {
        content: '';
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        box-sizing: border-box;
        border: 2px rgba(53, 73, 94, 0.5) dashed;
        border-radius: math.div(1.1rem, 1.6);
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: -1;
        background: #fff;
      }
    }

    &--hovered .add-photo-drag-area__bg-layer,
    &:hover .add-photo-drag-area__bg-layer {
      width: calc(100% + #{math.div(0.6rem, 1.6)});
      height: calc(100% + #{math.div(0.6rem, 1.6)});
      top: math.div(-0.3rem, 1.6);
      left: math.div(-0.3rem, 1.6);
    }

    &__add-files-but-label {
      position: relative;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }

    &__add-files-but {
    }

    &__add-files-input {
      width: 0.1px;
      height: 0.1px;
      position: absolute;
      top: math.div(1rem, 1.6);
      left: math.div(1rem, 1.6);
      z-index: -1;
    }
  }

  &__upload-interface {
    border-radius: math.div(1.2rem, 1.6);
    position: absolute !important;
    left: calc(50% - #{math.div(16.5rem, 1.6)});
    bottom: calc(50% - #{math.div(25rem, 1.6)});
    box-shadow: 0 math.div(0.5rem, 1.6) math.div(1rem, 1.6) rgba(0, 0, 0, 0.1);
  }

  &__add-photo-clickable-area {
    position: absolute !important;
    bottom: 0;
  }

  .add-photo-clickable-area {
    width: math.div(17rem, 1.6);
    height: math.div(17rem, 1.6);
    padding: math.div(2rem, 1.6);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    z-index: 0;
    text-align: center;
    cursor: pointer;
    user-select: none;

    &__plus-icon {
      width: math.div(2.4rem, 1.6);
      height: math.div(2.4rem, 1.6);
      margin-bottom: math.div(1.6rem, 1.6);

      ::v-deep svg {
        width: 100%;
        height: 100%;
        fill: rgb(53, 73, 94);
      }
    }

    &__text-label {
      text-align: center;
    }

    &__text-label-span {
      border-bottom: 1px #fff solid;
      transition: border-bottom-color 0.15s ease;
    }

    &:hover .add-photo-clickable-area__text-label-span {
      border-bottom-color: rgba(53, 73, 94, 0.2);
    }

    &__bg-layer {
      width: 100%;
      height: 100%;
      border-radius: math.div(1rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      transition: width 0.15s ease, height 0.15s ease, top 0.15s ease, left 0.15s ease;

      &::before {
        content: '';
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        box-sizing: border-box;
        border: 2px rgba(53, 73, 94, 0.5) dashed;
        border-radius: math.div(1.1rem, 1.6);
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: -1;
        background: #fff;
      }
    }

    &:hover .add-photo-clickable-area__bg-layer {
      width: calc(100% + #{math.div(0.6rem, 1.6)});
      height: calc(100% + #{math.div(0.6rem, 1.6)});
      top: math.div(-0.3rem, 1.6);
      left: math.div(-0.3rem, 1.6);
    }
  }

  &__main-photo-wrap1 {
    width: 100%;
    padding-top: 41%;
    position: relative;
    //outline: 1px #0f0 solid;

    &--blurred {
      filter: blur(3px);
      pointer-events: none;
    }
  }

  &__slider-arrow {
    width: math.div(2.4rem, 1.6);
    height: math.div(2.4rem, 1.6);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - #{math.div(1.2rem, 1.6)});
    z-index: 2;
    cursor: pointer;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px #35495e solid;
      border-radius: math.div(10rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: scale(1);
      transition: border-color 0.15s ease, transform 0.15s ease, background-color 0.15s ease;
      background: #f0f0f0;
    }

    &::after {
      content: '';
      width: calc(100% + #{math.div(2rem, 1.6)});
      height: calc(100% + #{math.div(2rem, 1.6)});
      border-radius: math.div(10rem, 1.6);
      position: absolute;
      top: calc(50% - #{math.div(2.2rem, 1.6)});
      left: calc(50% - #{math.div(2.2rem, 1.6)});
    }

    ::v-deep svg {
      width: math.div(1.2rem, 1.6);
      transition: fill 0.15s ease;
    }

    &:hover {
      &::before {
        border-color: #2c97df;
        transform: scale(1.13);
        background: #fff;
      }

      ::v-deep svg {
        fill: #2c97df;
      }
    }

    &--left {
      padding-right: 1px;
      left: 2%;
    }

    &--right {
      padding-left: 2px;
      right: 2%;
    }
  }

  &__main-photo-wrap2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 0;
    left: 0;

    & > ::v-deep div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
  &__main-photo-wrap3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: darken;
    user-select: none;
  }

  &__main-photo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &__bottom-panel {
    width: 83%;
    display: flex;
    user-select: none;

    &--blurred {
      filter: blur(3px);
      pointer-events: none;
    }
  }

  &__previews-list-wrap1 {
    width: 92%;
    position: relative;
    //outline: 1px #f00 solid;

    &::before,
    &::after {
      content: '';
      width: math.div(1.5rem, 1.6);
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      background: rgba(255, 0, 0, 0.1);
    }

    &::before {
      left: 0;
      background: rgb(240, 240, 240);
      background: linear-gradient(90deg, rgba(240, 240, 240, 1) 0%, rgba(240, 240, 240, 0) 100%);
    }

    &::after {
      right: 0;
      background: rgb(240, 240, 240);
      background: linear-gradient(270deg, rgba(240, 240, 240, 1) 0%, rgba(240, 240, 240, 0) 100%);
    }

    &--white-gradients {
      &::before {
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }

      &::after {
        background: rgb(255, 255, 255);
        background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }

    &--full-width {
      width: 100%;
    }
  }

  &__previews-list-wrap2 {
    width: 100%;
    padding-top: 28%;
    position: relative;
    overflow: hidden;
    //outline: 1px #f00 solid;

    &--high {
      height: math.div(9.5rem, 1.6);
    }
  }

  &__previews-list-wrap3 {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__previews-list {
    width: 24.07%;
    height: 74%;
    padding: 0;
    margin: 0;
    display: flex;
    position: relative;
    left: 3.6%;
    list-style: none;
    transition: transform 0.15s ease;
    mix-blend-mode: darken;
    //outline: 1px #f00 solid;

    &--3-slides {
      width: 32%;
      height: 68%;
    }
  }

  &__single-preview {
    width: 100%;
    min-width: 100%;
    height: 100%;
    user-select: none;
  }

  &__single-preview-in-wrap {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: math.div(1rem, 1.6);
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 calc(1.1vw) rgba(0, 0, 0, 0.1);
    transform: translateY(0);
    transition: transform 0.1s ease;
    cursor: pointer;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 2px rgba(53, 73, 94, 0) solid;
      border-radius: math.div(1rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
      transition: border-color 0.07s ease;
    }

    &:hover {
      transform: translateY(#{math.div(-0.5rem, 1.6)});
    }

    &--active {
      cursor: default;

      &::before {
        border-color: rgba(53, 73, 94, 1);
      }

      &:hover {
        transform: translateY(0);
      }
    }
  }

  &__single-preview-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &__add-edit-buttons {
    width: 1px;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    z-index: 2;
    //outline: 1px #f00 solid;

    &--under-previews-positioned {
      width: math.div(5.5rem, 1.6);
      flex-direction: row;
      justify-content: center;
      position: absolute;
      left: calc(50% - #{math.div(4.1rem, 1.6)});
      bottom: math.div(-3.5rem, 1.6);
    }
  }

  &__add-but,
  &__edit-but {
    width: math.div(2.4rem, 1.6);
    height: math.div(2.4rem, 1.6);
    margin: math.div(0.3rem, 1.6) 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    cursor: pointer;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: math.div(10rem, 1.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: scale(1);
      transition: transform 0.15s ease, border-color 0.15s ease, background-color 0.15s ease;
    }

    &::after {
      content: '';
      width: calc(100% + #{math.div(1.6rem, 1.6)});
      height: calc(100% + #{math.div(1.6rem, 1.6)});
      border-radius: math.div(10rem, 1.6);
      position: absolute;
      left: math.div(-0.8rem, 1.6);
    }

    ::v-deep svg {
      width: math.div(1.1rem, 1.6);
      transition: fill 0.15s ease;
    }

    &:hover {
      &::before {
        transform: scale(1.13);
      }
    }
  }

  &__add-but {
    &::before {
      background: #35495e;
    }
    &::after {
      bottom: math.div(-0.5rem, 1.6);
    }

    &:hover {
      &::before {
        background: #2c97df;
      }
    }

    ::v-deep svg {
      fill: #fff;
    }
  }

  &__edit-but {
    &::before {
      border: 1px #35495e solid;
    }

    &::after {
      top: math.div(-0.5rem, 1.6);
    }

    ::v-deep svg {
      fill: #35495e;
    }

    &:hover {
      &::before {
        border-color: #2c97df;
        background: #fff;
      }

      ::v-deep svg {
        fill: #2c97df;
      }
    }
  }
}

// ============================================================================
// ============================================================================
// Generic Modal window
.generic-modal-window {
  .popover-inner {
    padding: 0 !important;
    overflow: hidden;
  }
}
</style>
