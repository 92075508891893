var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "mainSelectContainer",
      class: {
        "custom-select": true,
        "custom-select--active": _vm.isOpened,
        "custom-select--disabled": _vm.isDisabled,
        "custom-select--error": _vm.isError,
        "custom-select--medium": _vm.size === "medium",
        "custom-select--unselected": _vm.localValue === null,
      },
      attrs: { tabindex: "0" },
    },
    [
      _c(
        "label",
        {
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.$emit("keyup-enter", $event)
            },
          },
        },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.localValueAsComparable,
                  expression: "localValueAsComparable",
                },
              ],
              ref: "select",
              staticClass: "custom-select__form",
              attrs: { name: _vm.name, id: _vm.id, disabled: _vm.isDisabled },
              on: {
                focus: function ($event) {
                  return _vm.$emit("focus", $event)
                },
                blur: function ($event) {
                  return _vm.$emit("blur", $event)
                },
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.localValueAsComparable = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.optionsAsComparable, function (item, index) {
              return _c(
                "option",
                {
                  key: index,
                  domProps: {
                    value: item,
                    selected: item == _vm.localValueAsComparable,
                  },
                },
                [
                  _vm._t(
                    "default",
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.comparableToValue(item)) +
                          "\n        "
                      ),
                    ],
                    { option: _vm.comparableToValue(item) }
                  ),
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "fakeInput",
          staticClass: "custom-select__fake-input",
          attrs: { tabindex: "0" },
          on: { click: _vm.openDropdown },
        },
        [
          !_vm.isWithSearch
            ? _c(
                "span",
                {
                  class: {
                    "custom-select__title": true,
                    "custom-select__title--error": _vm.isError,
                    "custom-select__title--disabled": _vm.isDisabled,
                    "custom-select__title--medium-size": _vm.size === "medium",
                    "custom-select__title--transparent-bg": _vm.isTransparentBg,
                    "custom-select__title--right-not-rounded":
                      _vm.rightNotRounded,
                  },
                },
                [
                  _c("span", { staticClass: "custom-select__title-in-wrap1" }, [
                    _c(
                      "span",
                      { staticClass: "custom-select__title-in-wrap2" },
                      [
                        _vm.flagIcon &&
                        _vm.$store.countries.codeAlpha3FromName(_vm.activeValue)
                          ? _c("img", {
                              staticClass: "custom-select__flag-icon",
                              attrs: {
                                src: _vm.$store.countries.getFlagPathFromName(
                                  _vm.activeValue
                                ),
                                alt: _vm.activeValue,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.icon
                          ? _c("span", {
                              staticClass: "custom-select__title-icon",
                              domProps: { innerHTML: _vm._s(_vm.icon) },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeValue
                          ? _vm._t(
                              "default",
                              [
                                _c(
                                  "span",
                                  { staticClass: "custom-select__title-text" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.optionDescriptionDelimiter
                                            ? _vm.activeValue.split(
                                                _vm.optionDescriptionDelimiter
                                              )[0]
                                            : _vm.activeValue
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.activeValue &&
                                _vm.optionDescriptionDelimiter &&
                                _vm.activeValue.split(
                                  _vm.optionDescriptionDelimiter
                                )[1]
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "custom-select__title-option-description",
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.activeValue.split(
                                                _vm.optionDescriptionDelimiter
                                              )[1]
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              { option: _vm.activeValue }
                            )
                          : [_vm._v(_vm._s(_vm.placeholder))],
                      ],
                      2
                    ),
                  ]),
                ]
              )
            : _c("input-custom", {
                staticClass: "custom-select__search-field",
                attrs: {
                  placeholder: _vm.placeholder,
                  tabindex: 0,
                  "is-search": _vm.isWithMagnifierIcon,
                  "no-clear-button": true,
                },
                on: {
                  "real-change": _vm.validateNSetActive,
                  focus: function ($event) {
                    _vm.isOpened = true
                    _vm.$emit("focus", $event)
                  },
                  blur: function ($event) {
                    return _vm.$emit("blur", $event)
                  },
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function ($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery",
                },
              }),
          _vm._v(" "),
          _c(
            "fs-popper",
            {
              ref: "popperDropdownPart",
              attrs: {
                placement: "bottom-start",
                "no-styling": "",
                "close-on-outside-click-check": _vm.closeOnOutsideClickCheck,
              },
              on: {
                open: function ($event) {
                  _vm.isOpened = true
                  _vm.setOptionsListOffset()
                },
                close: function ($event) {
                  _vm.isOpened = false
                  _vm.optionsListOffset = 0
                },
              },
            },
            [
              _c(
                "div",
                {
                  class: {
                    "dropdown-part": true,
                    "dropdown-part--medium": _vm.size === "medium",
                    "dropdown-part--error": _vm.isError,
                    "custom-select__dropdown-part": true,
                  },
                  style: {
                    maxHeight: _vm.maxDropdownHeight + "px",
                    width: _vm.dropdownWidth + "px",
                    minWidth: _vm.dropdownWidth + "px",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.select.focus()
                    },
                  },
                },
                [
                  _vm.searchQuery && _vm.filteredOptionsList.length === 0
                    ? _c(
                        "div",
                        { staticClass: "dropdown-part__no-results-text" },
                        [_vm._v("\n          No such elements\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.filteredOptionsList.length > 8
                    ? _c(
                        "scrollable-block",
                        {
                          staticClass: "dropdown-part__scrollable-block",
                          style: {
                            height: _vm.maxDropdownHeight - 50 + "px",
                            minHeight: _vm.maxDropdownHeight - 50 + "px",
                            maxHeight: _vm.maxDropdownHeight - 50 + "px",
                          },
                          attrs: {
                            "shadows-color": "dark-gray",
                            "no-bottom-margin": "",
                            "active-element-offset": _vm.optionsListOffset,
                          },
                        },
                        [
                          _c(
                            "ul",
                            {
                              ref: "optionsListLong",
                              staticClass: "dropdown-part__options-list",
                            },
                            _vm._l(
                              _vm.filteredOptionsList,
                              function (option, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    class: {
                                      "dropdown-part__item": true,
                                      "dropdown-part__item--small-left-padding":
                                        !_vm.isCheckmarkShowed,
                                      "dropdown-part__item--active":
                                        _vm.compareAsComparable(
                                          option,
                                          _vm.localValue
                                        ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectOption($event, option)
                                      },
                                    },
                                  },
                                  [
                                    _vm.compareAsComparable(
                                      option,
                                      _vm.localValue
                                    ) && _vm.isCheckmarkShowed
                                      ? _c("span", {
                                          staticClass:
                                            "dropdown-part__selected-checkmark",
                                          domProps: {
                                            innerHTML: _vm._s(_vm.svg.selected),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._t(
                                      "default",
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.optionDescriptionDelimiter
                                                ? option.split(
                                                    _vm.optionDescriptionDelimiter
                                                  )[0]
                                                : option
                                            ) +
                                            "\n                "
                                        ),
                                        _vm.optionDescriptionDelimiter &&
                                        option.split(
                                          _vm.optionDescriptionDelimiter
                                        )[1]
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "dropdown-part__option-description",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      option.split(
                                                        _vm.optionDescriptionDelimiter
                                                      )[1]
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      { option: option }
                                    ),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.filteredOptionsList.length <= 8
                    ? _c(
                        "ul",
                        {
                          ref: "optionsListShort",
                          staticClass: "dropdown-part__options-list",
                        },
                        _vm._l(
                          _vm.filteredOptionsList,
                          function (option, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                class: {
                                  "dropdown-part__item": true,
                                  "dropdown-part__item--small-left-padding":
                                    !_vm.isCheckmarkShowed,
                                  "dropdown-part__item--active":
                                    _vm.compareAsComparable(
                                      option,
                                      _vm.localValue
                                    ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectOption($event, option)
                                  },
                                },
                              },
                              [
                                _vm.compareAsComparable(
                                  option,
                                  _vm.localValue
                                ) && _vm.isCheckmarkShowed
                                  ? _c("span", {
                                      staticClass:
                                        "dropdown-part__selected-checkmark",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.svg.selected),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._t(
                                  "default",
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.optionDescriptionDelimiter
                                            ? option.split(
                                                _vm.optionDescriptionDelimiter
                                              )[0]
                                            : option
                                        ) +
                                        "\n              "
                                    ),
                                    _vm.optionDescriptionDelimiter &&
                                    option.split(
                                      _vm.optionDescriptionDelimiter
                                    )[1]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "dropdown-part__option-description",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  option.split(
                                                    _vm.optionDescriptionDelimiter
                                                  )[1]
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  { option: option }
                                ),
                              ],
                              2
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.additionalActions.length
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "additional-actions-list dropdown-part__additional-actions-list",
                        },
                        _vm._l(_vm.additionalActions, function (action, index) {
                          return _c(
                            "li",
                            {
                              key: action.name,
                              class: {
                                "additional-action": true,
                                "additional-action--disabled":
                                  action.isDisabled,
                                "additional-actions-list__action": true,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickOnAdditionalAction(
                                    $event,
                                    action
                                  )
                                },
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "additional-action__icon",
                                domProps: { innerHTML: _vm._s(action.icon) },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "additional-action__text" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(action.name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.isWithSearch
            ? _c("span", {
                class: {
                  "custom-select__arrow": true,
                  "custom-select__arrow--disabled": _vm.isDisabled,
                  "custom-select__arrow--active": _vm.isOpened,
                },
                domProps: { innerHTML: _vm._s(_vm.svg.caretDown) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isWithSearch && _vm.searchQuery
            ? _c("span", {
                ref: "clearBtn",
                staticClass: "custom-select__clear-btn",
                domProps: { innerHTML: _vm._s(_vm.svg.crossIcon) },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clearSearchInput($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }