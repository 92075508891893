// We could use ResizeObserver here instead, but there are 2 downsides:
// 1) Triggered while in transition (animating) - which is typically not
// what we're looking for (and which can cause unexpected effects/"issues")
// 2) Browser-compatibility (IE11/Edge/Safare <13.1)

// One benefit of using ResizeObserver is that it triggers when something
// else triggers a resize - but that's rarely (no case yet?) what we're
// looking for. When that happens, we can easily add a choice using an arg.

import { windowResizeAdd, windowResizeRemove } from 'apps/public/lib/window-resize';

const _targets = [];

function _getTargetFromEl(el) {
  return _targets.find((target) => target.el === el);
}

function _onResize() {
  _targets.forEach(_check);
}

function _check(target) {
  const { el, instance, margin, property } = target;

  // See https://stackoverflow.com/a/22675563/856559 for details about why this is solid
  // Note that this rounds to whole integers. In practice, this shouldn't be a problem.
  instance[property] = el.scrollHeight <= el.clientHeight + margin;
}

function bind(el, binding, vnode) {
  const target = {
    el,
    instance: vnode.context,
    margin: parseInt(binding.arg) || 0,
    property: binding.expression,
  };

  _targets.push(target);

  if (_targets.length === 1) windowResizeAdd(_onResize);
}

function inserted(el) {
  const target = _getTargetFromEl(el);
  _check(target);
}

function unbind(el) {
  if (_targets.length === 1) windowResizeRemove(_onResize);

  const target = _getTargetFromEl(el);
  _targets.splice(_targets.indexOf(target), 1);
}

export default {
  bind,
  inserted,
  unbind,
};
